<template>
  <article class="h5_setting_view">
    <div class="setting_item d_flex_space" @click="goRouter('/language')">
      <p class="d_flex"><i class="h5_icon icon_set_list_language"></i> <span class="tx1">{{ $t('language') }}</span></p>
      <p class="d_flex"><span class="tx2 mr_5">{{langObj[storeLang].label}}</span><i class="h5_icon icon_gray_arrow"></i></p>
    </div>
    <div class="h10"></div>
    <div class="setting_item d_flex_space" @click="linkRouterFn('/loan_wallet')">
      <p class="d_flex"><i class="h5_icon icon_set_main_manage"></i> <span class="tx1">{{ $t('loan_wallet') }}</span></p>
      <p><i class="h5_icon icon_gray_arrow"></i></p>
    </div>
    <div class="h10"></div>
    <div class="setting_item d_flex_space" @click="linkRouterFn('/share_wallet')">
      <p class="d_flex"><i class="h5_icon icon_set_main_invite"></i> <span class="tx1">{{ $t('invite_friends') }}</span></p>
      <p><i class="h5_icon icon_gray_arrow"></i></p>
    </div>
    <div class="h10"></div>

    <div class="setting_item d_flex_space" @click="goRouter('/abouts')">
      <p class="d_flex"><i class="h5_icon icon_set_list_about"></i> <span class="tx1">{{ $t('about') }}</span></p>
      <p><i class="h5_icon icon_gray_arrow"></i></p>
    </div>
    <index-footer :index="4"></index-footer>
    <com-bind-wallet-id :isShow="isShowWallet" @on-hide="()=>{this.isShowWallet = false}"></com-bind-wallet-id>

  </article>
</template>

<script>
import indexFooter from '../../components/index_footer'
import comBindWalletId from '@/components/bind_wallet_id'
export default {
  name: "setting",
  components: {
    indexFooter,
    comBindWalletId
  },
  methods: {
    linkRouterFn (path, query = {}) {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      if (!this.storeChangeAddressId) {
        this.isShowWallet = true
        return false
      }
      this.goRouter(path, query)
    },
  }
}
</script>

<style lang="scss">
.h5_setting_view{
  .h10{
    width: 100%;
    height: 0.1rem;
    background: $BG2;
  }
  .setting_item{
    padding: 0.16rem;

    .tx1{
      font-size: 0.14rem;
      color: $T6;
      margin-left: 0.14rem;
    }
    .tx2{
      font-size: 0.12rem;
      color: $T7;
    }
    .icon_gray_arrow{
      width: 0.12rem;
      height: 0.12rem;
    }
  }
}
</style>