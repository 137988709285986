<template>
  <article class="h5_abouts_view">
    <div class="logo_main">
      <p><i class="h5_icon icon_logo_scf" style="width: 0.8rem; height: 0.7rem;"></i></p>
      <p class="tx1">SCF</p>
      <p class="tx2">V1.0.0</p>
      <p class="tx3">{{ $t('index_view.msg9') }}</p>
      <p class="tx4 d_flex_cen" @click="copyText('help@SCF.com')">

        <span class="color_T8">help@SCF.com</span>
        <i class="h5_icon icon_green_copy ml_5"></i>
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: "abouts"
}
</script>

<style lang="scss">
.h5_abouts_view{
  padding: 0.7rem 0.24rem;
  text-align: center;
  background: $BG4;
  min-height: 100vh;
  .logo_main{
    background: $FFF;
    border-radius: 0.12rem;
    padding: 0.12rem;
  }
  .icon_logo{
    background-image: url("../../assets/img/logo1.png");
    width: 1rem;
    height: 1rem;
    margin: 0.6rem 0 0rem;
  }
  .tx1{
    font-size: 0.26rem;
    font-weight: bold;
    color: $T6;
  }
  .tx2{
    font-size: 0.16rem;
    font-weight: bold;
    color: $T7;
  }
  .tx3{
    font-size: 0.2rem;
    padding:0.4rem 0 0.7rem;
    color: $T6;
    border-top: 1px solid $BR2;
    margin:0.4rem 0 0;
  }
  .tx4{
    font-size: 0.2rem;
    background: $BG2;
    line-height: 0.5rem;
    border-radius: 0.06rem;
  }
  .tx5{}

}
</style>