<template>
  <article class="h5_repayment_confirm_view">
    <p class="contract_tx d_flex"><span>{{ $t('contract_number') }}: {{ $route.query.id }}</span> <i class="h5_icon icon_copy ml_5" @click="copyText($route.query.id)"></i></p>

    <section class="assets_main">
      <div class="assets_box">
        <p class="tx2 tx2_br mb_10">{{ coin_data.code }} {{ $t('loan_details') }}</p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('loan_amount') }}</span>
          <span class="tx1">{{resData.borrow_amount ? resData.borrow_amount + ' ' + coin_data.code : '-'}} </span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('loan_period') }}</span>
          <span class="tx1">{{resData.borrow_days ? resData.borrow_days + ' ' + $t('day') : '-'}} </span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('daily_interest_rate') }}</span>
          <span class="tx1">{{resData.daily_rate ? resData.daily_rate : '-'}}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('interest_period') }}</span>
          <span class="tx1">{{resData.each_interest ? resData.each_interest + ' ' + coin_data.code  : '-'}} </span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('installments_paid') }}</span>
          <span class="tx1">{{resData.revert_phase ? resData.revert_phase + ' ' + $t('day') : '-'}}</span>
        </p>
        <p class="d_flex_space ">
          <span class="tx1">{{ $t('pending_period') }}</span>
          <span class="tx1">{{resData.surplus_days ? resData.surplus_days + ' ' + $t('day') : '-'}}</span>
        </p>
      </div>



    </section>

    <section class="assets_main">
      <div class="assets_box">
        <p class="tx2 tx2_br mb_10">{{ $t('early_payment_confirmation') }}</p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('loan_principal') }}</span>
          <span class="tx1">{{resData.borrow_amount ? resData.borrow_amount + ' ' + coin_data.code : '-'}} </span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('liquidated_damage_rate') }}</span>
          <span class="tx1">{{resData.violate_rate ? resData.violate_rate : '-'}}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('repayment_interest_fee') }}</span>
          <span class="tx1">{{resData.each_interest ? resData.each_interest + ' ' + coin_data.code  : '-'}} </span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('clear_contract_fee') }}</span>
          <span class="tx1">{{resData.clear_amount ? resData.clear_amount + ' ' + coin_data.code : '-'}} </span>
        </p>

        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('total_repayment') }}</span>
          <span class="tx3">{{resData.total_amount ? resData.total_amount  + ' ' + coin_data.code : '-'}}</span>
        </p>

      </div>

    </section>

    <p class="btn_input" @click="submitFn">{{$t('confirm')}}</p>

    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('coupon_view.msg12') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_5">{{ $t('coupon_view.msg11') }}</p>
            <p class="tx5 color_T6 mb_5">{{ $t('loan_coin_wallet') }}: {{substr(resData.wallet_addr, 0, 8) +'...'+ substr(resData.wallet_addr, -8)}}</p>
            <p class="tx5 color_T6">{{ $t('wallet_balance') }}：{{ resData.wallet_amount }} {{ coin_data.code }}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="isShowTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "repayment_confirm",
  data () {
    return {
      num: '',
      isShowTips: false,
      loadInfinite: false,
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isShowLoading: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      tabIndex: 2,
      resData: {},
      coin_data: {},
      balance: ''
    }
  },
  components: {
    comLoading,
  },
  created () {
    this.resInit()
  },
  mounted () {

  },
  methods: {
    async submitFn () {
      if (!this.$route.query.id) {
        return false
      }
      if (!this.resData.is_adequate) {
        this.isShowTips = true
        return false
      }
      let time = parseInt(new Date().getTime() / 1000) + '' + this.$route.query.id
      let sing = await this.dappSingFn(time)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }
      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')
      let req = {
        id: this.$route.query.id,
        features: time,
        signature: sing,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/create_prepayment_order`, req, 'post')
          .then(res => {
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')

            setTimeout(() => {
              this.isShowLoading = false
              // this.$router.replace({path: '/loan_order_details', query: {id: this.$route.query.id}})
              this.$router.go(-1)
            }, 2000)
          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/prepayment_details`, {
        id: this.$route.query.id
      }, 'get')
          .then(res => {
            this.resData = res ? res : {}

            this.coin_data = res.coin_data ? res.coin_data : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  },
}
</script>

<style lang="scss">
.h5_repayment_confirm_view{
  padding: 0.2rem 0.16rem;
  .contract_tx{
    color: $T7;
    font-size: 0.12rem;
    margin-bottom: 0.12rem;
  }
  .assets_main{
    padding: 0.16rem;
    background: $BG2;
    border-radius: 0.06rem;
    margin-bottom: 0.16rem;
    .tx1{
      font-size: 0.12rem;
      color: $T6;
    }
    .tx2{
      color: $T6;
      font-size: 0.14rem;
      font-weight: bold;
    }
    .tx3{
      color: $T8;
      font-size: 0.14rem;
      font-weight: bold;
    }
    .tx2_br{
      border-bottom: 1px solid $BR2;
      padding-bottom: 0.1rem;
    }

  }

  .btn_input{
    margin-top: 0.3rem;
    text-align: center;
    line-height: 0.44rem;
    color: $FFF;
    font-weight: bold;
    border-radius: 0.06rem;
    background: $T8;
    font-size: 0.16rem;
  }



}
</style>