<template>
  <article class="h5_invest_order_profit_details_view">
    <section class="profit_box">
      <div class="coin_box br_1">

        <div class="d_flex ">
          <div class="com_icon_img_box3">
            <img :src="coin_data.icon_url" class="com_coin_img"/>
            <i class="h5_icon com_icon_subscript" :class="coin_data.type == 'tron' ? 'icon_subscript_tron' : coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
          </div>
          <span class="tx1">{{ coin_data.code }}</span>
          <span class="tx3  " :class="coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[coin_data.type] }}</span></span>
        </div>
        <div>
          <span class="tx3 tx4 "><span class="fz_10 font_bold">{{ profitData.invest_days }} {{$t('day')}}</span></span>
          <span class="tx3 tx6"><span class="fz_10 font_bold">{{ profitData.daily_profit }}</span></span>
        </div>


      </div>
      <p class="tx2">
        <span>{{$t('investment_amount')}}</span>
        <span>{{ profitData.invest_amount }} {{ profitData.symbol }}</span>
      </p>
      <p class="tx2">
        <span>{{$t('daily_income')}}</span>
        <span>{{ profitData.each_profit }} {{ profitData.symbol }}</span>
      </p>
      <p class="tx2">
        <span>{{$t('number_replays')}}</span>
        <span>{{ profitData.repeat_times }} {{ $t('bout') }}</span>
      </p>
      <p class="tx2">
        <span>{{$t('cumulative_income')}}</span>
        <span>{{ profitData.total_profit }} {{ profitData.symbol }}</span>
      </p>
    </section>
    <p class="profit_tx">{{$t('income_details')}}</p>
    <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      <section class="profit_details" v-for="(list, index) in resData" :key="list.contract_number">
        <div class="tx_top">
          <p class="tx1">{{list.profit_type == 1 ? $t('loan_wealth_management_income') : $t('loan_wealth_management_income')}}</p>
          <p>
            <span class="tx2">{{ list.profit_amount }} {{ list.symbol }}</span>
          </p>
        </div>
        <div class="tx_bottom">
          <p class="tx6">
            <span>{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
          <p class="tx7">
            {{statusTxFn(list.status)}}
          </p>
        </div>
      </section>
    </div>

    <div class="com_no_data_box" v-show="!resData.length">
      <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
      <p class="com_no_data_tx">{{$t('nodata')}}</p>
    </div>
  </article>
</template>

<script>
export default {
  name: "invest_order_profit_details",
  data () {
    return {
      resData: [],
      profitData: {},
      coin_data: {},
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
    }
  },
  created () {
    this.profitInit()
    this.resInit()
  },
  methods: {
    statusTxFn (status) {
      if (status == 1) {
        return this.$t('issued')
      }
      if (status == 2) {
        return this.$t('extracted')
      }
    },
    resInit (type) {
      let req = {
        id: this.$route.query.id,
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/invest_profit_details_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    profitInit () {
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/my_invest_profit_details`, {
        id: this.$route.query.id
      }, 'get')
          .then(res => {
            this.profitData = res ? res : {}
            this.coin_data = res.coin_data ? res.coin_data : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_invest_order_profit_details_view{
  padding: 0.16rem;
  .profit_box{
    padding-bottom: 0.04rem;
    background: $BG2;
    margin-bottom: 0.24rem;
    border-radius: 0.06rem;
    .coin_box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.16rem 0;

      margin:0 0.16rem 0.16rem;
    }
    .br_1{
      border-bottom: 1px solid $BR2;
    }
    .tx1{
      font-size: 0.16rem;
      font-weight: bold;
      color: $T6;
      margin: 0 0.05rem 0 0;
    }
    .tx2{
      padding: 0 0.16rem 0.12rem;
      color: $T6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.12rem;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.04rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
      min-width: 0.36rem;
      text-align: center;
    }
    .tx4{
      color: $T12;
      background: $T11;
    }
    .tx5{
      color: $T14;
      background: $T13;
    }
    .tx6{
      margin-left: 0.1rem;
      color: $T5;
      background: $T1;
    }
  }
  .profit_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
    margin-bottom: 0.12rem;
  }
  .profit_details{
    background: $BG2;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    .tx_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
    }
    .tx_bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.12rem;
      color: $T7;
    }
    .tx1{
      font-size: 0.12rem;
      color: $T6;
    }
    .tx2{
      font-size: 0.12rem;
      color: $T8;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.03rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }
    .tx4{
      color: $T12;
      background: $T11;
    }
    .tx5{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
    .tx6{}
    .tx7{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon_gray_arrow{
        width: 0.12rem;
        height: 0.12rem;
      }
    }
  }
}
</style>