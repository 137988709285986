<template>
  <article class="h5_fin_chain_node_token_details_view">

    <section class="coin_details_box">
      <div class="item">
        <p class="tx1">{{ $t('voteActivityView.tips128') }}</p>
        <p class="tx2">{{ curData.title }}</p>
      </div>
      <div class="item">
        <p class="tx1">{{ $t('voteActivityView.subscription_progress') }}</p>
        <p class="tx2">{{ curData.gain_amount }} / {{ curData.subscribe_amount }}</p>
      </div>
      <div class="item" v-show="false">
        <p class="tx1">trx20 {{ $t('voteActivityView.payment_address') }}</p>
        <p class="tx2">
          <span>{{ walletChainTypeAddress['tron'] }}</span>
          <i class="h5_icon icon_origin_copy" @click="appCopy(walletChainTypeAddress['tron'])"></i>
        </p>
      </div>
      <div class="item" v-show="false">
        <p class="tx1">bep20 {{ $t('voteActivityView.payment_address') }}</p>
        <p class="tx2">
          <span>{{ walletChainTypeAddress['bsc'] }}</span>
          <i class="h5_icon icon_origin_copy" @click="appCopy(walletChainTypeAddress['bsc'])"></i>
        </p>
      </div>
      <div class="item">
        <p class="tx1">{{ $t('created_time') }}</p>
        <p class="tx2">{{ dataFormat(curData.created_at, 'yyyy-MM-dd', false) }}</p>
      </div>
    </section>
    <section class="coin_info">
      <p class="tx1">{{ $t('voteActivityView.tips129') }}</p>
    </section>
    <div class="introduction" v-show="curData?.introduction">
      <p class="tx2">
        {{ curData.introduction }}
      </p>
    </div>
    <div class="btn_box mt_40" v-show="curData.status == 2">
      <input type="button" @click="voteFn" class="input_btn" :class="curData.status == 2 ? 'input_btn_cur' : ''"
        :value="$t('voteActivityView.subscription')" />
    </div>

    <div class="fth_record_main" v-show="resData.length">
      <p class="title_tx">{{ $t('voteActivityView.tips104') }}</p>
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50"
        :infinite-scroll-immediate="false">
        <div class="fth_item" v-for="(list, index) in resData" :key="index">
          <p class="item_t d_flex_space">
            <span class="tx14 ">{{ list.tx_type == 29 ? $t('voteActivityView.tips105') : list.tx_type == 32 ?
              $t('voteActivityView.tips107') : $t('voteActivityView.tips106') }}</span>
            <span class="tx14  font_bold">{{ list.amount }} {{ list.symbol }}</span>
          </p>
          <p class="tx13 mb_10">{{ $t('receiveActivity.tip26') }}: {{ substr(list.user_id, 0,
            12) }}...{{ substr(list.user_id, -12) }}</p>
          <p class="tx13 ">{{ $t('times_1') }}: {{ dataFormat(list.created_at, 'yyyy-MM-dd hh:mm:ss', false) }}</p>
        </div>
      </div>

    </div>

    <transition>
      <article class="com_popup_main" v-show="isVote">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('voteActivityView.tips146') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.tips147') }}</p>
              <p class="right_box d_flex">{{ curData.title }}</p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.payment_address') }}</p>
              <p class="right_box">
                <span>{{ Wallet_Uuid }}</span><i class="h5_icon icon_origin_copy" @click="appCopy(Wallet_Uuid)"></i>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.tips103') }}</p>
              <p class="right_box">
                <span>{{ curData.user_id }}</span><i class="h5_icon icon_origin_copy"
                  @click="appCopy(curData.user_id)"></i>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.subscription_progress') }}</p>
              <p class="right_box d_flex">{{ curData.gain_amount }} / {{ curData.subscribe_amount }}</p>
            </div>
            <div class="com_popup_from_box">
              <!-- <div class="com_popup_input_box mb_10" :class="errorTx ? 'com_popup_input_error' : ''">
                <div>
                  <input type="number" pattern="[0-9]*" v-model="num" class="com_popup_input_tx"
                    :placeholder="$t('voteActivityView.placeholder_tips2')" />
                </div>
                <div class="error_tx" v-show="errorTx">{{ errorTx }}</div>
              </div> -->

              <!-- <div class="tx1 mb_20" v-if="coinIdObj[coinCur]">
                <span class="color_T14 mr_5">{{ $t('voteActivityView.wallet_balance') }}：<span class="color_T11">{{
                  numFormat(coinIdObj[coinCur].balance) }}
                    {{ coinIdObj[coinCur].coin }}</span></span>
              </div> -->

              <p class="mb_10 d_flex" >
                <i class="h5_icon mr_5" :class="!isCash ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                <span class="tx18 font_bold">{{ $t('voteActivityView.tips86') }}</span>
              </p>

              <div class="com_popup_input_box mb_10" :class="errorCashTx ? 'com_popup_input_error' : ''">
                <div>
                  <input type="number" pattern="[0-9]*" v-model="cashNum" class="com_popup_input_tx"
                    :placeholder="$t('voteActivityView.tips87')" />
                </div>
                <div class="error_tx" v-show="errorCashTx">{{ errorCashTx }}</div>
              </div>

              <div class="tx1 mb_10" >
                <span class="color_T14 mr_5">{{ $t('voteActivityView.cash_coupons') }}：<span class="color_T11">{{
                  cashBalance }} USDT</span></span>
              </div>

              <div class="com_popup_btn_box">
                <!-- <button class="com_popup_input_btn" :class="(cashNum != '' && +cashNum >= 100) ? 'com_popup_input_btn_cur' : ''" @click="voteSubmitFn" :disabled="(cashNum == '' || +cashNum < 100) ? true : false" style="border: 0;">{{ $t('voteActivityView.subscription') }}</button> -->
                <input type="button" class="com_popup_input_btn" :class="cashNum ? 'com_popup_input_btn_cur' : ''" :value="$t('voteActivityView.subscription')" @click="voteSubmitFn"/>
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <transition>
      <article class="com_popup_main" v-show="isShowCoin">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('payment_type') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="coin_box">
              <div class="coin_item" v-for="(list, index) in coinIdObj" :key="index" @click="checkCoinFn(index)">
                <div class="coin_l">
                  <img :src="coinImgObj[list.coinId]" class="coin_img" />
                  <div class="">
                    <p class="coin_tx">
                      <span class="coin_tx1">{{ list.coin }}</span>
                      <span class="fz_10"><span class="coin_tx2"
                          :class="list.chain_type == 'tron' ? 'coin_tx3' : list.chain_type == 'bsc' ? 'coin_tx4' : 'coin_tx5'">{{
                            coinTxType(list.coinId) }}</span></span>
                    </p>
                    <p class="coin_balance">{{ $t('balance') }}: {{ cashBalance }}</p>
                  </div>
                </div>

                <div class="coin_r">
                  <i class="h5_icon" :class="coinCur == index ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                </div>
              </div>

            </div>
            <div class="com_popup_from_box">
              <div class="com_popup_btn_box">
                <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('next')"
                  @click="nextVoteFn" />
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <com-loading :isShow="isShowLoading" text="loading"></com-loading>

  </article>
</template>
  
<script>
import comLoading from "@/components/loading";
export default {
  name: "fin_chain_node_token_details",
  components: {
    comLoading
  },
  data() {
    return {
      isShowLoading: false,
      Wallet_Uuid: '',
      iosAPPDown: "",
      isVote: false,
      errorTx: '',
      num: '',
      isShowCoin: false,
      coinCur: 0,
      coinImgObj: {},
      coinList: [],
      curData: {},
      coinIdObj: {},
      addressData: {},
      walletAddress: '',
      nodeData: {},
      cashNum: '',
      errorCashTx: '',
      cashBalance: 0,
      isCash: false,
      resData: [],
      page: 1,
      size: 30,
      total: 1,
      loadInfinite: false,
      isLoad: false,
      walletChainTypeAddress: {
        'tron': '',
        'bsc': '',
        'eth': '',
      },
      isuid: false,
      isGetAuth: false,
    };
  },
  beforeUnmount() {
    window.Wallet_Uuid = null
  },
  created() {
    this.curData = window.sessionStorage.getItem('listData') ? JSON.parse(window.sessionStorage.getItem('listData')) : {}
    this.Wallet_Uuid = window.Wallet_Uuid
    this.resInit()
    this.cashBalanceFn()
    this.resNodeInfoFn()
  },
  mounted() {
  },
  methods: {
    resInit(type) {
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      let req = {
        node_id: this.curData.id,
        page: this.page,
        size: this.size,
      }
      if (this.searchTx) {
        req.title = this.searchTx
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}super/super_fth_record`, req, 'get')
        .then(res => {
          this.isLoad = false
          let arr = res.list ? res.list : []
          if (arr.length < this.size) {
            this.loadInfinite = true
          }

          this.page = this.page + 1
          if (type == 'search') {
            this.resData = arr
          } else {
            this.resData = this.resData.concat(arr)
          }
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.isLoad = false
          this.messageFn(res.msg)
        })
    },
    cashBalanceFn() {
      let req = {

      }
      this.$ajax(`${this.Utils.URL.goAPPURL}super/ticket_num`, req, 'get')
        .then(res => {
          this.cashBalance = res.quantity ? res.quantity : 0
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    resNodeInfoFn() {
      let req = {

      }
      this.$ajax(`${this.Utils.URL.goAPPURL}fin/info`, req, 'get')
        .then(res => {
          this.nodeData = res ? res : {}
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    hideFn() {
      this.isVote = false
      this.isShowCoin = false
      this.errorCashTx = ''
      this.errorTx = ''
    },
    coinTxType(tx) {
      if (tx) {
        return ((tx.split('|'))[0]).toLocaleUpperCase()
      }
      return tx ? tx.toLocaleUpperCase() : tx
    },
    checkCoinFn(index) {
      this.coinCur = index
      this.walletAddress = this.walletChainTypeAddress[this.coinIdObj[this.coinCur].chain_type]
    },
    voteSubmitFn() {
      // let num = this.num ? parseFloat(this.num) : 0
      let cashNum = this.cashNum ? parseFloat(this.cashNum) : 0
      let cashBalance = this.cashBalance ? parseFloat(this.cashBalance) : 0
      let min_subscribe = parseFloat(this.nodeData.min_subscribe)
      let subscribe_amount = parseFloat(this.curData.subscribe_amount) - parseFloat(this.curData.gain_amount)

      
      let req = {
        // title: this.curData.title,
        introduction: '',
        area: '',
        node_id: this.curData.id + '|' + this.cashNum,
        chain_type: 'bsc',
        receive_address: this.walletAddress, // cold wallet
        sender_address: this.curData.user_id
      }

      this.errorCashTx = ''

      if (!window.Wallet_Uuid) {
        this.messageFn(this.$t('voteActivityView.tips85'))
        return false
      }
      if (this.curData.status == 2) {
        if (cashNum > subscribe_amount) {
          this.errorTx = this.$t('voteActivityView.error_tips4')
          return false
        }

        if (subscribe_amount >= min_subscribe) {
          if (cashNum < min_subscribe) {
            this.errorCashTx = this.$t('voteActivityView.error_tips1_1') + min_subscribe +' USDT'
            return false
          }
        } else {
          if (cashNum != subscribe_amount) {
            this.errorCashTx = this.$t('voteActivityView.error_tips1_1') + subscribe_amount +' USDT'
            return false
          }
        }
        if (this.isCash) {
          if (cashNum < 1 || !cashNum) {
            this.errorCashTx = this.$t('voteActivityView.tips89')
            return false
          }
          if ((this.cashNum + '').indexOf('.') >= 0) {
            this.errorCashTx = this.$t('voteActivityView.error_tips2')
            return false
          }
          if (cashNum > cashBalance) {
            this.errorCashTx = this.$t('voteActivityView.tips88')
            return false
          }
        }

      }
      
      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}super/subscribe/ticket`, req, 'post')
          .then(res => {
            if (res.result) {
              this.messageFn(this.$t('success'))
              this.isVote = false
              this.$router.push('/node_index_fin_chain')
            } else {
              this.messageFn(res.msg)
            }
            this.isShowLoading = false
          })
          .catch(res => {
            this.isShowLoading = false
            this.messageFn(res.msg)
          })
      return false

    },
    voteFn() {
      if (!window.Wallet_Uuid) {
        this.messageFn(this.$t('voteActivityView.placeholder_tips7'))
        return false
      }
      // if (!this.addressData.chain_type) {
      //   this.messageFn(this.$t('voteActivityView.placeholder_tips8'))
      //   return false
      // }
      if (this.nodeData.period_end <= 0) {
        this.messageFn(this.$t('voteActivityView.error_tips6'))
        return false
      }
      if (this.curData.status == 2) {
        this.num = ''
        this.cashNum = ''
        this.isVote = true
        this.resWalletAddressFn('bsc')
        return false
      }
    },
    nextVoteFn() {
      this.resWalletAddressFn('bsc')
      this.isVote = true
      this.isShowCoin = false
    },
    getCoinList() {
      let req = {
        symbol: 'USDT',
        // is_recommend: true,
        page: 1,
        size: 100,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}coin`, req, 'get')
        .then(res => {
          let arr = res.list ? res.list : []
          arr.forEach(val => {
            if (val.chain_type == 'bsc') {
              this.coinImgObj[val.front_coin_id] = val.icon_url
              this.coinIdObj[val.front_coin_id] = {}
              if (!this.coinCur) {
                this.coinCur = val.front_coin_id
              }
              this.Utils.tool.app.getTokenBalance({ address: val.contract_addr, jsCallBack: 'getTokenBalance' })
            }

          })
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    resWalletAddressFn(chainType) {
      let req = {
        chain_type: 'bsc',
        node_id: this.curData.id,
        type: 6,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}get_cold_wallet`, req, 'get')
        .then(res => {

          this.walletChainTypeAddress[chainType] = res.addr
          this.walletAddress = res.addr
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
  },
};
</script>
  
<style lang="scss">
.h5_fin_chain_node_token_details_view {

  min-height: 100vh;
  padding: 0.24rem 0.16rem;
  background: linear-gradient(180deg, #FFA700 0%, #F86A32 100%);

  .com_loading_box {
    background: rgba(7, 22, 56, 1);

    .loading_tx {
      color: rgba(220, 229, 250, 1);
    }

    .icon_loading {
      background-image: url("../../assets/img/icon/loading_origin.png");
      width: 0.7rem;
      height: 0.7rem;
    }
  }

  .com_popup_main {

    .com_popup_box {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      z-index: 3;
      background: white;
      border-radius: 0.2rem 0.2rem 0 0;

      .com_popup_head {
        padding: 0.16rem;
        background: rgba(255, 255, 255, 0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border-bottom-color: #454545;

        .title_tx {
          font-size: 0.16rem;
          font-weight: bold;
          color: #454545;
        }

        .h5_icon {
          position: absolute;
          top: 20px;
          right: 20px;
          filter: invert(1);
        }
      }

      .com_popup_info_box {
        padding: 0.24rem 0.16rem;

        .item {
          display: flex;
          justify-content: space-between;
          font-size: 0.14rem;
          margin-bottom: 0.2rem;

          .left_box {
            color: #454545;

          }

          .right_box {
            max-width: 2.6rem;
            color: #454545;
            text-align: right;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }

      .com_popup_from_box {

        padding-top: 0.2rem;

        .tx1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.14rem;
        }

        .com_popup_input_box {
          border-radius: 0.12rem;
          background: #F1F1F1;
          padding: 0.12rem;

          .com_popup_input_tx {

            height: 0.3rem;
            font-size: 0.16rem;
            color: #454545;

            width: 100%;

          }

        }

        .com_popup_input_error {
          background: rgba(224, 49, 93, 0.05);
        }

        .error_tx {
          margin-top: 0.05rem;
          font-size: 0.12rem;
          color: $T9;
        }

        .com_popup_btn_box {
          .com_popup_input_btn {
            height: 0.44rem;
            border-radius: 0.08rem;
            text-align: center;
            background: #A9A9A9;
            color: white;
            font-size: 0.16rem;
            font-weight: bold;
            width: 100%;
            margin-top: 0.36rem;
          }

          .com_popup_input_btn_cur {
            background: linear-gradient(180.07deg, #F59428 0.06%, #F86A32 99.94%);
            color: $FFF;
          }
        }
      }
    }
  }

  .coin_box {

    .coin_item:last-child {
      margin-bottom: 0;
    }

    .coin_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.2rem;

      .coin_l {
        display: flex;
        align-content: center;

        .coin_img {
          width: 0.4rem;
          height: 0.4rem;
          margin-right: 0.1rem;
        }

        .coin_tx {
          display: flex;
          align-items: center;
          margin-bottom: 0.03rem;
        }

        .coin_tx1 {
          font-size: 0.16rem;
          font-weight: bold;
          color: rgba(220, 229, 250, 1);
          margin-right: 0.1rem;
        }

        .coin_tx2 {
          padding: 0.02rem 0.04rem;
          border-radius: 0.08rem;
          font-size: 0.12rem;
          font-weight: bold;
        }

        .type_tx1 {
          font-size: 0.18rem;

        }

        .coin_tx3 {
          background: rgba(197, 48, 39, 0.1);
          color: $T9;
        }

        .coin_tx4 {
          background: rgba(244, 187, 11, 0.1);
          color: $T8;
        }

        .coin_tx5 {
          background: rgba(73, 115, 145, 0.1);
          color: rgba(90, 142, 178, 1);
        }

        .coin_balance {
          font-size: 0.12rem;
          color: $T7;
        }
      }
    }
  }

  .coin_details_box {
    background: white;
    border-radius: 0.12rem;
    padding: 0.14rem 0.16rem;

    .item:last-child {
      margin-bottom: 0;
    }

    .item {
      display: flex;
      justify-content: space-between;
      font-size: 0.14rem;
      margin-bottom: 0.2rem;

      .tx1 {
        // color: $T7;
        color: #454545;
      }

      .tx2 {
        text-align: right;
        // color: rgba(220, 229, 250, 1);
        color: #EE9922;
        max-width: 2.2rem;
        word-wrap: break-word;
        word-break: break-all;

      }
    }
  }

  .coin_info {
    padding: 0.2rem 0.16rem;
    // background: rgba(255, 255, 255, 0.06);
    background: white;
    border-radius: 0.12rem;
    margin-top: 0.16rem;
    display: flex;
    place-items: center;
    justify-content: center;

    .tx1 {
      text-align: center;
      color: #454545;
      font-size: 0.16rem;
      font-weight: bold;
    }

    .tx2 {
      color: #EE9922;
      word-wrap: break-word;
      word-break: break-all;
      line-height: 1.8;
    }
  }

  .tips_tx {
    text-align: center;
    margin: 0.32rem 0;
    color: $T7;
    font-size: 0.2rem;
    font-weight: bold;
  }

  .btn_box {


    width: 100%;

    .input_btn {
      height: 0.44rem;
      border-radius: 0.22rem;
      background: linear-gradient(0deg, #FF4F00 -25%, #FF8310 125%);
      text-align: center;
      color: white;
      font-size: 0.16rem;
      font-weight: bold;
      width: 100%;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.309804);
    }

    .input_btn_cur {
      background: linear-gradient(0deg, #FF4F00 -25%, #FF8310 125%);
      color: $FFF;
    }
  }

  .fth_record_main {
    margin-top: 0.28rem;
    color: white;

    .title_tx {
      font-weight: bold;
      font-size: 0.14rem;
      margin-bottom: 0.1rem;
    }

    .fth_item {
      padding: 0.14rem 0.16rem;
      background: rgba(255, 255, 255, 0.06);
      border-radius: 0.12rem;
      margin-bottom: 0.12rem;
    }

    .item_t {
      padding-bottom: 0.14rem;
      margin-bottom: 0.14rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    }
  }

  .introduction {
    padding: 0.2rem;
    background: white;
    border-radius: 0.09rem;
    margin-top: 0.2rem;
  }
}
</style>