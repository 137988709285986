<template>
  <article class="h5_vote_fin_chain_node_record_view">
    <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      <section class="item_box" v-for="(list, index) in resData" :key="index">
        <div class="t_box d_flex">
          <p class="tx1">{{list.title}}</p>
          <p class="tx4">{{dataFormat(list.created_at, 'yyyy-MM-dd hh:mm:ss', false)}}</p>
        </div>
        <div class="t_box d_flex">
          <!-- <p class="tx2">{{list.subscribe_amount - list.ticket_num}} {{list.coin_symbol}} <span v-show="list.ticket_num > 0"> + {{list.ticket_num}} {{$t('voteActivityView.cash_coupons')}}</span></p> -->
          <p class="tx2"><span v-show="list.ticket_num > 0"> {{list.ticket_num}} {{$t('voteActivityView.cash_coupons')}}</span></p>
          <p class="tx3 " :class="list.status == 1 ? 'color_T11' : list.status == 2 ? 'color_T12' : 'color_T13' ">{{list.status == 1 ? $t('voteActivityView.confirming') : list.status == 2 ? $t('voteActivityView.success') : $t('voteActivityView.fail')}}</p>
        </div>
        <div class="hash_box ">
          <p class="mr_5">Hash:</p>
          <p>
            <span class="hx_tx">{{list.tx_id}}</span>
            <i class="h5_icon icon_origin_copy" @click="appCopy(list.tx_id)"></i>
          </p>

        </div>
        <p class="b_box d_flex" @click="linkOpenFn(list)"><span>{{ $t('voteActivityView.query_transaction') }}</span><i class="h5_icon h5_arrow1_right"></i></p>
        </section>
    </div>


    <div class="com_no_data_box" v-show="resData.length == 0">
      <p class="tx_cen"><img src="../../assets/img/icon/no_data1-02.svg" class="com_no_data_img"></p>
      <p class="tx_cen com_no_data_tx1">{{ $t('nodata') }}</p>
    </div>
  </article>
</template>

<script>
export default {
  name: "vote_fin_chain_node_record",
  data() {
    return {
      resData: [],
      page: 1,
      size: 30,
      total: 1,
      loadInfinite: false,
      isLoad: false,
      isuid: false,
      isGetAuth: false,
      Wallet_Uuid: '',
    };
  },
  created() {
    this.resInit()
  },
  mounted() {
  },
  methods: {
    linkOpenFn (list) {
      let url = ''
      if (list.chain_type == 'tron') {
        url = 'https://www.tronscan.org/#/transaction/' + list.tx_id
      } else if (list.chain_type == 'eth') {
        url = 'https://etherscan.io/tx/' + list.tx_id
      } else {
        url = 'https://bscscan.com/tx/' + list.tx_id
      }
      this.goRouter(url, {}, true)
    },
    // getAuth (res) {
    //   if (!this.isGetAuth) {
    //     window.xxx_Authentication = res
    //     this.resInit()
    //   }
    //   this.isGetAuth = true
    // },
    // getUUID (res) {
    //   window.Wallet_Uuid = res
    //   if (res) {
    //     this.Wallet_Uuid = res
    //     window.Wallet_Uuid = res
    //     this.Utils.tool.app.getAuth({ jsCallBack: 'getAuth' })
    //     setTimeout(() => {this.getAuth()}, 200)
    //   } else {
    //     this.messageFn(this.$t('voteActivityView.placeholder_tips7'))
    //     return false
    //   }
    // },
    async resInit () {
      if (!window.Wallet_Uuid) {
        return false
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      let req = {
        page: this.page,
        size: this.size,
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}fin/subscribe_record`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []
            if (arr.length < 30) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            this.resData = this.resData.concat(arr)
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    }
  },
};
</script>

<style lang="scss">
  .h5_vote_fin_chain_node_record_view{
    background: linear-gradient(180deg, #FFA700 0%, #F86A32 100%);
    min-height: 100vh;
    padding: 0.16rem;
    .com_no_data_tx1 {
      color: #dce5fa;
    font-weight: 700;
    font-size: .16rem;
    }
    .item_box{
      padding: 0.14rem 0.16rem;
      background: white;
      margin-bottom: 0.12rem;
      border-radius: 0.12rem;
      .t_box{
        justify-content: space-between;
        margin-bottom: 0.1rem;
        .tx1{
          font-size: 0.13rem;
          color: #454545;
        }
        .tx4{
          font-size: 0.13rem;
          color: $T7;
        }
        .tx2{
          font-size: 0.16rem;
          color: #F35323;
          font-weight: bold;
        }
        .tx3{
          font-size: 0.14rem;

        }

      }
      .hash_box{
        display: flex;
        font-size: 0.13rem;
        color: #929292;
        padding: 0 0 0.05rem;
        border-bottom: 1px solid #C0C0C0;
        .hx_tx{

          word-break: break-word;
        }
        .mr_5 {
          color: #454545;
        }
      }
      .b_box{
        justify-content: space-between;
        font-size: 0.13rem;
        color: #585858;
        padding: 0.07rem 0 0;
      }

    }
  }
  .color_T12 {
    color: #009a47;
  }
  .color_T11 {
    color: #e92;
  }
  .color_T13 {
    color: #e32f2f
}
</style>
