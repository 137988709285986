<template>
  <article class="h5_fsp_trading_swap_record_view">
    <div class="container">
      <div class="com_scroll" v-infinite-scroll="swapRecordFn" :infinite-scroll-distance="50"
        :infinite-scroll-immediate="false">
        <div class="box_record_exchange">
          <div v-if="list_record.length > 0">
            <div class="item" v-for="(item, index) in list_record" :key="index">
              <div class="top">
                <p class="date">{{ dataFormat(item.time, 'yyyy/MM/dd hh:mm:ss', false) }}</p>
                <p class="hyperlink" :class="item.status == 1 ? 'review' : item.status == 3 ? 'fail' : 'success'"
                  @click="openLinkFn(item.tx_id)">
                  <span>{{ item.status == 1 ? $t('fsp_trading.msg10') : item.status == 3 ? $t('fsp_trading.msg11') : $t('fsp_trading.msg12') }}</span>
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 9.5L5 5L0.5 0.5" stroke="#EE9922" stroke-linecap="round" />
                  </svg>
                </p>
              </div>
              <div class="box_currency">
                <div class="inner">
                  <p class="left">
                    <span class="icon">
                      <img :src="item.pay_coin.icon_url" alt="">
                    </span>
                    <span class="currency">{{ item.pay_coin.code }}</span>
                    <span class="tag">BEP20</span>
                  </p>
                  <p class="arrow">
                    <img src="../../assets/img/icon/icon_right_exchange.png" alt="">
                  </p>
                  <p class="right">
                    <span class="icon">
                      <img :src="item.rec_coin.icon_url" alt="" style="width: 0.25rem;">
                    </span>
                    <span class="currency">{{ item.rec_coin.code }}</span>
                    <span class="tag">BEP20</span>
                  </p>
                </div>
              </div>
              <div class="box_amount">
                <p>{{ item.pay_amt }}</p>
                <p>{{ item.rec_amt }}</p>
              </div>
            </div>
          </div>
          <div class="com_no_data_box" v-else>
            <p class="tx_cen"><img src="../../assets/img/icon/no_data.svg" class="com_no_data_img"></p>
            <p class="tx_cen com_no_data_tx">{{ $t('nodata') }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>
  <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
</template>
<script>
import comLoading from '../../components/loading/index'
export default {
  name: 'FspTradingSwapRecordView',
  components: {
    comLoading,
  },

  data() {

    return {
      tabIndex: 1,
      isShowLoading: false,
      status: 'loading',
      text: '',
      isGetAuth: false,
      page: 1,
      size: 10,
      loadOrderInfinite: false,
      isOrderLoad: false,
      Wallet_Uuid: '',
      isuid: false,
      isAjax: false,
      list_record: []
    };
  },
  beforeUnmount() {
    window.getUUID = null
    window.getAuth = null
    window.xxx_Authentication = null
  },
  created() {
    window.getUUID = this.getUUID
    window.getAuth = this.getAuth
    this.getLangFn().then((res) => {
      this.Utils.tool.app.setHeader({ title: this.$t('fsp_trading.msg65') });
      this.Utils.tool.app.getUUID({ jsCallBack: 'getUUID' })
      setTimeout(() => {
        if (this.Wallet_Uuid == '' && !this.isuid) {
          this.messageFn(this.$t('community_view.msg_190'))
        }
      }, 2000)
    });
    
    // example Wallet_Uuid
    // this.getUUID('ID62522fabb167717a7d7ee4854e585dd3846e0892')
    // this.getUUID('ID1cf9118141e9ccee214190f6a15e476c87abbcc8') // joseph wallet
    if (this.$route.path !== '/fsp_trading_swap_record') {
      window.location.href = '/fsp_trading_swap_record'
    }
  },
  
  methods: {
    openLinkFn(hash) {
      let url = ''
      if (!hash) {
        return false
      }
      url = 'https://bscscan.com/tx/' + hash // production
      // url = 'https://testnet.bscscan.com/tx/' + hash // dev
      this.goRouter(url, {}, true)
    },
    getAuth (res) {
      if (!this.isGetAuth) {
        window.xxx_Authentication = res
        this.swapRecordFn()
      }
      this.isGetAuth = true
    },
    getUUID(res) {
      if (res) {
        this.Wallet_Uuid = res
        window.Wallet_Uuid = res
        this.isuid = true
        this.Utils.tool.app.getAuth({ jsCallBack: 'getAuth' })
        setTimeout(() => {this.getAuth()}, 200)
      } else {
        this.messageFn(this.$t('voteActivityView.placeholder_tips7'))
        return false
      }
    },
    async swapRecordFn() {
      try {
        // this.isShowLoading = true
        let req = {
          page: this.page,
          size: this.size,
          chain_type: 'bsc',
          coin: 'FSP',
        }
        if (this.loadOrderInfinite) {
          // this.isShowLoading = false
          return false
        }
        if (this.isOrderLoad) {
          return false
        }
        this.isOrderLoad = true
        let res = await this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/swap_record_fsp`, req, 'get')
        // this.isShowLoading = false

        this.isOrderLoad = false
        let arr = res.list ? res.list : []
        if (arr.length < this.size) {
          this.loadOrderInfinite = true
        }
        this.page = this.page + 1
        this.list_record = this.list_record.concat(arr)

      }
      catch (error) {
        console.log('error', error)
      }
    },

  },
};
</script>
<style lang="scss" scoped>
.h5_fsp_trading_swap_record_view {
  padding-bottom: 0.2rem;
  overflow: hidden;

  .container {
    padding: 0.06rem 0.16rem 0.12rem;
  }

  .box_record_exchange {
    margin-top: 0.2rem;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.1rem;

      .text {
        font-size: 0.15rem;
        color: #224455;
        font-weight: 600;
      }

      span {
        margin-right: 0.05rem;
      }

      .link {
        display: flex;
        align-items: center;
        color: #EE9922;
        font-size: 0.13rem;

        img {
          width: 0.06rem;
        }
      }
    }

    .item {
      background: #F7F7F7;
      border-radius: .06rem;
      padding: 0.1rem 0.16rem;
      margin-bottom: 0.08rem;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date {
          font-size: 0.13rem;
          color: #224455;
        }

        .hyperlink {
          display: flex;
          align-items: center;

          span {
            font-size: 0.13rem;
            margin-right: 0.05rem;
            font-weight: 500;
          }

          &.review {
            span {
              color: #EE9922;
            }

            svg {
              path {
                stroke: #EE9922;
              }
            }
          }

          &.fail {
            span {
              color: #DD4466;
            }

            svg {
              path {
                stroke: #DD4466;
              }
            }
          }

          &.success {
            span {
              color: #778E99;
            }

            svg {
              path {
                stroke: #778E99;
              }
            }
          }
        }
      }

      .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.15rem;

        .left {
          display: flex;
          align-items: center;

          .icon {
            line-height: 0;

            img {
              width: 0.3rem;
            }
          }

          .currency {
            font-size: 0.16rem;
            font-weight: 500;
            color: #224455;
            text-transform: uppercase;
            margin-left: 0.05rem;
          }

          .tag {
            font-size: 0.1rem;
            color: #F4BB0B;
            background: #F4BB0B1A;
            text-transform: uppercase;
            line-height: 0.16rem;
            padding: 0 0.06rem;
            border-radius: 0.1rem;
            font-weight: 500;
            margin-left: 0.06rem;
          }
        }

        .right {
          display: flex;
          align-items: center;

          .icon {
            line-height: 0;

            img {
              width: 0.3rem;
            }
          }

          .currency {
            font-size: 0.16rem;
            font-weight: 500;
            color: #224455;
            text-transform: uppercase;
            margin-left: 0.05rem;
          }

          .tag {
            font-size: 0.1rem;
            color: #F4BB0B;
            background: #F4BB0B1A;
            text-transform: uppercase;
            line-height: 0.16rem;
            padding: 0 0.06rem;
            border-radius: 0.1rem;
            font-weight: 500;
            margin-left: 0.06rem;

            &.main {
              background: #1BA27A1A;
              color: #1BA27A;
              text-transform: capitalize;
            }
          }
        }

        .arrow {
          line-height: 0;

          img {
            width: 0.16rem;
          }
        }
      }

      .box_amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.15rem;

        p {
          font-size: 0.16rem;
          font-weight: 600;
          color: #224455;
        }
      }
    }
  }

  .box_record_exchange_liquidity {

    .item {
      .top {
        .hyperlink {
          color: #EE9922;
        }
      }

      .inner {
        .icon {
          line-height: 0;

          img {
            // width: 0.3rem;
            width: 0.2rem;
            margin-right: 0.05rem;
          }
        }

        .pair_currency {
          display: flex;
          align-items: center;
          flex: 1;
          font-size: 0.16rem;
          color: #224455;
          font-weight: 500;
          padding-left: 0.05rem;

          .pl_10 {
            padding-left: 0.1rem;
          }

          .pl_20 {
            padding-left: 0.20rem;
          }
        }
      }

      .box_status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.14rem;
        color: #224455;
        margin-top: 0.1rem;

        .status {
          font-weight: 600;
          padding-right: 0.1rem;
          font-size: 0.13rem;

          &.removed {
            color: #DD4466;
          }

          &.removing {
            color: #FFA94D;
          }

          &.added {
            color: #1BA27A;
          }
        }

        .flex {
          flex: 1;
          display: flex;
          align-items: center;
          line-height: .18rem;

          .amount_exchange {
            padding-left: 0.1rem;
            margin-left: 0.15rem;
            border-left: 1px solid #B1BDC2;
          }
        }
      }
    }
  }
}

.box_nav {
  display: flex;
  align-items: center;
  position: relative;
  height: 0.25rem;
  text-align: center;

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  .title {
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    color: #17233d;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>