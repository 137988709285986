<template>
  <article class="h5_vote_fin_chain_rule_view">
    <div class="head_box">
      <div class="h5_icon rule_arrow_bg">{{$t("voteActivityView.tips75")}}</div>
      <div class="rule_arrow_box tx_cen mb_20">
        <p class="tx1 br">{{$t("voteActivityView.tips125")}}</p>
        <p><i class="h5_icon rule_arrow_bg1"></i></p>
      </div>
      <div class="rule_arrow_box tx_cen mb_20">
        <p class="tx1 br">{{$t("voteActivityView.tips126")}}</p>
        <p><i class="h5_icon rule_arrow_bg1"></i></p>
      </div>
      <div class="rule_arrow_box tx_cen mb_20">
        <p class="tx1 br">{{$t("voteActivityView.tips127")}}</p>
        <p><i class="h5_icon rule_arrow_bg1"></i></p>
      </div>
      <div class="rule_arrow_box tx_cen mb_20">
        <p class="tx1">{{$t("voteActivityView.tips79")}}</p>
      </div>
    </div>
    <div class="h5_icon rule_arrow_bg">{{$t("voteActivityView.tips80")}}</div>
    <p class="tx2 mb_20">{{$t("voteActivityView.tips114")}}</p>
    <p class="tx2 mb_20">{{$t("voteActivityView.tips115")}}</p>
    <p class="tx2 mb_20">{{$t("voteActivityView.tips116")}}</p>
    <p class="tx2 mb_20">{{$t("voteActivityView.tips117")}}</p>
    
    <div class="h5_icon rule_arrow_bg">{{$t("voteActivityView.tips118")}}</div>
    <p class="tx2 mb_20">{{$t("voteActivityView.tips119")}}</p>

  </article>
</template>

<script>
export default {
  name: "vote_fin_chain_rule",
  data() {
    return {
      iosAPPDown: "",
    };
  },
  created() {
    // this.getLangFn().then((res) => {
    //   this.Utils.tool.app.setHeader({ title: this.$t('voteActivityView.tips141') });
    // });
  },
  mounted() {
  },
  methods: {

  },
};
</script>

<style lang="scss">
 .h5_vote_fin_chain_rule_view{
   padding: 0 0 0.3rem;
   min-height: 100vh;
   background: linear-gradient(180deg, #FFA700 0%, #F86A32 100%);
   .time_info{
     padding: 0 0.16rem;
     .br_t{
       background-image: url("../../assets/img/vote/br_t.png");
       width: 3.43rem;
       height: 0.2rem;
     }
     .br_b{
       background-image: url("../../assets/img/vote/br_b.png");
       width: 3.43rem;
       height: 0.2rem;
     }
   }
   .rule_arrow_bg{
     background-image: url("../../assets/img/icon/rule_arrow_01.png");
     background-repeat: no-repeat;
     background-size: contain;
     color: $FFF;
     font-size: 0.2rem;
     font-weight: bold;
     text-align: center;
     width: 100%;
     line-height: 0.6rem;
     margin-bottom: 0.2rem;
   }
   .rule_arrow_bg1{
     background-image: url("../../assets/img/icon/rule_arrow1_01.png");
     color: $FFF;
     text-align: center;
     width: 0.18rem;
     height: 0.1rem;
   }
   .rule_arrow_box{}
   .tx1{
     padding-bottom: 0.1rem;
     font-size: 0.16rem;
     color: white;
     margin: 0 0.16rem;
   }
   .tx14{
     padding: 0 0.16rem;
   }
   .tx2{
     font-size: 0.14rem;
     color: white;
     margin: 0 0.16rem 0.2rem;
     line-height: 1.8;
   }
   .br{
     border-bottom: 1px solid #FFEDD1;

   }
   .table_main_tb{
     padding: 0 0.16rem 0.4rem;
   }
   .info_table {
     text-align: center;
     border: 1px solid rgba(119, 136, 170, 0.16);

     width: 100%;
     th {
       font-size: 0.13rem;
       background: rgba(255, 255, 255, 0.06);
       vertical-align: middle;
       color: $T8;
       padding: 0.12rem;
     }
     td {
       padding: 0.16rem 0.05rem;
       font-size: 0.13rem;
       color: $FFF;
       vertical-align: middle;

       font-weight: bold;

     }
     .w_1 {
       width: 0.8rem;
     }
   }

 }
</style>
