<template>

  <article class="h5_vote_created_fin_chain_node_view">
    <section class="banner_box " :class="'fin_chain_banner_' + storeLang"></section>
    <section class="from_main">
      <p class="title_tx1 ">{{ $t('voteActivityView.tips130') }}</p>
      <p class="tx1 mb_10">{{ $t('voteActivityView.tips131') }}</p>
      <div class="input_box">
        <input type="text" v-model="nodeName" class="input_tx" :placeholder="$t('voteActivityView.placeholder_tips4')"/>
      </div>
      <p class="title_tx1 ">{{ $t('voteActivityView.tips56') }}({{$t('dinnerActivityView.tip46')}})</p>
      <p class="tx1 mb_10">{{ $t('voteActivityView.tips132') }}</p>
      <div class="input_box">
        <input type="text" v-model="area" class="input_tx" :placeholder="$t('voteActivityView.tips58')"/>
      </div>
      <p class="title_tx1 ">{{ $t('voteActivityView.tips133') }}({{$t('dinnerActivityView.tip46')}})</p>
      <p class="tx1 mb_10">{{ $t('voteActivityView.tips134') }}</p>
      <div class="input_box">
        <textarea v-model="nodeInfo" class="input_textarea_tx" :placeholder="$t('voteActivityView.tips135')"></textarea>
      </div>

      <p class="title_tx2 mb_10">{{ $t('voteActivityView.tips136') }}</p>
      <p class="tx1 mb_10">{{ $t('voteActivityView.tips137') }}</p>
      <p class="tx1 mb_10">{{ $t('voteActivityView.tips138') }}</p>
    </section>
    <div class="btn_box">
      <input type="button" @click="nextVoteFn" class="input_btn" :class="nodeName ? 'input_btn_cur' :''" :value="$t('voteActivityView.tips139')"/>
    </div>


    <transition>
      <article class="com_popup_main" v-show="isShowType">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('voteActivityView.tips164') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="coin_box">
              <div class="check_item"  @click="checkTypeFn(1)">
                <div class="coin_l type_tx1" :class="typeCur == 1 ? 'color_T11' : 'color_T7'">
                  {{ $t('voteActivityView.tips40') }}
                </div>

                <div class="coin_r">
                  <i class="h5_icon" :class="typeCur == 1 ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                </div>
              </div>

              <div class="check_item"  @click="checkTypeFn(2)" >
                <div class="coin_l type_tx1" :class="typeCur == 2 ? 'color_T11' : 'color_T7'">
                  {{ $t('voteActivityView.tips41_1') }}
                </div>

                <div class="coin_r">
                  <i class="h5_icon" :class="typeCur == 2 ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                </div>
              </div>

            </div>
            <div class="com_popup_from_box">
              <div class="com_popup_btn_box">
                <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('next')" @click="nextCoin"/>
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>

    <transition>
      <article class="com_popup_main" v-show="isVote">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('voteActivityView.tips146') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.tips147') }}</p>
              <p class="right_box d_flex">{{nodeName}}</p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.payment_address') }}</p>
              <p class="right_box">
                <span>{{walletAddress}}</span><i class="h5_icon icon_origin_copy" @click="appCopy(walletAddress)"></i>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.tips103') }}</p>
              <!-- <p class="right_box">
                <span>{{curData.user_id}}</span><i class="h5_icon icon_origin_copy" @click="appCopy(curData.user_id)"></i>
              </p> -->
              <!-- current wallet uuid -->
              <p class="right_box">
                <span>{{randomUserId}}</span><i class="h5_icon icon_origin_copy" @click="appCopy(randomUserId)"></i>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.subscription_progress') }}</p>
              <!-- <p class="right_box d_flex">{{curData.gain_amount}} / {{curData.subscribe_amount}}</p> -->
              <p class="right_box d_flex">{{typeCur == 1 ? nodeData.subscribe_amount : nodeData.apply_amount}}</p>
            </div>
            <div class="com_popup_from_box">
              <!-- <div class="com_popup_input_box mb_10" :class="errorTx ? 'com_popup_input_error' : ''" >
                <div>
                  <input type="number" pattern="[0-9]*" v-model="num" class="com_popup_input_tx" :placeholder="$t('voteActivityView.placeholder_tips2')" />
                </div>
                <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
              </div> -->

              <!-- <div class="tx1 mb_20" v-if="coinIdObj[coinCur]">
                <span class="color_T14 mr_5 ">{{ $t('voteActivityView.wallet_balance') }}：<span class="color_T11">{{numFormat(coinIdObj[coinCur].balance)}} {{coinIdObj[coinCur].coin}}</span></span>
              </div> -->

              <p class="mb_10 d_flex">
                <i class="h5_icon mr_5" :class="!isCash ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                <span class="tx18 font_bold">{{$t('voteActivityView.tips86')}}</span>
              </p>

              <div class="com_popup_input_box mb_10" :class="errorCashTx ? 'com_popup_input_error' : ''" >
                <div>
                  <input type="number" pattern="[0-9]*" v-model="cashNum" class="com_popup_input_tx" :placeholder="$t('voteActivityView.tips87')"/>
                </div>
                <div class="error_tx" v-show="errorCashTx">{{errorCashTx}}</div>
              </div>

              <div class="tx1 mb_10">
                <span class="color_T14 mr_5">{{ $t('voteActivityView.cash_coupons') }}：<span class="color_T11">{{ cashBalance }} USDT</span></span>
              </div>

              <div class="com_popup_btn_box">
                <button class="com_popup_input_btn" :class="(cashNum != '' && +cashNum == (typeCur == 1 ? nodeData.subscribe_amount : nodeData.apply_amount)) ? 'com_popup_input_btn_cur' : ''" @click="voteSubmitFn" :disabled="(cashNum == '' || +cashNum != (typeCur == 1 ? nodeData.subscribe_amount : nodeData.apply_amount)) ? true : false">{{ $t('voteActivityView.tips168') }}</button>
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <com-loading :isShow="isShowLoading" text="loading"></com-loading>
  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "vote_created_fin_chain_node",
  components: {comLoading},
  data() {
    return {
      isShowLoading: false,
      text: '',
      isDisabled: false,
      chooseTypePayment: '',
      listFinList: [],
      randomUserId: '',
      cashNum: '',
      cashBalance: 0,
      errorCashTx: '',
      isCash: false,
      isVote: false,
      errorTx: '',
      curData: {},
      nodeName: '',
      nodeInfo: '',
      area: '',
      walletAddress: '',
      walletChainTypeAddress: {
        'tron': '',
        'bsc': '',
        'eth': '',
      },
      nodeData: {},
      addressData: {

      },
      isShowCoin: false,
      isShowType: false,
      coinCur: 0,
      typeCur: 1,
      coinImgObj: {},
      coinList: [],
      coinIdObj: {},
      isuid: false,
      isGetAuth: false,
      Wallet_Uuid: '',
    };
  },
  beforeUnmount () {
   window.Wallet_Uuid = null 
  },
  created() {
    this.Wallet_Uuid = window.Wallet_Uuid
    this.resNodeInfoFn()
    this.cashBalanceFn()
    this.getCoinList()
    this.getFinListRandomUser()
  },
  mounted() {
  },
  watch: {
    nodeName (val) {
      this.nodeName = val.replace(/[^\a-\z\A-\Z\s]/g,'')
    },
  },
  methods: {
    cashBalanceFn () {
      let req = {

      }
      this.$ajax(`${this.Utils.URL.goAPPURL}super/ticket_num`, req, 'get')
          .then(res => {
            this.cashBalance = res.quantity ? res.quantity : 0
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    getFinListRandomUser() {
      let req = {
        page: 1,
        size: 100,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}fin/list`, req, 'get')
          .then(res => {
            this.listFinList = res.list ? res.list : []
            let randomIndex = Math.floor(Math.random() * this.listFinList.length)
            this.randomUserId = this.listFinList[randomIndex]?.user_id
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    voteSubmitFn() {
      let value = this.typeCur == 1 ? parseFloat(this.nodeData.subscribe_amount) : parseFloat(this.nodeData.apply_amount)
      // let num = this.num ? parseFloat(this.num) : 0
      let cashNum = this.cashNum ? parseFloat(this.cashNum) : 0
      let cashBalance = this.cashBalance ? parseFloat(this.cashBalance) : 0

      let balance = parseFloat(this.coinIdObj[this.coinCur].balance)
      if ((cashNum) != value) {
        return false
      }

      if (cashNum > cashBalance) {
        return false
      }

      // if (num > balance) {
      //   return false
      // }
      if (!this.walletAddress) { // un comment when deploy
        this.messageFn(this.$t('voteActivityView.tips85'))
        return false
      }
      // this.errorTx = ''
      this.errorCashTx = ''
      
      let amount = (this.typeCur == 1 ? this.nodeData.subscribe_amount : this.nodeData.apply_amount) + ''
      let req = {
        title: this.nodeName + '|' + amount,
        introduction: this.nodeInfo,
        area: this.area,
        chain_type: 'bsc',
        receive_address: this.walletAddress, // cold wallet
        sender_address: this.randomUserId
      }
      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}super/create/ticket`, req, 'post')
          .then(res => {
            if (res.result) {
              this.messageFn(this.$t('success'))
              this.isVote = false
              // this.nodeName = ''
              // this.area = ''
              // this.nodeInfo = ''
              // this.cashNum = ''
              this.$router.push('/node_index_fin_chain')
            } else {
              this.messageFn(res.msg)
            }
            this.isShowLoading = false
          })
          .catch(res => {
            this.isShowLoading = false
            this.messageFn(res.msg)
          })
      return false
  
    },
    
    hideFn () {
      // this.isShowCoin = false // old
      this.isVote = false
      this.isShowType = false
    },
    checkTypeFn (index) {
      this.typeCur = index
    },
    nextCoin () {
      if (this.typeCur) {
        this.isShowType = false
        this.isVote = true
      }
    },
    coinTxType (tx) {
      if (tx) {
        return ((tx.split('|'))[0]).toLocaleUpperCase()
      }
      return tx ? tx.toLocaleUpperCase() : tx
    },
    checkCoinFn (index) {
      this.coinCur = index
      this.walletAddress = this.walletChainTypeAddress[this.coinIdObj[this.coinCur].chain_type]
    },
    nextVoteFn () {
      if (!(this.nodeData.places_surplus > 0)) {
        this.messageFn(this.$t('voteActivityView.tips102'))
        return false
      }
      // if (!this.coinList.length) {
      //   this.messageFn(this.$t('voteActivityView.error_tips10'))
      //   return false
      // }
      if (this.nodeName.length < 2 || this.nodeName.length > 50 || this.nodeName == '') {
        this.messageFn(this.$t('voteActivityView.tips60'))
        return false
      }
      this.resWalletAddressFn('bsc')
      this.isShowType = true
    },
    getCoinList () {
      let req = {
        symbol: 'USDT',
        // is_recommend: true,
        page: 1,
        size: 100,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}coin`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            arr.forEach(val => {
              if (val.chain_type == 'bsc') {
                this.coinImgObj[val.front_coin_id] = val.icon_url
                this.coinIdObj[val.front_coin_id] = {}
                if (!this.coinCur) {
                  this.coinCur = val.front_coin_id
                }
              }

            })
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    resNodeInfoFn () {
      let req = {

      }
      this.$ajax(`${this.Utils.URL.goAPPURL}fin/info`, req, 'get')
          .then(res => {
            this.nodeData = res ? res : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    resWalletAddressFn (chainType) {
      let req = {
        chain_type: 'bsc',
        type: 5,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}get_cold_wallet`, req, 'get')
          .then(res => {

            this.walletChainTypeAddress[chainType] = res.addr
            this.walletAddress = res.addr
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  },
};
</script>

<style lang="scss">
.h5_vote_created_fin_chain_node_view{
  background: linear-gradient(180deg, #FFA700 0%, #F86A32 100%);
  .payment_tx{
    line-height: 0.44rem;
    background: transparent;
    text-align: center;
    font-weight: bold;
    font-size: 0.14rem;
    margin-bottom: 0.2rem;
    color: #454545;
  }
  .com_popup_main{

    // .com_popup_box{
    //   position: absolute;
    //   bottom:0;
    //   left: 0;
    //   width: 100vw;
    //   z-index: 3;
    //   background: #FFFFFF;

    //   .com_popup_head{
    //     padding: 0.16rem;
    //     border-bottom: 0;
    //     background: rgba(255, 255, 255, 0.06);
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     .title_tx{
    //       font-size: 0.16rem;
    //       font-weight: bold;
    //       color: #454545;
    //     }
    //     .h5_icon {
    //       filter: invert(1);
    //       position: absolute;
    //       right: 20px;
    //       top: 21px;
    //     }
    //   }
    //   .com_popup_info_box{
    //     padding: 0.24rem 0.16rem;
    //     .item{
    //       display: flex;
    //       justify-content: space-between;
    //       font-size: 0.14rem;
    //       margin-bottom: 0.2rem;
    //       .left_box{
    //         color: $T7;

    //       }
    //       .right_box{
    //         max-width: 2.6rem;
    //         color: rgba(220, 229, 250, 1);
    //         text-align: right;
    //         word-wrap: break-word;
    //         word-break: break-all;
    //       }
    //     }
    //   }
    //   .com_popup_from_box{

    //     padding-top: 0.2rem;
    //     .tx1{
    //       display: flex;
    //       align-items: center;
    //       justify-content: space-between;
    //       font-size: 0.14rem;
    //     }

    //     .com_popup_input_box{
    //       border-radius: 0.12rem;
    //       background: rgba(5, 16, 41, 1);
    //       padding: 0.12rem;
    //       .com_popup_input_tx{

    //         height: 0.3rem;
    //         font-size: 0.16rem;
    //         color: rgba(220, 229, 250, 1);

    //         width: 100%;

    //       }

    //     }
    //     .com_popup_input_error{
    //       background: rgba(224, 49, 93, 0.05);
    //     }
    //     .error_tx{
    //       margin-top: 0.05rem;
    //       font-size: 0.12rem;
    //       color: $T9;
    //     }
    //     .com_popup_btn_box{
    //       .com_popup_input_btn{
    //         height: 0.44rem;
    //         border-radius: 0.08rem;
    //         text-align: center;
    //         background: rgba(118, 135, 168, 0.08);
    //         color: rgba(118, 135, 168, 0.5);
    //         font-size: 0.16rem;
    //         font-weight: bold;
    //         width: 100%;
    //         margin-top: 0.36rem;
    //       }
    //       .com_popup_input_btn_cur{
    //         background: linear-gradient(180.07deg, #F59428 0.06%, #F86A32 99.94%);
    //         color: $FFF;
    //       }
    //     }
    //   }
    // }
    .com_popup_box{
      position: absolute;
      bottom:0;
      left: 0;
      width: 100vw;
      z-index: 3;
      background: white;
      border-radius: 0.2rem 0.2rem 0 0;
      .com_popup_head{
        padding: 0.16rem;
        border-bottom: 0;
        background: rgba(255, 255, 255, 0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-color: #454545;
        .title_tx{
          font-size: 0.16rem;
          font-weight: bold;
          color: #454545;
        }
        .h5_icon {
          position: absolute;
          top: 20px;
          right: 20px;
          filter: invert(1);
        }
      }
      .com_popup_info_box{
        padding: 0.24rem 0.16rem;
        .item{
          display: flex;
          justify-content: space-between;
          font-size: 0.14rem;
          margin-bottom: 0.2rem;
          .left_box{
            color: #454545;
          }
          .right_box{
            max-width: 2.6rem;
            color: #454545;
            text-align: right;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }
      .com_popup_from_box{

        padding-top: 0.2rem;
        .tx1{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.14rem;
          span {

          }
        }

        .com_popup_input_box{
          border-radius: 0.12rem;
          background: #F1F1F1;
          padding: 0.12rem;
          .com_popup_input_tx{

            height: 0.3rem;
            font-size: 0.16rem;
            color: #454545;

            width: 100%;

          }

        }
        .com_popup_input_error{
          background: rgba(224, 49, 93, 0.05);
        }
        .error_tx{
          margin-top: 0.05rem;
          font-size: 0.12rem;
          color: $T9;
        }
        .com_popup_btn_box{
          .com_popup_input_btn{
            height: 0.44rem;
            border-radius: 0.2rem;
            text-align: center;
            background: #A9A9A9;
            color: white;
            font-size: 0.16rem;
            font-weight: bold;
            width: 100%;
            margin-top: 0.36rem;
          }
          .com_popup_input_btn_cur{
            background: linear-gradient(180.07deg, #F59428 0.06%, #F86A32 99.94%);
            color: $FFF;
          }
          button {
            border: 0;
          }
        }
      }
    }
  }
  .coin_box{

    .check_item:last-child{
      margin-bottom: 0;
    }
    .check_item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      .coin_l{
        display: flex;
        align-content: center;
        .coin_img{
          width: 0.4rem;
          height: 0.4rem;
          margin-right: 0.1rem;
        }
        .coin_tx{
          display: flex;
          align-items: center;
          margin-bottom: 0.03rem;
        }
        .coin_tx1{
          font-size: 0.16rem;
          font-weight: bold;
          color: rgba(220, 229, 250, 1);
          margin-right: 0.1rem;
        }
        .coin_tx2{
          padding: 0.02rem 0.04rem;
          border-radius: 0.08rem;
          font-size: 0.12rem;
          font-weight: bold;
        }
        .type_tx1{
          font-size: 0.18rem;

        }
        .coin_tx3{
          background: rgba(197, 48, 39, 0.1);
          color: $T9;
        }
        .coin_tx4{
          background: rgba(244, 187, 11, 0.1);
          color: $T8;
        }
        .coin_tx5{
          background: rgba(73, 115, 145, 0.1);
          color: rgba(90, 142, 178, 1);
        }
        .coin_balance{
          font-size: 0.12rem;
          // color: $T7;
          color: #454545;
        }
      }
    }
  }
  .banner_box{
    position: relative;
    background-size: cover;
    height: 1.5rem;

  }
  .fin_chain_banner_cn{
    background-image: url("/down/fin_chain_banner_cn1.png");
  }
  .fin_chain_banner_en{
    background-image: url("/down/fin_chain_banner_en1.png");
  }
  .fin_chain_banner_es{
    background-image: url("/down/fin_chain_banner_es1.png");
  }
  .fin_chain_banner_fr{
    background-image: url("/down/fin_chain_banner_fr1.png");
  }
  .fin_chain_banner_id{
    background-image: url("/down/fin_chain_banner_id1.png");
  }
  .fin_chain_banner_ja{
    background-image: url("/down/fin_chain_banner_ja1.png");
  }
  .fin_chain_banner_ko{
    background-image: url("/down/fin_chain_banner_ko1.png");
  }
  .fin_chain_banner_ms{
    background-image: url("/down/fin_chain_banner_ms1.png");
  }
  .fin_chain_banner_th{
    background-image: url("/down/fin_chain_banner_th1.png");
  }
  .fin_chain_banner_vi{
    background-image: url("/down/fin_chain_banner_vi1.png");
  }
  .fin_chain_banner_zh{
    background-image: url("/down/fin_chain_banner_zh1.png");
  }
  .from_main{
    padding: 0.2rem 0.16rem 0.8rem;
    position: relative;
    .title_tx1{
      font-size: 0.16rem;
      color: white;
      margin-bottom: 0.05rem;
    }
    .tx1{
      font-size: 0.13rem;
      color: white;
    }
    .title_tx2{
      font-weight: bold;
      font-size: 0.13rem;
      color: white;
    }
    .input_box{
      margin-bottom: 0.28rem;
      .input_tx{
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid white;
        height: 0.5rem;
        font-size: 0.16rem;
        color: #585858;
        border-radius: 0.12rem;
        width: 100%;
        padding:0 0.12rem;
        &::placeholder{
          color: #c1c1c1;
        }
      }
      .input_textarea_tx{
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid white;
        padding: 0.16rem 0.12rem;
        height: 1.08rem;
        font-size: 0.16rem;
        color: #585858;
        border-radius: 0.12rem;
        width: 100%;
        &::placeholder{
          color: #c1c1c1;
        }
      }
      .input_error{
        background: rgba(224, 49, 93, 0.05);
      }
    }

  }
  .btn_box{
    position: fixed;
    bottom: 0;
    padding: 0.16rem;
    width: 100%;
    background: #FFFFFF;
    .input_btn{
      height: 0.44rem;
      border-radius: 0.22rem;
      background: #d1cccc;
      text-align: center;
      color: #454545;
      font-size: 0.16rem;
      font-weight: bold;
      width: 100%;

    }
    .input_btn_cur{
      background: linear-gradient(180deg, #F59428 0%, #F86A32 100%);
      color: white;
    }
  }
}
</style>
