<template>
  <article class="h5_fth_subscription_view">
    <div class="head_bg"></div>
    <div class="fth_info_main d_flex_space mb_15">
      <p class="d_flex">
        <i class="h5_icon icon_fth_logo"></i>
        <span class="tx1">FTH</span>
      </p>
      <!-- <p class="d_flex" @click="goRouter('/fth_profit')">
        <span class="tx2">{{ $t('order_view.current_price') }}: ${{profitData.fth_price ? profitData.fth_price : '0'}}</span>
        <i class="h5_icon icon_orange_arrow"></i>
      </p> -->
      <p class="d_flex">
        <span class="tx2">{{ $t('order_view.current_price') }}: ${{profitData.fth_price ? profitData.fth_price : '0'}}</span>
        <!-- <i class="h5_icon icon_orange_arrow"></i> -->
      </p>
    </div>
    <div class="subscription_info_box mb_15">
      <div class="t_box b_br d_flex_space">
        <div>
          <p class="tx1 color_T7 mb_5">{{$t('fth_subscription_view.msg1')}}</p>
          <p class="color_T8 ">
            <span class="tx2 font_bold ">{{profitData.can_buy_quota ? profitData.can_buy_quota : '0'}}</span><span class="ml_5 font_bold tx3">USDT</span>
          </p>
        </div>
        <div class="subscription_btn" @click="showExchangeFn">{{$t('fth_subscription_view.msg2')}}</div>
      </div>
      <div class="b_box d_flex_space">
        <div>
          <p class="tx1 color_T7 mb_5">{{$t('fth_subscription_view.msg3')}}</p>
          <p class="tx3 font_bold color_T6">
            {{profitData.give_out ? profitData.give_out : '0'}} USDT
          </p>
        </div>
        <div class="tx_r">
          <p class="tx1 color_T7 mb_5">{{$t('fth_subscription_view.msg4')}}</p>
          <p class="tx3 font_bold color_T6">
            {{profitData.already_buy_quota ? profitData.already_buy_quota : '0'}} USDT
          </p>
        </div>
      </div>
    </div>
    <div class="info_main mb_15">
      <div class="b_br d_flex_space">
        <p class="tx1 font_bold color_T6">{{$t('fth_subscription_view.msg5')}}</p>
        <p class="tx2 color_T8 d_flex" @click="goRouter('/fth_subscription_history')">
          <span>{{profitData.direct_amount ? profitData.direct_amount : '0'}} USDT</span>
          <i class="h5_icon icon_orange_arrow"></i>
        </p>
      </div>
      <div class="d_flex_space mb_10">
        <p class="tx2 color_T6">{{$t('fth_subscription_view.msg6')}}</p>
        <p class="tx2 color_T6">{{profitData.yesterday_direct_amount ? profitData.yesterday_direct_amount : '0'}} USDT</p>
      </div>
      <div class="d_flex_space ">
        <p class="tx2 color_T6">{{$t('fth_subscription_view.msg7')}}</p>
        <p class="tx2 color_T6">{{profitData.history_amount ? profitData.history_amount : '0'}} USDT</p>
      </div>
    </div>
    <div class="info_main">
      <div class="d_flex_space">
        <p class="tx2 color_T6">{{$t('fth_subscription_view.msg8')}}</p>
        <p class="tx2 color_T6">{{profitData.yesterday_quota ? profitData.yesterday_quota : '0'}} USDT</p>
      </div>
    </div>
    <h2 class="list_title">{{$t('fth_subscription_view.msg9')}}</h2>
    <div>
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <div class="info_main mb_10" v-for="(list, index) in resData" :key="index">
          <div class="d_flex_space">
            <p class="tx2 color_T6">{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</p>
            <p class="tx2 color_T6 d_flex"><span>{{$t('fth_subscription_view.msg10')}}:</span><span class="ml_5">{{ list.amount }} {{ list.coin_data.code }}</span></p>
          </div>
        </div>

      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </div>


    <article class="com_popup_main" v-show="isShowExchange">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('fth_subscription_view.msg2')}}</span>
          <i class="h5_icon icon_close" @click="isShowExchange = false"></i>
        </div>
        <div class="comp_popup_link_box">
          <div class="tx2 d_flex_space color_T8" >
            <p>{{$t('fth_subscription_view.msg11')}}</p>
            <p>{{profitData.fth_price ? profitData.fth_price : '0'}} USDT</p>

          </div>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('subscription_amount') }}</p>
              <p class="color_T7 tx4">{{$t('fth_subscription_view.msg13')}}:<span class="color_T8 ml_5">{{profitData.can_buy_quota ? profitData.can_buy_quota : '0'}} USDT</span></p>
            </div>
            <div class="com_popup_input_box mb_10" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div class="d_flex_space">
                <input type="number" @keyup="(e) => {this.num = e.target.value.replace(/[^\d.]/g, '')}" v-model="num" @input="eachUsdtAmountFn" class="com_popup_input_tx" :placeholder="$t('fth_subscription_view.msg14') + profitData.min_amount +' USDT'"/>
                <span class="color_T8">USDT</span>
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <div class="color_T7 tx4 d_flex_space mb_25">
              <p>{{$t('wallet_balance')}}：{{ balance }} USDT</p>
              <p>{{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}}</p>
            </div>
            <div class="item">
              <div class="left_box d_flex">
                <span>{{$t('order_view.expected_to_get')}}</span>
              </div>
              <div class="right_box">{{usdtAmount}} FTH</div>
            </div>
            <div class="item">
              <div class="left_box">{{$t('fth_subscription_view.msg12')}}</div>
              <div class="right_box">{{substr(profitData.wallet_addr, 0, 5)}}...{{substr(profitData.wallet_addr, -5)}}</div>
            </div>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn" :class="num > 0 ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="subExchangeFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    <div class="bottom_loading_box" v-show="isApproveShow">
      <i class="h5_icon icon_small_loading"></i>
      <p>{{ $t('tips_view.msg57') }}</p>
    </div>

  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "fth_subscription",
  data () {
    return{
      balance: 0,
      usdtAmount: 0,
      errorTx: '',
      num: '',
      profitData: {},
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      resData: [],
      isShowExchange: false,
      isAjax: false,
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isShowLoading: false,
      isApproveShow: false,
      eachTimeFn: null
    }
  },
  components: {
    comLoading,
  },
  created () {
    this.profitInit()
    this.resInit()
  },
  mounted () {
    if (this.storeChainType) {
      this.getUSDTBalanceFn().then(res => {
        this.balance = res
      }).catch(res => {
        this.balance = 0
        this.messageFn(res.msg)
      })
    }
  },
  methods: {
    eachUsdtAmountFn () {
      if (this.num <= 0) {
        return false
      }
      this.usdtAmount = (this.num / this.profitData.fth_price) ? parseFloat(this.padRight(this.num / this.profitData.fth_price, 8)) : 0
      /*clearTimeout(this.eachTimeFn)
      this.eachTimeFn = setTimeout(() => {
        let num = parseFloat(this.num)
        if (num > 0) {
          let req = {
            amount: num,
            chain_type: this.storeChainType.toLocaleLowerCase(),
          }
          this.$ajax(`${this.Utils.URL.goAPPURL}profit/estimate_profit`, req, 'get')
              .then(res => {

                this.usdtAmount = res.amount ? res.amount : 0


              })
              .catch(res => {

                this.messageFn(res.msg)
              })
        } else {
          this.usdtAmount = 0
        }
      }, 300)*/

    },
    showExchangeFn () {
      this.profitInit()
      this.num = ''
      this.usdtAmount = 0
      this.isShowExchange = true
    },
    async subExchangeFn () {
      if (this.storeChainType != 'BSC') {
        this.messageFn(this.$t('tips_view.msg38'))
        return false
      }
      let max = parseFloat(this.profitData.can_buy_quota)
      let min = parseFloat(this.profitData.min_amount)
      let balance = parseFloat(this.balance)
      let num = parseFloat(this.num)
      if (num > balance) {
        this.errorTx = this.$t('tips_view.msg28')
        return false
      }
      if (num < min) {
        this.errorTx = this.$t('fth_subscription_view.msg18') + min + 'USDT'
        return false
      }
      if (num > max) {
        this.errorTx = this.$t('fth_subscription_view.msg19') + max + 'USDT'
        return false
      }
      if (!(num > 0)) {
        this.errorTx = this.$t('tips_view.msg18') + min + 'USDT'
        return false
      }


      if (this.isAjax) {
        return  false
      }
      this.isAjax = true
      let time = parseInt(new Date().getTime() / 1000)
      let sing
      let txId = ''
      this.isApproveShow = true

      /*setTimeout(() => {

          this.isApproveShow = false

          this.isAjax = false


      }, 3000)*/

      await this.approveUSDTBalanceFn(this.profitData.subscribe_addr, num + '').then(res => {
        this.isApproveShow = false
        txId = res
      }).catch(res => {
        txId = ''
        this.isApproveShow = false
        this.isAjax = false
        this.messageFn('USDT ' + this.$t('tips_view.msg55'))

        return false
      })

      if (!txId) {
        this.isApproveShow = false

        this.isAjax = false
        return false
      }

      sing = await this.dappSingFn(time + ':' + this.storeChangeAddressId)
      if (!sing) {
        this.isAjax = false
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }



      let req = {
        out_desired: this.usdtAmount,
        usdt_amount: num,
        features: time,
        signature: sing,

      }


      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/fth_subscribe`, req, 'post')
          .then(res => {
            this.isShowExchange = false
            this.isAjax = false
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')
            // this.profitData.can_buy_quota = this.profitData.can_buy_quota - num
            setTimeout(() => {
              this.isShowLoading = false
              this.profitInit()
            }, 2000)

          })
          .catch(res => {
            this.isAjax = false
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            // this.messageFn(res.msg)
          })



    },
    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/fth_subscribe_record_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    profitInit () {
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/fth_subscribe_top`, req, "get")
          .then((res) => {
            this.profitData = res ? res : {}

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_fth_subscription_view{
  padding: 0.2rem 0.16rem;
  .icon_orange_arrow{
    width: 0.12rem;
    height: 0.12rem;
  }
  .head_bg{
    position: absolute;
    height: 2.08rem;
    width: 100vw;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #EE9922 0%, rgba(238, 153, 34, 0) 100%);
    z-index: -1;
  }
  .fth_info_main{
    background: $FFF;
    border-radius: 0.06rem;
    padding: 0.14rem 0.16rem;
    position: relative;
    z-index: 1;
    .tx1{
      font-size: 0.17rem;
      font-weight: bold;
      color: $T6;
      margin-left: 0.1rem;
    }
    .tx2{
      font-size: 0.14rem;
      font-weight: bold;
      color: $T8;
    }
  }
  .subscription_info_box{
    background: $FFF;
    border-radius: 0.06rem;
    padding: 0.14rem 0.16rem;
    position: relative;
    z-index: 1;
    box-shadow: 0px 4px 9px rgba(34, 68, 85, 0.05);
    .tx1{
      font-size: 0.13rem;
    }
    .tx2{
      font-size: 0.2rem;
    }
    .tx3{
      font-size: 0.14rem;
    }
    .subscription_btn{
      color: $FFF;
      padding: 0.06rem 0.12rem;
      background: $T8;
      border-radius: 0.06rem;
    }
    .b_br{
      padding-bottom: 0.14rem;
      margin-bottom: 0.14rem;
      border-bottom: 1px solid $BR;
    }
  }
  .info_main{
    background: $BG2;
    border-radius: 0.06rem;
    padding: 0.14rem 0.16rem;
    position: relative;
    .b_br{
      padding-bottom: 0.14rem;
      margin-bottom: 0.14rem;
      border-bottom: 1px solid $BR;
    }
    .tx2{
      font-size: 0.13rem;
    }

    .tx1{
      font-size: 0.14rem;
    }
  }
  .list_title{
    color: $T6;
    font-size: 0.14rem;
    margin: 0.24rem 0 0.12rem;
  }
}

</style>