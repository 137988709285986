<template>
  <article class="h5_buy_order_list_view">
    <section class="tab_box d_flex">
      <span class="item " :class="tabIndex == 1 ? 'item_cur' : ''" @click="tabFn(1)">{{ $t('status_view.status17') }}</span>
      <span class="item" :class="tabIndex == 2 ? 'item_cur' : ''" @click="tabFn(2)">{{ $t('status_view.status14') }}</span>
      <span class="item" :class="tabIndex == 3 ? 'item_cur' : ''" @click="tabFn(3)">{{ $t('status_view.status15') }}</span>
    </section>
    <section class="order_box">
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <div class="order_item mb_10" v-for="list in resData" @click="goRouter('/otc_buy_order', {id: list.order_id})">
          <div class="head_box mb_10 d_flex_space">
            <p class="tx14 font_bold color_T6">{{ $t('buy_1') }} {{ list.coin_data.code }}</p>
            <p class="tx14 font_bold color_T8">{{statusFn(list.status)}}</p>
          </div>
          <div class="info_box mb_15 d_flex_space">
            <div class="tx14 color_T7">{{ $t('number') }}</div>
            <div class="tx14 color_T6 d_flex">
              <span>{{ list.num }} {{ list.coin_data.code }}</span>
              <span class="tx2 ml_5 bsc_color"  :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>
            </div>
          </div>
          <div class="info_box mb_15 d_flex_space">
            <div class="tx14 color_T7">{{ $t('unit_price') }}</div>
            <div class="tx14 color_T6 d_flex">
              <span>{{list.currency_symbol}}{{ list.price }}</span>
            </div>
          </div>
          <div class="info_box mb_15 d_flex_space">
            <div class="tx14 color_T7">{{ $t('total_price') }}</div>
            <div class="tx14 color_T6 d_flex">
              <span>{{list.currency_symbol}}{{ list.amount }}</span>
            </div>
          </div>
          <div class="info_box  d_flex_space">
            <div class="tx14 color_T7">{{ $t('down_order_time') }}</div>
            <div class="tx14 color_T6 d_flex">
              <span>{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
            </div>
          </div>
        </div>

      </div>


      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
      <div class="contact_tx" @click="isShowContact = true">{{ $t('contact_information') }}</div>
    </section>

    <article class="com_popup_main" v-show="isShowContact">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('contact_information') }}</span>
          <i class="h5_icon icon_close" @click="isShowContact = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('name_1') }}</p>
            </div>
            <div class="com_popup_input_box mb_25"  >
              <div>
                <input type="text"  v-model="name" class="com_popup_input_tx" :placeholder="$t('tips_view.msg141')"/>
              </div>
            </div>
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('phone') }}</p>
            </div>
            <div class="com_popup_input_box d_flex phone_main mb_5"  >
              <p class="area_box d_flex_space" @click="isShowCountry = true">
                <span class="tx14 font_bold" :class="country.area_code ? 'color_T6' : 'color_T7'">{{country.area_code ? '+' + country.area_code : $t('please_1')}}</span>
                <i class="h5_icon icon_gray_arrow_down_small"></i>
              </p>
              <div>
                <input type="number" pattern="[0-9]*" v-model="phone" class="com_popup_input_tx" :placeholder="$t('tips_view.msg162')"/>
              </div>
            </div>
            <p class="tx13 color_T9 mb_20">{{ $t('tips_view.msg169') }}</p>


            <div class="com_popup_btn_box d_flex_space">
              <input type="button" class="com_popup_input_btn " :class="name && phone && country.area_code ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="contactSubmitFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="search_main" v-show="isShowCountry">
      <div class="bg"></div>
      <div class="search_box">
        <p class="search_title_tx d_flex_space"><span>{{$t('select_code')}}</span> <i class="h5_icon icon_close" @click="isShowCountry = false"></i></p>
        <div class="search_input_box d_flex_space">
          <input type="text" class="input_tx" v-model="searchCountryTx" @input="searchCodeFn($event)" :placeholder="$t('search_code')"/>
          <img src="../../assets/img/icon/search.svg" class="search_img" />
        </div>
        <div class="item_box">
          <div class="item  d_flex_space" :class="list.area_code == country.area_code ? 'item_cur' : ''" v-for="list in countryData" v-show="list.isShow"  @click="checkAreaFn('country', list)">
            <p class="d_flex">
              <span class="mr_10">{{ list.country }}</span>
              <i class="h5_icon icon_orange_success" v-show="list.area_code == country.area_code"></i>
            </p>
            <span>{{list.area_code}}</span>

          </div>
        </div>
      </div>
    </article>
    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>

  </article>
</template>

<script>
import comLoading from "@/components/loading/index.vue";

export default {
  name: "buy_order_list",
  data () {
    return {
      tabIndex: 1,
      resData: [],
      page: 1,
      size: 30,
      loadInfinite: false,
      isLoad: false,
      name: '',
      phone: '',
      country: {
        area_code: '',
        country: '',
        id: '',
      },
      isShowContact: false,
      isShowCountry: false,
      isShowCity: false,
      countryData: [],
      searchCountryTx: '',
      status: 'loading',
      text: '',
      isAjax: false,
      isShowLoading: false,
    }
  },
  components: {
    comLoading,
  },
  created() {
    this.resInit()
    this.searchCountryFn()
    this.userContactFn()
  },
  methods: {
    contactSubmitFn () {
      if (this.isAjax) {
        return  false
      }
      if (this.name === '') {
        this.messageFn(this.$t('tips_view.msg141'))
        return  false
      }
      if (this.phone === '') {
        this.messageFn(this.$t('tips_view.msg162'))
        return  false
      }
      if (this.phone.length <7 || this.phone.length > 11) {
        this.messageFn(this.$t('tips_view.msg163'))
        return  false
      }
      this.isAjax = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/set_user_information`, {
        area_code: this.country.area_code,
        name: this.name,
        phone: this.phone,
      }, 'post')
          .then(res => {
            this.isShowContact = false
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('success')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          })
          .catch(res => {
            this.isAjax = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg108')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })
    },
    userContactFn () {
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/user_information`, {}, 'get')
          .then(res => {
            let data = res ? res : {}

            this.country.area_code = data.area_code ? data.area_code : this.country.area_code
            this.name = data.name ? data.name : ''
            this.phone = data.phone ? data.phone : ''

          })
          .catch(res => {

            this.messageFn(res.msg)
          })
    },
    checkAreaFn (type, data) {
      if (type == 'city') {


      } else {
        this.country = data
        this.isShowCountry = false

      }
    },
    searchCodeFn () {
      if (this.searchCountryTx) {
        this.countryData.forEach((val, index) => {
          if (val.area_code.indexOf(this.searchCountryTx) >= 0) {
            this.countryData[index].isShow = true
          } else {
            this.countryData[index].isShow = false
          }
        })
      } else {
        this.countryData.forEach((val, index) => {
          this.countryData[index].isShow = true
        })
      }
    },
    searchCountryFn (eve) {

      let req = {
        page: 1,
        size: 1000,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}buy/area_code`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            arr.forEach((val, i) => {
              arr[i].isShow = true
              if (this.storeLang == 'cn') {
                if (val.area_code == 86 && !this.country.area_code) {
                  this.country = val
                }
              } else {
                if (val.area_code == 1 && !this.country.area_code) {
                  this.country = val
                }
              }

            })
            this.countryData = arr
            // this.searchCityFn()

          })
          .catch(res => {

            this.messageFn(res.msg)
          })

    },
    statusFn (type) {

      // （0-无操作，1-申诉过，2-超时过）
      if (type== 1) {
        return this.$t('status_view.status11')

      }
      if (type== 2) {
        return this.$t('status_view.status12')


      }
      if (type== 3) {
        return this.$t('status_view.status13')

      }
      if (type== 4) {
        return this.$t('status_view.status14')

      }
      if (type== 5) {
        return this.$t('status_view.status15')
        

      }
      if (type== 6) {
        return this.$t('status_view.status16')
        

      }
    },
    tabFn (type) {
      if (type != this.tabIndex) {
        this.tabIndex = type
        this.resData = []
        this.page = 1
        this.loadInfinite = false
        this.isLoad = false
        this.resInit()
      }
    },
    resInit (type) {

      let req = {
        size: this.size,
        page: this.page,
        op_type: this.tabIndex,
      };


      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_list`, req, "get")
          .then((res) => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1

            this.resData = this.resData.concat(arr)


          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    }
  }
}
</script>

<style lang="scss">
.h5_buy_order_list_view{
  .phone_main{
    .area_box{
      padding: 0 0.12rem 0 0rem;
      margin-right: 0.1rem;
      width: 0.9rem;
      border-right: 1px solid $BR4;
    }
    .com_popup_input_tx{
      width: 2.2rem !important;
    }
  }

  .search_main{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 996;
    .bg{
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100vw;
      height: 100vh;
    }
    .search_box{
      z-index: 997;
      position: absolute;
      width: 100vw;
      bottom: 0;
      background: $FFF;
    }
    .search_title_tx{
      padding: 0.14rem 0.16rem;
      font-weight: bold;
      font-size: 0.16rem;
      color: $T6;
    }
    .search_input_box{
      margin: 0 0.16rem 0rem;
      padding: 0 0.12rem ;
      background: $BG2;
      border-radius: 0.16rem;

    }
    .input_tx{
      line-height: 0.32rem;
      font-size: 0.12rem;
      width: 2rem;
    }
    .search_img{
      margin-left: 0.1rem;
    }
    .item_box{
      margin-top: 0.12rem;
      border-top: 1px solid $BR2;
      padding: 0.1rem 0;
      height: 3.5rem;
      overflow-y: scroll;
    }
    .item{
      font-size: 0.16rem;
      color: $T6;
      padding: 0.1rem 0.16rem;
    }
    .item_cur{
      background: $T11;
    }
  }
  .contact_tx{
    background: $T8;
    box-shadow: 0px 4px 9px rgba(238, 153, 34, 0.3);
    border-radius: 0.22rem;
    font-weight: bold;
    color: $FFF;
    font-size: 0.16rem;
    position: fixed;
    padding: 0.1rem 0.24rem;
    right: 0.16rem;
    bottom: 0.48rem;
  }
  .tab_box{

    .item{
      width: 33.33%;
      padding: 0.1rem 0rem;
      border-bottom: 1px solid $BR;
      font-size: 0.14rem;
      font-weight: bold;
      color: $T7;
      text-align: center;
    }
    .item_cur{
      color: $T8;
      border-bottom: 1px solid $T8;
    }
  }
  .order_box{
    padding: 0.1rem 0.16rem;
    .order_item{
      padding: 0.16rem;
      border-radius: 0.06rem;
      background: $BG2;
      .tx2{
        font-size: 0.12rem;
        padding: 0.01rem 0.04rem;
        border-radius: 0.1rem;
        display: inline-block;
        min-width: 0.36rem;
        text-align: center;
      }
      .head_box{
        padding-bottom: 0.12rem;
        border-bottom: 1px solid $BR2;
      }
    }
  }
}

</style>