<template>
  <article class="h5_loan_order_view">

    <section class="loan_list_main">
      <div class="list_box">
        <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
          <div class="list_item" v-for="(list, index) in resData" :key="list.id">
            <div @click="goRouter('/loan_order_details', {id: list.id})">
              <div class="coin_box d_flex_space">
                <div class="l_box d_flex">
                  <div class="com_icon_img_box3">
                    <img :src="list.coin_data.icon_url" class="com_coin_img"/>
                    <i class="h5_icon com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                  </div>
                  <span class="tx1 ">{{list.coin_data.code}}</span>
                  <span class="tx2 ml_5" :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>
                </div>
                <div class="r_box d_flex">
                  <span class="tx2 tx7"><span class="fz_10 font_bold">{{list.invest_days}} {{$t('day')}}</span></span>
                  <span class="tx2 tx6 ml_5"><span class="fz_10 font_bold">{{list.daily_profit}}</span></span>
                  <i class="h5_icon ml_3 icon_orange_arrow"></i>
                </div>
              </div>
              <div class="tx3 mb_10 color_T6">{{$t('loan_amount')}}: {{list.borrow_amount}} {{list.coin_data.code}}</div>
              <div class="tx3  color_T6 d_flex_space">
                <span v-show="list.status == 1 || list.status == 7">{{$t('application_time')}}: {{dataFormat(list.apply_data.apply_time, 'yyyy/MM/dd hh:mm:ss')}}</span>
                <span v-show="list.status == 4 || list.status == 5 || list.status == 10 || list.status == 11">{{$t('repayment_period')}}: {{list.repayment_data.repayment_phases}}/{{list.repayment_data.phases}} {{$t('day')}}</span>
                <span v-show="list.status == 6 || list.status == 12 || list.status == 13">{{$t('repayment_time')}}: {{dataFormat(list.settled_data.settled_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
                <span v-show="list.status == 2 || list.status == 3 || list.status == 8 || list.status == 9">{{$t('fundraisings_progress')}}: {{list.loan_data.progress ? list.loan_data.progress : '-'}}</span>
                <span class="font_bold " :class="(list.status == 1 || list.status == 2 || list.status == 3 || list.status == 4 || list.status == 5 || list.status == 11)? 'color_T8' : (list.status == 7 || list.status == 8 || list.status == 9 || list.status == 10  ) ? 'color_T9' : 'color_T7'">{{ statusTxFn(list)}}</span>
              </div>
            </div>

          </div>
        </div>
        <div class="com_no_data_box" v-show="!resData.length">
          <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
          <p class="com_no_data_tx">{{$t('nodata')}}</p>
        </div>
      </div>
    </section>


  </article>
</template>

<script>

export default {
  name: "loan_order",
  data () {
    return {
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      resData: [],
      isCheck: false,
      curIndex: ''
    }
  },
  created () {

    this.resInit()
  },

  methods: {
    statusTxFn (obj) {

      if (obj.status == 1) {
        return this.$t('status_view.status1')
      }
      if (obj.status == 2) {
        return this.$t('status_view.status2')
      }
      if (obj.status == 3) {
        return this.$t('status_view.status2')
      }
      if (obj.status == 4) {
        return this.$t('status_view.status3')
      }
      if (obj.status == 5) {
        return this.$t('status_view.status3')
      }
      if (obj.status == 6) {
        return this.$t('status_view.status4')
      }
      if (obj.status == 7) {
        return this.$t('status_view.status5')
      }
      if (obj.status == 8) {
        return this.$t('status_view.status6')
      }
      if (obj.status == 9) {
        return this.$t('status_view.status6')
      }
      if (obj.status == 10) {
        return this.$t('status_view.status7')
      }
      if (obj.status == 11) {
        return this.$t('status_view.status3')
      }
      if (obj.status == 12) {
        return this.$t('status_view.status4')
      }
      if (obj.status == 13) {
        return this.$t('status_view.status4')
      }
    },

    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/my_borrow_order_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
  },
}
</script>

<style lang="scss">
.h5_loan_order_view{


  .loan_list_main{
    padding: 0.12rem 0.16rem 0.16rem 0.12rem;
    .list_box{

      .list_item{
        border-radius: 0.06rem;
        padding: 0.16rem;
        background: $BG2;
        margin-bottom: 0.12rem;
      }
      .coin_box{
        margin-bottom: 0.16rem;
        .icon_orange_arrow{
          width: 0.12rem;
          height: 0.12rem;
        }
      }
      .tx1{
        font-size: 0.16rem;
        font-weight: bold;
      }
      .tx2{
        font-size: 0.12rem;
        padding: 0.01rem 0.04rem;
        border-radius: 0.1rem;
        display: inline-block;
        min-width: 0.36rem;
        text-align: center;
      }
      .tx6{
        color: $T5;
        background: $T1;
      }
      .tx5{
        color: $T12;
        background: $T11;
      }
      .tx7{
        color: $T8;
        background: $T10;
      }
      .tx8{
        color: $T14;
        background: $T13;
      }
      .tx9{
        color: $T16;
        background: $T15;
      }
      .tx3{
        font-size: 0.12rem;
      }
      .tx3_br{
        margin-bottom: 0.16rem;
        border-bottom: 1px solid $BR2;
        padding-bottom: 0.12rem;
      }

    }
  }
}
</style>