<template>
  <article class="h5_otc_coin_view">
    <article class="select_coin_main">
      <section class="select_coin_box">
        <div @click="checkFn(list)" class="select_item d_flex_space" :class="fastCoinData.id == list.id ? 'select_item_cur' : ''" v-for="list in coinList">
          <div class="d_flex">
            <div class="com_icon_img_box">
              <img :src="list.icon_url" class="com_coin_img"/>
              <i v-show="list.contract_addr" class="h5_icon_1 com_icon_subscript" :class="list.type == 'tron' ? 'icon_subscript_tron' : list.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx3 color_T6 font_bold ml_10">{{ list.code }}</span>
            <span class="tx2 ml_5 bsc_color" v-show="list.contract_addr" :class="list.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span></span>

          </div>

        </div>

      </section>
    </article>

  </article>
</template>

<script>
export default {
  name: "otc_coin",
  data() {
    return {
      coinList: [],
      fastCoinData: {},
      size: 30,
      page: 1,
    }
  },

  created() {
    this.coinList = window.sessionStorage.getItem('fastCoinList') ? JSON.parse(window.sessionStorage.getItem('fastCoinList')) : []
    this.fastCoinData = window.sessionStorage.getItem('fastCoinData') ? JSON.parse(window.sessionStorage.getItem('fastCoinData')) : {}
    // this.coinInit()
  },
  mounted() {
  },
  methods: {
   checkFn(list) {
     window.sessionStorage.setItem('fastCoinData', JSON.stringify(list))
     window.sessionStorage.setItem('fastCurrencyData', JSON.stringify({}))
     this.$router.go(-1)
   },
  }
}
</script>

<style lang="scss">
.h5_otc_coin_view {
  min-height: 100vh;
  .select_coin_box{
    padding: 0;
    .select_item_cur{
      background: $BG2;
    }
    .select_item{
      padding: 0.16rem;
      margin-bottom: 0;
    }
  }

}

</style>