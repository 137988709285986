<template>
  <article class="h5_otc_currency_view">
    <div class="currency_item " @click=checkFn(list) :class="fastCurrencyData.currency == list.currency ? 'currency_item_cur' : ''" v-for="list in resData">{{ list.currency_name }}({{ list.currency }})</div>
  </article>
</template>

<script>
export default {
  name: "otc_currency",
  data() {
    return {
      name: '',
      phone: '',
      isTrue: true,
      fastCurrencyData: {},
      resData: [],
      size: 30,
      page: 1,
    }
  },

  created() {
    this.resData = window.sessionStorage.getItem('fastCurrencyList') ? JSON.parse(window.sessionStorage.getItem('fastCurrencyList')) : []
    this.fastCurrencyData = window.sessionStorage.getItem('fastCurrencyData') ? JSON.parse(window.sessionStorage.getItem('fastCurrencyData')) : {}

  },
  mounted() {
  },
  methods: {
    checkFn(list) {
      window.sessionStorage.setItem('fastCurrencyData', JSON.stringify(list))
      this.$router.go(-1)
    },
    resInit() {
    },
  }
}
</script>

<style lang="scss">
.h5_otc_currency_view {
  min-height: 100vh;
  .currency_item{
    padding: 0.2rem 0.16rem;
    color: $T6;
    font-size: 0.16rem;
  }
  .currency_item_cur{
    background: $BG2;
    color: $T8;
  }

}

</style>