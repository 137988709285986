<template>
  <article class="h5_team_profit_view">
    <p class="level_tx" v-show="profitData.management_level > 0">{{ $t('management_level') }}: {{profitData.management_name}} {{ $t('level') }} 
      <span v-if="profitData.community_level > 0">({{communityArr[profitData.community_level].label}})</span>
    </p>
    <div class="level_bg" v-show="profitData.management_level > 0"></div>

    <section class="profit_box">
      <div class="fin_box">
        <p class="tx1" style="margin-bottom: 0;">{{ $t('income_profit.tips22') }}</p>
        <p class="tx2_box" style="border-bottom: 0; padding-bottom: 0; margin-bottom: 0.05rem;">
          <span class="tx2">{{ profitData.invite_profit }}</span>
          <span class="tx3 ml_5">SCF</span>
          <span class="tx4"> ≈ ${{ numFormat(+profitData.invite_profit) }}</span>
        </p>
        <div class="flex">
          <div class="item">
            <p class="tx1" style="margin-bottom: 0; display: flex; align-items: center;">
              <span>团队总奖励剩余额度</span> 
              <span style="line-height: 0; padding-left: 0.02rem;" @click="showPopup('case1')"><i class="h5_icon icon_question"></i></span>
            </p>
            <p class="tx2_box" >
              <span class="tx2">0</span>
              <span class="tx3 ml_5">SCF</span>
              <span class="tx4"> ≈ $0</span>
            </p>
          </div>
          <div class="item right">
            <p class="tx1" style="margin-bottom: 0; display: flex; align-items: center; justify-content: flex-end;">
              <span>最小新增质押金额</span>
              <span style="line-height: 0; padding-left: 0.02rem;" @click="showPopup('case2')"><i class="h5_icon icon_question"></i></span>
            </p>
            <p class="tx2_box" >
              <span class="tx2">0</span>
              <span class="tx3 ml_5">USDT</span>
            </p>
          </div>
        </div>
        
        <div class="item_box last">
          <div>
            <p class="tx5">{{ $t('extracted') }}</p>
            <p class="tx6">{{ scientificNotationToString(profitData.draw_amount) }} SCF</p>
          </div>
          <div class="tx_r">
            <p class="tx5">{{ $t('not_extracted') }}</p>
            <p class="tx6">{{ scientificNotationToString(profitData.no_draw_amount) }} SCF</p>
          </div>
        </div>
      </div>
      <div class="tx7_main">
        <p class="tx7_box ">
          <span class="tx7">{{ $t('income_profit.tips23') }}</span>
          <span class="tx6">{{ scientificNotationToString(profitData.direct_profit) }} SCF</span>
        </p>
        <p class="tx7_box">
          <span class="tx7">{{ $t('income_profit.tips24') }}</span>
          <span class="tx6">{{ scientificNotationToString(profitData.indirect_profit) }} SCF</span>
        </p>
        <p class="tx7_box" v-show="profitData.management_level > 0">
          <span class="tx7">{{ $t('management_earnings') }}</span>
          <span class="tx6">{{profitData.administer_invite_profit}} {{profitData.symbol}}</span>
        </p>
        <p class="tx7_box" v-show="profitData.management_level > 0">
          <span class="tx7">团队净入金</span>
          <span class="tx6 flex">
            <span>0 FUSD</span>
             
            <i class="h5_icon icon_gray_arrow"></i>
          </span>
        </p>

      </div>

    </section>
    <div class="yesterday_profit" v-show="profitData.management_level >= 6">
      <div class="d_flex_space" >
        <p class="tx13 color_T6">{{ mdfileterFn($t('platform_revenue')) }}</p>
        <p class="tx13 color_T6 font_bold d_flex_cen" @click="goRouter('/team_md_revenue', {management_name: profitData.management_name, show_dividend_header: profitData.show_dividend_header, md_earnings_withdraw_percent: profitData.md_earnings_withdraw_percent, md_earnings_remaining_percent: profitData.md_earnings_remaining_percent})">
          <span>{{profitData.partner_profit ? profitData.partner_profit : 0}} {{profitData.symbol}}</span>
          <i class="h5_icon icon_black_arrow"></i>
        </p>
      </div>
      <div class="d_flex_space mt_10" v-show="profitData.md_add_invest !== ''">
        <p class="tx13 color_T6">{{ mdfileterFn($t('md_invest')) }}</p>
        <p class="tx13 color_T6 font_bold d_flex_cen"  @click="goRouter('/team_md_invite')">
          <span>{{profitData.md_add_invest ? profitData.md_add_invest : 0}} {{profitData.symbol}}</span>
          <i class="h5_icon icon_black_arrow"></i>
        </p>
      </div>
      <div class="d_flex_space mt_10" v-show="profitData.show_dividend_header">
        <p class="tx13 color_T6">{{ mdfileterFn($t('md_income')) }}</p>
        <p class="tx13 color_T6 font_bold">{{profitData.net_income ? profitData.net_income : 0}} {{profitData.symbol}}</p>
      </div>


    </div>

    <div class="yesterday_profit">
      <p class="d_flex_space ">
        <span>{{ $t('income_profit.tips25') }}</span>
        <span class="font_bold">{{ profitData.administer_invite_profit ? profitData.administer_invite_profit : 0 }}
          SCF</span>
      </p>
      <p class="d_flex_space mt_10">
        <span>上月贡献值</span>
        <span class="font_bold">0</span>
      </p>
      <p class="d_flex_space mt_10">
        <span>累计贡献值</span>
        <span class="font_bold">0</span>
      </p>
      <p class="d_flex_space mt_10">
        <span>贡献值奖励</span>
        <span class="font_bold flex">
          <span>0 USDT</span>
             
            <i class="h5_icon icon_gray_arrow"></i>
        </span>
      </p>
      <p class="d_flex_space mt_10" v-show="profitData.community_level > 0">
        <span>{{ $t('yesterday_contribute') }}</span>
        <span class="font_bold">{{ profitData.last_month_contribution ? profitData.last_month_contribution : 0 }}
          </span>
      </p>
      <p class="d_flex_space mt_10" v-show="profitData.community_level > 0">
        <span>{{ $t('total_contribute') }}</span>
        <span class="font_bold">{{ profitData.accumulated_contribution ? profitData.accumulated_contribution : 0 }}
          </span>
      </p>


    </div>
    <p class="btn">
      <button @click="handleMiningExtraction">{{ $t('income_profit.tips28') }}</button>
    </p>
    <div class="chain_tab d_flex">
      <span class="chain_item " @click="tabChainFn(key)" :class="checkChainType == key ? 'chain_cur' : ''" v-for="(list, key) in detaiList" :key="key">{{list}}</span>
    </div>
    
    <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      
      <section v-for="(list, index) in resData" :key="index" >
        <div class="profit_details" v-show="checkChainType == 0">
          <div class="left tx_bottom">
            <p>
              <span class="tx2">{{ list.type == 1 ? $t('income_profit.tips23') : list.type == 2 ? $t('income_profit.tips24') : list.type == 3 ? $t('team_benefits') : list.type == 4 ? $t('income_profit.tips41') : ''}}</span>
            </p>
            <p class="tx6">
              <span>{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
            </p>
          </div>
          <div class="right">
            <p class="txx"> <span class="tx1">{{list.amount}} {{ list.symbol }}</span> ≈ $244687.23 FUSD</p>
            <p class="txx">{{ list.status == 1 ? $t('issued') : '' }}</p>
          </div>
        </div>
        <div v-show="checkChainType == 1">
          <div class="profit_details" v-if="list.tx_id?.length > 0" @click="goRouter('https://scan.scfchain.io/transactions_details/?hash=' + list.tx_id , {}, true)">
            <div class="hash">
              <p>Hash：{{ substr(list.tx_id, 0, 10) }}...{{ substr(list.tx_id, -10) }} </p>
              <i class="h5_icon icon_green_arrow"></i>
            </div>
            <div class="left tx_bottom">
              <p>{{$t('income_profit.tips43')}}{{ list.amount }} {{list.symbol}}</p>
              <p class="tx6">
                <span>{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
              </p>
            </div>
            <div class="right">
              <p class="status" :class="(list.status == 1 || list.status == 2 || list.status == 3) ?'success' : 'fail'">{{ (list.status == 1 || list.status == 2) ? $t('extracting') : list.status == 3 ? $t('extract_success') : $t('extract_fail') }}</p>
            </div>
          </div>
          
          <div class="profit_details" v-else>
            <div class="left tx_bottom">
              <p>{{$t('income_profit.tips43')}}{{ list.amount }} {{list.symbol}}</p>
              <p class="tx6">
                <span>{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
              </p>
            </div>
            <div class="right">
              <p class="status" :class="(list.status == 1 || list.status == 2 || list.status == 3) ?'success' : 'fail'">{{ (list.status == 1 || list.status == 2) ? $t('extracting') : list.status == 3 ? $t('extract_success') : $t('extract_fail') }}</p>
            </div>
          </div>
        </div>
        
      </section>
    </div>
    <div class="com_no_data_box" v-show="!resData.length">
      <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
      <p class="com_no_data_tx">{{ $t('nodata') }}</p>
    </div>

    <transition>
      <article class="com_popup_main" v-show="isMiningExtraction">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('income_profit.tips28') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="head">
              <p class="ml-5">{{ $t('voteActivityView.payment_address') }}</p>
            </div>
            <div class="input text">
              <input type="text" class="input_tx" :placeholder="$t('income_profit.tips52')" v-model="receive_address">
            </div>
            <div class="head has-icon">
              <i class="h5_icon icon_ftc"></i>
              <p class="ml-5">SCF</p>
            </div>
            <div class="input">
              
              <input type="number" pattern="[0-9]*" class="input_tx" :placeholder="$t('income_profit.tips16')" v-model="amount" disabled>
              <!-- <span @click="spendAll">{{$t('tips_view.msg186')}}</span> -->
            </div>
            <div class="detail">
              <p>{{ $t('income_profit.tips34') }}</p>
              <p>{{ profitData.no_draw_amount }} SCF</p>
            </div>
            <div class="detail">
              <p>{{ $t('income_profit.tips35') }}</p>
              <p>5%</p>
            </div>
            <div class="detail wrap">
              <p>{{ $t('income_profit.tips36') }}</p>
              <p>{{ $t('income_profit.tips37') }}</p>
            </div>
            <div class="button">
              <button @click="hideFn">{{ $t('cancel') }}</button>
              <button @click="nextStep" class="btn-default" :class="receive_address != '' ? 'btn-cur' : ''" :disabled="receive_address == '' ? true : false">{{ $t('extract') }}</button>
            </div>
          </div>
        </section>
      </article>
    </transition>

    <transition>
      <article class="com_popup_main" v-show="isConfirm">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('income_profit.tips28') }}</span>
            <i class="h5_icon icon_white_close" @click="isConfirm = false"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="detail final">
              <p>{{ $t('extract_number') }}</p>
              <p>{{ amount }} SCF</p>
            </div>
            <div class="detail final">
              <p>{{ $t('income_profit.tips35') }}</p>
              <p>5%</p>
            </div>
            <div class="detail final">
              <p>{{ $t('expected_to_arrive') }}</p>
              <p>{{ scientificNotationToString(actualReceive) }}</p>
            </div>
            <div class="detail final">
              <p>{{ $t('order_view.to_wallet') }}</p>
              <!-- <p>{{ Wallet_Uuid }}</p> -->
              <p>{{ receive_address }}</p>
            </div>
            <div class="button">
              <button @click="hideConfimFn">{{ $t('cancel') }}</button>
              <button @click="submit" class="btn-default btn-cur">{{ $t('extract') }}</button>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <com-loading :isShow="isShowLoading" text="loading"></com-loading>

    <el-drawer v-model="drawer" :with-header="false" direction="btt" size="auto">
      <div class="box_drawer">
        <div class="header">
          <h3>团队总奖励剩余额度</h3>
          <i class="h5_icon icon_close" @click="drawer = false"></i>
        </div>
        <div class="content">
          <p>团队总奖励剩余额度将决定团队奖励是否发放，当团队总奖励剩余额度为 0 时，将停止发放团队奖励。此时，用户可通过增加自身质押流动性金额，获得团队总奖励剩余额度。</p>
          <p><strong>团队总奖励剩余额度 = 团队总奖励额度 - 团队总奖励</strong></p>
          <p>团队总奖励额度以账户质押总金额基数* 管理级别额度倍数进行计算</p>
          <p class="btn" @click="drawer = false">我知道了</p>
        </div>
      </div>
    </el-drawer>
    <el-drawer v-model="drawer2" :with-header="false" direction="btt" size="auto">
      <div class="box_drawer">
        <div class="header">
          <h3>最小新增质押金额</h3>
          <i class="h5_icon icon_close" @click="drawer2 = false"></i>
        </div>
        <div class="content">
          <p>最小新增质押金额是指根据以下公式计算出的最低新增质押金额：</p>
          <p><strong>最小新增质押金额 = (用户9月15日0点以后团队总奖励 / 管理级别额度倍数) - 当前质押总金额</strong></p>
          <p>用户团队总奖励额度中的团队总奖励只统计2023年9月15日00:00以后的收益数据。最小新增质押金额数值表示要获取团队总奖励时，所需新增流动性质押的最小金额。</p>
          <p class="btn" @click="drawer2 = false">我知道了</p>
        </div>
      </div>
    </el-drawer>
  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "team_profit",
  components: {
    comLoading
  },
  data() {
    return {
      isConfirm: false,
      receive_address: '',
      isShowLoading: false,
      detaiList: [this.$t('income_profit.tips29'), this.$t('income_profit.tips12')],
      checkChainType: 0,
      symbolStatic: 'USDT',
      resData: [],
      profitData: {
        
      },
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      levelArr: [this.$t('general_user'), 'GM', 'VP', 'SVP', 'ED', 'MD', 'MD+'],
      communityArr: [],
      isMiningExtraction: false,
      Wallet_Uuid: null,
      drawer: false,
      drawer2: false,
    }
  },
  computed: {
    actualReceive() {
      return this.amount - this.amount * 0.05
    },
    amount() {
      return this.profitData.no_draw_amount
    },
  },
  beforeUnmount () {
    window.Wallet_Uuid = null
  },
  created() {
    this.Wallet_Uuid = window.Wallet_Uuid
    this.communityArr = {
      1: { value: 1, label: this.$t('community_view.msg_13') },
      2: { value: 2, label: this.$t('community_view.msg_14') },
      3: { value: 3, label: this.$t('community_view.msg_15') },
      4: { value: 4, label: this.$t('community_view.msg_16') },
    }
    this.resInit(this.checkChainType)
    this.profitInit()
  },
  methods: {
    showPopup(type) {
      switch (type) {
        case 'case1':
          this.drawer = true
          break;
        case 'case2':
          this.drawer2 = true
          break;
        default:
          break;
      }
    },
    hideFn() {
      this.isMiningExtraction = false
      this.amount = ''
      this.receive_address = ''
    },
    hideConfimFn() {
      this.isConfirm = false
      this.amount = ''
      this.receive_address = ''
    },
    spendAll() {
      this.amount = this.profitData.no_draw_amount
    },
    nextStep() {
      // if (this.amount > this.profitSummary.remaining_reward) {
      //   this.messageFn(this.$t('income_profit.tips53'))
      //   return false
      // }
      if (this.amount <= 0) {
        this.messageFn(this.$t('income_profit.tips54'))
        return false
      }
      // if (this.receive_address.length < 42) {
      //   this.messageFn(this.$t('income_profit.tips55'))
      //   return false
      // }
      this.isConfirm = true
      this.isMiningExtraction = false
    },
    submit() {
      const req = {
        // amount: this.amount,
        addr: this.receive_address
      }
      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/create_extract_team_order`, req, 'post')
        .then(res => {
          if (res.result) {
            this.amount = ''
            this.receive_address = ''
            this.isConfirm = false
            this.messageFn(this.$t('success'))
            this.checkChainType = 0
            this.resInit(this.checkChainType)
            this.profitInit()
            this.isShowLoading = false
          } else {
            this.isShowLoading = false
            this.messageFn(this.$t('fail'))
          }
        })
        .catch(res => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        })
    },
    
    handleMiningExtraction() {
      this.isMiningExtraction = true
    },
    tabChainFn(index) {
      this.checkChainType = index
      this.page = 1
      this.loadInfinite = false
      this.resInit(index)
    },
    mdfileterFn(str) {
      if (str) {
        str = str.replace(/MD\+/g, this.profitData.management_name)
      }
      return str
    },
    typeTxFn(type) {
      if (type == 5) {
        return this.$t('direct_invitation_income')
      }
      if (type == 6) {
        return this.$t('indirect_income')
      }
      if (type == 7) {
        return this.$t('management_earnings')
      }
      if (type == 11) {
        return this.$t('platform_revenue')
      }
    },
    statusTxFn(status) {
      if (status == 1) {
        return this.$t('issued')
      }
      if (status == 2) {
        return this.$t('extracted')
      }
    },
    resInit(type) {
      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.resData = []
      if (type == 0) {
        this.$ajax(`${this.Utils.URL.goAPPURL}profit/withdraw_detail_list`, req, 'get')
        .then(res => {
          this.isLoad = false
          let arr = res.list ? res.list : []

          if (arr.length < this.size) {
            this.loadInfinite = true
          }

          this.page = this.page + 1
          this.resData = this.resData.concat(arr)
          
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.isLoad = false
          this.messageFn(res.msg)
        })
      } else {
        const req = {
          page: this.page,
          size: this.size,
          type: 2
        }
        this.$ajax(`${this.Utils.URL.goAPPURL}profit/withdraw_record_list`, req, 'get')
        .then(res => {
          this.isLoad = false
          let arr = res.list ? res.list : []

          if (arr.length < this.size) {
            this.loadInfinite = true
          }

          this.page = this.page + 1
          this.resData = this.resData.concat(arr)
          this.total = parseFloat(res.total)
        })
        .catch(res => {
          this.isLoad = false
          this.messageFn(res.msg)
        })
      }
      
    },
    profitInit() {
       let req = {
 
 
       };
       this.$ajax(`${this.Utils.URL.goAPPURL}profit/invite_profit_page_details`, req, "get")
         .then((res) => {
          this.profitData = res ? res : {}
          // example
          // this.profitData.management_level = 5
          // this.profitData.community_level = 5
       .catch((res) => {
           this.messageFn(res.msg)
        });
      })
     },

  }
}
</script>

<style lang="scss">
.h5_team_profit_view {
  padding: 0.16rem;
  .com_popup_info_box {
    padding-top: 0.12rem !important;
    .head {
      display: flex;
      place-items: center;
      margin-bottom: 0.1rem;
      &.has-icon {
          padding-left: 0.02rem;
        }
      .h5_icon {
        margin-right: 0.05rem;
      }
      p {
        font-size: 0.15rem;
        font-weight: bold;
      }
    }
    .input {
      display: flex;
      place-items: center;
      justify-content: space-between;
      background: #F0F0F0;
      border-radius: .05rem;
      padding: 0.2rem;
      margin-bottom: .1rem;
      input {
        flex-basis: 80%;
      }
      span {
        color: #EE9922;
      }
      &.text {
        input {
          flex-basis: 100%;
        }
      }
    }
    .detail {
      display: flex;
      place-items: center;
      p {
        margin: .1rem 0;
        &:first-child {
          color: #778E99;
        }
      }
      &.wrap {
        flex-wrap: wrap;
        flex-direction: column;
        place-items: flex-start;
        p {
          margin: 0;
          color: #778E99;
        }
      }
      &.final {
        justify-content: space-between;
        p {
          &:first-child {
            flex-basis: 40%;
          }
          &:last-child {
            word-break: break-all;
            text-align: right;
          }
        }
      }
    }
    .button {
      display: flex;
      place-items: center;
      margin-top: 0.2rem;
      button {
        flex-basis: 50%;
        background: rgba(119, 142, 153, 0.08);
        text-align: center;
        border-radius: 0.06rem;
        font-weight: 700;
        font-size: 0.16rem;
        color: #778E99;
        line-height: 0.44rem;
        width: 100%;
        border: 0;
        &:first-child {
          margin-right: 0.08rem;
        }
        &:last-child {
          margin-left: 0.08rem;
        }
        &.btn-cur {
          background: #EE9922;
          color: white;
        }
      }
    }
  }
  .icon_white_close {
    filter: invert(1);
  }
  .btn {
    margin-bottom: .1rem;

    button {
      background: #e92;
      text-align: center;
      border-radius: 0.06rem;
      font-weight: 700;
      font-size: .16rem;
      color: #fff;
      line-height: .44rem;
      width: 100%;
      border: 0;
      margin-top: .1rem;
    }
  }

  .chain_tab {
    margin: 0.2rem -0.16rem;

    .chain_item {
      width: 50%;
      text-align: center;
      font-size: .14rem;
      font-weight: 700;
      padding: 0 0 0.1rem;
      color: #778e99;
      border-bottom: 0.5px solid rgba(119, 142, 153, 0.2);
    }

    .chain_cur {
      color: #e92;
      border-bottom: 1px solid #e92;
    }
  }

  .level_tx {
    padding: 0.08rem 0 0.24rem;
    color: $FFF;
    font-size: 0.19rem;
    font-weight: bold;
  }

  .level_bg {
    background: $T8;
    height: 1.5rem;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
  }

  .profit_box {
    background: $BG3;
    border-radius: 0.06rem;
    padding-top: 0.03rem;
    margin-bottom: 0.12rem;

    .fin_box {
      margin: 0 0.03rem;
      border-radius: 0.03rem;
      background: $FFF;
      padding: 0.16rem 0.13rem;

      .tx2_box {
        padding-bottom: 0.12rem;
        border-bottom: 1px solid $BR2;
        margin-bottom: 0.12rem;
      }

      .flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: .12rem;
        .item {
          flex-basis: 50%;
          &.right {
            text-align: right;
          }
        }
       
        .tx2_box {
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .item_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $BR2;

      padding-top: 0.1rem;
    }

    .tx1 {
      font-size: 0.13rem;
      color: $T7;
      margin-bottom: 0.03rem;
    }

    .tx2 {
      font-size: 0.2rem;
      color: $T8;
      font-weight: bold;
    }

    .tx3 {
      font-size: 0.14rem;
      color: $T8;
      font-weight: bold;
    }

    .tx4 {
      font-size: 0.14rem;
      color: $T7;
    }

    .tx5 {
      font-size: 0.12rem;
      color: $T7;
      margin-bottom: 0.03rem;
    }

    .tx6 {
      font-size: 0.13rem;
      font-weight: bold;
      color: $T6;
    }

    .tx7_main {
      padding: 0.14rem 0.16rem 0.04rem;
    }

    .tx7_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.1rem;
      .flex {
        display: flex;
        align-items: center;
        .h5_icon {
          width: .11rem;
          height: 0.12rem;
        }
      }
    }

    .tx7 {
      font-size: 0.13rem;
      color: $T6;
    }
  }

  .yesterday_profit {
    padding: 0.14rem 0.16rem;
    background: $BG3;
    font-size: 0.13rem;

    color: $T6;

    border-radius: 0.06rem;
    margin-bottom: 0.24rem;

    .flex {
      display: flex;
      align-items: center;
      .h5_icon {
        width: .12rem;
        height: 0.12rem;
      }
    }
  }

  .profit_tx {
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
    margin-bottom: 0.12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .profit_details {
    background: $BG3;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    display: flex;
    // place-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    .hash {
      display: flex;
      justify-content: space-between;
      place-items: center;
      width: 100%;
      border-bottom: 1px solid $BR2;
      padding-bottom: .05rem;
      margin-bottom: .05rem;
      color: #00968D;
    }
    .tx_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
    }

    .tx_bottom {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      font-size: 0.12rem;
      color: $T7;
      p {
        margin: 0.05rem 0;
      }
    }
    .right {
      text-align: right;
      p {
        margin: 0.05rem 0;
      }
      .status {
        &.success {
          color: #EE9922;
        }
        &.fail {
          color: #DD4466;
        }
      }
    }
    .tx1 {
      font-size: 0.13rem;
      color: #EE9922;
    }

    .tx2 {
      font-size: 0.13rem;
      color: #224455;
      font-weight: bold;
    }

    .tx3 {
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.05rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }

    .tx4 {
      color: $T12;
      background: $T11;
    }

    .tx5 {
      color: $T14;
      background: $T13;
    }

    .tx9 {
      color: $T16;
      background: $T15;
    }

    .tx6 {}

    .tx7 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon_gray_arrow {
        width: 0.12rem;
        height: 0.12rem;
      }
    }
    .txx {
      color: #778E99;
    }
  }
  .box_drawer {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.16rem;

      border-bottom: 1px solid rgba(119, 142, 153, 0.12);

      .title {
        font-size: 0.16rem;
        font-weight: bold;
        color: #224455;
      }
    }
    .content {
      padding: 0.16rem 0.16rem 0.24rem;
      p {
        margin-top: 0.1rem;
        &:first-child {
          margin-top: 0;
        }
      }
      .btn {
        line-height: 0.44rem;
        font-size: 0.16rem;
        color: rgba(238, 153, 34, 1);
        border-radius: 0.06rem;
        font-weight: bold;
        background: rgba(119, 142, 153, 0.08);
        text-align: center;
        margin-top: 0.24rem;
      }
    }
  }
}</style>
