<template>
  {{tx_id}}
  <article class="h5_otc_free_form_view">
    <section class="order_info mb_15">
      <p class="tx14 mb_15 color_T6 d_flex">
        <span class="mr_5">{{ $t('merchant_1') }}:</span>
        <span>{{ resData.merchant_name }}</span>
      </p>
      <p class="tx14 mb_15 color_T6 d_flex">
        <span class="mr_5">{{ $t('unit_price') }}:</span>
        <span>{{ resData.currency_symbol }}{{resData.price}}</span>
      </p>
      <p class="tx14 mb_15 color_T6 d_flex">
        <span class="mr_5">{{ $t('surplus_1') }}:</span>
        <span>{{ resData.amount }} {{resData.coin_data.code}}</span>
      </p>
      <div class="d_flex_wrap">
        <p class="type_box d_flex" v-for="pay in resData.card_list">
          <span class="type_round " :style="{backgroundColor: pay.color}"></span>
          <span class="tx13 color_T6">{{pay.pay_way_name}}</span>
        </p>
      </div>
    </section>
    <section class="order_coin">
      <div class="d_flex mb_10">
        <div class="com_icon_img_box4">
          <img :src="resData.coin_data.icon_url" class="com_coin_img"/>
          <i class="h5_icon_1 com_icon_subscript" :class="resData.coin_data.type == 'tron' ? 'icon_subscript_tron' : resData.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
        </div>
        <span class="tx14 color_T6 font_bold ">{{ resData.coin_data.code }}</span>
        <span class="subscript_tx ml_5 bsc_color" v-show="resData.coin_data.contract_addr" :class="resData.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[resData.coin_data.type] }}</span></span>
      </div>
      <div class="input_box d_flex_space mb_10">
        <input type="number" pattern="[0-9]*" v-model="num" class="input_num" :placeholder="resData.buy_base + $t('tips_view.msg24')"/>
        <p class="tx14 d_flex">
          <span class="color_T7">{{ resData.coin_data.code }}</span>
          <span class="ml_10 color_T8" @click="allFn">{{ $t('all') }}</span>
        </p>
      </div>
      <p class="tx12 color_T9 mb_5" v-show="errortx">{{ errortx }}</p>
      <p class="tx12 color_T8 mb_5">{{ $t('money_1') }}: {{ resData.currency_symbol }}{{ freePrice }}</p>
      <p class="tx12 color_T7">
        {{ $t('norm_1') }}: {{ resData.currency_symbol }}{{resData.min_quota}}-{{ resData.currency_symbol }}{{resData.max_quota}}
      </p>
      <p class="input_sub" :class="num ? 'input_sub_cur' : ''" @click="showConfirmFn">{{ $t('buy_1') }}</p>
    </section>
    <p class="buy_tip tx13 d_flex_cen" @click="isShowTips = true">
      <span>{{ $t('tips_view.msg91') }}</span>
      <i class="h5_icon icon_question_red ml_5"></i>
    </p>
    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg92') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg93') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg94') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg95') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg96') }}</p>
            <p class="tx5 color_T6 ">{{ $t('tips_view.msg97') }}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="isShowTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowConfirm">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg103') }}</span>
          <i class="h5_icon icon_close" @click="isShowConfirm = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="buy_otc_main">
              <p class="price_tx tx_cen font_bold">{{ resData.currency_symbol }}{{freePrice}}</p>
              <p class="d_flex_space mb_10 tx14">
                <span class="color_T7">{{ $t('buy_price') }}</span>
                <span class="color_T6">{{ resData.currency_symbol }}{{resData.price}}</span>
              </p>
              <p class="d_flex_space tx14 mb_30">
                <span class="color_T7">{{ $t('buy_num') }}</span>
                <span class="color_T6">{{ num }} {{resData.coin_data.code}}</span>
              </p>
              <div class="payment_list1  d_flex_space " @click="fastCurrencyTab(index)" :class="fastCurrencyIndex == index ? 'payment_list_cur' : ''" v-for="(list, index) in resData.card_list">
                <p class="d_flex">
                  <i class="h5_icon  payment_30 mr_10" :style="{backgroundColor: list.color}" ></i>
                  <span class="tx16 tx_over_hidden" style="width: 1.8rem">{{ list.pay_way_name}}</span>
                </p>
                <p class="d_flex" >
                  <span class="mr_15 tx14" v-show="resData.price && resData.price != 0">{{resData.currency_symbol}}{{resData.price}}</span>
                  <span class="mr_15 tx14" v-show="resData.price == 0 || !resData.price">{{$t('tips_view.msg166')}}</span>
                  <i class="h5_icon icon_check_no " v-show="resData.price &&  resData.price != 0" :class="fastCurrencyIndex == index ? 'icon_check_yes' : ''"></i>
                </p>
              </div>
            </div>

            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="submitFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowTips1">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg98') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips1 = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg99') }}</p>
            <p class="tx5 color_T9 mb_10">{{ $t('tips_view.msg100') }} {{pledgeData.amount}} {{pledgeData.coin_data.code}};</p>
            <p class="tx5 color_T9 mb_10">{{ $t('tips_view.msg101') }}</p>
            <p class="tx5 color_T9 ">{{ $t('tips_view.msg102') }}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips1 = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="tranCoinFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowTips2">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg168') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips2 = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 ">{{ $t('tips_view.msg167') }}</p>

            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips2"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="showConfirmFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>


    <article class="com_popup_main" v-show="isShowContact">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('contact_information') }}</span>
          <i class="h5_icon icon_close" @click="isShowContact = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('name_1') }}</p>
            </div>
            <div class="com_popup_input_box mb_25"  >
              <div>
                <input type="text"  v-model="name" class="com_popup_input_tx" :placeholder="$t('tips_view.msg141')"/>
              </div>
            </div>
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('phone') }}</p>
            </div>
            <div class="com_popup_input_box d_flex phone_main mb_5"  >
              <p class="area_box d_flex_space" @click="isShowCountry = true">
                <span class="tx14 font_bold" :class="country.area_code ? 'color_T6' : 'color_T7'">{{country.area_code ? '+' + country.area_code : $t('please_1')}}</span>
                <i class="h5_icon icon_gray_arrow_down_small"></i>
              </p>
              <div>
                <input type="number" pattern="[0-9]*" v-model="phone" class="com_popup_input_tx" :placeholder="$t('tips_view.msg162')"/>
              </div>
            </div>
            <p class="tx13 color_T9 mb_20">{{ $t('tips_view.msg169') }}</p>


            <div class="com_popup_btn_box d_flex_space">
              <input type="button" class="com_popup_input_btn " :class="name && phone && country.area_code ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="contactSubmitFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="search_main" v-show="isShowCountry">
      <div class="bg"></div>
      <div class="search_box">
        <p class="search_title_tx d_flex_space"><span>{{$t('select_code')}}</span> <i class="h5_icon icon_close" @click="isShowCountry = false"></i></p>
        <div class="search_input_box d_flex_space">
          <input type="text" class="input_tx" v-model="searchCountryTx" @input="searchCodeFn($event)" :placeholder="$t('search_code')"/>
          <img src="../../assets/img/icon/search.svg" class="search_img" />
        </div>
        <div class="item_box">
          <div class="item  d_flex_space" :class="list.area_code == country.area_code ? 'item_cur' : ''" v-for="list in countryData" v-show="list.isShow"  @click="checkAreaFn('country', list)">
            <p class="d_flex">
              <span class="mr_10">{{ list.country }}</span>
              <i class="h5_icon icon_orange_success" v-show="list.area_code == country.area_code"></i>
            </p>
            <span>{{list.area_code}}</span>

          </div>
        </div>
      </div>
    </article>


    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>

  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "otc_free_form",
  data() {
    return {
      isShowConfirm: false,
      isShowTips2: false,
      isShowTips1: false,
      isShowTips: false,
      isTrue: true,
      resData: {
        buy_base: '',
        coin_data: {},
        card_list: []
      },
      size: 30,
      page: 1,
      num: '',
      errortx: '',
      fastCurrencyIndex: 0,
      isAjax: false,
      status: 'loading',
      text: '',
      isShowLoading: false,
      tx_id: '',
      pledgeData: {coin_data: {}},
      name: '',
      phone: '',
      country: {
        area_code: '',
        country: '',
        id: '',
      },
      isShowContact: false,
      isShowCountry: false,
      isShowCity: false,
      countryData: [],
      searchCountryTx: '',
    }
  },
  components: {
    comLoading,
  },
  computed: {
    freePrice () {
      if (this.num && this.resData.price) {
        return this.padRight(this.num * this.resData.price, 2)
      }
      return 0
    },
  },
  created() {
    this.resInit()

  },
  mounted() {
  },
  methods: {
    contactSubmitFn () {
      if (this.isAjax) {
        return  false
      }
      if (this.name === '') {
        this.messageFn(this.$t('tips_view.msg141'))
        return  false
      }
      if (this.phone === '') {
        this.messageFn(this.$t('tips_view.msg162'))
        return  false
      }
      if (this.phone.length <7 || this.phone.length > 11) {
        this.messageFn(this.$t('tips_view.msg163'))
        return  false
      }
      this.isAjax = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/set_user_information`, {
        area_code: this.country.area_code,
        name: this.name,
        phone: this.phone,
      }, 'post')
          .then(res => {
            this.isShowContact = false
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('success')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          })
          .catch(res => {
            this.isAjax = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg108')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })
    },

    checkAreaFn (type, data) {
      if (type == 'city') {


      } else {
        this.country = data
        this.isShowCountry = false

      }
    },
    searchCodeFn () {
      if (this.searchCountryTx) {
        this.countryData.forEach((val, index) => {
          if (val.area_code.indexOf(this.searchCountryTx) >= 0) {
            this.countryData[index].isShow = true
          } else {
            this.countryData[index].isShow = false
          }
        })
      } else {
        this.countryData.forEach((val, index) => {
          this.countryData[index].isShow = true
        })
      }
    },
    searchCountryFn (eve) {

      let req = {
        page: 1,
        size: 1000,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}buy/area_code`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            arr.forEach((val, i) => {
              arr[i].isShow = true
              if (this.storeLang == 'cn') {
                if (val.area_code == 86 && !this.country.area_code) {
                  this.country = val
                }
              } else {
                if (val.area_code == 1 && !this.country.area_code) {
                  this.country = val
                }
              }

            })
            this.countryData = arr
            // this.searchCityFn()

          })
          .catch(res => {

            this.messageFn(res.msg)
          })

    },
     showConfirmFn () {
      this.errortx = ''
       this.isShowTips2 = false
      let num = parseFloat(this.num)
      let base_amount = parseFloat(this.resData.buy_base)
      let min_buy_coin = parseFloat(this.resData.min_amount)
      let max_buy_coin = parseFloat(this.resData.max_amount)
      if (!this.resData.is_buy) {
        this.messageFn(this.$t('tips_view.msg105'))
        return false
      }
      if (num < min_buy_coin) {
        this.errortx = this.$t('tips_view.msg109') + min_buy_coin + this.resData.coin_data.code
        return false
      }
      if (num > max_buy_coin) {
        this.errortx = this.$t('tips_view.msg110') + max_buy_coin + this.resData.coin_data.code
        return false
      }
      if (new window.windowBigNumber(num).mod(base_amount).toString() != 0) {
        this.errortx = this.$t('tips_view.msg111') + base_amount + this.$t('tips_view.msg24')
        return false
      }

       this.status = 'loading'
       this.isShowLoading = true
       this.isShowConfirm = false
       this.text = ''
       if (this.isAjax) {
         return false
       }
       this.isAjax = true

      this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_judge`, {
        order_id: this.$route.query.id,
       chain_type: this.resData.coin_data.type,
        pay_way: this.resData.card_list[this.fastCurrencyIndex].pay_way,
       currency: this.resData.currency,
       coin_id: this.resData.coin_data.id,
       price: parseFloat(this.resData.price),
       amount: parseFloat(this.num),
     }, "get")
         .then( async (res) => {
           this.isAjax = false
           setTimeout(() => {
             this.isShowConfirm = true
             this.isShowLoading = false
           }, 2000)



         })
         .catch((res) => {
           this.isAjax = false
           setTimeout(() => {
             this.isShowLoading = false
           }, 2000)
           if (res.code == 161) {
             this.searchCountryFn()
             this.isShowContact = true
             return false
           }
           if (res.code == 120) {
             this.messageFn(this.$t('tips_view.msg106'))
             return false
           }
           if (res.code == 121) {
             this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
             return false
           }
           if (res.code == 123) {
             this.messageFn(this.$t('tips_view.msg107'))
             return false
           }
           if (res.code == 151) {
             // 价格变动
             this.messageFn(this.$t('tips_view.msg124'))
             this.resInit()
             return false
           }
           if (res.code == 152) {
             this.messageFn(this.$t('tips_view.msg125'))
             this.resInit()

             return false
           }
           if (res.code == 153) {
             this.messageFn(this.$t('tips_view.msg105'))
             return false
           }
           if (res.code == 154) {
             this.messageFn(this.$t('tips_view.msg165'))
             return false
           }

           this.messageFn(this.$t('tips_view.msg108'))
         });



    },
    async coinPledgeFn () {
      let req = {
        chain_type: this.storeChainType.toLocaleLowerCase(),
        pay_type: this.resData.card_list[this.fastCurrencyIndex].pay_way,
        currency: this.resData.currency,
        coin_id: this.resData.coin_data.id,
        order_id: this.$route.query.id,
        price: parseFloat(this.resData.price),
        amount: parseFloat(this.num),
      }
      this.isAjax = false
      await this.$ajax(`${this.Utils.URL.goAPPURL}buy/buyer_pledge`, req, "get")
          .then((res) => {
            this.pledgeData = res ? res : {amount: '', coin_data: {}, isAjax: true}
            this.pledgeData.isAjax = true
          })
          .catch((res) => {
            this.pledgeData = {amount: '', coin_data: {}, isAjax: false}
            this.status = 'error'
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            if (res.code == 120) {
              this.messageFn(this.$t('tips_view.msg106'))
              return false
            }
            if (res.code == 121) {
              this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
              return false
            }
            if (res.code == 123) {
              this.messageFn(this.$t('tips_view.msg107'))
              return false
            }
            if (res.code == 151) {
              // 价格变动
              this.messageFn(this.$t('tips_view.msg124'))
              this.resInit()
              this.isShowTips2 = true
              return false
            }
            if (res.code == 152) {
              this.messageFn(this.$t('tips_view.msg125'))
              this.resInit()
              return false
            }
            if (res.code == 153) {
              this.messageFn(this.$t('tips_view.msg105'))
              return false
            }
            if (res.code == 154) {
              this.messageFn(this.$t('tips_view.msg165'))
              return false
            }

            this.messageFn(this.$t('tips_view.msg108'))
          });
    },
    async tranCoinFn () {
      this.status = 'loading'
      this.isShowLoading = true
      this.text = this.$t('tips_view.msg8')
      if (this.isAjax) {
        return false
      }
      this.isShowTips1 = false
      let txId = ''
      this.isAjax = true
      if (this.pledgeData.coin_data.contract_addr) {
        await this.transferUSDTFn(this.pledgeData.amount, this.pledgeData.to_addr, this.pledgeData.coin_data.contract_addr).then(res => {
          txId = res
        }).catch(res => {
          txId = ''
          this.isAjax = false
          this.status = 'error'
          this.text = this.$t('tips_view.msg12')
          setTimeout(() => {
            this.isShowLoading = false
          }, 2000)
          return false
        })
      } else {
        await this.transactionCoinFn(this.pledgeData.amount, this.pledgeData.to_addr).then(res => {
          txId = res
        }).catch(res => {
          txId = ''
          this.isAjax = false
          this.status = 'error'
          this.text = this.$t('tips_view.msg12')
          setTimeout(() => {
            this.isShowLoading = false
          }, 2000)
          return false
        })
      }
      this.isAjax = false
      this.tx_id = txId
      if (!txId) {

        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      } else {
        this.submitFn()
      }

    },
    async submitFn () {
      this.status = 'loading'
      this.isShowLoading = true
      this.isShowConfirm = false
      this.text = ''
      if (this.isAjax) {
        return false
      }
      this.isAjax = true

      await this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_judge`, {
        chain_type: this.resData.coin_data.type,
        pay_type: this.resData.card_list[this.fastCurrencyIndex].pay_way,
        currency: this.resData.currency,
        coin_id: this.resData.coin_data.id,
        price: parseFloat(this.resData.price),
        amount: parseFloat(this.num),
        order_id: this.$route.query.id,
      }, "get")
          .then( async (res) => {
            await this.coinPledgeFn()
            if (!this.pledgeData.isAjax) {
              return  false
            }
            if (this.pledgeData.amount != 0 && this.pledgeData.amount && !this.tx_id) {
              this.isShowLoading = false
              this.isShowTips1 = true
              return false
            }

            let req = {
              order_id: this.$route.query.id,
              coin_id: this.resData.coin_data.id,
              currency: this.resData.currency,
              num: parseFloat(this.num),
              pay_way: this.resData.card_list[this.fastCurrencyIndex].pay_way,
              bank_id: this.resData.card_list[this.fastCurrencyIndex].bank_id,
              price: parseFloat(this.resData.price),
            }
            if (this.tx_id) {
              if (this.tx_id.transaction){
                this.tx_id = this.tx_id.transaction.txID
              }
              req.tx_id = this.tx_id
            }

            this.$ajax(`${this.Utils.URL.goAPPURL}buy/free_order_confirm`, req, "post")
                .then((res) => {
                  this.tx_id = ''
                  this.isAjax = false
                  this.isShowLoading = false
                  this.goRouter('/otc_buy_order', {id: res.order_id})
                })
                .catch((res) => {
                  this.isAjax = false
                  this.status = 'error'
                  setTimeout(() => {
                    this.isShowLoading = false
                  }, 2000)
                  if (res.code == 120) {
                    this.messageFn(this.$t('tips_view.msg106'))
                    return false
                  }
                  if (res.code == 121) {
                    this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
                    return false
                  }
                  if (res.code == 123) {
                    this.messageFn(this.$t('tips_view.msg107'))
                    return false
                  }
                  if (res.code == 151) {
                    // 价格变动
                    this.messageFn(this.$t('tips_view.msg124'))
                    this.resInit()
                    return false
                  }
                  if (res.code == 152) {
                    this.messageFn(this.$t('tips_view.msg125'))
                    this.resInit()
                    return false
                  }
                  if (res.code == 153) {
                    this.messageFn(this.$t('tips_view.msg105'))
                    return false
                  }
                  if (res.code == 154) {
                    this.messageFn(this.$t('tips_view.msg165'))
                    return false
                  }

                  this.messageFn(this.$t('tips_view.msg108'))
                });

          })
          .catch((res) => {
            this.isAjax = false
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            if (res.code == 120) {
              this.messageFn(this.$t('tips_view.msg106'))
              return false
            }
            if (res.code == 121) {
              this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
              return false
            }
            if (res.code == 123) {
              this.messageFn(this.$t('tips_view.msg107'))
              return false
            }
            if (res.code == 151) {
              // 价格变动
              this.messageFn(this.$t('tips_view.msg124'))
              this.resInit()
              return false
            }
            if (res.code == 152) {
              this.messageFn(this.$t('tips_view.msg125'))
              this.resInit()

              return false
            }
            if (res.code == 153) {
              this.messageFn(this.$t('tips_view.msg105'))
              return false
            }
            if (res.code == 154) {
              this.messageFn(this.$t('tips_view.msg165'))
              return false
            }

            this.messageFn(this.$t('tips_view.msg108'))
          });







    },
    fastCurrencyTab (index) {
      this.fastCurrencyIndex = index
    },
    allFn () {
      this.num = this.resData.max_amount
    },
    resInit() {

      let req = {
        order_id: this.$route.query.id
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/free_buy_coin_detail`, req, "get")
          .then((res) => {

            this.resData = res ? res : {coin_data: {}, card_list: []}

            if (parseFloat(this.resData.amount) < parseFloat(this.resData.max_amount)) {

              let maxAmount = parseInt((this.resData.max_quota / this.resData.price) / this.resData.buy_base)
              this.resData.max_amount = new window.windowBigNumber(maxAmount).times(this.resData.buy_base).toString()
            }


          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_otc_free_form_view {
  min-height: 100vh;
  padding: 0.16rem;
  .phone_main{
    .area_box{
      padding: 0 0.12rem 0 0rem;
      margin-right: 0.1rem;
      width: 0.9rem;
      border-right: 1px solid $BR4;
    }
    .com_popup_input_tx{
      width: 2.2rem !important;
    }
  }
  .order_info{
    border-radius: 0.06rem;
    padding: 0.16rem;
    background: $BG2;
    .type_box{
      margin-right: 0.16rem;
      .type_round{
        border-radius: 0.03rem;
        margin-right: 0.05rem;
        width: 0.06rem;
        height: 0.14rem;
      }
      .bank_bg{
        background: $T8;
      }
      .wechat_bg{
        background: rgba(9, 187, 7, 1);
      }
      .alipay_bg{
        background: rgba(23, 119, 255, 1);
      }
    }
  }
  .order_coin{
    border-radius: 0.06rem;
    padding: 0.16rem;
    background: $BG2;
    .input_box{
      border-radius: 0.06rem;
      border: 1px solid $BR4;
      background: $BG4;
      padding: 0 0.12rem;
      .input_num{
        line-height: 0.56rem;
        width: 1.9rem;
        font-weight: bold;
        font-size: 0.2rem;
        color: $T6;
      }
    }

    .input_sub{
      margin-top: 0.3rem;
      text-align: center;
      border-radius: 0.06rem;
      font-size: 0.16rem;
      font-weight: bold;
      line-height: 0.44rem;
      background: $BG1;
      color: $T4;
    }
    .input_sub_cur{
      background: $T8;
      color: $FFF;
    }
  }
  .buy_otc_main{
    .price_tx{
      font-size: 0.26rem;
      margin-bottom: 0.24rem;
    }
    .payment_list1:last-child{
      margin-bottom: 0;
    }
    .payment_list1{
      padding: 0.15rem 0.16rem;
      border: 1px solid $BG2;
      background: $BG2;
      border-radius: 0.06rem;
      margin-bottom: 0.1rem;
      .payment_30{
        width: 0.06rem;
        height: 0.2rem;
        border-radius: 0.03rem;
      }
    }
    .payment_list_cur{
      border: 1px solid $T8;
      background: $T11;
    }
  }
  .buy_tip{
    background: $T9_01;
    border-radius: 0.06rem;
    color: $T9;
    padding: 0.14rem 0.16rem;
    margin-top: 0.24rem;
  }
  .search_main{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 996;
    .bg{
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100vw;
      height: 100vh;
    }
    .search_box{
      z-index: 997;
      position: absolute;
      width: 100vw;
      bottom: 0;
      background: $FFF;
    }
    .search_title_tx{
      padding: 0.14rem 0.16rem;
      font-weight: bold;
      font-size: 0.16rem;
      color: $T6;
    }
    .search_input_box{
      margin: 0 0.16rem 0rem;
      padding: 0 0.12rem ;
      background: $BG2;
      border-radius: 0.16rem;

    }
    .input_tx{
      line-height: 0.32rem;
      font-size: 0.12rem;
      width: 2rem;
    }
    .search_img{
      margin-left: 0.1rem;
    }
    .item_box{
      margin-top: 0.12rem;
      border-top: 1px solid $BR2;
      padding: 0.1rem 0;
      height: 3.5rem;
      overflow-y: scroll;
    }
    .item{
      font-size: 0.16rem;
      color: $T6;
      padding: 0.1rem 0.16rem;
    }
    .item_cur{
      background: $T11;
    }
  }
}

</style>
