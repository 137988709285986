<template>
  <article class="h5_team_md_invite_view">
    <div class="invite_box">
      <p class="d_flex_space mb_15">
        <span class="tx14 color_FFF">{{mdfileterFn($t('md_invest'))}}</span>
        <span class="tx14 color_FFF font_bold">{{profitData.md_add_valid_invest ? profitData.md_add_valid_invest : 0}} USDT</span>
      </p>
      <p class="d_flex_space mb_15">
        <span class="tx14 color_FFF">{{mdfileterFn($t('total_team_investment'))}}</span>
        <span class="tx14 color_FFF font_bold">{{profitData.md_add_total_invest ? profitData.md_add_total_invest : 0}} USDT</span>
      </p>
      <p class="d_flex_space">
        <span class="tx14 color_FFF">{{mdfileterFn($t('md_tip5'))}}</span>
        <span class="tx14 color_FFF ">{{dataFormat(profitData.updater_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
    </div>
    <div class="revenue_box tx_cen">
      <p class="title_tx">{{$t('illustrate_1')}}</p>
      <p class="tx13 color_T6 tx_l">{{mdfileterFn($t('md_tip6'))}}</p>
    </div>
    <p class="profit_tx">
      <span>{{ $t('exclude_details') }}</span>
      <i class="h5_icon icon_filter" v-show="false"></i>
    </p>
    <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      <section class="profit_details" v-for="(list, index) in resData" :key="index">
        <div class="tx_top">
          <p class="tx1">{{substr(list.wallet_id, 0, 6)}}...{{substr(list.wallet_id, -6)}} <span class="color_T8">({{list.management_name}})</span></p>
          <p>
            <span class="tx2">{{ list.amount }} {{list.coin_data.code}}</span>
            <span class="tx3" :class="list.coin_data.type + '_color'">
            <span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span>
          </span>
          </p>
        </div>
        <div class="tx_bottom">
          <p class="tx6">
            <span>{{$t('upgradation_1')}} {{list.management_name}} {{$t('times')}}</span>
          </p>
          <p class="tx7">
            <span>{{dataFormat(list.updater_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </div>
      </section>
    </div>
    <div class="com_no_data_box" v-show="!resData.length">
      <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
      <p class="com_no_data_tx">{{$t('nodata')}}</p>
    </div>


  </article>
</template>

<script>
export default {
  name: "team_md_invite",
  data () {
    return {
      resData: [],
      profitData: {},
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      levelArr: [this.$t('general_user'), 'GM', 'VP', 'SVP', 'ED', 'MD', 'MD+'],
      communityArr: []
    }
  },
  created() {

    this.profitInit()
    this.resInit()
  },
  methods: {
    mdfileterFn (str) {
      if (str && this.profitData.management_name) {
        str = str.replace(/MD\+/g, this.profitData.management_name)
      }
      return  str
    },
    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/md_add_remove_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    profitInit () {
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/md_add_page_details`, req, "get")
          .then((res) => {
            this.profitData = res ? res : {}

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_team_md_invite_view{
  padding: 0.16rem;
  .invite_box{
    position: relative;
    margin-left: -0.16rem;
    margin-top: -0.16rem;
    width: 100vw;
    background: $T8;
    padding: 0.2rem 0.16rem;
    margin-bottom: 0.46rem;
  }

  .revenue_box{
    border: 1px solid $BR5;
    border-radius: 0.06rem;
    padding: 0rem 0.16rem 0.13rem;
    margin-bottom: 0.24rem;
    line-height: 1.8;
    .title_tx{
      display: inline-block;
      top: -0.11rem;
      margin: -0.1rem auto 0;
      background: $BG2;
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 0.14rem;
      border: 1px solid $BR5;
      border-radius: 0.06rem;
      padding: 0.08rem 0.16rem;
    }
  }

  .profit_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
    margin-bottom: 0.12rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pd20_16{

  }
  .profit_details{
    background: $BG3;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    .tx_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
    }
    .tx_bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.12rem;
      color: $T7;
    }
    .tx1{
      font-size: 0.13rem;
      color: $T6;
    }
    .tx2{
      font-size: 0.13rem;
      color: $T8;
      font-weight: bold;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.05rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }
    .tx4{
      color: $T12;
      background: $T11;
    }
    .tx5{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
    .tx6{}
    .tx7{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon_gray_arrow{
        width: 0.12rem;
        height: 0.12rem;
      }
    }
  }
}
</style>
