<template>
  <article class="h5_link_wallet_introduce_view">
    <div class="tab_box">
      <p class="item" @click="tabFn(0)" :class="tabIndex == 0 ? 'item_cur' : ''">Metamask</p>
      <p class="item" @click="tabFn(1)" :class="tabIndex == 1 ? 'item_cur' : ''">Imtoken</p>
      <p class="item" @click="tabFn(2)" :class="tabIndex == 2 ? 'item_cur' : ''">TokenPocket</p>
    </div>
    <section class="info_main" v-show="tabIndex == 0">
      <h2 class="h2_title">{{ $t('link_wallet_introduce_view.msg1') }}</h2>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">1</span></span>

          <div>
            <p class="tx1">{{ $t('link_wallet_introduce_view.msg2') }}</p>
            <p class="tx2">{{ $t('link_wallet_introduce_view.msg13') }}</p>
          </div>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg1"></div>
        </div>
        <div class="p_line"></div>
      </section>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">2</span></span>
          <p class="tx1">
            {{ $t('link_wallet_introduce_view.msg3') }}
            <span class="color_T8">{{ $t('link_wallet_introduce_view.msg4') }}</span>
            {{ $t('link_wallet_introduce_view.msg5') }}
          </p>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg2"></div>
        </div>
        <div class="p_line" v-show="false"></div>
      </section>

    </section>

    <section class="info_main" v-show="tabIndex == 1">
      <h2 class="h2_title">1. {{ $t('link_wallet_introduce_view.msg1') }}</h2>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">1</span></span>

          <div>
            <p class="tx1">{{ $t('link_wallet_introduce_view.msg6') }}</p>
            <p class="tx2">{{ $t('link_wallet_introduce_view.msg14') }}</p>
          </div>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg3"></div>
        </div>
        <div class="p_line"></div>
      </section>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">2</span></span>
          <p class="tx1">
            {{ $t('link_wallet_introduce_view.msg3') }}
            <span class="color_T8">{{ $t('link_wallet_introduce_view.msg4') }}</span>
            {{ $t('link_wallet_introduce_view.msg5') }}
          </p>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg4"></div>
        </div>
        <div class="p_line" v-show="false"></div>
      </section>

      <h2 class="h2_title">2. {{ $t('link_wallet_introduce_view.msg7') }}</h2>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">1</span></span>

          <div>
            <p class="tx1">
              {{ $t('link_wallet_introduce_view.msg8') }}
              <span class="color_T8">{{ $t('link_wallet_introduce_view.msg15') }}</span>
            </p>

          </div>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg5"></div>
        </div>
        <div class="p_line"></div>
      </section>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">2</span></span>
          <p class="tx1">
            {{ $t('link_wallet_introduce_view.msg9') }}
          </p>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg6"></div>
        </div>
        <div class="p_line" v-show="false"></div>
      </section>

    </section>

    <section class="info_main" v-show="tabIndex == 2">
      <h2 class="h2_title">{{ $t('link_wallet_introduce_view.msg10') }}</h2>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">1</span></span>

          <div>
            <p class="tx1">{{ $t('link_wallet_introduce_view.msg11') }}</p>
            <p class="tx2">{{ $t('link_wallet_introduce_view.msg12') }}</p>
          </div>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg7"></div>
        </div>
        <div class="p_line"></div>
      </section>
      <section class="box_1">
        <div class="title_box">
          <span class="round1"><span class="round2">2</span></span>
          <p class="tx1">
            {{ $t('link_wallet_introduce_view.msg16') }}
          </p>
        </div>
        <div class="tx_cen">
          <div class="h5_icon phone_bg8"></div>
        </div>
        <div class="p_line" v-show="false"></div>
      </section>

    </section>

  </article>
</template>

<script>
export default {
  name: "link_wallet_introduce",
  data() {
    return {
      tabIndex: 0,
    };
  },
  created() {

  },
  mounted() {
  },
  methods: {
    tabFn (type) {
      this.tabIndex = type
    }

  },
};
</script>

<style lang="scss">
.h5_link_wallet_introduce_view{

  .info_main{
    padding: 0.16rem;
  }
  .tab_box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BR;
    background: $FFF;
    .item{
      width: 33.33%;
      font-size: 0.14rem;
      color: $T7;
      padding: 0.12rem 0;
      text-align: center;
    }
    .item_cur{
      color: $T8;
      font-weight: bold;
      border-bottom: 1px solid $T8;
    }
  }
  .h2_title{
    margin:0.04rem 0 0.2rem;
    font-size: 0.24rem;
    color: $T6;
    font-weight: bold;
  }
  .title_box{
    font-size: 0.14rem;
    color: $T6;
    display: flex;
    .tx1{
      flex: 1;
      word-break:break-all;
      word-wrap:break-word;
    }
    .tx2{
      color: $T8;
      font-weight: bold;
      flex: 1;
      word-break:break-all;
      word-wrap:break-word;
    }
  }
  .box_1{
    position: relative;
    overflow: hidden;
  }
  .round1{
    width: 0.26rem;
    height: 0.26rem;
    display: inline-block;
    border-radius: 100%;
    position: relative;
    background: rgba(238, 153, 34, 0.25) ;

    margin-right: 0.05rem;
    .round2{
      text-align: center;
      line-height: 0.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      color: $FFF;
      background: $T8;
      transform: translate(-50%,-50%);
      display: inline-block;
      border-radius: 100%;
      width: 0.2rem;
      height: 0.2rem;
    }
  }
  .phone_bg1{
    background-image: url("../../assets/img/index/metamask1.png");
    width: 2.5rem;
    height: 3rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg2{
    background-image: url("../../assets/img/index/metamask2.png");
    width: 2.5rem;
    height: 4rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg3{
    background-image: url("../../assets/img/index/imtoken1.png");
    width: 2.5rem;
    height: 3rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg4{
    background-image: url("../../assets/img/index/imtoken2.png");
    width: 2.5rem;
    height: 3.6rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg5{
    background-image: url("../../assets/img/index/imtoken3.png");
    width: 2.5rem;
    height: 3rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg6{
    background-image: url("../../assets/img/index/imtoken4.png");
    width: 2.5rem;
    height: 3.6rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg7{
    background-image: url("../../assets/img/index/tokenpocket1.png");
    width: 2.5rem;
    height: 3rem;
    margin: 0.2rem 0 0.4rem;
  }
  .phone_bg8{
    background-image: url("../../assets/img/index/tokenpocket2.png");
    width: 2.5rem;
    height: 4rem;
    margin: 0.2rem 0 0.4rem;
  }
  .p_line{
    position: absolute;
    height: 100%;
    left: 0.12rem;
    top: 0.12rem;
    z-index: -1;
    border: 2px dashed rgba(238, 153, 34, 0.35);
  }
  .id_box{
    border-radius: 0.06rem;
    text-align: center;
    font-size: 0.14rem;
    background: $T8;
    color: $FFF;
    padding: 0.14rem 0.16rem;
    margin: 0.2rem 0;
    word-break:break-all;
    word-wrap:break-word;
  }
}
</style>