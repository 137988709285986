<template>
  <article class="comp_loading_view" v-show="isShow">
    <section class="com_loading_bg"></section>
    <section class="com_loading_box">
      <p v-show="status == 'loading'"><i class="h5_icon icon_loading"></i></p>
      <p v-show="status == 'success'"><i class="h5_icon icon_success"></i></p>
      <p v-show="status == 'error'"><i class="h5_icon icon_error"></i></p>
      <p v-show="text" class="loading_tx">{{text}}</p>
    </section>
  </article>
</template>

<script>
export default {
  name: 'compLoading',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    status: {
      type: String,
      default: 'loading',
    },
    text: {
      type: String,
      default: '',
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.comp_loading_view{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  .com_loading_bg{
    position: absolute;
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
  .loading_tx{
    font-size: 0.16rem;
    font-weight: bold;
    margin-top: 0.16rem;
    color: $T6;
  }
  .com_loading_box{
    z-index: 3;
    position: absolute;
    min-width: 2.1rem;
    padding: 0.3rem 0.16rem;
    background: $FFF;
    border-radius: 0.06rem;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
  .icon_loading{
    animation: roundRotate 3s infinite linear;
  }


}
</style>
