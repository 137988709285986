<template>
  <article class="h5_loan_wallet_view">
    <section class="wallet_head">
      <section class="no_wallet_box" v-show="!resData.is_wallet_addr">
        <p><i class="h5_icon icon_no_loan_wallet"></i></p>
        <p class="tx1">{{$t('tips_view.msg67')}}</p>
        <p><span class="tx2" @click="goRouter('/loan_details')">{{$t('tips_view.msg68')}}</span></p>
      </section>
      <section class="wallet_box" v-show="resData.is_wallet_addr">
        <p class="tx1">{{$t('tips_view.msg69')}}(2/3)</p>
        <p class="tx2">${{coinCurrency}}</p>
        <div class="item_box">
          <div class="item" @click="tipsFn(resData.power_type)">
            <p><i class="h5_icon icon_loan_invest"></i></p>
            <p class="tx3">{{ $t('coupon_view.msg6') }}</p>
          </div>
          <div class="item" @click="goRouter('/collection_qrcode', {addr: resData.wallet_addr, chain_type: resData.chain_type})">
            <p><i class="h5_icon icon_loan_collection"></i></p>
            <p class="tx3">{{ $t('collection') }}</p>
          </div>
          <div class="item" v-if="resData.is_switch" @click="goRouter('/loan_wallet_list', {addr: addr})">
            <p><i class="h5_icon icon_select_wallet"></i></p>
            <p class="tx3">{{ $t('switch_wallet') }}</p>
          </div>
        </div>
      </section>
    </section>
    <section class="coin_main" >
      <div class="title_box d_flex_space">
        <p class="tx1">{{ $t('assets') }}</p>
        <p class="d_flex" @click="goRouter('/coin_list')">
          <span class="tx2">{{ $t('credit_coin') }}</span>
          <i class="h5_icon icon_orange_arrow"></i>
        </p>
      </div>
      <section class="coin_box" v-show="coin_list.length">
        <div class="item d_flex_space" v-for="(list, index) in coin_list" :key="index" @click="goLink(list)">
          <div class="left_box">
            <div class="com_icon_img_box">
              <img :src="list.icon_url" class="com_coin_img"/>
              <i  class="h5_icon com_icon_subscript" :class="list.type == 'tron' ? 'icon_subscript_tron' : list.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
          </div>
          <div class="right_box d_flex_space">
            <div>
              <p class="d_flex">
                <span class="tx1">{{list.code}}</span>
                <span class="tx3  " :class="list.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span></span>

              </p>
            </div>
            <div class="tx_r">
              <p class="tx1">{{list.amount}}</p>
              <p class="tx2">${{list.currency}}</p>
            </div>
          </div>
        </div>
      </section>
      <div class="com_no_data_box" v-show="!coin_list.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </section>

    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('tips_view.msg85')}}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6">{{$t('tips_view.msg76')}}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('tips_view.msg77')" @click="openLink"/>
            </div>
          </div>
        </div>
      </section>
    </article>

  </article>
</template>

<script>

export default {
  name: "loan_wallet",
  data () {
    return {
      resData: {},
      coin_list: [],
      coinCurrency: 0,
      isShowTips: false,
      addr: '',
      coin_data: {
        type: 'tron'
      }
    }
  },
  created () {
    this.resInit(this.$route.query.addr ? this.$route.query.addr : '')
  },

  mounted () {

  },
  methods: {
    openLink () {
      window.open(this.Utils.URL.h5URL + '/download?langId=' + this.storeLang, '_blank');
    },
    goLink (list) {

      list.wallet_addr = this.addr
      window.sessionStorage.setItem('listData', JSON.stringify(list))
      this.goRouter('/coin_transfer_history')
    },
    tipsFn (type) {
      if (type == 1) {
        this.messageFn(this.$t('tips_view.msg70'))
      }
      if (type == 2) {
        this.messageFn(this.$t('tips_view.msg71'))
      }
      if (type == 0) {
        this.isShowTips = true
      }
    },
    resInit (addr) {
      let req = {

      }
      if (addr) {
        req.wallet_addr = addr
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/borrow_account_details`, req, 'get')
          .then(res => {
            this.resData = res ? res : {}
            if (req.wallet_addr) {
              let arr = res.list ? res.list : []
              arr.forEach((coin, index) => {

                this.storeChangeCurrencyList.forEach(val => {
                  if (coin.coin_id == val.coin_id) {
                    arr[index].currency = parseFloat(this.padRight(coin.amount * val.price, 2))
                    this.coinCurrency = this.padRight(this.coinCurrency + coin.currency, 2)
                  }
                  if (!arr[index].currency) {
                    arr[index].currency = 0
                  }
                })


              })
              this.coin_list = arr
              this.addr = req.wallet_addr
            } else {
              if (res.is_wallet_addr) {
                this.resInit(res.wallet_addr)
              }
            }
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  },
  watch: {
    storeChangeCurrencyList() {
      this.coin_list.forEach((coin, index) => {

        this.storeChangeCurrencyList.forEach(val => {
          if (coin.coin_id == val.coin_id) {
            this.coin_list[index].currency = parseFloat(this.padRight(coin.amount * val.price, 2))
            this.coinCurrency = this.padRight(this.coinCurrency + coin.currency, 2)
          }
        })
      })


    }
  }
}
</script>

<style lang="scss">
.h5_loan_wallet_view{
  .wallet_head{
    height: 2.12rem;
    background: $T8;
    .no_wallet_box{
      padding: 0.24rem;
      text-align: center;
      .tx1{
        font-size: 0.14rem;
        color: $FFF;
        margin: 0.1rem auto 0.12rem;
      }
      .tx2{
        display: inline-block;
        width: 1.2rem;
        line-height: 0.36rem;
        border-radius: 0.06rem;
        background: $FFF;
        color: $T8;
        font-size: 0.14rem;
        font-weight: bold;
      }
    }
    .wallet_box{
      padding-top: 0.2rem;
      color: $FFF;
      text-align: center;
      .tx1{
        font-size: 0.16rem;
        margin-bottom: 0.05rem;
      }
      .tx2{
        font-size: 0.32rem;
        font-weight: bold;
        margin-bottom: 0.16rem;
      }
      .tx3{
        font-size: 0.14rem;
        font-weight: bold;
      }
      .item_box{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
      .item:last-child{
        border-right: 0px;

      }
      .item{
        width: 33.33%;
        border-right: 1px solid $FFF;

        border-image-slice: 1;
        border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 38.73%, rgba(255, 255, 255, 0.2) 61.17%, rgba(255, 255, 255, 0) 100%);

      }
    }
  }
  .coin_main{
    padding: 0.32rem 0.16rem 0;
    .title_box{
      margin-bottom: 0.14rem;
      .tx1{
        color: $T6;
        font-weight: bold;
        font-size: 0.17rem;
      }
      .tx2{
        font-size: 0.14rem;
        color: $T8;
      }
      .icon_orange_arrow{
        width: 0.12rem;
        height: 0.12rem;
      }
    }
    .coin_box{
      .item:last-child{
        .right_box{
          border-bottom: 0px;
        }
      }
      .item{
        .coin_img{
          width: 0.4rem;
          height: 0.4rem;
        }
        .right_box{
          padding: 0.16rem 0;
          margin-left: 0.16rem;
          flex: 1;
          border-bottom: 1px solid $BR2;
        }
        .tx1{
          color: $T6;
          font-size: 0.17rem;
          font-weight: bold;
        }
        .tx2{
          font-size: 0.13rem;
          color: $T7;
        }
        .tx3{
          font-size: 0.12rem;
          margin-left: 0.1rem;
          padding: 0.01rem 0.03rem;
          border-radius: 0.08rem;
          font-weight: bold;
          display: inline-block;
        }
        .tx4{
          color: $T12;
          background: $T11;
        }
        .tx5{
          color: $T14;
          background: $T13;
        }
        .tx9{
          color: $T16;
          background: $T15;
        }
      }
    }
  }
}
</style>