<template>
  <article class="h5_otc_buy_order_view">
    <div class="head_bg"></div>
    <section class="order_status_box">
      <p class="tx1">{{ statusTx }}</p>
      <div class="tx2 d_flex">
        <p class="d_flex" v-show="time > 0 && (resData.status == 1 || resData.status == 2)">
          <i class="h5_icon icon_time_white"></i>
          <span class="ml_5 mr_5">{{ minute }} {{$t('minute_1')}} {{second}} {{$t('second_1')}}</span>
        </p>
        <span>{{statusTip}}</span>
      </div>
    </section>
    <section class="form_main">
      <div class="order_info_box mb_15">
        <div class="title_tx mb_20 color_T6 tx16 font_bold">{{ $t('buy_1') }} {{ resData.coin_data.code }}</div>
        <div class="d_flex_space mb_15">
          <p class="tx14 color_T7">{{ $t('total_price') }}</p>
          <p class="tx_r info_r"><span class="font_bold color_T8 tx16">{{ resData.currency_symbol }}{{resData.amount}}</span></p>
        </div>
        <div class="d_flex_space mb_15">
          <p class="tx14 color_T7">{{ $t('unit_price') }}</p>
          <p class="tx_r info_r"><span class=" color_T6 tx14">{{ resData.currency_symbol }}{{resData.price}}</span></p>
        </div>
        <div class="d_flex_space mb_15">
          <p class="tx14 color_T7">{{ $t('number') }}</p>
          <p class="tx_r info_r"><span class=" color_T6 tx14">{{resData.num}} {{ resData.coin_data.code }}</span></p>
        </div>
        <div class="d_flex_space mb_15" style="width: 100%">
          <p class="tx14 color_T7">{{ $t('order_id') }}</p>
          <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><span class=" color_T6 tx14">{{resData.order_id}}</span><i class="h5_icon icon_copy ml_5" @click="copyText(resData.order_id)"></i></p>
        </div>
        <div class="d_flex_space ">
          <p class="tx14 color_T7">{{ $t('order_time') }}</p>
          <p class="tx_r  info_r "><span class=" color_T6 tx14">{{dataFormat(resData.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span></p>
        </div>
      </div>
      <div class="order_info_box mb_15" v-show="resData.phone">
        <div class="title_tx mb_20 color_T6 tx16 font_bold d_flex_space">
          <span>{{$t('merchant_contact')}}</span>
          <a :href="'tel:'+resData.area_code+resData.phone" class="d_flex"><i class="h5_icon icon_otc_tel"></i></a>
        </div>
        <div class="d_flex_space mb_15">
          <p class="tx14 color_T7">{{ $t('merchant_name') }}</p>
          <p class="tx_r info_r"><span class=" color_T6 tx14">{{ resData.name }}</span></p>
        </div>
        <div class="d_flex_space">
          <p class="tx14 color_T7">{{ $t('contact_information') }}</p>
          <p class="tx_r info_r"><span class=" color_T6 tx14">+{{resData.area_code}} {{ resData.phone }}</span></p>
        </div>
        <div class="d_flex_space mt_15" style="width: 100%" v-for="con in resData.social_account_list">
          <p class="tx14 color_T7 d_flex">
            <i class="h5_icon mr_5" :class="'icon_otc_contact_'+con.name"></i>
            <span>{{con.name}}</span>
          </p>
          <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><span class=" color_T6 tx14">{{con.account}}</span><i class="h5_icon icon_copy ml_5" @click="copyText(con.account)"></i></p>
        </div>
      </div>
      <div class="order_info_box mb_15">
        <div class="  color_T6 d_flex tx16 font_bold" :class="resData.status == 1 ? 'title_tx mb_15' : ''"><i class="h5_icon payment_icon  mr_10" :style="{backgroundColor: resData.card_details.color }"></i><span>{{resData.card_details.pay_way_name}}</span></div>
        <div v-show="resData.status == 1">
          <div class="d_flex_space mb_15" v-show="resData.card_details.otc_card_type == 1">
            <p class="tx14 color_T7">{{ $t('bank_name') }}</p>
            <p class="tx_r info_r"><span class=" color_T6 tx14">{{ resData.card_details.bank_name }}</span></p>
          </div>
          <div class="d_flex_space mb_15" style="width: 100%" v-show="resData.card_details.otc_card_type == 1 && resData.card_details.bank_branch">
            <p class="tx14 color_T7">{{ $t('bank_branch') }}</p>
            <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><span class=" color_T6 tx14">{{ resData.card_details.bank_branch }}</span><i class="h5_icon icon_copy ml_5" @click="copyText(resData.card_details.bank_branch)"></i></p>
          </div>
          <div class="d_flex_space mb_15" style="width: 100%">
            <p class="tx14 color_T7">{{ $t('name_1') }}</p>
            <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><span class=" color_T6 tx14">{{ resData.card_details.name }}</span><i class="h5_icon icon_copy ml_5" @click="copyText(resData.card_details.name)"></i></p>
          </div>
          <div class="d_flex_space " style="width: 100%">
            <p class="tx14 color_T7">{{resData.card_details.otc_card_type == 2 ? $t('alipay_account') : resData.card_details.otc_card_type == 3 ? $t('wechat_account') : resData.card_details.otc_card_type == 1 ? $t('bank_account') : $t('account_1')}}</p>
            <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><span class=" color_T6 tx14">{{ resData.card_details.account }}</span><i class="h5_icon icon_copy ml_5" @click="isShowTips = true"></i></p>
          </div>
          <div class="d_flex_space mt_15" style="width: 100%" v-show="resData.card_details.otc_card_type == 5">
            <p class="tx14 color_T7">{{ $t('other_info') }}</p>
            <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><span class=" color_T6 tx14">{{ resData.card_details.other_data }}</span></p>
          </div>
          <div class="d_flex_space mt_15" style="width: 100%" v-show="(resData.card_details.otc_card_type == 2 || resData.card_details.otc_card_type == 3 || resData.card_details.otc_card_type == 4) && resData.card_details.qr_code">
            <p class="tx14 color_T7">{{ $t('qrcode_1') }}</p>
            <p class="tx_r d_flex_r info_r " style="justify-content: flex-end;"><i class="h5_icon icon_payment_qrcode" @click="() => {this.copyType='qrcode';this.isShowTips=true;}"></i></p>
          </div>
        </div>

      </div>
      <p class="buy_tip tx13 d_flex_cen mb_25" v-show="resData.status == 1">
        <span>{{ $t('tips_view.msg113') }}</span>
      </p>
     <div class="mb_30" v-show="resData.currency == 'CNY' && resData.status == 1">
       <p class="font_bold tx14 tips_tx1 mb_10">{{ $t('tips_view.msg114') }}</p>
       <p class="d_flex tx13 tips_tx1 mb_10" v-show="resData.card_details.otc_card_type != 2 && resData.card_details.otc_card_type != 3" style="align-items: baseline">
         <span class="red_round mr_5"></span>
         <span>{{ $t('tips_view.msg115') }}</span>
       </p>
       <p class="d_flex tx13 tips_tx1 mb_10" style="align-items: baseline">
         <span class="red_round mr_5"></span>
         <span>{{ $t('tips_view.msg116') }}</span>
       </p>
       <p class=" tx13 color_T7 mb_10" >
         <span>{{ $t('tips_view.msg117') }}</span>
       </p>
       <p class=" tx13 color_T7  mb_10" v-show="resData.card_details.otc_card_type != 2 && resData.card_details.otc_card_type != 3">
         <span class="">{{ $t('tips_view.msg118') }}<span class="tips_tx1">{{ $t('tips_view.msg119') }}</span>{{ $t('tips_view.msg120') }}</span>
       </p>
       <p class="d_flex tx13 tips_tx1 mb_10" v-show="resData.card_details.otc_card_type != 2 && resData.card_details.otc_card_type != 3" style="align-items: baseline">
         <span class="red_round mr_5"></span>
         <span>{{ $t('tips_view.msg121') }}</span>
       </p>
       <p class=" tx13 color_T7 mb_10" v-show="resData.card_details.otc_card_type != 2 && resData.card_details.otc_card_type != 3">
         <span>{{ $t('tips_view.msg122') }}</span>
         <span class="tips_tx1">{{ $t('tips_view.msg123') }}</span>。
       </p>
       <p class="d_flex tx13 tips_tx1 mb_10" style="align-items: baseline">
         <span class="red_round mr_5"></span>
         <span>{{ $t('tips_view.msg126') }}</span>
       </p>
       <p class=" tx13 color_T7 mb_10" >
         <span>{{ $t('tips_view.msg127') }}</span>
       </p>
     </div>
      <p class="submit_box d_flex_space" v-show="resData.status == 1">
        <span class="input_sub input_cl" @click="isShowCancel = true">{{ $t('cancel') }}</span>
        <span class="input_sub input_con" @click="isShowPayment = true">{{ $t('my_paid') }}</span>
      </p>
      <p class="submit_box d_flex_space" v-show="resData.status == 2 && time <= 0">
        <span class="input_sub input_con1" @click="goRouter('/otc_appeal', {id: resData.order_id, type: 'buy'})">{{ $t('tips_view.msg128') }}</span>
      </p>
      <p class="submit_box d_flex_space" v-show="resData.status == 6 && resData.appeal_status == 2 && !resData.is_buyer_submit">
        <span class="input_sub input_con1" @click="goRouter('/otc_appeal_submit', {id: resData.order_id, type: 'buy'})">{{ $t('tips_view.msg129') }}</span>
      </p>
      <p class="submit_box d_flex_space" v-show="resData.status == 6 && resData.appeal_status == 2 && resData.is_buyer_submit">
        <span class="input_sub input_con2" >{{ $t('submit_done') }}</span>
      </p>
      <p class="submit_box d_flex_space" v-show="resData.status == 4">
        <span class="input_sub input_con1" @click="goRouter('/invest_order_details', {id: resData.order_id})">{{ $t('tips_view.msg164') }}</span>
      </p>
    </section>
    <article class="qrcode_alert" v-show="isShowqrcode">
      <div class="qrcode_box">
        <img class="qrcode_img mb_30" :src="resData.card_details.qr_code">
        <p class="tx_cen"><i class="h5_icon icon_close_white" @click="isShowqrcode = false"></i></p>
      </div>
    </article>

    <article class="com_popup_main" v-show="isShowPayment">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg130') }}</span>
          <i class="h5_icon icon_close" @click="isShowPayment = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_20">{{ $t('tips_view.msg131') }}</p>
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('name_1') }}</p>
            </div>
            <div class="com_popup_input_box mb_25"  >
              <div>
                <input type="text"  v-model="name" class="com_popup_input_tx" :placeholder="resData.card_details.otc_card_type == 2 ? $t('tips_view.msg174') : resData.card_details.otc_card_type == 3 ? $t('tips_view.msg175') : resData.card_details.otc_card_type == 1 ? $t('tips_view.msg176') : $t('tips_view.msg141')"/>
              </div>
            </div>
            <div class="tx1 mb_10">
              <p class="color_T6">{{resData.card_details.otc_card_type == 2 ? $t('alipay_name') : resData.card_details.otc_card_type == 3 ? $t('wechat_name') : resData.card_details.otc_card_type == 1 ? $t('bank_card_no') : $t('account_1')}}</p>
            </div>
            <div class="com_popup_input_box mb_25"  >
              <div>
                <input :type="resData.pay_type == 'BankCard' ? 'number' : 'text'" pattern="[0-9]*" v-model="account" class="com_popup_input_tx" :placeholder="resData.card_details.otc_card_type == 2 ? $t('tips_view.msg172') : resData.card_details.otc_card_type == 3 ? $t('tips_view.msg173') : resData.card_details.otc_card_type == 1 ? $t('tips_view.msg171') : $t('tips_view.msg140')"/>
              </div>
            </div>
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('transfer_img') }}({{$t('optional_1')}})</p>
            </div>
            <div class="upload_main d_flex_cen">
              <p class="img_item tx_cen" v-show="imgSrc || imgShow">
                <i class="h5_icon icon_loading" v-show="imgShow"></i>
                <img class="upload_img" v-show="imgSrc" :src="Utils.URL.imgURL + imgSrc"/>
                <i class="h5_icon icon_del_red" v-show="imgSrc" @click="delUploadFn('img')"></i>
              </p>
              <el-upload
                  v-show="!imgSrc && !imgShow"
                  class="avatar-uploader"
                  accept="image/*"
                  action="''"
                  :auto-upload="false"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :on-change="(val => beforeAvatarUpload('img',  val))"
              >
                <i class="h5_icon icon_upload_add"></i>
              </el-upload>
            </div>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowPayment = false"/>
              <input type="button" class="com_popup_input_btn " :class="name && account ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm_pay')" @click="paySubmitFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowCancel">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg132') }}</span>
          <i class="h5_icon icon_close" @click="isShowCancel = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg133') }}</p>
            <p class="tx5 color_T6 ">{{ $t('tips_view.msg134') }}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowCancel = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="cancelSubmitFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg135') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg136') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg137') }}<span class="color_T8">"{{ $t('my_paid') }}"</span>{{ $t('tips_view.msg138') }}</p>
            <p class="tx5 color_T9 ">{{ $t('tips_view.msg139') }}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="showTypeFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "otc_buy_order",
  data() {
    return {
      name: '',
      account: '',
      isTrue: true,
      time: 0,
      timeFn: null,
      resData: {
        coin_data: {},
        card_details: {}
      },
      size: 30,
      page: 1,
      isShowTips: false,
      isShowqrcode: false,
      isShowCancel: false,
      isShowPayment: false,
      bank_card: '',
      imgShow: false,
      imgSrc: '',
      minute: '',
      second: '',
      copyType: '',
      statusTx: '',
      statusTip: '',
      status: 'loading',
      text: '',
      isShowLoading: false,
      isAjax: false,
    }
  },
  components: {
    comLoading,
  },
  beforeUnmount() {
    clearInterval(this.timeFn)
  },
  created() {
    this.resInit()
  },
  mounted() {

  },
  methods: {
    paySubmitFn () {
      if (this.resData.status != 1) {
        return false
      }
      if (this.isAjax) {
        return  false
      }

      if (this.account === '') {
        this.messageFn(this.resData.card_details.otc_card_type == 2 ? this.$t('tips_view.msg172') : this.resData.card_details.otc_card_type == 3 ? this.$t('tips_view.msg173') : this.resData.card_details.otc_card_type == 1 ? this.$t('tips_view.msg171') : this.$t('tips_view.msg140'))
        return  false
      }
      if (this.name === '') {
        this.messageFn(this.resData.card_details.otc_card_type == 2 ? this.$t('tips_view.msg174') : this.resData.card_details.otc_card_type == 3 ? this.$t('tips_view.msg175') : this.resData.card_details.otc_card_type == 1 ? this.$t('tips_view.msg176') : this.$t('tips_view.msg141'))
        return  false
      }


      this.isAjax = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''

      let req = {
        order_id: this.$route.query.id,
        op_type: 1,
        account: this.account,
        card_name: this.name,
      }
      if (this.imgSrc) {
        req.transfer_pic = this.imgSrc
      }
      this.isShowPayment = false
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_submit`, req, "post")
          .then((res) => {
            this.resInit()
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg143')
            setTimeout(() => {

              this.isShowLoading = false
            }, 2000)
          })
          .catch((res) => {
            this.isAjax = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg108')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          });
    },
    cancelSubmitFn () {
      if (this.resData.status != 1) {
        return false
      }
      if (this.isAjax) {
        return  false
      }
      this.isAjax = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''

      let req = {
        order_id: this.$route.query.id,
        op_type: 2,
      }
      this.isShowCancel = false
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_submit`, req, "post")
          .then((res) => {
            this.resInit()
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg144')
            setTimeout(() => {

              this.isShowLoading = false
            }, 2000)
          })
          .catch((res) => {
            this.isAjax = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg108')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          });
    },
    showTypeFn () {
      this.isShowTips = false
      if (this.copyType == 'qrcode') {
        this.isShowqrcode = true
        this.copyType = ''
        return false
      }
      this.copyText(this.resData.card_details.account)

    },
    statusFn (type) {

      // （0-无操作，1-申诉过，2-超时过）
      if (this.resData.status == 1) {
        this.statusTx = this.$t('status_view.status11')
        this.statusTip = this.$t('tips_view.msg145')
      }
      if (this.resData.status == 2) {
        this.statusTx = this.$t('status_view.status12')
        if (this.time > 0) {
          this.statusTip = this.$t('tips_view.msg146')
        } else {
          this.statusTip = this.$t('tips_view.msg143')
        }

      }
      if (this.resData.status == 3) {
        this.statusTx = this.$t('status_view.status13')
        this.statusTip = this.$t('tips_view.msg147')
        if (this.resData.win_square == 1) {
          this.statusTip = this.$t('tips_view.msg148')
        } else {
          this.statusTip = this.$t('tips_view.msg147')
        }
      }
      if (this.resData.status == 4) {
        this.statusTx = this.$t('status_view.status14')
        this.statusTip = this.$t('tips_view.msg150')
      }
      if (this.resData.status == 5) {
        this.statusTx = this.$t('status_view.status15')
        if (this.resData.win_square == 2) {
          this.statusTip = this.$t('tips_view.msg151')
        } else {
          this.statusTip = this.$t('tips_view.msg152')
        }

      }
      if (this.resData.status == 6) {
        this.statusTx = this.$t('status_view.status16')
        if (this.resData.appeal_status == 1) {
          this.statusTip = this.$t('tips_view.msg149')
        } else {
          this.statusTip = this.$t('tips_view.msg153')
        }

      }
    },
    orderTimeFn () {
      this.time = this.time - 1
      let date
      if (this.time >= 0) {
        date = this.time / 60 % 60
        if (date >= 0) {
          this.minute = date >= 10 ? parseInt(date, 10) : '0' + parseInt(date)
        }
        date = this.time % 60
        if (date >= 0) {
          this.second = date >= 10 ? parseInt(date) : '0' + parseInt(date)
        }
      }
      if (this.time < 0) {
        clearInterval(this.timeFn)
        this.timeFn = null
        this.resInit()
      }
    },
    resInit() {
      let req = {
        order_id: this.$route.query.id
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_detail`, req, "get")
          .then((res) => {
            this.resData = res ? res : {coin_data: {}, card_details: {}}
            this.statusFn()

            if (res.status  == 1 || res.status == 2) {
              clearInterval(this.timeFn)
              this.timeFn = null

              this.time = parseFloat(res.surplus_time)
              if (this.time > 0) {
                this.timeFn = setInterval(this.orderTimeFn, 1000)
              }
            }
          })
          .catch((res) => {

            this.messageFn(res.msg)
          });
    },
    delUploadFn (type, index) {

      if (type == 'img') {
        this.imgShow = false
        this.imgSrc = ''
      }

    },
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(type,file) {
      this.uploadFn(file, type)
    },
    uploadFn(file, type) {
      let nameFile = ''

      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      let isLt2M = file.size / 1024 / 1024 < 5
      isLt2M = file.size / 1024 / 1024 < 10
      if (!/png|jpg|jpeg/.test(last)) {
        this.messageFn(this.$t('tips_view.msg154'))
        return;
      }
      if (!isLt2M) {
        this.messageFn(this.$t('tips_view.msg155'))
      }
      this.imgShow = true
      nameFile = 'otc_payment'
      let time = parseInt(new Date().getTime())
      str = nameFile + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'dinnerActivity')
      this.$ajax(`${this.Utils.URL.goAPPURL}upload`, formData, 'post', 25000000, true)
          .then(res => {

            this.imgShow = false
            this.imgSrc = res.url

          })
          .catch(res => {
            this.imgShow = false
            this.imgSrc = ''
            this.messageFn(res.msg)
          })
    }
  }
}
</script>

<style lang="scss">
.h5_otc_buy_order_view {
  min-height: 100vh;
  position: relative;
  padding-bottom: 0.9rem;
  .payment_icon{
    width: 0.06rem;
    height: 0.2rem;
    border-radius: 0.03rem;
  }
  .head_bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 2rem;
    background: linear-gradient(180deg, #EE9922 50%, rgba(238, 153, 34, 0) 100%);
  }
  .order_status_box{
    position: relative;
    padding: 0.2rem 0.16rem;

    .tx1{
      font-size: 0.2rem;
      color: $FFF;
      font-weight: bold;
      margin-bottom: 0.05rem;
    }
    .tx2{
      font-size: 0.14rem;
      color: $FFF;
      font-weight: bold;
    }
  }
  .form_main{
    position: relative;
    padding: 0 0.16rem 0.16rem;
    .order_info_box{
      padding: 0.16rem;
      background: $BG2;
      border-radius: 0.06rem;
      .title_tx{
        padding-bottom: 0.16rem;
        border-bottom: 1px solid $BR;
      }

      .info_r{
        width: 1.7rem;
      }
    }
    .buy_tip{
      background: $T9_01;
      border-radius: 0.06rem;
      color: $T9;
      padding: 0.14rem 0.16rem;
    }
    .tips_tx1{
      color: $T9;
    }
    .red_round{
      display: inline-block;
      border-radius: 100%;
      width: 0.08rem;
      height: 0.08rem;
      background: $T9;
    }
    .submit_box{
      position: fixed;
      width: 100vw;
      bottom: 0;
      background: #FFFFFF;
      box-shadow: 0px -4px 9px rgba(34, 68, 85, 0.05);
      padding: 0.16rem;
      left: 0;
      .input_sub{
        border-radius: 0.06rem;
        line-height: 0.44rem;
        font-weight: bold;
        font-size: 0.16rem;
        text-align: center;
      }
      .input_cl{
        width: 1.1rem;
        background: $BG1;
        color: $T7;
      }
      .input_con{
        width: 2.2rem;
        background: $T8;
        color: $FFF;
      }
      .input_con1{
        width: 3.43rem;
        background: $T8;
        color: $FFF;
      }
      .input_con2{
        width: 3.43rem;
        background: $BG1;
        color: $T7;
      }
    }
  }
  .qrcode_alert{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    .qrcode_box{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .qrcode_img{
        width: 3rem;
        max-height: 4.6rem;

      }
    }
  }
  .upload_main{
    border-radius: 0.06rem;
    background: $BG2;
    width: 1.64rem;
    height: 0.82rem;
    .upload_img{
      width: 1.64rem;
      height: 0.82rem;

    }
    .img_item{
      font-size: 0;
      position: relative;
      width: 1.64rem;
      height: 0.82rem;
    }
    .icon_del_red{
      position: absolute;
      right: -0.05rem;
      top: -0.05rem;
    }
    .icon_upload_add{
      width: 1.64rem;
      height: 0.82rem;
      background-repeat: no-repeat;
      background-size: auto;
      background-position: 0.65rem 0.26rem;
    }
    .icon_loading{
      animation: roundRotate 3s infinite linear;
    }
  }


}

</style>
