<template>
  <article class="h5_invest_order_details_view">

    <section class="order_coin_info_box">
      <div class="coin_box d_flex_space">
        <div class="l_box d_flex">
          <div class="com_icon_img_box3">
            <img :src="coin_data.icon_url" class="com_coin_img"/>
            <i class="h5_icon com_icon_subscript" :class="coin_data.type == 'tron' ? 'icon_subscript_tron' : coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
          </div>
          <span class="tx1 ">{{ coin_data.code }}</span>
          <span class="tx2 ml_5" :class="coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[coin_data.type] }}</span></span>
        </div>
        <div class="tx3 color_T8">
           {{ (resData.is_order_repeat && resData.status == 4) ? $t('re_cast') :  statusTxFn(resData)}}<span v-show="resData.is_advance">({{$t('pepayment')}})</span>
        </div>
      </div>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{$t('subscription_time')}}</span>
        <span>{{dataFormat(resData.invest_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{$t('investment_type')}}</span>
        <span>{{checkTxFn(resData)}}</span>
      </p>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{$t('interest_current_period')}}</span>
        <span>{{dataFormat(resData.interest_period_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{$t('progress_period')}}</span>
        <span>{{profitData.periods_days}} / {{$t('common')}} {{profitData.total_days}} {{ $t('day') }}</span>
      </p>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{$t('expire_date')}}</span>
        <span>{{dataFormat(resData.redeem_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
    </section>
    <section class="order_info_box">
      <div class="d_flex_space">
        <p class="tx1 font_bold color_T6">{{$t('earnings_details')}}</p>
        <p class="tx3 d_flex_space color_T8" @click="goRouter('/invest_order_profit_details', {id: resData.contract_number})">
          <span>{{$t('income_details')}}</span>
          <i class="h5_icon icon_orange_arrow ml_3"></i>
        </p>
      </div>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('daily_income')}}</span>
        <span>{{profitData.each_profit ? profitData.each_profit : '-'}} {{ coin_data.code }}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('number_replays')}}</span>
        <span>{{profitData.repeat_times ? profitData.repeat_times : '-'}} {{$t('bout')}}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('cumulative_income')}}</span>
        <span>{{profitData.profit ? profitData.profit : '-'}} {{ coin_data.code }}</span>
      </p>
    </section>
    <section class="order_info_box">
      <p class="tx1 font_bold color_T6">{{$t('subscription_details')}}</p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('subscription_amount')}}</span>
        <span>{{subscribeData.subscribe_amount ? subscribeData.subscribe_amount : '-'}} {{ coin_data.code }}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('subscription_wallet')}}</span>
        <span>{{subscribeData.subscribe_addr ?  substr(subscribeData.subscribe_addr, 0, 8) +'...'+ substr(subscribeData.subscribe_addr, -8) : '-'}}</span>
      </p>
      <div class="tx2 color_T6 d_flex_space">
        <span>{{$t('transaction_hash')}}</span>
        <p class="d_flex_space" @click="openLinkFn(subscribeData.hash)">
          <i class="h5_icon  " :class="subscribeData.status == 2 ? 'icon_round_success' : subscribeData.status == 3 ? 'icon_round_error' : 'icon_round_pending'" v-show="subscribeData.hash"></i>
          <span class="ml_5 color_T5">{{subscribeData.hash ? substr(subscribeData.hash, 0, 8) +'...'+ substr(subscribeData.hash, -8) : '-'}}</span>
          <i class="h5_icon icon_green_arrow ml_5"></i>
        </p>
      </div>
    </section>
    <section class="order_info_box">
      <p class="tx1 font_bold color_T6">{{$t('wealth_management_product_details')}}</p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('financing_amount')}}</span>
        <span>{{productData.borrow_amount ? productData.borrow_amount : '-'}} {{ coin_data.code }}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('financial_days')}}</span>
        <span>{{productData.borrow_days ? productData.borrow_days : '-'}} {{$t('day')}}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('daily_interest_rate')}}</span>
        <span>{{productData.daily_profit ? productData.daily_profit : '-'}}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{$t('fundraising_progress')}}</span>
        <span>{{productData.curr_borrow ? productData.curr_borrow + '/' + productData.borrow_amount: '-'}} {{ coin_data.code }}</span>
      </p>
    </section>
  </article>
</template>

<script>
export default {
  name: "invest_order_details",
  data () {
    return {
      resData: {},
      subscribeData: {},
      productData: {},
      profitData: {},
      funData: {},
      coin_data: {},
    }
  },
  created () {
    this.resInit()
  },
  methods: {
    openLinkFn (hash) {
      let url = ''
      if (!hash) {
        return false
      }
      if (this.coin_data.type == 'bsc') {
        url = 'https://bscscan.com/tx/' + hash
      } else if (this.coin_data.type == 'eth') {
            url = 'https://etherscan.io/tx/' + hash
      } else {
        url = 'https://tronscan.org/#/transaction/' + hash
      }
      this.goLinkFn(url, {}, true)
    },
    checkTxFn (obj) {
      if (obj.is_order_repeat) {
        return this.$t('re_invoice')
      } else {
        return this.$t('ordinary_single')
      }

      if (obj.repeat_status == 1) {
        return this.$t('re_invoice')
      }
      if (obj.repeat_status == 2) {
        return this.$t('ordinary_single')
      }
      if (obj.repeat_status == 3) {
        return this.$t('re_invoice')
      }


    },
    statusTxFn (obj) {
      if (obj.order_type == 2) {
        return this.$t('experience_coupon')
      }
      if (obj.status == 1) {
        return this.$t('under_subscription')
      }
      if (obj.status == 2) {
        return this.$t('investing')
      }
      if (obj.status == 3) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 4) {
        return this.$t('completed')
      }
      if (obj.status == 5) {
        return this.$t('re_cast')
      }
      if (obj.status == 6) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 7) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 8) {
        return this.$t('under_subscription')
      }
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/my_invest_order_detail`, {
        id: this.$route.query.id
      }, 'get')
          .then(res => {
            this.resData = res ? res : {}
            this.subscribeData = res.subscribe_details ? res.subscribe_details : {}
            this.productData = res.product_details ? res.product_details : {}
            this.profitData = res.profit_details ? res.profit_details : {}
            this.funData = res.fund_product_details ? res.fund_product_details : {}
            this.coin_data = res.coin_data ? res.coin_data : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_invest_order_details_view{
  .order_coin_info_box{
    margin: 0.16rem 0.16rem 0.24rem;
    background: $BG2;
    padding: 0.16rem;
    border-radius: 0.06rem;
    .coin_box{
      margin-bottom: 0.12rem;
      border-bottom: 1px solid $BR2;
      padding-bottom: 0.16rem;
    }
    .tx1{
      font-size: 0.16rem;
      font-weight: bold;
    }
    .tx2{
      font-size: 0.12rem;
      padding: 0.01rem 0.04rem;
      border-radius: 0.1rem;
      display: inline-block;
      min-width: 0.36rem;
      text-align: center;
    }
    .tx6{
      color: $T5;
      background: $T1;
    }
    .tx5{
      color: $T12;
      background: $T11;
    }
    .tx7{
      color: $T8;
      background: $T10;
    }
    .tx8{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
    .tx4:last-child{
      margin-bottom: 0;
    }
    .tx4{
      margin-bottom: 0.12rem;
      font-size: 0.12rem;
    }
    .tx3{
      font-size: 0.13rem;
      font-weight: bold;
    }
  }
  .order_info_box{
    padding: 0.2rem 0.16rem;
    border-top: 1px solid $BR2;
    .tx1{
      font-size: 0.14rem;
      margin-bottom: 0.16rem;
    }
    .tx2:last-child{
      margin-bottom: 0;
    }
    .tx2{
      margin-bottom: 0.16rem;
      font-size: 0.13rem;
    }
    .tx3{
      margin-bottom: 0.16rem;
      font-size: 0.13rem;
    }

    .icon_green_arrow{
      width: 0.12rem;
      height: 0.12rem;
    }
  }
}
</style>