<template>
  <article class="h5_income_profit_details_view">
    <section class="profit_box">
      <div class="coin_box">
        <p class="d_flex br_1">
          <i class="h5_icon icon_trx_usdt"></i>
          <span class="tx1">USDT</span>
          <span class="tx3 tx4 tx5"><span class="fz_10 font_bold">TRC20</span></span>
        </p>
        <p>
          <span class="tx3 tx4 "><span class="fz_10 font_bold">17 天</span></span>
          <span class="tx3 tx6"><span class="fz_10 font_bold">1.68%</span></span>
        </p>
      </div>
      <p class="tx2">
        <span>投资金额</span>
        <span>0.02 USDT</span>
      </p>
      <p class="tx2">
        <span>每天收益</span>
        <span>0.02 USDT</span>
      </p>
      <p class="tx2">
        <span>复投次数</span>
        <span>5 次</span>
      </p>
      <p class="tx2">
        <span>累计收益</span>
        <span>0.02 USDT</span>
      </p>
    </section>
    <p class="profit_tx">收益明细</p>
    <section class="profit_details">
      <div class="tx_top">
        <p class="tx1">借贷理财收益</p>
        <p>
          <span class="tx2">0.00231 USDT</span>
        </p>
      </div>
      <div class="tx_bottom">
        <p class="tx6">
          <span>2022/04/18 13:06:19</span>
        </p>
        <p class="tx7">
          已发放
        </p>
      </div>
    </section>
    <section class="profit_details">
      <div class="tx_top">
        <p class="tx1">借贷理财收益</p>
        <p>
          <span class="tx2">0.00231 USDT</span>
        </p>
      </div>
      <div class="tx_bottom">
        <p class="tx6">
          <span>2022/04/18 13:06:19</span>
        </p>
        <p class="tx7">
          已提取
        </p>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "income_profit_details"
}
</script>

<style lang="scss">
.h5_income_profit_details_view{
  padding: 0.16rem;
  .profit_box{
    padding-bottom: 0.04rem;
    background: $BG2;
    margin-bottom: 0.24rem;
    border-radius: 0.06rem;
    .coin_box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.16rem;

      margin-bottom: 0.16rem;
    }
    .br_1{
      border-bottom: 1px solid $BR2;
    }
    .tx1{
      font-size: 0.16rem;
      font-weight: bold;
      color: $T6;
      margin: 0 0.05rem;
    }
    .tx2{
      padding: 0 0.16rem 0.12rem;
      color: $T6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.12rem;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.04rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
      min-width: 0.36rem;
      text-align: center;
    }
    .tx4{
      color: $T12;
      background: $T11;
    }
    .tx5{
      color: $T14;
      background: $T13;
    }
    .tx6{
      margin-left: 0.1rem;
      color: $T5;
      background: $T1;
    }
  }
  .profit_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
    margin-bottom: 0.12rem;
  }
  .profit_details{
    background: $BG3;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    .tx_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
    }
    .tx_bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.12rem;
      color: $T7;
    }
    .tx1{
      font-size: 0.13rem;
      color: $T6;
    }
    .tx2{
      font-size: 0.13rem;
      color: $T5;
      font-weight: bold;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.05rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }
    .tx4{
      color: $T12;
      background: $T11;
    }
    .tx5{
      color: $T14;
      background: $T13;
    }
    .tx6{}
    .tx7{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon_gray_arrow{
        width: 0.12rem;
        height: 0.12rem;
      }
    }
  }
}
</style>