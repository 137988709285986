<template>
  <article class="h5_my_team_profit_view">
    <section class="tab_box">
      <p class="item" :class="tabIndex == 0 ? 'cur' : ''" @click="tabFn(0)">{{$t('all')}}</p>
      <p class="item" :class="tabIndex == 1 ? 'cur' : ''" @click="tabFn(1)">{{$t('invested')}}</p>
      <p class="item" :class="tabIndex == 2 ? 'cur' : ''" @click="tabFn(2)">{{$t('not_invested')}}</p>
    </section>
    <article class="profit_main">
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">

        <section class="profit_box" v-for="(list, index) in resData" :key="list.wallet_id">
          <p class="tx1_box">
            <span class="tx1">{{substr(list.wallet_id, 0, 6)}}...{{substr(list.wallet_id, -6)}} <span v-show="list.level" class="color_T8">({{list.level}})</span></span>
            <span class="tx4">{{ statusTxFn(list.invest_type) }}</span>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ typeTxFn(list.invite_type) }}</span>
            <span class="tx3">{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </section>
      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article>


  </article>
</template>

<script>
export default {
  name: "my_team_profit",
  data () {
    return {
      resData: [],
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      tabIndex: 0,
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    tabFn (index) {
      if (this.tabIndex == index) {
        return false
      }
      this.tabIndex = index
      this.page = 1
      this.loadInfinite = false
      this.isLoad = false
      this.resData = []
      this.resInit('search')
    },
    statusTxFn (status) {
      if (status == 1) {
        return this.$t('invested')
      }
      if (status == 2) {
        return this.$t('not_invested')
      }
    },
    typeTxFn (status) {
      if (status == 1) {
        return this.$t('direct_invitation')
      }
      if (status == 2) {
        return this.$t('indirect_invitation')
      }
    },
    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.tabIndex !== 0) {
        req.invest_type = this.tabIndex
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}team/my_team_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },

  }
}
</script>

<style lang="scss">
.h5_my_team_profit_view{
  .tab_box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BR;
    .item{
      width: 33.33%;
      font-size: 0.14rem;
      color: $T7;
      padding: 0.12rem 0;
      text-align: center;
    }
    .cur{
      color: $T8;
      font-weight: bold;
      border-bottom: 1px solid $T8;
    }
  }

  .profit_main{
    padding: 0.12rem 0.16rem;
    .profit_box{
      padding: 0.14rem 0.16rem;
      margin-bottom: 0.12rem;
      border-radius: 0.06rem;
      background: $BG3;
      .tx1_box{
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 0.07rem;

      }
      .tx1{
        font-size: 0.14rem;
        color: $T6;
        font-weight: bold;
      }
      .tx2_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .tx2{
        color: $T7;
        font-weight: bold;
        font-size: 0.12rem;
      }
      .tx4{
        color: $T6;
        font-size: 0.14rem;
      }
      .tx3{
        color: $T7;
        font-size: 0.12rem;
      }

    }
  }

}
</style>