<template>
  <article class="h5_loan_form_view">
    <div class="chain_tab d_flex">
      <span class="chain_item " @click="tabChainFn(key)" :class="checkChainType == key ? 'chain_cur' : ''" v-for="(list, key) in coinMian">{{key}}{{$t('chain')}}</span>
    </div>
    <div class="main_box">
      <section class="form_main">
        <p class="tx1 font_bold color_T6 tx_cen mb_10">{{$t('loan_amount')}}</p>
        <div class="num_box d_flex_space">
          <input type="number" pattern="[0-9]*" class="input_num" :placeholder="resData.base_amount + $t('tips_view.msg24')" v-model="num"/>
          <div class="l_box d_flex" @click="isShowCoin = true">
            <div class="com_icon_img_box3">
              <img :src="coin_data.icon_url" class="com_coin_img"/>
              <i class="h5_icon com_icon_subscript" :class="coin_data.type == 'tron' ? 'icon_subscript_tron' : coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx3 color_T6 font_bold ">{{coin_data.code}}</span>
            <span class="tx2 ml_5" :class="coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[coin_data.type] }}</span></span>
            <i class="h5_icon icon_gray_arrow_down ml_5"></i>
          </div>
        </div>
        <p class="tx4 mb_20 color_T7">{{$t('available_quantity')}}: {{ resData.min_borrow_amount }} {{coin_data.code}} ~ {{ resData.max_borrow_amount }} {{coin_data.code}}</p>
        <p class="tx1 font_bold color_T6 tx_cen mb_10">{{$t('security_deposit_rate')}}</p>
        <div class="day_box d_flex_space mb_20" @click="checkBondShow = true">
          <span class="font_bold">{{checkBond.bond * 100}}% {{$t('security_deposit')}}</span>
          <i class="h5_icon icon_gray_arrow_down"></i>
        </div>
        <p class="tx1 font_bold color_T6  mb_5">{{$t('margin_amount')}}: {{plegeNum}} {{coin_data.code}}</p>
        <p class="tx4 mb_20  color_T7 d_flex_space">
          <span>{{ $t('balance') }}: {{ balance }} {{coin_data.code}}</span>
          <span>{{substr(storeChangeAddress, 0, 8)}}...{{substr(storeChangeAddress, -8)}}</span>
        </p>
        <p class="tx1 font_bold color_T6 tx_cen mb_10">{{$t('borrowing_cycle')}}</p>
        <div class="day_box d_flex_space mb_10" @click="checkDayShow = true">
          <span class="font_bold">{{checkDays.days}} {{$t('day')}}</span>
          <i class="h5_icon icon_gray_arrow_down"></i>
        </div>
        <p class="tx4  color_T7">{{$t('borrowing_day_interest_rate')}}: {{checkDays.rate}}</p>
      </section>


      <section class="form_main">
        <div class="tx1 font_bold color_T6 tx_cen mb_10 d_flex_cen">
          <span>{{$t('investment_channel')}}</span>
          <div class="h5_icon icon_question ml_5" @click="showToastFn('tips1')">
            <div class="tips_question_box tips_question_box1" v-show="tipsShow1">
              <p class="title_tx">{{$t('investment_channel')}}</p>
              <p class="title_msg">
                {{$t('loan_confirm_view.tips9')}}</p>
              <p class="title_msg">
                {{$t('loan_confirm_view.tips10')}}</p>
              <i class="h5_icon add_top_arrow"></i>
            </div>
          </div>
        </div>

        <div class="day_box d_flex_space mb_10" @click="goRouter(swap_list.swap_link, {}, true)">
          <p class="d_flex" >
            <i class="h5_icon " :class="swap_list.swap_name == 'PancakeSwap' ? 'icon_pancakeswap' : swap_list.swap_name == 'SunSwap' ? 'icon_sunswap' : ''"></i>
            <span class="font_bold ml_5">{{swap_list.swap_name}}</span>
          </p>

          <i class="h5_icon icon_next_arrow"></i>
        </div>
        <div class="tx4 mb_5 color_T7 d_flex">
          <span>{{$t('create_coin_wallet_miner_fee')}}</span>
          <div class="h5_icon icon_question ml_5" @click="showToastFn('tips2')">
            <div class="tips_question_box tips_question_box2" v-show="tipsShow2">
              <p class="title_tx">{{$t('create_coin_wallet_miner_fee')}}</p>
              <p class="title_msg">{{$t('loan_confirm_view.tips7')}}
              </p>
              <p class="title_msg">
                {{$t('loan_confirm_view.tips8')}}</p>
              <i class="h5_icon add_top_arrow"></i>
            </div>
          </div>
        </div>
        <p class="tx3 font_bold  color_T6">{{ resData.miner_fee }} {{ miner_coin_data.code }}</p>
      </section>

      <p class="check_box d_flex">
        <i class="h5_icon " @click="checkFn" :class="isCheck ? 'icon_treaty_yes' : 'icon_treaty_no'"></i>
        <span class="color_T7 ml_5">{{$t('loan_confirm_view.tips11')}}</span>
        <span class="color_T8" @click="goLinkFn(resData.clause_link + '?langId=' + storeLang, {}, true)">{{$t('loan_confirm_view.tips12')}}</span>
      </p>

      <p class="input_btn" @click="submitFn"><span class="com_popup_input_btn " :class="isCheck && num > 0 ? 'com_popup_input_btn_cur' : ''">{{$t('confirm')}}</span></p>

    </div>

    <article class="com_popup_main" v-show="checkDayShow">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('select_cycle')}}</span>
          <i class="h5_icon icon_close" @click="checkDayShow = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="item_day_check_box" >
              <p class="day_item  " @click="checkDayFn(list)" :class="list.days == checkDays.days ? 'day_item_cur' : ''" v-for="(list, index) in days_list" :key="index">{{list.days}} {{$t('day')}}</p>
            </div>

          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="checkBondShow">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('select_security_deposit_rate')}}</span>
          <i class="h5_icon icon_close" @click="checkBondShow = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="item_day_check_box" >
              <p class="day_item  " @click="checkBondFn(list)" :class="list.bond == checkBond.bond ? 'day_item_cur' : ''" v-for="(list, index) in bond_list" :key="index">{{list.bond * 100}}% {{$t('security_deposit')}}</p>
            </div>

          </div>
        </div>
      </section>
    </article>

    <article class="select_coin_main" v-show="isShowCoin">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowCoin = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{ $t('select_coin') }}</span>
      </header>
      <section class="select_coin_box">
        <div class="select_item d_flex" @click="checkChainTypeFn(list)" v-for="list in coinList">
          <div class="com_icon_img_box3">
            <img :src="list.coin_data.icon_url" class="com_coin_img"/>
            <i class="h5_icon_1 com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
          </div>
          <span class="tx3 color_T6 font_bold ml_5">{{ list.coin_data.code }}</span>
          <span class="tx2 ml_5 bsc_color" v-show="list.coin_data.contract_addr" :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>
        </div>

      </section>
    </article>

  </article>
</template>

<script>
export default {
  name: "loan_form",
  data () {
    return {
      num: '',
      checkDayShow: false,
      tipsShow1: false,
      tipsShow2: false,
      isCheck: false,
      isLoad: false,
      checkBondShow: false,
      resData: {
        base_amount: 100,
        pledge_rate: 0,
      },
      coin_data: {},
      miner_coin_data: {},
      bond_list: [],
      days_list: [],
      swap_list: {},
      balance: '',
      checkDays: {},
      checkBond: {
        bond: 0
      },
      coinBanlace: '',
      isShowCoin: false,
      checkChainType: '',
      coinId: '',
      coinList: [],
      coinDataObj: {
        coin_data: {
          code: 'USDT'
        }
      },
      contract_addr: ''
    }
  },
  computed: {
    plegeNum () {
      let num = this.num
      let bond = this.checkBond.bond
      if (this.num && this.checkBond.bond) {

        return new window.windowBigNumber(this.num).times(this.checkBond.bond).toString()
      } else {
        return  0
      }
    }
  },
  created () {
    if (!this.storeChainType) {
      this.messageFn(this.$t('tips_view.msg3'))

    }
    this.checkChainType = this.storeChainType
    this.coinInit(this.storeChainType.toLocaleLowerCase())
    if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH' || this.storeChainType == 'TRON') {
      let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'
      this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
        addr: this.storeChangeAddress
      }, "post").then(res => {
        this.coinBanlace = res.quantity ? parseFloat(res.quantity) : 0
      })
    }
  },
  mounted () {

  },
  methods: {
    tabChainFn (type) {
      if (this.storeChainType != type) {
        this.messageFn(this.$t('tips_view.msg78') + type.toLocaleUpperCase() + this.$t('tips_view.msg84'))
        return false
      }
      this.checkChainType = type
    },
    coinBalanceFn (contract_addr) {

      this.getUSDTBalanceFn(this.storeChainType, contract_addr, this.storeAddress).then(res => {
        this.balance = res
      }).catch(res => {
        this.balance = 0
        this.messageFn(res.msg)
      })

    },
    checkChainTypeFn (obj) {
      this.coinDataObj = obj
      this.coinId = obj.coin_data.id
      this.isShowCoin = false
      this.isCheck = false
      if (this.storeChainType) {
        this.balance = 0
        if (obj.coin_data.contract_addr) {
          this.coinBalanceFn(obj.coin_data.contract_addr)
        } else {
          let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'

          this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
            addr: this.storeChangeAddress,
          }, "post").then(res => {
            this.balance =  res.quantity ? parseFloat(res.quantity) : 0
          })
        }
      }

      this.resInit()
    },
    coinInit (type) {
      let req = {
        size: 10000
      }
      if (type) {
        req.chain_type = type
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/many_coin_list`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            let code = this.envDev ? 'UDT' : 'USDT'
            this.coinList = arr
            arr.forEach(val => {

              if (val.coin_data.type == this.storeChainType.toLocaleLowerCase() && (val.coin_data.code == code)) {
                this.coinDataObj = val
                this.coinId = val.coin_data.id
                this.coinBalanceFn(val.coin_data.contract_addr)
              }
            })

            this.resInit()


          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },

    showToastFn (type) {
      if (type == 'tips2') {
        this.tipsShow2 = true
      } else {
        this.tipsShow1 = true
      }
      setTimeout(() => {
        this.tipsShow1 = false
        this.tipsShow2 = false
      }, 3000)
    },
    checkFn () {
      this.isCheck = !this.isCheck
    },
    submitFn () {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg3'))
        return false
      }
      if (this.checkChainType != this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg78') + this.checkChainType + this.$t('tips_view.msg84'))
        return  false
      }
      let num = parseFloat(this.num)
      let balance = parseFloat(this.balance)
      let coinBanlace = parseFloat(this.coinBanlace)
      let min = parseFloat(this.resData.min_borrow_amount)
      let max = parseFloat(this.resData.max_borrow_amount)
      let miner_fee = parseFloat(this.resData.miner_fee)
      let base_amount = parseFloat(this.resData.base_amount)
      let pledge = parseFloat(new window.windowBigNumber(num).times(this.checkBond.bond).toString())

      if (!this.resData.is_open) {
        this.messageFn(this.$t('tips_view.msg59'))
        return false
      }
      if (!this.checkBond.is_open) {
        this.messageFn(this.$t('tips_view.msg59'))
        return false
      }
      if (!this.isCheck) {
        this.messageFn(this.$t('tips_view.msg64'))
        return false
      }
      if (new window.windowBigNumber(num).mod(base_amount).toString() != 0) {
        this.messageFn(`${this.$t('tips_view.msg23')} ${base_amount} ${this.$t('tips_view.msg24')}`)
        return false
      }
      if (num > max) {
        this.messageFn(this.$t('tips_view.msg60') + this.resData.max_borrow_amount)
        return false
      }
      if ((num < min) || (num == 0)) {
        this.messageFn(this.$t('tips_view.msg61') + this.resData.min_invest_amount)
        return false
      }
      if (balance < pledge) {
        this.messageFn(this.$t('tips_view.msg81') + pledge + this.coin_data.code + ',' + this.$t('tips_view.msg82'))
        return false
      }
      if ((miner_fee > coinBanlace) || (coinBanlace == 0)) {
        this.messageFn(this.$t('tips_view.msg56'))
        return false
      }

      let listData = {
        coin_id: this.coinId,
        is_open: this.resData.is_open,
        base_amount: this.resData.base_amount,
        max: this.resData.max_borrow_amount,
        min: this.resData.min_invest_amount,
        num: num,
        icon_url: this.coin_data.icon_url,
        code: this.coin_data.code,
        chain_type: this.coin_data.type,
        days: this.checkDays.days,
        rate: this.checkDays.rate,
        swap_name: this.swap_list.swap_name,
        swap_type: this.swap_list.swap_type,
        pledge_rate: this.checkBond.bond,
        pledge: pledge,
        miner_fee: this.resData.miner_fee,
        predict_miner_fee: this.resData.predict_miner_fee,
        miner_code: this.miner_coin_data.code,
        factory_contract: this.resData.factory_contract,
        owner_addrs: this.resData.owner_addrs,
        required: this.resData.required,
        contract_addr: this.coin_data.contract_addr,
      }
      window.sessionStorage.setItem('listData', JSON.stringify(listData))
      this.goRouter('/loan_confirm')
    },
    checkDayFn (data) {
      this.checkDays = data
      this.checkDayShow = false
    },
    checkBondFn (data) {
      this.checkBond = data
      this.checkBondShow = false
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/borrow_details`, {
        coin_id: this.coinId
      }, 'get')
          .then(res => {
            this.resData = res ? res : {}

            this.coin_data = res.coin_data ? res.coin_data : {}
            this.miner_coin_data = res.miner_coin_data ? res.miner_coin_data : {}
            this.days_list = res.days_list ? res.days_list : []
            this.bond_list = res.bond_list ? res.bond_list : []
            this.swap_list = res.swap_list[0] ? res.swap_list[0] : {}
            this.checkDays = this.days_list[0] ? this.days_list[0] : {}
            this.checkBond = this.bond_list[0] ? this.bond_list[0] : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  },
}
</script>

<style lang="scss">
.h5_loan_form_view{
  .code_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
  }
  .type_tx{
    font-size: 0.12rem;
    padding: 0.01rem 0.04rem;
    border-radius: 0.1rem;
    display: inline-block;
    min-width: 0.36rem;
    text-align: center;
  }
  .chain_tab{
    border-bottom: 1px solid $BR;
    .chain_item{
      width: 33.33%;
      text-align: center;
      font-size: 0.14rem;
      font-weight: bold;
      padding: 0.1rem 0 0.1rem;
      color: $T7;
    }
    .chain_cur{
      color: $T8;
      border-bottom: 1px solid $T8;
    }
  }
  .main_box{
    padding: 0.16rem;
  }

  .form_main{
    padding: 0.24rem 0.16rem 0.16rem;
    background: $BG2;
    border-radius: 0.06rem;
    margin-bottom: 0.16rem;
    .tx1{
      font-size: 0.16rem;
    }
    .input_num{
      height: 0.56rem;
      font-size: 0.2rem;
      font-weight: bold;
      width: 1.45rem;
    }
    .num_box{
      padding: 0 0.12rem;
      border: 1px solid rgba(119, 142, 153, 0.25);
      border-radius: 0.06rem;
      margin-bottom: 0.1rem;
    }
    .tx3{
      font-size: 0.14rem;
    }
    .tx4_br{
      padding-bottom: 0.14rem;
      margin-bottom: 0.14rem;
      border-bottom: 1px solid $BR2;
    }
    .tx4{
      font-size: 0.12rem;
    }
    .tx2{
      font-size: 0.12rem;
      padding: 0.01rem 0.04rem;
      border-radius: 0.1rem;
      display: inline-block;
      min-width: 0.36rem;
      text-align: center;
    }
    .tx6{
      color: $T5;
      background: $T1;
    }
    .tx5{
      color: $T12;
      background: $T11;
    }
    .tx7{
      color: $T8;
      background: $T10;
    }
    .tx8{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
    .day_box{
      padding: 0 0.12rem;
      line-height: 0.52rem;
      background: $FFF;
      border-radius: 0.06rem;
      font-size: 0.17rem;
    }

    .tips_question_box1{
      left: -1.8rem;
      .add_top_arrow{
        left: 1.8rem;
      }
    }
    .tips_question_box2{
      left: -1.1rem;
      .add_top_arrow{
        left: 1.1rem;
      }
    }
    .tips_question_box{
      position: absolute;
      background: $T6;
      padding: 0.12rem;


      bottom: 0.24rem;
      border-radius: 0.06rem;
      width: 3.11rem;
      z-index: 1;
      .title_tx{
        text-align: center;
        font-size: 0.13rem;
        font-weight: bold;
        color: $FFF;
        margin-bottom: 0.1rem;
      }
      .title_msg{
        min-width: 2.2rem;
        color: $FFF;
        font-size: 0.12rem;
      }
      .add_top_arrow{
        position: absolute;
        bottom: -0.12rem;

        border: 0.06rem solid #000;
        border-top-color: $T6;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
    }

  }
  .check_box{
    font-size: 0.12rem;
    margin: 0.25rem 0 0.2rem;
  }
  .input_btn{
    .com_popup_input_btn{
      display: inline-block;
      line-height: 0.44rem;
      border-radius: 0.06rem;
      text-align: center;
      background: $BG1;
      color: $T4;
      font-size: 0.16rem;
      font-weight: bold;
      width: 100%;

    }

    .com_popup_input_btn_cur{
      background: $T8;
      color: $FFF;
    }
  }
  .item_day_check_box{
    .day_item:last-child{
      margin-bottom: 0;
    }
    .day_item{
      text-align: center;
      line-height: 0.52rem;
      font-size: 0.16rem;
      font-weight: bold;
      color: $T6;
      border-radius: 0.06rem;
      background: $BG4;
      border: 1px solid $BG4;
      margin-bottom: 0.15rem;
    }
    .day_item_cur{
      background: $T10;
      color: $T8;
      border: 1px solid $T8;
    }
  }



}
</style>