<template>
  <article class="h5_coin_transfer_history_view">
    <section class="coin_main">
      <div class="coin_box d_flex_space">
        <div class="left_box d_flex">
          <div class="com_icon_img_box">
            <img :src="coinData.icon_url" class="com_coin_img"/>
            <i  class="h5_icon com_icon_subscript" :class="coinData.type == 'tron' ? 'icon_subscript_tron' : coinData.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
          </div>
          <div>
            <p class="d_flex">
              <span class="tx1">{{ coinData.code }}</span>
              <span class="tx3  " :class="coinData.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[coinData.type] }}</span></span>

            </p>
            <p class="tx2 d_flex">
              <span>{{substr(coinData.wallet_addr, 0, 8)}}...{{substr(coinData.wallet_addr, -8)}}</span>
              <i class="h5_icon icon_copy ml_5" @click="copyText(coinData.wallet_addr)"></i>
            </p>
          </div>
        </div>
        <div class="right_box tx_r">
          <p class="tx1">{{ coinData.amount }}</p>
          <p class="tx2">${{coinData.currency}}</p>
        </div>
      </div>
      <div class="btn_box d_flex_space">
        <span class="item_btn collection_btn" @click="goRouter('/collection_qrcode', {addr: coinData.wallet_addr})">{{$t('collection')}}</span>
        <span class="item_btn transfer_btn" @click="tipsFn">{{$t('transfer')}}</span>
      </div>
    </section>
    <p class="h10"></p>
    <section class="tab_box d_flex">
      <div class="item " :class="curType == '' ? 'item_cur' : ''" @click="tabFn('')">{{$t('all')}}</div>
      <div class="item" :class="curType == 2 ? 'item_cur' : ''" @click="tabFn(2)">{{$t('transfer')}}</div>
      <div class="item" :class="curType == 1 ? 'item_cur' : ''" @click="tabFn(1)">{{$t('collection')}}</div>
    </section>
    <section class="history_box">
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">

        <div class="item d_flex_space" v-for="(list, index) in resData" @click="goLinkFn((coinData.type == 'bsc' ? 'https://bscscan.com/tx/' : coinData.type == 'tron' ? 'https://tronscan.org/#/transaction/' : 'https://etherscan.io/tx/') + list.txID, {}, true)">
          <div class="left_box d_flex">
            <i class="h5_icon  mr_10" :class="list.result == 3 ? 'icon_pendding' : list.result == 2 ? 'icon_transfer_error' : list.type == 1 ? 'icon_collection' : 'icon_transfer'"></i>
            <div >
              <p class="tx1 color_T6">{{substr(list.txID, 0, 8)}}...{{substr(list.txID, -8)}}</p>
              <p class="tx2 color_T7">{{dataFormat(list.timestamp, 'yyyy/MM/dd hh:mm:ss')}}</p>
            </div>
          </div>
          <div class="right_box tx_r">
            <p class="tx1 font_bold " :class="list.result == 3 ? 'color_T8' : list.result == 2 ? 'color_T7' : list.type == 1 ? 'color_T5' : 'color_T9'">{{list.type == 1 ? '+' : '-'}}{{ list.value }}</p>
            <p class="tx2 " :class="list.result == 3 ? 'color_T8' : list.result == 2 ? 'color_T7' : list.type == 1 ? 'color_T5' : 'color_T9'" v-show="list.result != 1">{{ list.result == 1 ? '成功' : list.result == 2 ? '失败' : '处理中'  }}</p>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>

export default {
  name: "coin_transfer_history",
  data () {
    return {
      coinData: {},
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      resData: [],
      curType: ''
    }
  },
  created () {
    this.coinData = window.sessionStorage.getItem('listData') ? JSON.parse(window.sessionStorage.getItem('listData')) : {}
    this.resInit()
  },

  mounted () {

  },
  methods: {
    tipsFn () {
        this.messageFn(this.$t('tips_view.msg65'))
    },
    tabFn (type) {
      if (this.curType == type) {
        return false
      }
      this.page = 1
      this.loadInfinite = false
      this.isLoad = false
      this.resData = []
      this.curType = type
      this.resInit('search')
    },
    resInit (type) {

      let req = {
        addr: this.coinData.wallet_addr,
        page: this.page,
        size: this.size,
      }
      if (this.coinData.coin_id.indexOf('|') > 0) {
        req.contract_addr = (this.coinData.coin_id.split('|'))[1]
      }
      if (this.curType) {
        req.type = this.curType
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      let url = this.coinData.type + '/transactions'
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    }
  }
}
</script>

<style lang="scss">
.h5_coin_transfer_history_view{
  .coin_main{
    padding: 0.16rem 0.16rem;
    .coin_box{
      margin-bottom: 0.2rem;
      .com_icon_img_box{
        margin-right: 0.16rem;
      }
      .coin_img{
        width: 0.4rem;
        height: 0.4rem;

      }
      .icon_copy{
        width: 0.12rem;
        height: 0.12rem;
      }
      .tx1{
        color: $T6;
        font-size: 0.18rem;
        font-weight: bold;
      }
      .tx2{
        font-size: 0.12rem;
        color: $T7;
      }
      .tx3{
        font-size: 0.12rem;
        margin-left: 0.1rem;
        padding: 0.01rem 0.03rem;
        border-radius: 0.08rem;
        font-weight: bold;
        display: inline-block;
      }
      .tx4{
        color: $T12;
        background: $T11;
      }
      .tx5{
        color: $T14;
        background: $T13;
      }
      .tx9{
        color: $T16;
        background: $T15;
      }

    }
    .btn_box{
      .item_btn{
        width: 1.66rem;
        line-height: 0.44rem;
        text-align: center;
        border-radius: 0.06rem;
        font-size: 0.14rem;
        font-weight: bold;
      }
      .collection_btn{
        color: $T5;
        background: $T1;
      }
      .transfer_btn{
        color: $T9;
        background: $T9_01;
      }
    }
  }
  .h10{
    height: 0.1rem;
    background: $BG2;
  }

  .tab_box{
    margin-bottom: 0.24rem;
    .item{
      font-size: 0.14rem;
      font-weight: bold;
      color: $T7;
      border-bottom: 1px solid $BR;
      padding: 0.14rem 0;
      text-align: center;
      width: 33.33%;
    }
    .item_cur{
      color: $T8;
      border-bottom: 1px solid $T8;
    }
  }
  .history_box{
    .item{
      padding: 0 0.16rem 0.24rem;
    }
    .tx1{
      font-size: 0.14rem;
    }
    .tx2{
      font-size: 0.12rem;
    }
  }

}
</style>