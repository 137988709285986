<template>
  <article class="h5_team_md_revenue_view">
    <div class="revenue_box tx_cen" v-show="show_dividend_header">
      <p class="title_tx">{{mdfileterFn($t('md_tip3'))}}</p>
      <p class="tx13 color_T6 tx_l">{{mdfileterFn($t('md_tip4'))}} {{md_earnings_withdraw_percent}} {{mdfileterFn($t('md_tip4_1'))}} {{md_earnings_remaining_percent}} {{mdfileterFn($t('md_tip4_2'))}} </p>
    </div>
    <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      <section class="profit_details" v-for="(list, index) in resData" :key="index">
        <div class="tx_top">
          <p class="tx1">{{list.management_name}}{{$t('platform_revenue')}}</p>
          <p>
            <span class="tx2">{{ list.amount }} {{list.coin_data.code}}</span>
            <span class="tx3" :class="list.coin_data.type + '_color'">
            <span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span>
          </span>
          </p>
        </div>
        <div class="tx_bottom">
          <p class="tx6">
            <span>{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
          <p class="tx7">
            <span>{{statusTxFn(list)}}</span>
          </p>
        </div>
      </section>
    </div>
    <div class="com_no_data_box" v-show="!resData.length">
      <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
      <p class="com_no_data_tx">{{$t('nodata')}}</p>
    </div>


  </article>
</template>

<script>
export default {
  name: "team_md_revenue",
  data () {
    return {
      resData: [],
      profitData: {},
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      levelArr: [this.$t('general_user'), 'GM', 'VP', 'SVP', 'ED', 'MD', 'MD+'],
      communityArr: [],
      show_dividend_header: false,
      md_earnings_withdraw_percent: '',
      md_earnings_remaining_percent: '',
      management_name: ''
    }
  },
  created() {
    this.show_dividend_header = this.$route.query.show_dividend_header == 'true' ? true : false
    this.md_earnings_remaining_percent = this.$route.query.md_earnings_remaining_percent
    this.md_earnings_withdraw_percent = this.$route.query.md_earnings_withdraw_percent
    this.management_name = this.$route.query.management_name
    this.resInit()
  },
  methods: {
    mdfileterFn (str) {
      if (str) {
        str = str.replace(/MD\+/g, this.$route.query.management_name)
      }
      return  str
    },
    statusTxFn (list) {
      if (list.op_type == 30) {
        if (list.picked){
          return this.$t('md_tip2')
        } else {
          return this.$t('md_tip2')
        }
      }
      if (list.op_type == 15) {
        if (list.picked){
          return this.$t('received_ed')
        } else {
          return this.$t('issued')
        }
      }
    },
    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/md_add_profit_record`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_team_md_revenue_view{
  padding: 0.16rem 0.16rem 0.16rem;
  .revenue_box{
    border: 1px solid $BR5;
    border-radius: 0.06rem;
    padding: 0rem 0.16rem 0.13rem;
    margin-bottom: 0.28rem;
    margin-top: 0.16rem;
    line-height: 1.8;
    .title_tx{
      display: inline-block;
      top: -0.11rem;
      margin: -0.1rem auto 0;
      background: $BG2;
      position: relative;
      text-align: center;
      font-weight: bold;
      font-size: 0.14rem;
      border: 1px solid $BR5;
      border-radius: 0.06rem;
      padding: 0.08rem 0.16rem;
    }
  }

  .profit_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
    margin-bottom: 0.12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .profit_details{
    background: $BG3;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    .tx_top{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
    }
    .tx_bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.12rem;
      color: $T7;
    }
    .tx1{
      font-size: 0.13rem;
      color: $T6;
    }
    .tx2{
      font-size: 0.13rem;
      color: $T8;
      font-weight: bold;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.05rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }
    .tx4{
      color: $T12;
      background: $T11;
    }
    .tx5{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
    .tx6{}
    .tx7{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon_gray_arrow{
        width: 0.12rem;
        height: 0.12rem;
      }
    }
  }
}
</style>
