<template>
  <article class="h5_invest_order_view">


    <section class="loan_count_box">
      <p class="tx1_box tx1_br d_flex_space">
        <span class="tx1">{{$t('total_current_investment')}}({{$t('folded')}})</span>
        <span class="tx2">{{resAssets.total_investment}} {{resAssets.symbol}}</span>
      </p>
      <p class="tx1_box d_flex_space">
        <span class="tx1">{{$t('cumulative_income')}}({{$t('folded')}})</span>
        <span class="tx2">{{resAssets.total_profit}} {{resAssets.symbol}}</span>
      </p>
      <p class="tx1_box d_flex_space">
        <span class="tx1">{{$t('principal_redeemed')}}({{$t('folded')}})</span>
        <span class="tx2">{{resAssets.principal}} {{resAssets.symbol}}</span>
      </p>
      <p class="tx1_box d_flex_space">
        <span class="tx1">{{$t('income_withdrawn')}}({{$t('folded')}})</span>
        <span class="tx2">{{resAssets.stay_profit}} {{resAssets.symbol}}</span>
      </p>

    </section>

    <section class="loan_list_main">
      <div class="list_title d_flex_space">
        <p class="tx1">{{$t('investment_order')}}</p>
      </div>
      <div class="list_box">
        <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
          <div class="list_item" v-for="(list, index) in resData" :key="list.id">
            <div @click="goRouter('/invest_order_details', {id: list.id})">
              <div class="coin_box d_flex_space">
                <div class="l_box d_flex">
                  <div class="com_icon_img_box3">
                    <img :src="list.coin_data.icon_url" class="com_coin_img"/>
                    <i class="h5_icon com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                  </div>
                  <span class="tx1 ">{{list.symbol}}</span>
                  <span class="tx2 ml_5" :class="list.chain_type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.chain_type] }}</span></span>
                </div>
                <div class="r_box d_flex">
                  <span class="tx2 tx7"><span class="fz_10 font_bold">{{list.invest_days}} {{$t('day')}}</span></span>
                  <span class="tx2 tx6 ml_5"><span class="fz_10 font_bold">{{list.daily_profit}}</span></span>
                  <i class="h5_icon ml_3 icon_orange_arrow"></i>
                </div>
              </div>
              <div class="tx3 mb_10 color_T6">{{$t('investment_amount')}}: {{list.invest_amount}}</div>
              <div class="tx3 tx3_br color_T6 d_flex_space">
                <span>{{$t('subscription_time')}}: {{dataFormat(list.invest_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
                <span class="font_bold " :class="(list.status == 8 || list.status == 2 || list.status == 1 ) ? 'color_T8' : (list.status == 3 || list.status == 6 || list.status == 7 ) ? 'color_T9' : 'color_T7'">{{ (list.is_order_repeat && list.status == 4) ? $t('re_cast') :  statusTxFn(list)}}</span>
              </div>
            </div>
            <div class="tx3 color_T6 d_flex_space">
              <p>{{$t('loan_financing')}}: <span class="" :class="list.repeat_status == 1 ? 'color_T8' : 'color_T6'">{{checkTxFn(list)}}</span></p>
              <i class="h5_icon" @click="showCheckFn(index)" :class="list.is_repeat ? 'icon_swipe_yes' : 'icon_swipe_no'" v-show="list.order_type != 2 &&(list.status == 1 || list.status == 2  || list.status == 5 || list.status == 8)"></i>
            </div>
          </div>
        </div>
        <div class="com_no_data_box" v-show="!resData.length">
          <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
          <p class="com_no_data_tx">{{$t('nodata')}}</p>
        </div>
      </div>
    </section>


    <article class="com_confirm_main" v-show="isShowCheckTips">
      <div class="com_confirm_bg"></div>
      <section class="com_confirm_box">
        <div class="com_confirm_head">
          <span class="title_tx">{{ $t('tips_view.msg30') }}</span>
        </div>
        <div class="com_confirm_info_box">
          <p class="com_confirm_tx color_T6">{{isCheck ? $t('tips_view.msg31') : $t('tips_view.msg32')}} </p>

          <div class="com_confirm_from_box">
            <div class="com_confirm_btn_box1 d_flex_space">
              <input type="button" class="com_confirm_input_btn com_confirm_input_btn_close" :value="$t('cancel')" @click="isShowCheckTips = false"/>
              <input type="button" class="com_confirm_input_btn com_confirm_input_btn_cur" :value="isCheck ? $t('turn_off') : $t('turn_on')" @click="subWithdrawFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
</template>

<script>
import comLoading from "@/components/loading";

export default {
  name: "invest_order",
  data () {
    return {
      status: 'loading',
      text: '',
      isShowLoading: false,
      isShowCheckTips: false,
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      resData: [],
      resAssets: {},
      isCheck: false,
      isAjax: false,
      curIndex: '',
      coinBanlace: 0,
    }
  },
  created () {
    let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'


    this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
      addr: this.storeChangeAddress,
    }, "post").then(res => {
      this.coinBanlace =  res.quantity ? parseFloat(res.quantity) : 0
    })
    this.resAssetsFn()
    this.resInit()
  },
  components: {
    comLoading,
  },
  methods: {
    async subWithdrawFn () {
      if (this.storeChainType != this.resData[this.curIndex].chain_type.toUpperCase()) {
        this.messageFn(this.$t('tips_view.msg78') + this.resData[this.curIndex].chain_type.toUpperCase() + this.$t('tips_view.msg88'))
        return false
      }

      let tx_fee = parseFloat(this.resData[this.curIndex].tx_fee)

      if ((tx_fee > this.coinBanlace) || (this.coinBanlace == 0)) {
        this.messageFn(this.coinMian[this.storeChainType] + this.$t('tips_view.msg89'))
        return false
      }

      let str = this.resData[this.curIndex].id + '|' + (this.isCheck ? 'close' : 'open')
      if (this.isAjax) {
        return false
      }
      this.isShowCheckTips = false
      this.isAjax = true

      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')

      let txId

      await this.cancelReinvestTokenCoinFn(this.resData[this.curIndex].pool_addr, str).then(res => {
        txId = res
      }).catch(res => {
        txId = ''

        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false

        }, 2000)
        return false
      })
      if (!txId) {
        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      } else {
        this.$ajax(`${this.Utils.URL.goAPPURL}borrow/notice`, {
          chain_type: this.storeChainType.toLocaleLowerCase(),
          tx_id: txId
        }, 'get')
            .then(res => {

            }).catch(res => {


        })
        this.isAjax = false
        this.isLoad = false
        this.status = 'success'
        this.text = !this.resData[this.curIndex].is_repeat ? this.$t('tips_view.msg74') : this.$t('tips_view.msg75')
        this.resData[this.curIndex].is_repeat = !this.resData[this.curIndex].is_repeat
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)


      }



      /*let time = (parseInt(new Date().getTime() / 1000)) + ''
      let sing = await this.dappSingFn(time + (this.isCheck ? '2' : '1') + this.resData[this.curIndex].id)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }
      let req = {
        id: this.resData[this.curIndex].id,
        opt_type: this.isCheck ? '2' : '1',
        features: time,
        signature: sing
      }
      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''
        this.$ajax(`${this.Utils.URL.goAPPURL}loan/repeat_invest`, req, 'post')
          .then(res => {

            this.isLoad = false
            this.status = 'success'
            this.text = !this.resData[this.curIndex].is_repeat ? this.$t('tips_view.msg74') : this.$t('tips_view.msg75')
            this.isShowCheckTips = false
            this.resData[this.curIndex].is_repeat = !this.resData[this.curIndex].is_repeat
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })*/
    },
    showCheckFn (index) {
      this.curIndex = index
      this.isCheck = this.resData[index].is_repeat
      this.isShowCheckTips = true
    },
    checkTxFn (obj) {
      if ( obj.status == 1 || obj.status == 2  || obj.status == 5 || obj.status == 8) {
        if (obj.is_repeat) {
          return this.$t('reinvestment_on')
        } else {
          return this.$t('reinvestment_off')
        }
      } else {
        if (obj.repeat_status == 1) {
          return this.$t('re_cast')
        }
        if (obj.repeat_status == 2) {
          return this.$t('not_reinvested')
        }
        if (obj.repeat_status == 3) {
          return this.$t('yes_reinvested')
        }
      }

    },
    statusTxFn (obj) {
      if (obj.order_type == 2) {
        return this.$t('experience_coupon')
      }
      if (obj.status == 1) {
        return this.$t('under_subscription')
      }
      if (obj.status == 2) {
        return this.$t('investing')
      }
      if (obj.status == 3) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 4) {
        return this.$t('completed')
      }
      if (obj.status == 5) {
        return this.$t('re_cast')
      }
      if (obj.status == 6) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 7) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 8) {
        return this.$t('under_subscription')
      }
    },
    resAssetsFn () {
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/my_investment`, {}, 'get')
          .then(res => {
            this.resAssets = res ? res : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/my_investment_order_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
  },
}
</script>

<style lang="scss">
.h5_invest_order_view{
  .loan_count_box{
    padding: 0.16rem;
    background: $BG2;
    margin-bottom: 0.2rem;
    .tx1_box{
      color: $T6;
      margin-bottom: 0.16rem;
    }
    .tx1_box:last-child{
      margin-bottom: 0;
    }
    .tx1_br{
      padding-bottom: 0.12rem;
      border-bottom: 1px solid $BR2;
    }
    .tx1{
      font-size: 0.13rem;
    }
    .tx2{
      font-size: 0.13rem;
      font-weight: bold;
    }
  }

  .loan_list_main{
    padding: 0 0.16rem 0.16rem 0.12rem;
    .list_title{
      margin-bottom: 0.12rem;
      .tx1{
        font-size: 0.15rem;
        color: $T6;
        font-weight: bold;
      }
      .tx2{
        font-size: 0.13rem;
        color: $T8;
      }
      .icon_orange_arrow{
        width: 0.12rem;
        height: 0.12rem;
      }
    }
    .list_box{

      .list_item{
        border-radius: 0.06rem;
        padding: 0.16rem;
        background: $BG2;
        margin-bottom: 0.12rem;
      }
      .coin_box{
        margin-bottom: 0.16rem;
        .icon_orange_arrow{
          width: 0.12rem;
          height: 0.12rem;
        }
      }
      .tx1{
        font-size: 0.16rem;
        font-weight: bold;
      }
      .tx2{
        font-size: 0.12rem;
        padding: 0.01rem 0.04rem;
        border-radius: 0.1rem;
        display: inline-block;
        min-width: 0.36rem;
        text-align: center;
      }
      .tx6{
        color: $T5;
        background: $T1;
      }
      .tx5{
        color: $T12;
        background: $T11;
      }
      .tx7{
        color: $T8;
        background: $T10;
      }
      .tx8{
        color: $T14;
        background: $T13;
      }
      .tx9{
        color: $T16;
        background: $T15;
      }
      .tx3{
        font-size: 0.12rem;
      }
      .tx3_br{
        margin-bottom: 0.16rem;
        border-bottom: 1px solid $BR2;
        padding-bottom: 0.12rem;
      }

    }
  }
}
</style>