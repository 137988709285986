<template>
  <article class="h5_profit_view">
    <section class="profit_head_main">
      <div class="item_box">
        <div class="item" @click="linkRouterFn('/income_profit')">
          <p class="tx5">
            <span>{{ $t('investment_income') }}</span>
            <i class="h5_icon icon_green_arrow"></i>
          </p>
          <p class="tx6">{{scientificNotationToString(resData.mining_profit)}} SCF</p>
        </div>
        <div class="item" @click="linkRouterFn('/team_profit')">
          <p class="tx5">
            <span>{{ $t('team_benefits') }}</span>
            <i class="h5_icon icon_green_arrow"></i>
          </p>
          <p class="tx6">{{scientificNotationToString(resData.team_profit)}} SCF</p>
        </div>
        <div class="item last">
          <div class="content">
            <p class="tx6"><strong>流动性质押</strong></p>
            <p class="tx6 flex">
              <span style="font-weight: bold;">22732.64 FUSD</span>
              <i class="h5_icon icon_green_arrow"></i>
            </p> 
          </div>
        </div>
      </div>
    </section>
    <section class="profit_box" @click="linkRouterFn('/node_voucher_balance')">
      <p class="tx5">
        <span>{{ $t('fth_subscription_view.msg23') }}</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">{{ $t('fth_subscription_view.msg24') }}</p>
          <p class="tx7">{{scientificNotationToString(node_voucher.quantity)}} {{symbolStatic}}</p>
          <!-- <p class="tx7">{{resData.node_voucher.quantity}} {{resData.symbol}}</p> -->
        </div>
        <div class="w48 tx_r">
          <p class="tx6">{{ $t('fth_subscription_view.msg25') }}</p>
          <p class="tx7">{{scientificNotationToString(node_voucher.ecology_amt)}} {{symbolStatic}}</p>
          <!-- <p class="tx7">{{resData.node_voucher.ecology_amt}} {{resData.symbol}}</p> -->
        </div>
      </div>

    </section>
    <section class="profit_box" @click="linkRouterFn('/')">
      <p class="tx5">
        <span>贡献值排名奖励</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">累计发放奖励</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx7">0 {{symbolStatic}}</p>
        </div>
      </div>

    </section>
    <section class="profit_box" @click="linkRouterFn('/')">
      <p class="tx5">
        <span>贡献值奖励</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">累计发放奖励</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx7">0 {{symbolStatic}}</p>
        </div>
      </div>

    </section>
    <section class="profit_box" @click="linkRouterFn('/')">
      <p class="tx5">
        <span>流动性质押</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">FIN-LP总额（折合）</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx7">0 {{symbolStatic}}</p>
        </div>
      </div>

    </section>
    <!-- <section class="profit_box" @click="linkRouterFn('/loan_pool_profit')">
      <p class="tx1" >
        <span>{{ $t('order_view.my_loan_matching_pool_balance') }}</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <p class="tx2_box">
        <span class="tx2">{{scientificNotationToString(resData.pool_details.amount)}}</span>
        <span class="tx3 ml_5">{{resData.symbol}}</span>
      </p>
    </section> -->
    <section class="profit_box" @click="linkRouterFn('/fth_profit')">
      <p class="tx1">
        <span>FTH权益</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <p class="tx2_box">
        <span class="tx7">{{scientificNotationToString(this.resData.sto_details.sto_profit)}} FTH</span>
        <span class="tx4"> ≈ {{scientificNotationToString(resData.sto_details.sto_amount)}} {{symbolStatic}}</span>
      </p>
    </section>
    <section class="profit_box" @click="linkRouterFn('/fth_subscription')">
      <p class="tx5">
        <span>{{ $t('fth_subscription_view.msg15') }}</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">{{ $t('fth_subscription_view.msg16') }}</p>
          <p class="tx7">{{scientificNotationToString(resData.fth_details.total_quota)}} {{symbolStatic}}</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx6">{{ $t('fth_subscription_view.msg17') }}</p>
          <p class="tx7">{{scientificNotationToString(resData.fth_details.give_out)}} {{symbolStatic}}</p>
        </div>
      </div>

    </section>
    <section class="profit_box" @click="linkRouterFn('/my_team_profit')">
      <p class="tx5">
        <span>{{ $t('my_team') }}</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box mb_20">
        <div class="w48">
          <p class="tx6">{{ $t('investissement_personnel_total') }}({{ $t('folded') }})</p>
          <p class="tx7">{{scientificNotationToString(resData.my_team.self_invest_num)}} {{symbolStatic}}</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx6">{{ $t('adresses_push_directes') }}</p>
          <p class="tx7">{{scientificNotationToString(resData.my_team.direct_invite_num)}}</p>
        </div>
      </div>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">{{ $t('total_team_investment') }}({{ $t('folded') }})</p>
          <p class="tx7">{{scientificNotationToString(resData.my_team.invest_profit)}} {{symbolStatic}}</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx6">{{ $t('total_number_addresses_team') }}</p>
          <p class="tx7">{{scientificNotationToString(resData.my_team.invite_num)}}</p>
        </div>
      </div>
    </section>
    <section class="profit_box" @click="linkRouterFn('/yesterday_profit')">
      <p class="tx5">
        <span>{{ $t('yesterday_earnings') }}</span>
        <i class="h5_icon icon_gray_arrow"></i>
      </p>
      <div class="item_box">
        <div class="w48">
          <p class="tx6">{{ $t('yesterday_investment_income') }}({{ $t('folded') }})</p>
          <p class="tx7">{{scientificNotationToString(resData.yesterday_details.invest_profit)}} {{symbolStatic}}</p>
        </div>
        <div class="w48 tx_r">
          <p class="tx6">{{ $t('yesterday_team_earnings') }}({{ $t('folded') }})</p>
          <p class="tx7">{{scientificNotationToString(resData.yesterday_details.team_profit)}} {{symbolStatic}}</p>
        </div>
      </div>
    </section>
    <index-footer :index="3"></index-footer>
    <com-bind-wallet-id :isShow="isShowWallet" @on-hide="()=>{this.isShowWallet = false}"></com-bind-wallet-id>
    <article class="select_coin_main" v-show="isShowCoin">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowCoin = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{ $t('profit_coin_detail') }}</span>
      </header>
      <section class="select_coin_box">
        <div class="select_item d_flex_space" v-for="(list, index) in coinList" :key="index">
          <div class="d_flex">
            <div class="com_icon_img_box3">
              <img :src="list.coin_data.icon_url" class="com_coin_img"/>
              <i class="h5_icon_1 com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx3 color_T6 font_bold ml_5">{{ list.coin_data.code }}</span>
            <span class="tx2 ml_5 bsc_color" v-show="list.coin_data.contract_addr" :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>

          </div>
          <div class="tx16 color_T6">
            {{list.amount}}
          </div>
        </div>

      </section>
    </article>
  </article>
</template>

<script>
import indexFooter from '../../components/index_footer'
import comBindWalletId from '@/components/bind_wallet_id'
export default {
  name: "profit",
  data () {
    return {
      symbolStatic: 'USDT',
      node_voucher: {},
      isShowCoin: false,
      coinList: [],
      resData: {

        isShowWallet: false,
        cumulative_profit: '',
        invest_profit: '',
        team_profit: '',
        symbol: '',
        my_team: {},
        partner_details: {},
        pool_details: {},
        spread_details: {},
        sto_details: {},
        fth_details: {},
        yesterday_details: {},
      }
    }
  },
  created() {
    this.resInit()
    this.getVoucherBalance()
    this.coinInit()
  },
  components: {
    indexFooter,
    comBindWalletId,
  },
  computed: {
    ftcBalance () {
      return parseFloat(this.resData.sto_details.sto_profit).toFixed(6)
    },
  },
  methods: {
    getVoucherBalance() {
      
      this.$ajax(`${this.Utils.URL.goAPPURL}super/ticket_num`, {}, 'get')
          .then(res => {
            this.node_voucher = res ? res : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    coinInit (type) {
      let req = {
        size: 10000
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}profit/cumulative_profit_list`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            this.coinList = arr

          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    linkRouterFn (path, query = {}) {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      if (!this.storeChangeAddressId) {

        this.isShowWallet = true
        return false
      }
      this.goRouter(path, query)
    },
    resInit () {
      let req = {
      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/home_page_profit_list`, req, "get")
          .then((res) => {
            this.resData = res ? res : {}

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_profit_view{
  padding: 0.16rem;
  .w48{
    width: 48%;
  }
  .profit_head_main{
    padding: 0.16rem;
    background: $T8;
    border-radius: 0.06rem;
    margin-bottom: 0.16rem;

    .tx1{
      color: $FFF;
      font-size: 0.14rem;

      margin-bottom: 0.05rem;
    }
    .tx2_box{
      color: $FFF;
      margin-bottom: 0.05rem;
      
    }
    .tx2{
      font-size: 0.23rem;
      font-weight: bold;
    }
    .tx3{
      font-size: 0.14rem;
      font-weight: bold;
      margin: 0 0.05rem;
    }
    .tx4{
      font-size: 0.14rem;
    }
    .item_box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item{
        width: 1.5rem;
        padding: 0.12rem;
        color: $T5;
        background: $FFF;
        border-radius: 0.06rem;
        display: flex;
        flex-direction: column;
        place-content: inherit;
        &.last {
          flex-basis: 100%;
          margin-top: 0.1rem;
          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .flex {
              display: flex;
              align-items: center;
              .h5_icon {
                align-self: flex-start;
              }
            }
          }
        }
      }
      .icon_green_arrow{
        width: 0.12rem;
        height: 0.12rem;
        position: relative;
        top: 0.04rem;
      }
      .tx5{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: 0.13rem;
        margin-bottom: 0.03rem;
      }
      .tx6{
        font-weight: bold;
        font-size: 0.14rem;
      }
      
    }
  }
  .profit_box{
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.16rem;
    border-radius: 0.06rem;
    background: $BG2;
    .tx1{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.16rem;
      margin-bottom: 0.03rem;
      color: $T6;
    }
    .tx2_box{}
    .tx2{
      color: $T6;
      font-size: 0.2rem;
      font-weight: bold;
    }
    .tx3{
      color: $T6;
      font-size: 0.14rem;
      font-weight: bold;
    }
    .tx4{
      color: $T7;
      font-size: 0.13rem;
    }
    .tx5{
      color: $T6;
      font-size: 0.16rem;
      font-weight: bold;
      padding-bottom: 0.14rem;
      border-bottom: 1px solid $BR2;
      margin-bottom: 0.14rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .item_box{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      .w48 {
        display: flex;
        flex-direction: column;
        justify-content: inherit;
      }
    }
    .tx6{
      margin-bottom: 0.03rem;
      color: $T7;
      font-size: 0.14rem;
    }
    .tx7{
      color: $T6;
      font-size: 0.17rem;
      font-weight: bold;
    }
  }
  .tx2_box_01{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: normal;
  }
}
</style>