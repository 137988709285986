(function (doc, win) {
    function setFontSize () {
        var docEl = doc.documentElement
        var winWidth = docEl.clientWidth
        /* if (winWidth <= 375) {
          doc.documentElement.style.fontSize = (winWidth / 375) * 100 + 'px'
        } else {
          docEl.style.fontSize = 100 + 'px'
        } */
        doc.documentElement.style.fontSize = (winWidth / 375) * 100 + 'px'
        window.HTMLWIDTH = (winWidth / 375) * 100
        /*if (winWidth <= 375) {
          doc.documentElement.style.fontSize = (winWidth / 375) * 100 + 'px'
          window.HTMLWIDTH = (winWidth / 375) * 100
        } else {
          docEl.style.fontSize = 100 + 'px'
          window.HTMLWIDTH = 100
        }*/
    }
    var evt = 'onorientationchange' in win ? 'orientationchange' : 'resize'
    var timer = null
    win.addEventListener(evt, function () {
        clearTimeout(timer)
        timer = setTimeout(setFontSize, 300)
    }, false)
    win.addEventListener('pageshow', function (e) {
        if (e.persisted) {
            clearTimeout(timer)
            timer = setTimeout(setFontSize, 300)
        }
    }, false)
    setFontSize()
})(document, window)
