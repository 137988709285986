<template>
  <article class="h5_coupon_list_view">
    <section class="tab_box">
      <p class="item" @click="tabFn(1)" :class="tabIndex == 1 ? 'cur' : ''">{{ $t('all') }}</p>
      <p class="item" @click="tabFn(2)" :class="tabIndex == 2 ? 'cur' : ''">{{ $t('used') }}</p>
      <p class="item" @click="tabFn(4)" :class="tabIndex == 4 ? 'cur' : ''">{{ $t('expired') }}</p>
    </section>
    <section class="coupon_list_main">
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <div class="coupon_list_box" :class="'coupon_list_box_' + list.deduct_amount" v-for="(list, index) in resData" :key="list.id">
          <div class="info_box">
            <p class="tx1">{{ $t('loan_and_wealth_management_experience_voucher') }} ({{ list.deduct_amount }} USDT)</p>
            <p class="tx2">{{$t('coupon_view.msg7')}}</p>
            <p class="tx2">{{ $t('investment_cycle') }}: {{list.invest_period}} {{ $t('day') }}</p>
            <p class="tx2">{{ $t('valid_until') }}: {{dataFormat(list.expired_at, 'yyyy-MM-dd')}}</p>
            <div class="btn_box d_flex_space">
              <span class="btn btn_cur" v-show="list.status == 1" @click="sendCouponFn(index)">{{ $t('give_away') }}</span>
              <span class="btn btn_cur" v-show="list.status == 1" @click="linkFn(list)">{{ $t('to_use') }}</span>
              <span class="btn btn_old" v-show="list.status != 1">{{statusTxFn(list.status)}}</span>
            </div>
          </div>
          <div class="coupon_list_banner"></div>
        </div>

      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </section>
    <article class="com_popup_main" v-show="isShowWallet">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('give_away_coupon') }}</span>
          <i class="h5_icon icon_close" @click="isShowWallet = false"></i>
        </div>
        <div class="com_popup_info_box">

          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('wallet_id') }}</p>
            </div>
            <div class="com_popup_input_box mb_20" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div>
                <input type="text" v-model="walletId" class="com_popup_input_tx" :placeholder="$t('index_view.msg4')"/>
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn " :class="walletId ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="subCouponFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('kind_tips') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{$t('coupon_view.msg8')}}</p>
            <p class="tx5 color_T6 mb_10">{{$t('coupon_view.msg9')}}</p>
            <p class="tx5 color_T6">{{$t('coupon_view.msg10')}}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="isShowTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>
  </article>
</template>

<script>

export default {
  name: "coupon_list",
  data() {
    return {
      walletId: '',
      errorTx: '',
      status: 'loading',
      text: this.$t('tips_view.msg1'),
      isShowLoading: false,
      isShowWallet: false,
      isShowTips: false,
      loadInfinite: false,
      isLoad: false,
      hasOrder: false,
      hasUsed: false,
      adId: '',
      resData: [],
      tabIndex: 1,
      page: 1,
      total: 0,
      size: 30,
      curIndex: '',
    }
  },
  created() {
    this.resInit()
  },
  methods: {
    sendCouponFn (index) {
      this.curIndex = index
      this.errorTx = ''
      this.isShowWallet = true
    },
    linkFn (list) {
      if (list.has_used) {
        this.isShowTips = true
        return false
      }
      if (!this.hasOrder || !this.adId) {
        this.messageFn(this.$t('tips_view.msg4'))
        return false
      }
      this.goRouter('/coupon_details', {ad_id: this.adId, id: list.id, deduct_amount: list.deduct_amount})
    },
    statusTxFn (status) {
      if (status == 2) {
        return this.$t('used')
      }
      if (status == 3) {
        return this.$t('gifted')
      }
      if (status == 4) {
        return this.$t('expired')
      }
    },
    tabFn (index) {
      if (this.tabIndex == index) {
        return false
      }
      this.tabIndex = index
      this.page = 1
      this.loadInfinite = false
      this.isLoad = false
      this.resData = []
      this.resInit('search')
    },
    resInit (type) {

      let req = {
        typ: this.tabIndex,
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/ticket`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []
            this.hasOrder = res.has_order
            this.adId = res.ad_id
            this.hasUsed = res.has_used
            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    async subCouponFn () {
      let index = this.curIndex
      if (!this.walletId) {
        this.errorTx = this.$t('index_view.msg4')
        return false
      }

      let time = parseInt(new Date().getTime() / 1000)
      let sing = await this.dappSingFn(time + ':' + this.resData[index].id + ':' + this.walletId)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }

      let req = {
        id: this.resData[index].id,
        user_id: this.walletId,
        features: time,
        signature: sing,
        chain_type: this.storeChainType.toLocaleLowerCase(),
      }




      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/send_ticket`, req, 'post')
          .then(res => {
            this.isLoad = false
            this.isShowWallet = false
            this.resData[index].status = 3
            this.messageFn(this.$t('tips_view.msg6'))
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_coupon_list_view{
  min-height: 100vh;

  .tab_box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BR;
    background: $FFF;
    .item{
      width: 33.33%;
      font-size: 0.14rem;
      color: $T7;
      padding: 0.12rem 0;
      text-align: center;
    }
    .cur{
      color: $T8;
      font-weight: bold;
      border-bottom: 1px solid $T8;
    }
  }
  .coupon_list_main{
    padding: 0.16rem;
    .coupon_list_box{
      position: relative;
      background: linear-gradient(180deg, #FAE3DC 0%, #F57B71 100%);
      border-radius: 0.1rem;
      margin-bottom: 0.16rem;
      padding: 0.16rem;
      .info_box{
        position: relative;
        z-index: 2;
      }
      .coupon_list_banner{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1.26rem;
        background-size: cover;
        background-image: url("../../assets/img/banner/coupon_list_banner.png");
        z-index: 1;
      }
      .tx1{
        margin-bottom: 0.1rem;
        color: $T9;
        font-weight: bold;
        font-size: 0.16rem;
      }
      .tx2{
        margin-bottom: 0.07rem;
        font-size: 0.12rem;
        color: $T6;
      }
      .btn_box{
        margin-top: 0.16rem;
        .btn_cur{
          width: 48%;
        }
        .btn_old{
          width: 100%;
          opacity: 0.3;
        }
        .btn{
          text-align: center;
          line-height: 0.44rem;
          border-radius: 0.22rem;
          background: $T9;
          color: $FFF;
          font-size: 0.16rem;
          font-weight: bold;
        }

      }
    }
    .coupon_list_box_100{
      background: linear-gradient(180deg, #FAF0DC 0%, #F5C971 100%);
      .tx1{
        color: rgba(242, 153, 29, 1);
      }
      .btn{
        background: rgba(242, 153, 29, 1) !important;
      }
    }
    .coupon_list_box_200{
      background: linear-gradient(180deg, #FAE8DC 0%, #F59D71 100%);
      .tx1{
        color: rgba(235, 97, 28, 1);
      }
      .btn{
        background:rgba(235, 97, 28, 1) !important;
      }
    }
    .coupon_list_box:last-child{
      margin-bottom: 0;
    }
  }
}

</style>