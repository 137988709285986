<template>
  <article class="h5_loan_confirm_view">
    <section class="activity_period_main">
      <div class="rate_bg"></div>
      <div class="rate_bg1"></div>
      <div class="activity_period_box">

        <div class="item">
          <div class="tx_box1">
            <span class="period_bg period_bg_cur">
              <i class="period_round period_cur"></i>
            </span>
            <p class="tx tx_over tx_cur">{{ $t('application') }}</p>
          </div>
        </div>
        <div class="item">
          <div class="tx_box1">
            <span class="period_bg ">
              <i class="period_round "></i>
            </span>
            <p class="tx tx_over ">{{ $t('fundraising') }}</p>
          </div>
        </div>
        <div class="item">
          <div class="tx_box1">
            <span class="period_bg ">
              <i class="period_round "></i>
            </span>
            <p class="tx tx_over ">{{ $t('loaning') }}</p>
          </div>
        </div>
        <div class="item">
          <div class="tx_box1">
            <span class="period_bg ">
              <i class="period_round "></i>
            </span>
            <p class="tx tx_over ">{{ $t('repayment') }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="assets_main">
      <div class="assets_box">
        <div class="tx2 tx2_br mb_10 d_flex">
        <div class="com_icon_img_box3">
          <img :src="resData.icon_url" class="com_coin_img"/>
          <i class="h5_icon com_icon_subscript" :class="resData.chain_type == 'tron' ? 'icon_subscript_tron' : resData.chain_type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
        </div>
          <span class="">{{ resData.code }} {{ $t('loan_details') }}</span>
        </div>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('loan_amount') }}</span>
          <span class="tx1">{{ resData.num }} {{ resData.code }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('loan_period') }}</span>
          <span class="tx1">{{ resData.days }} {{ $t('day') }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('daily_interest_rate') }}</span>
          <span class="tx1">{{ resData.rate }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('repayment_type') }}</span>
          <span class="tx1">{{ $t('first_interest') }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('investment_channel') }}</span>
          <span class="tx1">{{ resData.swap_name }}</span>
        </p>

      </div>
    </section>

    <section class="assets_main">
      <div class="assets_box">
        <p class="tx2 tx2_br mb_10 d_flex">

          <span class="ml_5">{{ $t('payment_coin') }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('security_deposit') }}</span>
          <span class="tx1">{{ resData.pledge }} {{ resData.code }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('create_coin_wallet_miner_fee') }}</span>
          <span class="tx1">{{ resData.miner_fee }} {{ resData.miner_code }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('mining_fee_estimated') }}</span>
          <span class="tx1">{{ resData.predict_miner_fee }} {{ resData.miner_code }}</span>
        </p>

      </div>
    </section>

    <section class="tips_box">
      <p class="title_tx font_bold mb_10">{{ $t('loan_confirm_view.tips1') }}</p>
      <p class="tx">{{ $t('loan_confirm_view.tips2') }}</p>
      <p class="tx">{{ $t('loan_confirm_view.tips3') }}</p>
      <p class="tx">{{ $t('loan_confirm_view.tips4') }}</p>
      <p class="tx">{{ $t('loan_confirm_view.tips5') }}</p>
      <p class="tx">{{ $t('loan_confirm_view.tips6') }}</p>
    </section>

    <p class="input_btn"><span class="com_popup_input_btn com_popup_input_btn_cur" @click="submitFn">{{$t('confirm')}}</span></p>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    <div class="bottom_loading_box" v-show="isApproveShow">
      <i class="h5_icon icon_small_loading"></i>
      <p>{{ $t('tips_view.msg57') }}</p>
    </div>
  </article>
</template>

<script>
import comLoading from "@/components/loading";

export default {
  name: "loan_confirm",
  data () {
    return {
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isAjax: false,
      isShowLoading: false,
      isApproveShow: false,
      resData: {},
      pledgeAddr: '',
      balance: '',
      coinBanlace: '',
      borrowInfoTuple: [],
      owner_addrs: []
    }
  },
  components: {
    comLoading,
  },
  created () {
    this.resData = window.sessionStorage.getItem('listData') ? JSON.parse(window.sessionStorage.getItem('listData')) : {}
    this.resInit()
    if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH' || this.storeChainType == 'TRON') {
      let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'
      this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
        addr: this.storeChangeAddress
      }, "post").then(res => {
        this.coinBanlace = res.quantity ? parseFloat(res.quantity) : 0
      })
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/v2/multi_param`, {
        addr: this.storeChangeAddress,
        bond_fee: this.resData.pledge_rate,
        borrow_amount : this.resData.num,
        chain_type : this.resData.chain_type,
        coin_id : this.resData.coin_id,
        days : this.resData.days,
        swap_type : this.resData.swap_type,
      }, "get").then(res => {
        this.owner_addrs = res.owner_addrs ? res.owner_addrs : []
        this.borrowInfoTuple[0] = res.borrow_id ? res.borrow_id : ''
        if (this.resData.contract_addr == '') {
          this.borrowInfoTuple[2] = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
        } else {
          this.borrowInfoTuple[2] = this.resData.contract_addr
        }

      })

    }
  },
  mounted () {
    this.getUSDTBalanceFn().then(res => {
      this.balance = res
    }).catch(res => {
      this.balance = 0
      this.messageFn(res.msg)
    })
  },
  methods: {

   async submitFn () {
      let num = parseFloat(this.resData.num)
      let balance = parseFloat(this.balance)
      let coinBanlace = parseFloat(this.coinBanlace)
      let min = parseFloat(this.resData.min)
      let max = parseFloat(this.resData.max)
      let miner_fee = parseFloat(this.resData.miner_fee) + parseFloat(this.resData.predict_miner_fee)
      let base_amount = parseFloat(this.resData.base_amount)
      let pledge = parseFloat(this.resData.pledge)


      if (!this.resData.is_open) {
        this.messageFn(this.$t('tips_view.msg59'))
        return false
      }

      if (new window.windowBigNumber(num).mod(base_amount).toString() != 0) {
        this.messageFn(`${this.$t('tips_view.msg23')} ${base_amount} ${this.$t('tips_view.msg24')}`)
        return false
      }
      if (num > max) {
        this.messageFn(this.$t('tips_view.msg60') + this.resData.max_borrow_amount)
        return false
      }
      if ((num < min) || (num == 0)) {
        this.messageFn(this.$t('tips_view.msg61') + this.resData.min_invest_amount)
        return false
      }
      if (balance < pledge) {
        this.messageFn(this.$t('tips_view.msg81') + this.resData.pledge + this.coin_data.code + ',' + this.$t('tips_view.msg82'))

        return false
      }
      if ((miner_fee > coinBanlace) || (coinBanlace == 0)) {
        this.messageFn(this.$t('tips_view.msg56'))
        return false
      }
      if (!this.borrowInfoTuple.length) {
        return  false
      }


      if (this.isAjax) {
        return  false
      }
      this.isAjax = true
      let time = parseInt(new Date().getTime() / 1000)
      let sing
      let txId = ''

     /*setTimeout(() => {

         this.isApproveShow = false

         this.isAjax = false


     }, 3000)*/
     if (this.resData.contract_addr) {
       this.isApproveShow = true
        await this.approveUSDTBalanceFn(this.resData.factory_contract, 0, this.storeAddress, this.storeChainType, this.resData.contract_addr).then(res => {
          this.isApproveShow = false
          txId = res
        }).catch(res => {
          txId = ''
          this.isApproveShow = false
          this.isAjax = false
          this.messageFn(this.resData.code + this.$t('tips_view.msg55'))
          return false
        })
        if (!txId) {
          this.isApproveShow = false

          this.isAjax = false
          return false
        }
     }

     this.borrowInfoTuple[1] = parseFloat(this.resData.num)
     let payloadJson = JSON.stringify({"borrowAmount": this.resData.num + '',"days": this.resData.days + ''})
     let payload = ''
     for(let i=0; i<payloadJson.length; i++){
       payload += payloadJson.charCodeAt(i).toString(16)
     }

     this.isShowLoading = true
     this.status = 'loading'
     this.text = this.$t('tips_view.msg8')


     if (this.resData.contract_addr) {
       await this.loanTokenCoinFn(this.resData.factory_contract, this.pledgeAddr, pledge, this.owner_addrs, this.resData.required, this.borrowInfoTuple, payload, this.borrowInfoTuple[2]).then(res => {
         txId = res
       }).catch(res => {
         txId = ''
         this.isAjax = false
         this.status = 'error'
         this.text = this.$t('tips_view.msg12')
         setTimeout(() => {
           this.isShowLoading = false
         }, 2000)
         return false
       })
     } else {
       await this.loanMainCoinFn(this.resData.factory_contract, this.pledgeAddr, pledge, this.owner_addrs, this.resData.required, this.borrowInfoTuple, payload, this.borrowInfoTuple[2]).then(res => {
         txId = res
       }).catch(res => {
         txId = ''
         this.isAjax = false
         this.status = 'error'
         this.text = this.$t('tips_view.msg12')
         setTimeout(() => {
           this.isShowLoading = false
         }, 2000)
         return false
       })
     }


     if (!txId) {
       this.isAjax = false
       this.status = 'error'
       this.text = this.$t('tips_view.msg12')
       setTimeout(() => {
         this.isShowLoading = false
       }, 2000)
       return false
     } else {
       this.status = 'success'
       this.text = this.$t('tips_view.msg11')

       setTimeout(() => {
         this.isShowLoading = false
         this.$router.replace('/loan_order')
       }, 3000)
     }



     this.$ajax(`${this.Utils.URL.goAPPURL}borrow/notice`, {
       chain_type: this.storeChainType.toLocaleLowerCase(),
       tx_id: txId
     }, 'get')
         .then(res => {



         }).catch(res => {


        })
      /*sing = await this.dappSingFn(time + ':' + this.$route.query.ad_id)
      if (!sing) {
        this.isAjax = false
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }*/

    },

    resInit () {

      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/pledge_addr`, {
        chain_type: this.storeChainType.toLocaleLowerCase(),
      }, 'get')
          .then(res => {
            this.pledgeAddr = res.addr ? res.addr : ''
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    }
  }
}
</script>

<style lang="scss">
.h5_loan_confirm_view{
  padding: 0.2rem 0;

  .input_btn{
    margin:  0 0.16rem;
    .com_popup_input_btn{
      display: inline-block;
      line-height: 0.44rem;
      border-radius: 0.06rem;
      text-align: center;
      background: $BG1;
      color: $T4;
      font-size: 0.16rem;
      font-weight: bold;
      width: 100%;

    }

    .com_popup_input_btn_cur{
      background: $T8;
      color: $FFF;
    }
  }
  .activity_period_main{

    padding: 0rem 0.16rem 0.2rem;

    position: relative;
    .rate_bg1{
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0.07rem;
      height: 0.04rem;
      background: $BG4;
    }
    .rate_bg{
      width: 15%;
      position: absolute;
      left: 0px;
      top: 0.07rem;
      height: 0.04rem;
      background: $T8;
      z-index: 1;
    }
    .activity_period_box{
      display: flex;
      position: relative;
      justify-content: space-between;
      .item{
        position: relative;
        text-align: center;
        width:25%;
      }


      .tx{
        font-weight: bold;

        color: $T7;
        font-size: 0.13rem;

        white-space: break-spaces;
      }
      .tx_cur{
        color: $T8;
      }
      .period_bg{
        position: relative;
        display: inline-block;
        background: $BG4;
        border-radius: 100%;
        width: 0.18rem;
        height: 0.18rem;
      }
      .period_bg_cur{
        background: rgba(238, 153, 34, 0.35);
      }
      .period_round{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        background: $T2;
        border-radius: 100%;
        width: 0.12rem;
        height: 0.12rem;
      }
      .period_cur{
        background: $T8;
      }
    }
  }
  .assets_main{
    margin:  0 0.16rem 0.16rem;
    padding: 0.16rem;
    background: $BG2;
    border-radius: 0.06rem;

    .tx1{
      font-size: 0.12rem;
      color: $T6;
    }
    .tx2{
      color: $T6;
      font-size: 0.14rem;
      font-weight: bold;
    }
    .tx3{
      color: $T8;
      font-size: 0.14rem;
      font-weight: bold;
    }
    .tx2_br{
      border-bottom: 1px solid $BR2;
      padding-bottom: 0.1rem;
    }

  }
  .tips_box{
    padding: 0.16rem;
    color: $T7;
    font-size: 0.12rem;
    .tx{
      margin-bottom: 0.05rem;
    }
  }

}
</style>
