<template>
  <article class="h5_coupon_view">
    <div class="coupon_banner"></div>
    <section class="coupon_main">
      <p class="coupon_tx1">{{ $t('coupon_view.msg1') }}</p>
      <section class="coupon_info_box d_flex_space" @click="linkRouterFn('/coupon_list')">
        <div class="l_box">
          <p class="tx1 mb_10">{{$t('number')}}: {{resData.num}}</p>
          <p class="tx1">{{$t('type')}}: {{$t('loan_and_wealth_management_experience_voucher')}}</p>
        </div>
        <i class="h5_icon icon_white_arrow"></i>
      </section>
      <p class="coupon_tx2 mb_5">{{ $t('coupon_view.msg2') }}</p>
      <p class="coupon_tx2">{{ $t('coupon_view.msg3') }}</p>

      <div class="coupon_msg">
        <p class="tx1">{{ $t('coupon_view.msg4') }}</p>
        <p class="tx2">{{ $t('coupon_view.msg5') }}</p>
        <p class="tx3" @click="linkRouterFn('/loan')">{{ $t('coupon_view.msg6') }}</p>
      </div>
    </section>

  </article>
</template>

<script>
export default {
  name: "coupon",
  data () {
    return {
      resData: {}
    }
  },
  created () {
    this.resInit()
  },

  methods: {
    linkRouterFn (path) {
      if (!this.storeChangeAddressId) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      this.goRouter(path)
    },
    resInit () {
      if (!this.storeChangeAddressId) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/ticket_num`, req, "get")
          .then((res) => {
            this.resData = res ? res : {}
          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_coupon_view{
  min-height: 100vh;
  background: $BG2;
  padding: 0.24rem 0.16rem;
  .coupon_banner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background: url("../../assets/img/banner/coupon_banner.png");
    background-size: cover;
    height: 4.44rem;
    z-index: 1;
  }
  .coupon_main{
    position: relative;
    z-index: 2;
  }
  .coupon_tx1{
    font-size: 0.2rem;
    color: $FFF;
    font-weight: bold;
  }
  .coupon_info_box{
    margin: 0.16rem 0;
    background: rgba(224, 138, 32, 1);
    padding: 0.16rem;
    border-radius: 0.06rem;
    .tx1{
      font-size: 0.15rem;
      color: $FFF;
    }
    .icon_white_arrow{
      width: 0.12rem;
      height: 0.12rem;
    }
  }
  .coupon_tx2{
    font-size: 0.13rem;
    color: $FFF;
  }
  .coupon_msg{
    margin: 0.2rem 0;
    background: $FFF;
    padding: 0.16rem;
    border-radius: 0.06rem;
    color: $T6;
    .tx1{
      font-size: 0.15rem;
      font-weight: bold;
      margin-bottom: 0.07rem;
    }
    .tx2{
      font-size: 0.14rem;
      margin-bottom: 0.16rem;
    }
    .tx3{
      background: $T8;
      color: $FFF;
      border-radius: 0.06rem;
      font-size: 0.16rem;
      font-weight: bold;
      text-align: center;
      line-height: 0.44rem;
    }
  }

}
</style>