<template>
  <article class="h5_confirm_view">
    <section>
      <div class="confirm">
        <h2>质押数量</h2>
        <div class="confirm_inner">
          <p class="num">{{ num }}</p>
          <div class="d_flex">
            <div class="com_icon_img_box3">
              <img :src="coinDataObj[currIndexCoin].icon_url" class="com_coin_img" />
              <i class="h5_icon com_icon_subscript"
                :class="coinDataObj[currIndexCoin].type == 'tron' ? 'icon_subscript_tron' : coinDataObj[currIndexCoin].type == 'bsc' ? 'icon_subscript_bsc' : coinDataObj[currIndexCoin].type == 'eth' ? 'icon_subscript_eth' : coinDataObj[currIndexCoin].type == 'mainnet' ? 'icon_subscript_mainnet' : ''"></i>
            </div>
            <span class="code_tx ">{{ coinDataObj[currIndexCoin].code }}</span>
            <span class="type_tx code_type" :class="coinDataObj[currIndexCoin].type + '_color'"><span
                class="fz_10 font_bold">{{ coinChainType[coinDataObj[currIndexCoin].type] }}</span></span>
          </div>
        </div>
      </div>
      <div class="box_detail">
        <p>
          <span>质押天数</span>
          <span>15天</span>
        </p>
        <p>
          <span>日收益率</span>
          <span>1%</span>
        </p>
        <p>
          <span>LP价格 </span>
          <span>128.56 FUSD</span>
        </p>
        <p>
          <span>每天收益</span>
          <span>4.94 FUSD</span>
        </p>
        <p>
          <span>本期到期</span>
          <span>2023/07/06 16:27:06</span>
        </p>
        <p>
          <span>预计总收益</span>
          <span>74.1 FUSD</span>
        </p>
      </div>
      <div class="box_recharge">
        <p class="head">
          <span>复押功能</span>
          <span>
            <i class="h5_icon" @click="toggle()" :class="is_check ? 'icon_swipe_yes' : 'icon_swipe_no'"></i>
          </span>
        </p>
        <div class="content">
          <p>快捷质押说明：</p>
          <p>快捷质押会替用户做FUSD兑换，添加流动性并进行质押，在链上交互的过程中需等待链上交易完成，在等待快速质押的过程，请用户耐心等候，勿做新的操作，避免快捷质押失败。</p>
        </div>
      </div>
      <p class="submit_btn" @click="submit">确定</p>
    </section>

    <el-drawer v-model="drawer" :with-header="false" direction="btt" size="auto">
      <div class="box_drawer">
        <div class="head">
          <h3>选择周期</h3>
          <i class="h5_icon icon_close" @click="drawer = false"></i>
        </div>
        <div class="body">
          <input type="password" v-model="password" class="input_tx" placeholder="请输入密码" />
          <p class="submit_btn submit_btn_transaction" @click="submitTransaction" :class="password != '' ? 'active' : ''">确定</p>
        </div>
      </div>
    </el-drawer>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
</template>
  
  
<script>
import comLoading from "@/components/loading/index.vue";
export default {
  name: "confirm",
  components: {
    comLoading
  },
  data() {
    return {
      num: 0,
      currIndexCoin: 0,
      coinDataObj: [
        {
          code: 'USDT',
          icon_url: '/icon/usdt.svg',
          type: 'bsc',
          value: 'bsc'
        },
        {
          code: 'USDT',
          icon_url: '/icon/usdt.svg',
          type: 'eth',
          value: 'eth'
        },
        {
          code: 'USDT',
          icon_url: '/icon/usdt.svg',
          type: 'tron',
          value: 'tron'
        },
        {
          code: 'FUSD',
          icon_url: '/icon/fusd.svg',
          type: 'mainnet',
          value: 'fusd'
        },
      ],
      is_check: true,
      drawer: false,
      password: '',
      status: 'loading',
      text: '',
      isAjax: false,
      isShowLoading: false,
    }
  },
  created() {
  },
  methods: {
    toggle() {
      this.is_check = !this.is_check
    },
    submit() {
      this.drawer = true
      this.password = ''
    },
    submitTransaction() {
      this.drawer = false
      this.isShowLoading = true
    }
  },
}
</script>
  
<style lang="scss">
.h5_confirm_view {
  padding: 0.16rem 0.16rem 0.3rem;

  .confirm {
    border-radius: 0.1rem;
    background: #F7F7F7;
    padding: 0.16rem 0.16rem 0.4rem;

    h2 {
      text-align: center;
      font-size: 0.16rem;
      margin-bottom: 0.1rem;
    }

    &_inner {
      background: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.1rem;
      border-radius: 0.06rem;

      .num {
        font-size: 0.2rem;
        font-weight: bold;
      }

      .code_tx {
        font-weight: bold;
        margin-right: 0.05rem;
      }

      .code_type {
        border-radius: 0.5rem;
        padding: 0.03rem 0.05rem;
        font-size: 0.1rem;
        line-height: 1;
      }
    }
  }

  .box_detail {
    border-radius: 0.1rem;
    background: #F7F7F7;
    padding: 0.16rem;
    margin-top: 0.16rem;

    p {
      display: flex;
      justify-content: space-between;
      margin-top: 0.1rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .box_recharge {
    border-radius: 0.1rem;
    background: #F7F7F7;
    padding: 0.16rem;
    margin-top: 0.5rem;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      font-size: 0.16rem;
      margin-bottom: 0.16rem;
    }

    .content {
      p {
        margin-top: 0.1rem;
      }
    }
  }

  .submit_btn {
    background: $T8;
    text-align: center;
    border-radius: 0.06rem;
    font-weight: bold;

    font-size: 0.16rem;
    color: $FFF;
    line-height: 0.44rem;
    margin-top: 0.6rem;

    &.submit_btn_transaction {
      margin-top: 0.4rem;
      color: rgba(119, 142, 153, 0.5);
      background: rgba(119, 142, 153, 0.08);
      &.active {
        background: $T8;
        color: $FFF;
      }
    }
  }

  .box_drawer {
    .head {
      display: flex;
      place-items: center;
      justify-content: space-between;
      padding-bottom: 0.13rem;
      border-bottom: 1px solid #E5E5E5;
      padding: 0.16rem 0.1rem;
    }

    .body {
      padding: 0.16rem;
      margin-top: 0.2rem;

      .input_tx {
        width: 100%;
        height: 0.6rem;
        border-radius: 0.06rem;
        padding: 0 0.16rem;
        background: #F0F0F0;
        font-size: 0.16rem;
      }
    }
  }
}</style>