<template>
  <article class="h5_coupon_details_view">
    <section class="coupon_info_box">
      <p class="tx1">{{ $t('investment_amount') }}</p>
      <div class="tx2_box d_flex_cen mb_10">
        <p class="tx2">{{ $route.query.deduct_amount }} {{coin_data.code}}</p>
        <p class="tx3  ml_5" :class="coin_data.type + '_color'">{{coinChainType[coin_data.type]}}</p>
      </div>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('balance') }}: {{balance}} {{coin_data.code}}</span>
        <span>{{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}}</span>
      </p>
      <p class="tx5">{{ $t('number_investments') }}: {{ resData.min_invest_amount }} {{coin_data.code}} ~ {{ resData.surplus_amount }} {{coin_data.code}}</p>
    </section>
    <section class="coupon_info_box">
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('lock_day') }}</span>
        <span>{{resData.borrow_days}} {{ $t('day') }}</span>
      </p>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('daily_rate') }}</span>
        <span>{{resData.daily_profit}}</span>
      </p>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('daily_income') }}</span>
        <span>{{resData.each_profit}} {{coin_data.code}}</span>
      </p>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('redemption_method') }}</span>
        <span>{{$t('tips_view.msg7')}}</span>
      </p>
      <p class="tx4 d_flex_space">
        <span>{{ $t('estimated_total_revenue') }}</span>
        <span>{{resData.borrow_days || resData.each_profit ? resData.borrow_days * resData.each_profit : '-'}} {{coin_data.code}}</span>
      </p>
    </section>

    <section class="coupon_info_box" v-show="$route.query.ticket != 0">
      <p class="tx6 d_flex_space mb_10">
        <span>{{ $t('use_experience_coupon') }}</span>
        <i class="h5_icon icon_swipe_yes"></i>
      </p>
      <p class="tx5">{{ $route.query.deduct_amount }} {{coin_data.code}} {{ $t('wealth_management_experience_voucher') }} ({{ $t('invest') }} {{resData.borrow_days}} {{$t('day')}})</p>
    </section>

    <section class="coupon_info_box" v-show="$route.query.ticket == 0">
      <p class="tx6 d_flex_space mb_10">
        <span>{{ $t('reinvestment_function') }}</span>
        <i class="h5_icon " :class="check ? 'icon_swipe_yes' : 'icon_swipe_no'" @click="check = !check"></i>
      </p>
      <p class="tx5 mb_10">{{ $t('tips_view.msg25') }}</p>
      <p class="tx5 mb_10">{{ $t('tips_view.msg26') }}</p>
      <p class="tx5">{{ $t('tips_view.msg27') }}</p>
    </section>

    <div class="sub_btn" v-show="resData.is_invest" @click="submitFn">{{ $t('confirm') }}</div>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    <div class="bottom_loading_box" v-show="isApproveShow">
      <i class="h5_icon icon_small_loading"></i>
      <p>{{ $t('tips_view.msg57') }}</p>
    </div>
  </article>
</template>

<script>

import comLoading from "@/components/loading";

export default {
  name: "coupon_details",
  data() {
    return {
      walletId: '',
      errorTx: '',
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isApproveShow: false,
      isShowLoading: false,
      isLoad: false,
      isAjax: false,
      check: true,
      resData: {},
      coin_data: {},
      balance: '',
      coinBanlace: 0,
    }
  },
  components: {
    comLoading,
  },
  created() {

    let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'


    this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
      addr: this.storeChangeAddress,
    }, "post").then(res => {
      this.coinBanlace =  res.quantity ? parseFloat(res.quantity) : 0
    })

    this.resInit()


  },
  mounted () {
    this.getUSDTBalanceFn().then(res => {
      this.balance = res
    }).catch(res => {
      this.balance = 0
      this.messageFn(res.msg)
    })
  },
  methods: {
    resInit () {

      let req = {
        id: this.$route.query.ad_id,
        ticket_id: this.$route.query.id,
      };
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/loan_finance_order_details`, req, "get")
          .then((res) => {
            this.resData = res ? res : {}
            this.coin_data = res.coin_data ? res.coin_data : {}
          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    async submitFn () {

      if (!this.$route.query.ad_id) {
        return false
      }
      if (!this.$route.query.id && this.$route.query.ticket != 0) {
        return false
      }
      if (parseFloat(this.$route.query.deduct_amount) > parseFloat(this.resData.surplus_amount)) {
        this.messageFn(this.$t('tips_view.msg9') + this.resData.surplus_amount)
        return false
      }
      if (!this.resData.is_invest  && this.$route.query.ticket != 0) {
        this.messageFn(this.$t('tips_view.msg10'))
        return false
      }
      if (this.isAjax) {
        return false
      }
      this.isAjax = true


      let time = parseInt(new Date().getTime() / 1000)
      let sing = ''

      let req = {
        order_id: this.$route.query.ad_id,
        features: time,

        chain_type: this.$route.query.chain_type ? this.$route.query.chain_type : this.storeChainType.toLocaleLowerCase(),
      }
      if (this.$route.query.ticket != 0) {
        sing = await this.dappSingFn(time)
        if (!sing) {
          this.isAjax = false
          this.messageFn(this.$t('tips_view.msg5'))
          return false
        }
        req.ticket_id = this.$route.query.id
        req.use_ticket = true

        this.isLoad = true
        this.isShowLoading = true
        this.status = 'loading'
        this.text = this.$t('tips_view.msg8')



      } else {
        req.invest_amount = this.$route.query.deduct_amount
        req.use_pool = true
        req.is_repeat = this.check ? 1 : 2
        let tx_fee = parseFloat(this.resData.tx_fee)

        if ((tx_fee > this.coinBanlace) || (this.coinBanlace == 0)) {
          this.messageFn(this.coinMian[this.storeChainType] + this.$t('tips_view.msg89'))
          return false
        }
        let txId
        this.isApproveShow = true
        await this.approveUSDTBalanceFn(this.resData.invest_addr, this.$route.query.deduct_amount, this.storeAddress, this.storeChainType, this.resData.pool_addr).then(res => {
          this.isApproveShow = false
          txId = res
        }).catch(res => {
          txId = ''
          this.isApproveShow = false
          this.isAjax = false
          this.messageFn(this.coin_data.code + this.$t('tips_view.msg55'))

          return false
        })


        if (!txId) {
          this.isApproveShow = false

          this.isAjax = false
          return false
        }

        sing = await this.dappSingFn(time)
        if (!sing) {
          this.isAjax = false
          this.messageFn(this.$t('tips_view.msg5'))
          return false
        }

        this.isLoad = true
        this.isShowLoading = true
        this.status = 'loading'
        this.text = this.$t('tips_view.msg8')



      }
      req.signature =  sing
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/create_loan_order`, req, 'post')
          .then(res => {
            this.isAjax = false
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')
            this.resData.is_invest = false
            setTimeout(() => {
              this.isShowLoading = false
              if (this.$route.query.ticket != 0) {
                this.$router.replace('/invest_order')
              } else {
                this.$router.replace('/invest_order')
              }


            }, 2000)

          })
          .catch(res => {
            this.isAjax = false
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })


    }
  }
}
</script>

<style lang="scss">
.h5_coupon_details_view{
  padding: 0.16rem;
  .coupon_info_box{
    padding: 0.16rem;
    background: $BG3;
    border-radius: 0.06rem;
    margin-bottom: 0.16rem;
    .tx1{
      text-align: center;
      font-weight: bold;
      font-size: 0.13rem;
      margin-bottom: 0.16rem;
    }
    .tx2_box{
      background: $FFF;
      padding: 0.14rem 0.16rem;
      border-radius: 0.06rem;
      text-align: center;
    }
    .tx2{
      font-weight: bold;
      font-size: 0.2rem;
      color: $T6;
    }
    .tx3{
      padding: 0.02rem 0.06rem;
      border-radius: 0.1rem;
      font-size: 0.13rem;
      font-weight: bold;
    }
    .tx4{
      font-size: 0.12rem;
      color: $T6;
    }
    .tx5{
      font-size: 0.12rem;
      color: $T7;
    }
    .tx6{
      font-size: 0.13rem;
      color: $T6;
    }
    .tx7{
      color: $T12;
      background: $T11;
    }
    .tx8{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
  }
  .sub_btn{
    position: absolute;
    bottom: 0.32rem;
    width: 3.43rem;
    left: 0.16rem;
    line-height: 0.44rem;
    border-radius: 0.06rem;
    color: $FFF;
    background: $T8;
    font-size: 0.16rem;
    font-weight: bold;
    text-align: center;
  }

}

</style>