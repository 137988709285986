<template>
  <article class="h5_notice_list_view">
    <div class="notice_box">
      <div class="notice_item" v-for="list in noticeList" @click="goLinkFn(this.Utils.URL.h5URL + '/article_details?langId=' + storeLang + '&id=' + list.id)">
        <p class="tx1">{{list.title}}</p>
        <p class="tx2">{{ list.created_at }}</p>
      </div>
    </div>
  </article>
</template>

<script>
import socket from '@/utils/socket'
export default {
  name: "notice_list",
  data() {
    return {
      tabIndex: 0,
      socket: null,
      unMount: false,
      socketTimeOut: null,
      timeSec: 5000,
      uuid: 0,
      noticeList: []
    };
  },
  created() {
    this.uuid = parseInt(new Date().getTime() / 1000)
    this.socketInit()
  },
  beforeUnmount () {
    this.unMount = true
    if (this.socket) {
      this.socket.doClose()
      this.socket = null
    }

  },
  mounted() {
  },
  methods: {
    socketInit() {
      this.socket = new socket(`${this.Utils.URL.wsURL}message_webSocket/${this.uuid}?lang=${this.storeLang}`)
      this.socket.doOpen()

      this.socket.on('open', (data) => {
        this.socket.send({lang: this.storeLang})
      })
      this.socket.on('message', (res) => {
        if (res.t == 1) {
          this.noticeList = res.c ? res.c : []
        }

      })
      let slef = this
      this.socket.on('close', (res) => {
        slef.socketResetFn()

      })

    },
    socketResetFn () {
      if (this.socket && !this.unMount) {
        this.socket = null
        clearTimeout(this.socketTimeOut)
        this.socketTimeOut = setTimeout(() => {
          this.socketInit()
          this.timeSec = this.timeSec + 5000
        }, this.timeSec)
      }
    },

  },
};
</script>

<style lang="scss">
.h5_notice_list_view{
  padding: 0.12rem 0.16rem;
  .notice_item{
    border-radius: 0.06rem;
    background: $BG2;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    .tx1{
      font-size: 0.16rem;
      color: $T6;
    }
    .tx2{
      font-size: 0.13rem;
      color: $T7;

    }
  }

}
</style>