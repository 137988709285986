<template>
  <article class="h5_yesterday_profit_view">
    <div v-for="(list, index) in resData" :key="index">
      <!-- <section class="profit_box"  @click="linkFn(list.type)" v-if="list.type == 7">
        <p class="tx1">
          <span>{{typeTxFn(list.type)}}</span>
          <i class="h5_icon icon_gray_arrow"></i>
        </p>
        <p class="tx2_box">
          <span class="tx2">{{ $t('folded') }}({{ list.symbol }}): {{list.amount}}</span>
          <span class="tx3">{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
        </p>
      </section> -->
      <section class="profit_box">
        <p class="tx1">
          <span>{{typeTxFn(list.type)}}</span>
          <!-- <i class="h5_icon icon_gray_arrow"></i> -->
        </p>
        <p class="tx2_box">
          <span class="tx2">{{ $t('folded') }}({{ list.symbol }}): {{list.amount}}</span>
          <span class="tx3">{{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
        </p>
      </section>
    </div>
    

  </article>
</template>

<script>
export default {
  name: "yesterday_profit",
  data () {
    return {
      resData: [],

    }
  },
  created() {
    this.resInit()

  },
  methods: {
    linkFn (type) {
      if (type == 3) {
        this.goRouter('/income_profit')
        return false
      }
      this.goRouter('/team_profit')
    },
    typeTxFn (type) {
      if (type == 3) {
        return this.$t('loan_investment_income')
      }
      if (type == 5) {
        return this.$t('direct_invitation_income')
      }
      if (type == 6) {
        return this.$t('indirect_income')
      }
      if (type == 7) {
        return this.$t('management_earnings')
      }
      if (type == 11) {
        return this.$t('platform_revenue')
      }
    },
    resInit () {
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/yesterday_profit_page_list`, req, "get")
          .then((res) => {
            this.resData = res.list ? res.list : []

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_yesterday_profit_view{
  padding: 0.12rem 0.16rem;
  .profit_box{
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    background: $BG2;
    .icon_gray_arrow{
      width: 0.12rem;
      height: 0.12rem;
    }
    .tx1{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.14rem;
      margin-bottom: 0.1rem;
      color: $T6;
    }
    .tx2_box{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .tx2{
      color: $T6;
      font-size: 0.12rem;
    }
    .tx3{
      color: $T7;
      font-size: 0.12rem;
    }

  }
}
</style>