<template>
  <article class="h5_loan_pool_profit_view">

    <section class="banner_box">
      <p class="tx1 d_flex_cen" @click="goLinkFn(profitData.pool_link + '?langId=' + storeLang, {}, true)"><span>{{ $t('order_view.introduction_loan_matching_pool') }}</span><i class="h5_icon icon_white_arrow"></i></p>
    </section>
    <section class="profit_box">
      <div class="fth_box ">
        <p class="tx1">
          {{ $t('order_view.my_investment_capital') }}
        </p>
        <div class="tx2_box d_flex_space" v-for="(list, index) in poolList" :key="'p' + index">
          <p class="tx2">
            {{upperFn(list.type)}} {{ $t('order_view.chain_balance') }}
          </p>
          <p @click="showBalanceFn(list.type)" class="d_flex">
            <span class="tx3">≈{{list.chain_amount}} {{list.code}}</span>
            <span class="tx4 " :class="list.type + '_color'">
              <span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span>
            </span>
            <i class="h5_icon icon_gray_arrow"></i>
          </p>
        </div>

        <div class="btn_box d_flex_space">
          <div class="btn btn_invest" @click="showExchangeFn">{{ $t('reinvested') }}</div>
          <div class="btn btn_withdraw" @click="showBackFn">{{ $t('redemption') }}</div>
        </div>
      </div>
      <div class="balance_box">
        <div class="t_box">
          <div class="d_flex_space mb_10">
            <p class="tx1 font_bold">{{ $t('order_view.my_earnings_balance') }}</p>
            <p class="d_flex" @click="showWithdrawFn"><span class="tx3">{{ $t('withdrawal_income') }}</span> <i class="h5_icon icon_white_arrow ml_5"></i></p>
          </div>
          <p class="tx2 font_bold">{{profitData.my_profit}} {{profitData.symbol}}</p>
        </div>
        <div class="b_box">
          <div class="d_flex_space mb_10">
            <p class="tx3">{{ $t('matching_pool_cumulative_income') }}</p>
            <p class="tx3 font_bold">{{profitData.balance}} {{profitData.symbol}}</p>
          </div>
          <div class="d_flex_space">
            <p class="tx3">{{ $t('daily_rate') }}</p>
            <p class="tx3 font_bold">{{profitData.profit_rate}}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="tab_box">
      <p class="item" :class="tabIndex == 0 ? 'cur' : ''" @click="tabFn(0)">{{ $t('order_view.matching_pool_revenue_details') }}</p>
      <p class="item" :class="tabIndex == 1 ? 'cur' : ''" @click="tabFn(1)">{{ $t('order_view.principal_details') }}</p>
    </section>
    <article class="profit_main"  v-show="tabIndex == 0">
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <section class="profit_box" v-for="(list, index) in resData" :key="'f'+index">
          <p class="tx1_box">
            <span class="tx1">{{list.amount}} {{list.symbol}}</span>
            <span class="tx4">{{ $t('compound_interest') }}</span>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{$t('times')}}: {{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </section>
      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article>

    <article class="profit_main"  v-show="tabIndex == 1">
      <div class="com_scroll" v-infinite-scroll="oneResInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <section class="profit_box" v-for="(list, index) in oneResData" :key="'t'+index">
          <p class="tx5_box" v-show="list.tx_id">
            <span class="tx5 tx_over">Hash: {{substr(list.tx_id, 0, 10)}}...{{substr(list.tx_id, -10)}}</span>
            <i class="h5_icon icon_green_arrow"></i>
          </p>
          <p class="tx2_box d_flex">
            <span class="tx2">{{$t('number')}}: {{list.amount}} {{list.coin_data.code}}</span>
            <span class="tx6 ml_5" :class="list.coin_data.type + '_color'">
              <span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span>
            </span>

          </p>
          <p class="tx2_box d_flex_space">
            <span class="tx2">{{$t('times')}}: {{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
            <span class="tx10" :class="list.status == 3 ? 'color_T9' : 'color_T8'">{{ typeTxFn(list) }}</span>
          </p>
        </section>
      </div>
      <div class="com_no_data_box" v-show="!oneResData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article>

    <article class="com_popup_main" v-show="isShowBack">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('redeem_principal')}}</span>
          <i class="h5_icon icon_close" @click="isShowBack = false"></i>
        </div>
        <div class="chain_type_tab d_flex">
          <span class="item " @click="curTabIndexFn(index)" :class="curTabIndex == index ? 'cur' : ''" v-for="(list, index) in poolList" :key="'su' + index">{{upperFn(list.type)}} {{ $t('chain') }}</span>

        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="item" style="margin-bottom: 0.1rem">
              <div class="left_box d_flex" @click="isShowBackCoin = true">
                <div class="com_icon_img_box3">
                  <img :src="alertData.icon_url" class="com_coin_img"/>
                  <i class="h5_icon com_icon_subscript" :class="alertData.type == 'tron' ? 'icon_subscript_tron' : alertData.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                </div>
                <span class="code_tx ">{{alertData.code}}</span>
                <span class="type_tx ml_5" :class="alertData.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[alertData.type] }}</span></span>
                <i class="h5_icon icon_gray_arrow_down ml_5"></i>
              </div>
              <div class="right_box"></div>
            </div>
            <div class="com_popup_input_box mb_20" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div class="d_flex_space">
                <input @keyup="(e) => {this.num = e.target.value.replace(/[^\d.]/g, '')}" type="number"  v-model="num" class="com_popup_input_tx" :placeholder="$t('tips_view.msg33') + alertData.pool_extract_base + alertData.code"/>
                <span class="color_T8" @click="allAmountFn">{{$t('all')}}</span>
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <div class="item">
              <div class="left_box">{{$t('principal_balance')}}</div>
              <div class="right_box">{{ alertData.amount }} {{ alertData.code }}</div>
            </div>
            <div class="item">
              <div class="left_box">{{$t('fee')}}</div>
              <div class="right_box">{{ alertData.fee ? alertData.fee : 0 }}</div>
            </div>
            <div class="item">
              <div class="left_box">{{$t('order_view.to_wallet')}}</div>
              <div class="right_box">{{substr(alertData.wallet_addr, 0, 6)}}...{{substr(alertData.wallet_addr, -6)}}</div>
            </div>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowBack = false"/>
              <input type="button" class="com_popup_input_btn " :class="num > 0 ? 'com_popup_input_btn_cur' : ''" :value="$t('next')" @click="subBackFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowWithdraw">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('withdrawal_income')}}</span>
          <i class="h5_icon icon_close" @click="isShowWithdraw = false"></i>
        </div>
        <div class="chain_type_tab d_flex">
          <span class="item " @click="curTabIndexFn(index, 'withdraw')" :class="curTabIndex == index ? 'cur' : ''" v-for="(list, index) in withdrawList" :key="'wh' + index">{{upperFn(list.chain_type)}} {{ $t('chain') }}</span>

        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="item" style="margin-bottom: 0.1rem" v-if="alertData.coin_data">
              <div class="left_box d_flex" @click="isShowCoin = true">
                <div class="com_icon_img_box3">
                  <img :src="alertData.coin_data.icon_url" class="com_coin_img"/>
                  <i class="h5_icon com_icon_subscript" :class="alertData.coin_data.type == 'tron' ? 'icon_subscript_tron' : alertData.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                </div>
                <span class="code_tx ">{{alertData.coin_data.code}}</span>
                <span class="type_tx ml_5" :class="alertData.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[alertData.coin_data.type] }}</span></span>
                <i class="h5_icon icon_gray_arrow_down ml_5"></i>
              </div>
              <div class="right_box"></div>
            </div>
            <div class="com_popup_input_box1 mb_20" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div class="d_flex_space">
                <input type="number" pattern="[0-9]*" readonly="readonly"  v-model="num" class="com_popup_input_tx" />
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <div class="item">
              <div class="left_box">{{ $t('principal_balance') }}</div>
              <div class="right_box">{{ alertData.no_draw_amount }} {{alertData.symbol}}</div>
            </div>
            <div class="item">
              <div class="left_box">{{ $t('pool_fee') }}</div>
              <div class="right_box">{{ alertData.fee_rate }}</div>
            </div>
            <div class="tx4 color_T7">
              <p class="mb_5">{{$t('kind_tips')}}</p>
              <p>{{$t('tips_view.msg86')}}</p>
            </div>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowWithdraw = false"/>
              <input type="button" class="com_popup_input_btn" :class="num > 0 ? 'com_popup_input_btn_cur' : ''" :value="$t('next')" @click="nextWithdrawFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>



    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('kind_tips') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6">{{ $t('tips_view.msg42') }} {{ alertData.fee_rate }} {{ $t('tips_view.msg43') }} {{alertData.min_fee_amount}} {{alertData.symbol}}{{ $t('tips_view.msg44') }}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('continue')" @click="() => {isShowTips = false; isShowWithdrawInfo = true}"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowWithdrawInfo">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('extract') }} {{alertData.symbol}} {{ $t('currency_income') }}</span>
          <i class="h5_icon icon_close" @click="isShowWithdrawInfo = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">

            <div class="item">
              <div class="left_box">{{ $t('extract_number') }}</div>
              <div class="right_box">{{ alertData.no_draw_amount }} {{ alertData.symbol }}</div>
            </div>
            <div class="item">
              <div class="left_box">{{ $t('pool_fee') }}</div>
              <div class="right_box">{{ alertData.miner_fee }} {{ alertData.symbol }}</div>
            </div>
            <div class="item">
              <div class="left_box">{{ $t('expected_to_arrive') }}</div>
              <div class="right_box">{{ alertData.estimate_amount }} {{ alertData.symbol }}</div>
            </div>
            <div class="item">
              <div class="left_box">{{ $t('order_view.to_wallet') }}</div>
              <div class="right_box">{{alertData.wallet_addr}}</div>
            </div>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowWithdrawInfo = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="subWithdrawFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowExchange">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg20') }}</span>
          <i class="h5_icon icon_close" @click="isShowExchange = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="exchange_item_main ">
            <div class="exchange_item_box d_flex_space" v-show="list.match_amount != 0" @click="tabCurFn(index)" v-for="(list, index) in exchangeList" :key="'s' + index" :class="curIndex == index ? list.match_amount > 0 ? 'exchange_item_box_cur' : 'exchange_item_box_error' : ''">
              <div class="exchange_l_box">
                <p class="tx1">{{upperFn(list.type)}} {{ $t('balance') }}: {{list.amount}} {{list.code}}</p>
                <p class="tx2">{{ $t('tips_view.msg45') }} {{list.match_amount}} {{list.code}}</p>
              </div>
              <i class="h5_icon" :class="curIndex == index ? 'icon_check_yes' : 'icon_check_no'"></i>
            </div>

          </div>

          <div class="com_popup_from_box">
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowExchange = false"/>
              <input type="button" class="com_popup_input_btn " :class="(exchangeList[curIndex] && exchangeList[curIndex].amount) > 0 ? 'com_popup_input_btn_cur' : ''" :value="$t('reinvested')" @click="linkFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="select_coin_main" v-show="isShowCoin">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowCoin = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{ $t('select_coin') }}</span>
      </header>
      <section class="select_coin_box">
        <div class="select_item d_flex_space" @click="checkChainTypeFn(list, 'withdraw')" v-for="list in withdrawCoinList">
          <div class="d_flex">
            <div class="com_icon_img_box3">
              <img :src="list.coin_data.icon_url" class="com_coin_img"/>
              <i class="h5_icon_1 com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx3 color_T6 font_bold ml_5">{{ list.coin_data.code }}</span>
            <span class="tx2 ml_5 bsc_color" v-show="list.coin_data.contract_addr" :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>

          </div>
           <div class="tx16 color_T6">
            {{list.no_draw_amount}}
          </div>
        </div>

      </section>
    </article>
    <article class="select_coin_main" v-show="isShowBackCoin">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowBackCoin = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{ $t('select_coin') }}</span>
      </header>
      <section class="select_coin_box">
        <div class="select_item d_flex_space" @click="checkChainTypeFn(list, 'income')" v-for="list in incomeList">
          <div class="d_flex">
            <div class="com_icon_img_box3">
              <img :src="list.icon_url" class="com_coin_img"/>
              <i class="h5_icon_1 com_icon_subscript" :class="list.type == 'tron' ? 'icon_subscript_tron' : list.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx3 color_T6 font_bold ml_5">{{ list.code }}</span>
            <span class="tx2 ml_5 bsc_color" v-show="list.contract_addr" :class="list.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span></span>

          </div>
           <div class="tx16 color_T6">
            {{list.amount}}
          </div>
        </div>

      </section>
    </article>
    <article class="select_coin_main" v-show="isShowBalance">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowBalance = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{upperFn(showBalanceType)}} {{ $t('order_view.chain_balance') }}</span>
      </header>
      <section class="select_coin_box">
        <div class="select_item d_flex_space" v-show="list.type == showBalanceType"  v-for="list in exchangeList">
          <div class="d_flex">
            <div class="com_icon_img_box3">
              <img :src="list.icon_url" class="com_coin_img"/>
              <i class="h5_icon_1 com_icon_subscript" :class="list.type == 'tron' ? 'icon_subscript_tron' : list.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx3 color_T6 font_bold ml_5">{{ list.code }}</span>
            <span class="tx2 ml_5 bsc_color" v-show="list.contract_addr" :class="list.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span></span>

          </div>
           <div class="tx16 color_T6">
            {{list.amount}}
          </div>
        </div>

      </section>
    </article>



    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>



  </article>
</template>

<script>
import comLoading from "@/components/loading";

export default {
  name: "h5_loan_pool_profit_view",
  data () {
    return {
      isShowBack: false,
      isShowWithdraw: false,
      isShowExchange: false,
      isShowWithdrawInfo: false,
      isShowTips: false,
      isShowBackCoin: false,
      isShowCoin: false,
      num: '',
      errorTx: '',
      resData: [],
      loadInfinite: false,
      isAjax: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      tabIndex: 0,
      oneResData: [],
      oneLoadInfinite: false,
      oneIsLoad: false,
      onePage: 1,
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isShowLoading: false,
      isShowBalance: false,
      poolList: [
        {type: 'bsc', amount: 0, code: 'USDT',chain_amount: 0},
        {type: 'tron', amount: 0, code: 'USDT', chain_amount: 0},
        {type: 'eth', amount: 0, code: 'USDT', chain_amount: 0}
      ],
      withdrawList: [],
      profitData: {},
      curIndex: 0,
      curTabIndex: 0,
      curType: '',
      alertData: {},
      coinBanlace: 0,
      incomeList: [],
      exchangeList: [],
      withdrawCoinList: [],
      showBalanceType: ''
    }
  },
  created () {
    let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'
    
    
    this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
      addr: this.storeChangeAddress,
    }, "post").then(res => {
      this.coinBanlace =  res.quantity ? parseFloat(res.quantity) : 0
    })

    this.profitInit()

    this.withdrawInit()
    this.resInit()
    this.oneResInit()
  },
  components: {
    comLoading,
  },
  methods: {
    showBalanceFn (type) {
      this.showBalanceType = type
      this.isShowBalance = true
    },
    checkChainTypeFn (obj, type) {
      this.errorTx = ''
      this.alertData = obj
      if (type == 'income') {
        this.num = ''
        this.isShowBackCoin = false
      } else {
        this.alertData.chain_type = obj.coin_data.type
        this.alertData.symbol = obj.coin_data.code
        this.num = parseFloat(this.alertData.no_draw_amount)

        this.isShowCoin = false
      }



    },
    withdrawCoinFn (chain_type) {
      let req = {
        size: 10000
      }
      if (chain_type) {
        req.chain_type = chain_type
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/profit_coin_list`, req, 'get')
          .then(res => {
            this.withdrawCoinList = res.list ? res.list : []
          }).catch(res => {
        this.messageFn(res.msg)
      })
    },
    incomeFn (chain_type) {
      let req = {
        size: 10000
      }
      if (chain_type) {
        req.chain_type = chain_type
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/pool_coin_amount_list`, req, 'get')
          .then(res => {
            if (chain_type) {
              this.incomeList = res.list ? res.list : []
            } else {
              this.exchangeList = res.list ? res.list : []
            }
          }).catch(res => {
        this.messageFn(res.msg)
      })
    },
    showExchangeFn () {
      let count = 0
      this.poolList.forEach(val => {
        if (val.chain_amount == 0) {
          count = count + 1
        }
      })
      if (count != this.poolList.length) {
        this.isShowExchange = true
      } else {
        this.messageFn(this.$t('tips_view.msg40'))
      }
    },
    async subWithdrawFn () {

      if (this.isAjax) {
        return  false
      }
      this.isShowWithdrawInfo = false
      this.isAjax = true
      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')

      let txId

      await this.withdrawalIncomeTokenCoinFn(this.alertData.pool_addr).then(res => {
        txId = res
      }).catch(res => {
        txId = ''
        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      })
      if (!txId) {
        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      } else {
        this.$ajax(`${this.Utils.URL.goAPPURL}borrow/notice`, {
          chain_type: this.storeChainType.toLocaleLowerCase(),
          tx_id: txId
        }, 'get')
            .then(res => {

            }).catch(res => {


        })
        this.isAjax = false
        this.isLoad = false
        this.status = 'success'
        this.text = this.$t('tips_view.msg11')
        this.oneLoadInfinite = false
        this.onePage = 1
        this.oneResInit('search')
        setTimeout(() => {
          this.isShowLoading = false
          this.profitInit()
          this.withdrawInit()
        }, 2000)


      }








      /*let time = parseInt(new Date().getTime() / 1000)
      let features = time + '' + this.alertData.coin_id
      let sing = await this.dappSingFn(features)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }
      let req = {
        features: time,
        signature: sing,
        coin_id: this.alertData.coin_id,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/create_extract_order`, req, 'post')
          .then(res => {
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')
            this.oneLoadInfinite = false
            this.onePage = 1
            this.oneResInit('search')
            setTimeout(() => {
              this.isShowLoading = false
              this.profitInit()
              this.withdrawInit()
            }, 2000)

          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })*/
    },
    nextWithdrawFn () {
      let num = parseFloat(this.num)
      let min = parseFloat(this.alertData.min_fee_amount)
      let tx_fee = parseFloat(this.alertData.tx_fee)
      if (this.storeChainType != this.alertData.chain_type.toUpperCase()) {
        this.messageFn(this.$t('tips_view.msg78') + this.alertData.chain_type.toUpperCase() + this.$t('tips_view.msg79'))
        return false
      }
      if (num < min) {
        this.messageFn(`${this.$t('tips_view.msg47')} ${this.alertData.min_fee_amount} ${this.alertData.symbol},${this.$t('tips_view.msg48')}`)
        return false
      }
      if ((tx_fee > this.coinBanlace) || (this.coinBanlace == 0)) {
        this.messageFn(this.coinMian[this.storeChainType] + this.$t('tips_view.msg89'))
        return false
      }
      this.isShowWithdraw = false
      this.isShowTips = true
    },
    showWithdrawFn () {
      /*if (this.storeChainType != 'BSC') {
        this.messageFn(this.$t('tips_view.msg38'))
        return false
      }*/
      this.errorTx = ''
      this.curTabIndex = 0
      this.alertData = this.withdrawList[this.curTabIndex]
      this.num = parseFloat(this.alertData.no_draw_amount)
      this.isShowWithdraw = true
    },
    showBackFn () {
      /*if (this.storeChainType != 'BSC') {
        this.messageFn(this.$t('tips_view.msg38'))
        return false
      }*/
      this.num = ''
      this.errorTx = ''
      this.curTabIndex = 0
      this.alertData = this.poolList[this.curTabIndex]
      this.isShowBack = true
    },
    allAmountFn () {
      this.num = this.alertData.amount
    },
    async subBackFn () {
      let num = parseFloat(this.num)
      let tx_fee = parseFloat(this.alertData.tx_fee)
      let min = parseFloat(this.alertData.pool_extract_base)
      let fth_amount = parseFloat(this.alertData.amount)
      if (this.storeChainType != this.alertData.type.toUpperCase()) {
        this.messageFn(this.$t('tips_view.msg78') + this.alertData.type.toUpperCase() + this.$t('tips_view.msg80'))
        return false
      }
      if (!num) {
        return false
      }
      if (num > fth_amount) {
        this.errorTx = this.$t('tips_view.msg49')
        return false
      }

      if (num < min) {
        this.errorTx = this.$t('tips_view.msg39')
        return false
      }

      if ((tx_fee > this.coinBanlace) || (this.coinBanlace == 0)) {
        this.messageFn(this.coinMian[this.storeChainType] + this.$t('tips_view.msg89'))
        return false
      }


      if (this.isAjax) {
        return  false
      }
      this.isAjax = true
      this.isShowBack = false
      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')

      let txId

      await this.redemptionTokenCoinFn(this.alertData.pool_addr, this.alertData.wallet_addr, num, this.alertData.contract_addr).then(res => {
        txId = res
      }).catch(res => {
        txId = ''
        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      })
      if (!txId) {
        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      } else {

        this.$ajax(`${this.Utils.URL.goAPPURL}borrow/notice`, {
          chain_type: this.storeChainType.toLocaleLowerCase(),
          tx_id: txId
        }, 'get')
            .then(res => {

            }).catch(res => {


        })

        this.isAjax = false
        this.status = 'success'
        this.text = this.$t('tips_view.msg11')
        this.isLoad = false
        setTimeout(() => {
          this.isShowLoading = false
          this.oneLoadInfinite = false
          this.onePage = 1
          this.profitInit()
          this.oneResInit('search')
        }, 2000)


      }





      /*

      let time = parseInt(new Date().getTime() / 1000)
      let features = time + '' + this.alertData.type + this.alertData.id  + this.alertData.wallet_addr + num
      let sing = await this.dappSingFn(features)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }
      let req = {
        features: time,
        amount: num,
        wallet_addr: this.alertData.wallet_addr,
        signature: sing,
        coin_id: this.alertData.id,
        chain_type: this.alertData.type,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}profit/create_redeem_principal`, req, 'post')
          .then(res => {
            if (res.result) {
              this.isLoad = false
              this.status = 'success'
              this.text = this.$t('tips_view.msg11')
              setTimeout(() => {
                this.isShowLoading = false
                this.oneLoadInfinite = false
                this.onePage = 1
                this.profitInit()
                this.oneResInit('search')
              }, 2000)
            } else {
              this.isLoad = false
              this.status = 'error'
              this.text = this.$t('tips_view.msg12')
              setTimeout(() => {
                this.isShowLoading = false
              }, 2000)

            }


          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })*/
    },
    curTabIndexFn (index, type) {
      this.curTabIndex = index
      this.num = ''
      this.errorTx = ''
      if (type == 'withdraw') {
        this.withdrawCoinList = []
        this.withdrawCoinFn(this.withdrawList[index].chain_type)
        this.alertData = this.withdrawList[index]
        this.num = this.alertData.no_draw_amount
      } else {
        this.incomeList = []
        this.incomeFn(this.poolList[index].type)
        this.alertData = this.poolList[index]
      }

    },
    linkFn () {
      let list = this.exchangeList[this.curIndex]
      if (!(list.amount >0)) {
        return false
      }
      if (!list.order_id) {
        this.messageFn(this.$t('tips_view.msg4'))
        return false
      }
      if (list.match_amount <= 0) {
        this.messageFn(this.$t('tips_view.msg50'))
        return false
      }
      if (this.storeChainType != list.type.toUpperCase()) {
        this.messageFn(this.$t('tips_view.msg78') + list.type.toUpperCase() + this.$t('tips_view.msg87'))
        return false
      }
      this.goRouter('/coupon_details', {ad_id: list.order_id, deduct_amount: list.match_amount, ticket: 0, chain_type: list.type})
    },
    tabCurFn (index) {
      this.curIndex = index
    },
    typeTxFn (list) {
      if (list.op_type == 1002) {

        if (list.status == 1) {
          return this.$t('extracting')
        }
        if (list.status == 2) {
          return this.$t('extract_success')
        }
        if (list.status == 3) {
          return this.$t('extract_fail')
        }
      }
      if (list.op_type == 1006) {

        if (list.status == 1) {
          return this.$t('redemptioning')
        }
        if (list.status == 2) {
          return this.$t('redemption_success')
        }
        if (list.status == 3) {
          return this.$t('redemption_fail')
        }
        return this.$t('order_view.redemption_of_investment_principal')
      }
      if (list.op_type == 1007) {
        if (list.status == 1) {
          return this.$t('re_cast')
        }
        if (list.status == 2) {
          return this.$t('reinvested_success')
        }
        if (list.status == 3) {
          return this.$t('reinvested_fail')
        }
        return this.$t('order_view.reinvestment_of_investment_principal')
      }
      if (list.op_type == 18) {
        return this.$t('order_view.return_of_investment_principal')
      }
      if (list.op_type == 22) {
        return this.$t('order_view.partner_subscription_return')
      }
    },
    upperFn (tx) {
      if (tx) {
        return tx.toUpperCase()
      }
    },
    tabFn (index) {
      this.tabIndex = index
    },
    withdrawInit () {
      let req = {

      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/withdraw_coin_page_list`, req, "get")
          .then((res) => {
            this.withdrawList = res.list ? res.list : []
            this.withdrawCoinFn(this.withdrawList[0].chain_type)

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    profitInit () {
      let req = {

      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/pool_detail_interface`, req, "get")
          .then((res) => {
            this.profitData = res ? res : {}
            this.poolList = res.pool_list ? res.pool_list : []
            this.incomeFn()
            if (this.poolList.length) {
              this.incomeFn(this.poolList[0].type)

            }

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    resInit (type) {
      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/pool`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    oneResInit (type) {
      let req = {
        page: this.onePage,
        size: this.size,
      }
      if (this.oneIsLoad) {
        return false
      }
      if (this.oneLoadInfinite) {
        return false
      }
      this.oneIsLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/pool_redeem_list`, req, 'get')
          .then(res => {
            this.oneIsLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.oneLoadInfinite = true
            }

            this.onePage = this.onePage + 1
            if (type == 'search') {
              this.oneResData = arr
            } else {
              this.oneResData = this.oneResData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.oneIsLoad = false
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_loan_pool_profit_view{
  .icon_gray_arrow{
    width: 0.12rem;
    height: 0.12rem;
  }
  .code_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
  }
  .type_tx{
    font-size: 0.12rem;
    padding: 0.01rem 0.04rem;
    border-radius: 0.1rem;
    display: inline-block;
    min-width: 0.36rem;
    text-align: center;
  }

  .banner_box{
    background: url("../../assets/img/banner/loan_pool_banner.png");
    height: 1.25rem;
    background-size: cover;
    text-align: center;
    padding-top: 0.33rem;
    .tx1{
      font-size: 0.23rem;
      font-weight: bold;
      color: $FFF;
    }
    .icon_white_arrow{
      width: 0.18rem;
      height: 0.18rem;
    }
  }
  .profit_box{
    padding: 0 0.16rem;
    .fth_box{
      background: $FFF;
      box-shadow: 0px 4px 9px rgba(34, 68, 85, 0.05);
      border-radius: 0.06rem;
      padding: 0.16rem 0.16rem;
      justify-content: space-between;
      margin-top: -0.28rem;
      margin-bottom: 0.16rem;
      .tx1{
        font-size: 0.15rem;
        color: $T6;
        font-weight: bold;
        margin-bottom: 0.12rem;
      }
      .tx2_box{
        margin-bottom: 0.08rem;
      }
      .tx2{
        font-size: 0.14rem;
        color: $T6;
      }
      .tx3{
        font-size: 0.14rem;
        color: $T6;
        font-weight: bold;
      }
      .tx4{
        font-size: 0.12rem;
        margin-left: 0.05rem;
        padding: 0.01rem 0.05rem;
        border-radius: 0.08rem;
        font-weight: bold;
        display: inline-block;
      }
      .tx5{
        color: $T12;
        background: $T11;
      }
      .tx6{
        color: $T14;
        background: $T13;
      }
      .tx7{
        color: $T16;
        background: $T15;
      }
      .btn_box{
        padding-top: 0.16rem;
        border-top: 1px solid $BR2;
        margin-top: 0.16rem;

        .btn{
          width: 1.48rem;
          line-height: 0.4rem;
          font-size: 0.14rem;
          text-align: center;
          font-weight: bold;
          color: $FFF;
          border-radius: 0.06rem;
        }
        .btn_invest{
          background: $T5;
        }
        .btn_withdraw{
          background: $T8;
        }
      }
    }
    .balance_box {
      background: $T8;
      color: $FFF;
      padding: 0.16rem 0.16rem;
      margin-bottom: 0.16rem;
      border-radius: 0.06rem;
      .icon_white_arrow {
        width: 0.12rem;
        height: 0.12rem;
      }
      .t_box{
        padding-bottom: 0.12rem;
        margin-bottom: 0.12rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.12);
      }
      .b_box{}
      .tx1{
        font-size: 0.15rem;
      }
      .tx2{
        font-size: 0.18rem;
      }
      .tx3{
        font-size: 0.13rem;
      }
    }
  }
  .tab_box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BR;
    .item{
      width: 50%;
      font-size: 0.14rem;
      color: $T7;
      padding: 0.12rem 0;
      text-align: center;
    }
    .cur{
      color: $T8;
      font-weight: bold;
      border-bottom: 1px solid $T8;
    }
  }
  .profit_main{
    padding: 0.12rem 0.16rem;
    .profit_box{
      padding: 0.14rem 0.16rem;
      margin-bottom: 0.12rem;
      border-radius: 0.06rem;
      background: $BG3;
      .tx1_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .tx5_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $BR2;
        padding-bottom: 0.12rem;
        .tx5{
          flex: 1;
        }
        .icon_green_arrow{
          width: 0.12rem;
          height: 0.12rem;

        }
      }
      .tx1{
        font-size: 0.13rem;
        color: $T6;
      }
      .tx5{
        font-size: 0.13rem;
        color: $T5;
      }
      .tx2_box{
        margin-top: 0.07rem;
      }
      .tx2{
        color: $T7;
        font-size: 0.12rem;
      }
      .tx4{
        color: $T8;
        font-size: 0.12rem;
      }
      .tx10{
        font-size: 0.12rem;
      }

      .tx6{
        font-size: 0.12rem;
        margin-left: 0.05rem;
        padding: 0.01rem 0.05rem;
        border-radius: 0.08rem;
        font-weight: bold;
        display: inline-block;
      }
      .tx7{
        color: $T12;
        background: $T11;
      }
      .tx8{
        color: $T14;
        background: $T13;
      }
      .tx9{
        color: $T16;
        background: $T15;
      }
    }
  }
  .chain_type_tab{

    padding: 0.1rem 0.16rem;

    .item:nth-child(1){
      border-radius: 0.06rem 0 0 0.06rem;
    }
    .item:nth-child(3){
      border-radius: 0 0.06rem 0.06rem 0;
      border-right: none !important;
    }
    .item{
      width: 33.33%;
      text-align: center;
      font-size: 0.14rem;
      color: $T6;
      background: $BG3;
      line-height: 0.36rem;
      border-right: 1px solid $BR2;
    }
    .cur{
      color: $FFF;
      border-right: 1px solid $T8;
      background: $T8;
    }
  }

  .exchange_item_main{
    height: 2.5rem;
    overflow-y: scroll;
    width: 100%;
    .exchange_item_box{
      margin-bottom: 0.16rem;
      border-radius: 0.06rem;
      border: 1px solid $BR;
      padding: 0.14rem 0.16rem;
      background: $BG3;
      .tx1{
        color: $T6;
        font-size: 0.16rem;
        font-weight: bold;
        margin-bottom: 0.05rem;
      }
      .tx2{
        color: $T7;
        font-size: 0.13rem;
      }
    }
    .exchange_item_box_cur{
      border: 1px solid $T8;
      background: $T10;
      .tx1{
        color: $T8;
      }
      .tx2{
        color: $T8;
      }
    }
    .exchange_item_box_error{
      border: 1px solid $T9;
      background: $T9_01;
      .tx2{
        color: $T9;
      }
    }
    .exchange_item_box:last-child{
      margin-bottom: 0;
    }
  }

}
</style>