<template>
  <article class="h5_otc_index_view">
    <section class="tab_box d_flex_space">
      <div class="tab_l d_flex">
        <span class="tx13 item  font_bold" :class="tabIndex == 1 ? 'item_cur' : ''" @click="tabFn(1)">{{ $t('quick_zone') }}</span>
        <span class="tx13 item font_bold" :class="tabIndex == 2 ? 'item_cur' : ''" @click="tabFn(2)">{{ $t('self_selection') }}</span>
      </div>
      <div class="tab_r tx13 color_FFF" v-show="false">{{ $t('merchant_1') }}</div>
    </section>
    <section class="user_box d_flex_space">
      <div class="d_flex user_l" v-show="tabIndex == 1">
        <i class="h5_icon mr_10 icon_user_head"></i>
        <p class="tx13 color_T6">{{substr(storeChangeAddressId, 0, 6)}}...{{substr(storeChangeAddressId, -6)}}</p>
      </div>
      <div class="d_flex " v-show="tabIndex == 2">
        <p class="d_flex" @click="isShowSearch = true">
          <i class="h5_icon icon_filter_origin mr_5"></i>
          <span class="tx13 color_T8">{{ $t('filter_1') }}</span>
        </p>
        <p class="d_flex curreny_info" @click="goRouter('otc_currency')">
          <span class="tx13 curreny_tx color_T8">{{ fastCurrencyData.currency }}</span>
          <i class="h5_icon icon_switch_origin"></i>
        </p>
      </div>
      <div class="d_flex" @click="goRouter('/buy_order_list')">
        <p class="tx13 color_T8">{{ $t('my_order_1') }}</p>
        <i class="h5_icon icon_orange_arrow"></i>
      </div>
    </section>
    <article v-show="tabIndex == 1">
      <section class="form_main">
        <p class="tx15 font_bold color_T6 tx_cen mb_10">{{ $t('buy_coin') }}</p>
        <div class="d_flex_space select_input_box mb_25"  @click="goRouter('/otc_coin')">
          <div class="l_box d_flex">
            <div class="com_icon_img_box3">
              <img :src="fastCoinData.icon_url" class="com_coin_img"/>
              <i v-show="fastCoinData.contract_addr" class="h5_icon com_icon_subscript" :class="fastCoinData.type == 'tron' ? 'icon_subscript_tron' : fastCoinData.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="tx16 color_T6 font_bold ">{{fastCoinData.code}}</span>
            <span class="subscript_tx ml_5" v-show="fastCoinData.contract_addr" :class="fastCoinData.type + '_color'"><span class="`fz_10` font_bold">{{ coinChainType[fastCoinData.type] }}</span></span>
          </div>
          <i class="h5_icon icon_gray_arrow_down"></i>
        </div>
        <p class="tx15 font_bold color_T6 tx_cen mb_10">{{ $t('pay_type') }}</p>
        <div class="d_flex_space select_input_box mb_25" @click="goRouter('/otc_currency')">
          <div class="tx16 color_T6 font_bold">
            {{fastCurrencyData.currency}}
          </div>
          <i class="h5_icon icon_gray_arrow_down"></i>
        </div>
        <p class="tx15 font_bold color_T6 tx_cen mb_10">{{ $t('buy_amount') }}</p>
        <div class="input_box d_flex_space mb_10">
          <input type="number" pattern="[0-9]*" v-model="num" class="input_num" :placeholder="fastCoinData.min_buy_coin + $t('tips_view.msg24')"/>
          <span class="color_T8 tx14">{{fastCoinData.code}}</span>
        </div>
        <p class="tx13 color_T9 mb_5" v-show="fastErrorTx">{{fastErrorTx}}</p>
        <p class="tx12 color_T7 mb_5">{{ $t('reference_price') }}: {{fastCurrencyData.currency_symbol}}{{fastCurrencyData.visit_price}}</p>
        <p class="tx12 color_T7 mb_5">{{ $t('money_1') }}: {{fastCurrencyData.currency_symbol}}{{ fastPrice }}</p>
        <p class="input_sub tx16 font_bold tx_cen " :class="num ? 'input_sub_cur' : ''" @click="showConfirmFn">{{ $t('buy_1') }}</p>
      </section>
      <p class="buy_tip tx13 d_flex_cen" @click="isShowTips = true">
        <span>{{ $t('tips_view.msg91') }}</span>
        <i class="h5_icon icon_question_red ml_5"></i>
      </p>
    </article>
    <article class="otc_order_main" v-show="tabIndex == 2">
      <div v-show="resData.length">
        <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
          <div class="otc_item" v-for="(list, index) in resData" @click="goDetails(list, index)">
            <div class="otc_head d_flex_space mb_10">
              <div class="d_flex">
                <div class="com_icon_img_box3">
                  <img :src="list.coin_data.icon_url" class="com_coin_img"/>
                  <i v-show="list.coin_data.contract_addr" class="h5_icon_1 com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                </div>
                <span class="tx16 color_T6 font_bold ">{{ list.coin_data.code }}</span>
                <span class="subscript_tx ml_5 bsc_color" v-show="list.coin_data.contract_addr" :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>

              </div>
              <p class="tx13 color_T7">{{list.merchant_name}}</p>
            </div>
            <p class="mb_10 color_T6 d_flex" style="align-items: baseline">
              <span class="tx13 font_bold">{{ list.currency_symbol }}</span>
              <span class="item_price ml_3 font_bold">{{ list.price }}</span>
            </p>
            <div class="d_flex_space mb_15">
              <div class="item_info_box">
                <p class="tx12 color_T6 mb_5">{{ $t('number') }}: {{ list.amount }} {{ list.coin_data.code }}</p>
                <p class="tx12 color_T6 ">{{ $t('norm_1') }}: {{ list.currency_symbol }}{{ list.min_quota }} - {{ list.currency_symbol }}{{ list.max_quota }}</p>
              </div>
              <div class="buy_btn tx13 font_bold color_FFF">{{ $t('buy_1') }}</div>
            </div>
            <div class="d_flex_wrap">
              <p class="type_box d_flex" v-for="pay in list.card_list">
                <span class="type_round " :style="{backgroundColor: pay.color}"></span>
                <span class="tx13 color_T6">{{pay.pay_way_name}}</span>
              </p>

            </div>
          </div>
        </div>

      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article>



    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg92') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg93') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg94') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg95') }}</p>
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg96') }}</p>
            <p class="tx5 color_T6 ">{{ $t('tips_view.msg97') }}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="isShowTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowTips1">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg98') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips1 = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{ $t('tips_view.msg99') }}</p>
            <p class="tx5 color_T9 mb_10">{{ $t('tips_view.msg100') }} {{pledgeData.amount}} {{pledgeData.coin_data.code}};</p>
            <p class="tx5 color_T9 mb_10">{{ $t('tips_view.msg101') }}</p>
            <p class="tx5 color_T9 ">{{ $t('tips_view.msg102') }}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips1 = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="tranCoinFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowTips2">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg168') }}</span>
          <i class="h5_icon icon_close" @click="isShowTips2 = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 ">{{ $t('tips_view.msg167') }}</p>

            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips2"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="showConfirmFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowConfirm">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('tips_view.msg103') }}</span>
          <i class="h5_icon icon_close" @click="isShowConfirm = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="buy_otc_main">
              <p class="price_tx tx_cen font_bold">{{fastCurrencyData.currency_symbol}}{{ fastPrice }}</p>
              <p class="d_flex_space mb_10 tx14">
                <span class="color_T7">{{ $t('buy_price') }}</span>
                <span class="color_T6">{{fastCurrencyData.currency_symbol}}{{ fastCurrencyData.price }}</span>
              </p>
              <p class="d_flex_space tx14 mb_30">
                <span class="color_T7">{{ $t('buy_num') }}</span>
                <span class="color_T6">{{ num }} {{fastCoinData.code}}</span>
              </p>
              <div class="payment_list1  d_flex_space " @click="fastCurrencyTab(index)" :class="fastCurrencyIndex == index ? 'payment_list_cur' : ''" v-for="(list, index) in payPriceData">
                <p class="d_flex">
                  <i class="h5_icon  payment_30 mr_10" :style="{backgroundColor: list.color}" ></i>
                  <span class="tx16 tx_over_hidden" style="width: 1.8rem">{{ list.pay_way_name}}</span>
                </p>
                <p class="d_flex" >
                  <span class="mr_15 tx14" v-show="list.price && list.price != 0">{{fastCurrencyData.currency_symbol}}{{list.price}}</span>
                  <span class="mr_15 tx14" v-show="list.price == 0 || !list.price">{{$t('tips_view.msg166')}}</span>
                  <i class="h5_icon icon_check_no " v-show="list.price &&  list.price != 0" :class="fastCurrencyIndex == index ? 'icon_check_yes' : ''"></i>
                </p>
              </div>

            </div>

            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="fastSubmit"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowSearch">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('filter_1') }}</span>
          <i class="h5_icon icon_close" @click="isShowSearch = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="search_main">
              <div class="search_chain_box d_flex_cen mb_25">
                <span class="search_item " :class="searchChainType == '' ? 'search_item_cur' : ''" @click="searchChainTypeFn('')">{{ $t('all') }}</span>
                <span class="search_item " :class="searchChainType == 'bsc' ? 'search_item_cur' : ''" @click="searchChainTypeFn('bsc')">BSC {{$t('chain')}}</span>
                <span class="search_item " :class="searchChainType == 'tron' ? 'search_item_cur' : ''" @click="searchChainTypeFn('tron')">TRX {{$t('chain')}}</span>
                <span class="search_item " :class="searchChainType == 'eth' ? 'search_item_cur' : ''" @click="searchChainTypeFn('eth')">ETH {{$t('chain')}}</span>
              </div>
              <p class="tx14 font_bold color_T7 mb_10" v-show="fastCurrencyData.currency == 'CNY'">{{ $t('payment_type') }}</p>
              <div class="search_payment_box mb_15 d_flex_space" v-show="fastCurrencyData.currency == 'CNY'">
                <span class="search_item " :class="searchPayArr[0] == 1 ? 'search_item_cur' : ''" @click="searchPayFn('')">{{ $t('all') }}</span>
                <span class="search_item " :class="searchPayArr[index + 1] ? 'search_item_cur' : ''" @click="searchPayFn(index, list)" v-for="(list, index) in fastCurrencyData.pay_arr">{{ list == 'Alipay' ? $t('alipay_1') : list == 'WeChat' ? $t('wechat_1') : $t('bank_1') }}</span>

              </div>
              <p class="tx14 font_bold color_T7 mb_10" v-show="fastCurrencyData.currency != 'CNY'">{{ $t('payment_type') }}</p>
              <div class="search_bank_box tx14 d_flex_space mb_15" v-show="fastCurrencyData.currency != 'CNY'" @click="isShowBank = true">

                <span class="font_bold" :class="checkBankData.name ? 'color_T6' : 'color_T4'">{{checkBankData.name ? checkBankData.name : $t('tips_view.msg177')}}</span>
                <i class="h5_icon icon_gray_arrow_down"></i>

              </div>
              <p class="tx14 font_bold color_T7 mb_10">{{ $t('search_coin') }}</p>
              <div class="search_input_box d_flex_space mb_15">
                <p class="d_flex">
                  <i class="h5_icon icon_search_gray"></i>
                  <input class="search_tx" v-model="searchTx" @input="searchCoinFn" :placeholder="$t('tips_view.msg104')" type="text">
                </p>
                <i class="h5_icon icon_close_gray" v-show="searchTx" @click="()=>{this.searchTx ='';this.searchCoinFn()}"></i>
              </div>
              <div class="search_coin_box d_flex_space" v-show="searchCoinList.length" style="max-height: 1.2rem; overflow-y: scroll">
                <div class="search_item " @click="searchCheckCoinFn(index)" :class="searchCheckCoin[index] == list.id ? 'search_item_cur' : ''" v-for="(list, index) in searchCoinList">
                  <div class="d_flex">
                    <div class="com_icon_img_box4">
                      <img :src="list.icon_url" class="com_coin_img"/>
                      <i class="h5_icon_1 com_icon_subscript" v-show="list.contract_addr" :class="list.type == 'tron' ? 'icon_subscript_tron' : list.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                    </div>
                    <span class="tx14 color_T6 font_bold ">{{ list.code }}</span>
                    <span class="subscript_tx ml_5 bsc_color" v-show="list.contract_addr" :class="list.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span></span>

                  </div>
                  <i class="h5_icon icon_list_check" v-show="searchCheckCoin[index] == list.id "></i>
                </div>
              </div>
              <p class="search_nodata" v-show="isShowNo">{{ $t('no_search_data') }}</p>
            </div>

            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('reset_1')" @click="searchResetFn"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('done_1')" @click="resInit('search')"/>
            </div>
          </div>
        </div>
      </section>
    </article>


    <article class="com_popup_main" v-show="isShowContact">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('contact_information') }}</span>
          <i class="h5_icon icon_close" @click="isShowContact = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('name_1') }}</p>
            </div>
            <div class="com_popup_input_box mb_25"  >
              <div>
                <input type="text"  v-model="name" class="com_popup_input_tx" :placeholder="$t('tips_view.msg141')"/>
              </div>
            </div>
            <div class="tx1 mb_10">
              <p class="color_T6">{{ $t('phone') }}</p>
            </div>
            <div class="com_popup_input_box d_flex phone_main mb_5"  >
              <p class="area_box d_flex_space" @click="isShowCountry = true">
                <span class="tx14 font_bold" :class="country.area_code ? 'color_T6' : 'color_T7'">{{country.area_code ? '+' + country.area_code : $t('please_1')}}</span>
                <i class="h5_icon icon_gray_arrow_down_small"></i>
              </p>
              <div>
                <input type="number" pattern="[0-9]*" v-model="phone" class="com_popup_input_tx" :placeholder="$t('tips_view.msg162')"/>
              </div>
            </div>
            <p class="tx13 color_T9 mb_20">{{ $t('tips_view.msg169') }}</p>


            <div class="com_popup_btn_box d_flex_space">
              <input type="button" class="com_popup_input_btn " :class="name && phone && country.area_code ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="contactSubmitFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="search_main_coun" v-show="isShowCountry">
      <div class="bg"></div>
      <div class="search_box">
        <p class="search_title_tx d_flex_space"><span>{{$t('select_code')}}</span> <i class="h5_icon icon_close" @click="isShowCountry = false"></i></p>
        <div class="search_input_box d_flex_space">
          <input type="text" class="input_tx" v-model="searchCountryTx" @input="searchCodeFn($event)" :placeholder="$t('search_code')"/>
          <img src="../../assets/img/icon/search.svg" class="search_img" />
        </div>
        <div class="item_box">
          <div class="item  d_flex_space" :class="list.area_code == country.area_code ? 'item_cur' : ''" v-for="list in countryData" v-show="list.isShow"  @click="checkAreaFn('country', list)">
            <p class="d_flex">
              <span class="mr_10">{{ list.country }}</span>
              <i class="h5_icon icon_orange_success" v-show="list.area_code == country.area_code"></i>
            </p>
            <span>{{list.area_code}}</span>

          </div>
        </div>
      </div>
    </article>

    <article class="select_coin_main" v-show="isShowBank">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowBank = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{ $t('payment_type') }}</span>
      </header>
      <section class="select_bank_box">
        <div class="search_bank_input d_flex">
          <i class="h5_icon icon_search_gray"></i>
          <input type="text" class="search_bank" @input="searchBankTXFn" v-model="searchBankTx" :placeholder="$t('tips_view.msg178')">
        </div>
        <div class="bank_scroll">
          <div class="select_bank_item " v-for="list in searchBankList">
            <p class="tab_bank font_bold color_T7 tx14" v-show="list.isShow">{{list.typ == 'Re' ? $t('hot_1') : list.typ}}</p>
            <p class="bank_name d_flex color_T6 tx16"  @click="checkBankFn(bank)"  v-show="bank.isShow" v-for="bank in list.list"><i class="h5_icon bank_round mr_5" :style="{backgroundColor: bank.color}"></i> <span>{{bank.name}}</span></p>
          </div>
        </div>

      </section>
    </article>


    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>

  </article>
</template>

<script>

import comLoading from "@/components/loading";
export default {
  name: "otc_index",
  data () {
    return {
      isShowSearch: false,
      isShowConfirm: false,
      isShowTips: false,
      isShowTips1: false,
      isShowTips2: false,
      tabIndex: 1,
      fastCoinList: [],
      fastCoinData: {min_buy_coin: ''},
      fastCurrencyList: [],
      fastCurrencyData: {pay_arr: []},
      fastErrorTx: '',
      fastCurrencyIndex: 0,
      num: '',
      coin_data: {
        type: 'bsc',
        code: 'USDT'
      },
      resData: [],
      page: 1,
      size: 30,
      loadInfinite: false,
      isLoad: false,
      isShowNo: false,
      isAjax: false,
      searchChainType: '',
      searchPayArr: ['1', '', '', ''],
      searchTx: '',
      searchCoinList: [],
      searchCheckCoin: [],
      status: 'loading',
      text: '',
      isShowLoading: false,
      tx_id: '',
      pledgeData: {coin_data: {}},
      payPriceData: [],
      name: '',
      phone: '',
      country: {
        area_code: '',
        country: '',
        id: '',
      },
      isShowContact: false,
      isShowCountry: false,
      isShowCity: false,
      countryData: [],
      searchCountryTx: '',
      searchBankList: [],
      searchBankTx: '',
      checkBankData: {},
      isShowBank: false,
    }
  },
  computed: {
    fastPrice () {
      if (this.num && this.fastCurrencyData.price) {
        return this.padRight(this.num * this.fastCurrencyData.price, 2)
      }
      return 0
    },
  },
  created() {
    this.fastCoinData = window.sessionStorage.getItem('fastCoinData') ? JSON.parse(window.sessionStorage.getItem('fastCoinData')) : {}
    this.fastCurrencyData = window.sessionStorage.getItem('fastCurrencyData') ? JSON.parse(window.sessionStorage.getItem('fastCurrencyData')) : {}
    this.tabIndex = window.sessionStorage.getItem('tabIndex') ? JSON.parse(window.sessionStorage.getItem('tabIndex')) : 1
    if (!this.fastCoinData.id || !this.fastCurrencyData.currency) {
      this.fastCoinListFn()
    }
    if(this.fastCurrencyData.currency) {

      this.resInit()
      this.searchBankFn()

    }
  },
  components: {

    comLoading,
  },
  methods: {
    checkBankFn (list) {
      this.checkBankData = list
      this.isShowBank = false
    },
    contactSubmitFn () {
      if (this.isAjax) {
        return  false
      }
      if (this.name === '') {
        this.messageFn(this.$t('tips_view.msg141'))
        return  false
      }
      if (this.phone === '') {
        this.messageFn(this.$t('tips_view.msg162'))
        return  false
      }
      if (this.phone.length <7 || this.phone.length > 11) {
        this.messageFn(this.$t('tips_view.msg163'))
        return  false
      }
      this.isAjax = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/set_user_information`, {
        area_code: this.country.area_code,
        name: this.name,
        phone: this.phone,
      }, 'post')
          .then(res => {
            this.isShowContact = false
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('success')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          })
          .catch(res => {
            this.isAjax = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg108')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })
    },

    checkAreaFn (type, data) {
      if (type == 'city') {


      } else {
        this.country = data
        this.isShowCountry = false

      }
    },
    searchCodeFn () {
      if (this.searchCountryTx) {
        this.countryData.forEach((val, index) => {
          if (val.area_code.indexOf(this.searchCountryTx) >= 0) {
            this.countryData[index].isShow = true
          } else {
            this.countryData[index].isShow = false
          }
        })
      } else {
        this.countryData.forEach((val, index) => {
          this.countryData[index].isShow = true
        })
      }
    },
    searchCountryFn (eve) {

      let req = {
        page: 1,
        size: 1000,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}buy/area_code`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            arr.forEach((val, i) => {
              arr[i].isShow = true
              if (this.storeLang == 'cn') {
                if (val.area_code == 86 && !this.country.area_code) {
                  this.country = val
                }
              } else {
                if (val.area_code == 1 && !this.country.area_code) {
                  this.country = val
                }
              }

            })
            this.countryData = arr
            // this.searchCityFn()

          })
          .catch(res => {

            this.messageFn(res.msg)
          })

    },
    goDetails (list, index) {
      if (this.storeChainType != list.coin_data.type.toUpperCase()) {
        this.messageFn(this.$t('tips_view.msg78') + list.coin_data.type.toUpperCase() + this.$t('tips_view.msg170'))
        return false
      }
      this.goRouter('/otc_free_form', {id: list.order_id})
      return  false
      if (this.isAjax) {
        return false
      }
      this.isAjax = true
      let req = {
        chain_type: list.coin_data.type,
        currency: list.currency,
        coin_id: list.coin_data.id,
        price: parseFloat(list.price),
        order_id: list.order_id
      }
      this.status = 'loading'
      this.text = ''
      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_judge`, req, "get")
          .then((res) => {
            let data = res ? res : {}
            this.isAjax = false
            this.isShowLoading = false
            this.goRouter('/otc_free_form', {id: list.order_id})

          })
          .catch((res) => {
            this.isAjax = false
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            if (res.code == 161) {
              this.searchCountryFn()
              this.isShowContact = true
              return false
            }
            if (res.code == 120) {
              this.messageFn(this.$t('tips_view.msg106'))
              return false
            }
            if (res.code == 121) {
              this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
              return false
            }
            if (res.code == 123) {
              this.resInit('reset')
              this.messageFn(this.$t('tips_view.msg107'))
              return false
            }
            if (res.code == 151) {
              // 价格变动
              this.resInit('reset')
              this.messageFn(this.$t('tips_view.msg124'))
              return false
            }
            if (res.code == 152) {
              this.resInit('reset')
              this.messageFn(this.$t('tips_view.msg125'))
              return false
            }
            if (res.code == 153) {
              this.resInit('reset')
              this.messageFn(this.$t('tips_view.msg105'))
              return false
            }
            if (res.code == 154) {
              this.resInit('reset')
              this.messageFn(this.$t('tips_view.msg165'))
              return false
            }

            this.messageFn(this.$t('tips_view.msg108'))
          });
    },
    searchCheckCoinFn (index) {
      this.searchCheckCoin[index] = this.searchCoinList[index].id
    },
    searchCoinFn () {
      this.isShowNo = false
      this.searchCheckCoin = []
      this.searchCoinList = []
      if (!this.searchTx) {
        return false
      }
      let req = {

        symbol: this.searchTx
      }
      if (this.searchChainType) {
        req.chain_type =  this.searchChainType
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/otc_screen`, req, "get")
          .then((res) => {
            this.searchCoinList = res.list ? res.list : []
            if (!this.searchCoinList.length) {
              this.isShowNo = true
            } else {
              this.isShowNo = false
            }
          })
          .catch((res) => {

            this.messageFn(res.msg)
          });
    },
    searchBankTXFn () {
      if (this.searchBankTx) {
        this.searchBankList.forEach((val, i) => {
          this.searchBankList[i].list.forEach((vals, is) => {

            if ((vals.name.toUpperCase()).indexOf(this.searchBankTx.toUpperCase()) >= 0) {
              this.searchBankList[i].isShow = false
              this.searchBankList[i].list[is].isShow = true
            } else {
              this.searchBankList[i].isShow = false
              this.searchBankList[i].list[is].isShow = false
            }
          })

        })
      } else {
        this.searchBankList.forEach((val, i) => {
          this.searchBankList[i].list.forEach((vals, is) => {
            this.searchBankList[i].isShow = true
            this.searchBankList[i].list[is].isShow = true
          })

        })
      }
    },
    searchBankFn () {
      this.searchBankList = []

      let req = {

        currency: this.fastCurrencyData.currency,
      }
      if (this.searchBankTx) {
        req.card_name =  this.searchBankTx
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/otc_card_list`, req, "get")
          .then((res) => {
            let arr = res.list ? res.list : []
            arr.forEach((val, i) => {
              arr[i].isShow = true
              arr[i].list.forEach((vals, is) => {
                arr[i].list[is].isShow = true
              })
            })
            this.searchBankList = arr

          })
          .catch((res) => {

            this.messageFn(res.msg)
          });
    },
    searchResetFn () {
      this.searchPayArr = ['1', '', '', '']
      this.searchTx = ''
      this.searchBankTx = ''
      this.searchChainType = ''
      this.searchCheckCoin = []
      this.searchCoinList = []
      this.checkBankData = {}
      this.isShowNo = false
      this.searchBankTXFn()
    },
    searchPayFn (index, type) {
      if (index === '') {
        this.searchPayArr[0] = '1'
        this.searchPayArr.forEach((val, index) => {
          if (index != 0) {
            this.searchPayArr[index] = ''
          }
        })
        return false
      }
      this.searchPayArr[0] = ''
      this.searchPayArr[index + 1] = this.searchPayArr[index + 1] ? '' : type

    },
    searchChainTypeFn (type) {
      this.searchChainType = type
    },
    tabFn (type) {
      window.sessionStorage.setItem('tabIndex', type)
      this.tabIndex = type
      if (type == 2) {
        this.resInit('search')
      }
    },
    fastCurrencyTab (index) {
      this.fastCurrencyIndex = index
      if (this.payPriceData[index].price && this.payPriceData[index].price != 0) {
        this.fastCurrencyData.price = this.payPriceData[index].price
      }
    },
    async coinPledgeFn () {
      let req = {
        chain_type: this.fastCoinData.type,
        pay_type: this.payPriceData[this.fastCurrencyIndex].pay_way,
        currency: this.fastCurrencyData.currency,
        coin_id: this.fastCoinData.id,
        price: parseFloat(this.fastCurrencyData.price),
        amount: parseFloat(this.num),
      }
      this.isAjax = false
      await this.$ajax(`${this.Utils.URL.goAPPURL}buy/buyer_pledge`, req, "get")
          .then((res) => {
            this.pledgeData = res ? res : {amount: '', coin_data: {}, isAjax: true}
            this.pledgeData.isAjax = true
          })
          .catch((res) => {
            this.pledgeData = {amount: '', coin_data: {}, isAjax: false}
            this.status = 'error'
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            if (res.code == 120) {
              this.messageFn(this.$t('tips_view.msg106'))
              return false
            }
            if (res.code == 121) {
              this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
              return false
            }
            if (res.code == 123) {
              this.messageFn(this.$t('tips_view.msg107'))
              return false
            }
            if (res.code == 151) {
              // 价格变动
              this.isShowTips2 = true
              this.messageFn(this.$t('tips_view.msg124'))

              return false
            }
            if (res.code == 152) {
              this.messageFn(this.$t('tips_view.msg125'))

              return false
            }
            if (res.code == 153) {
              this.messageFn(this.$t('tips_view.msg105'))
              return false
            }
            if (res.code == 154) {
              this.messageFn(this.$t('tips_view.msg165'))
              return false
            }

            this.messageFn(this.$t('tips_view.msg108'))
          });
    },
    async tranCoinFn () {
      this.status = 'loading'
      this.isShowLoading = true
      this.text = this.$t('tips_view.msg8')
      if (this.isAjax) {
        return false
      }
      this.isShowTips1 = false
      let txId = ''
      this.isAjax = true
      if (this.pledgeData.coin_data.contract_addr) {
        await this.transferUSDTFn(this.pledgeData.amount, this.pledgeData.to_addr, this.pledgeData.coin_data.contract_addr).then(res => {
          txId = res
        }).catch(res => {
          txId = ''
          this.isAjax = false
          this.status = 'error'
          this.text = this.$t('tips_view.msg12')
          setTimeout(() => {
            this.isShowLoading = false
          }, 2000)
          return false
        })
      } else {
        await this.transactionCoinFn(this.pledgeData.amount, this.pledgeData.to_addr).then(res => {
          txId = res
        }).catch(res => {
          txId = ''
          this.isAjax = false
          this.status = 'error'
          this.text = this.$t('tips_view.msg12')
          setTimeout(() => {
            this.isShowLoading = false
          }, 2000)
          return false
        })
      }
      this.isAjax = false
      this.tx_id = txId
      if (!txId) {

        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      } else {
        this.fastSubmit()
      }

    },
    async fastSubmit () {
      if (this.payPriceData[this.fastCurrencyIndex].price == 0 || !this.payPriceData[this.fastCurrencyIndex].price) {
        this.messageFn(this.$t('tips_view.msg166'))
        return false
      }
      this.status = 'loading'
      this.isShowConfirm = false
      this.isShowLoading = true
      this.text = ''
      if (this.isAjax) {
        return false
      }
      this.isAjax = true
      await this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_judge`, {
        chain_type: this.fastCoinData.type,
        pay_type: this.payPriceData[this.fastCurrencyIndex].pay_way,
        currency: this.fastCurrencyData.currency,
        coin_id: this.fastCoinData.id,
        price: parseFloat(this.fastCurrencyData.price),
        amount: parseFloat(this.num),
      }, "get")
          .then( async (res) => {
            await this.coinPledgeFn()
            if (!this.pledgeData.isAjax) {
              return  false
            }
            if (this.pledgeData.amount != 0 && this.pledgeData.amount && !this.tx_id) {
              this.isShowLoading = false
              this.isShowTips1 = true
              return false
            }
            let req = {
              version: '2.1.5',
              coin_id: this.fastCoinData.id,
              currency: this.fastCurrencyData.currency,
              num: parseFloat(this.num),
              pay_type: this.payPriceData[this.fastCurrencyIndex].pay_way,
              price: parseFloat(this.fastCurrencyData.price),
            }
            if (this.tx_id) {
              if (this.tx_id.transaction){
                this.tx_id = this.tx_id.transaction.txID
              }
              req.tx_id = this.tx_id
            }

            this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_confirm`, req, "post")
                .then((res) => {
                  this.tx_id = ''
                  this.isAjax = false
                  this.isShowLoading = false
                  this.goRouter('/otc_buy_order', {id: res.order_id})
                })
                .catch((res) => {
                  this.isAjax = false
                  this.status = 'error'
                  setTimeout(() => {
                    this.isShowLoading = false
                  }, 2000)

                  if (res.code == 120) {
                    this.messageFn(this.$t('tips_view.msg106'))
                    return false
                  }
                  if (res.code == 121) {
                    this.messageFn(this.fastCurrencyData.currency + this.$t('tips_view.msg112'))
                    return false
                  }
                  if (res.code == 123) {
                    this.messageFn(this.$t('tips_view.msg107'))

                    return false
                  }

                  if (res.code == 151) {
                    this.isShowTips2 = true
                    this.messageFn(this.$t('tips_view.msg124'))
                    return false
                  }
                  if (res.code == 152) {
                    this.messageFn(this.$t('tips_view.msg125'))
                    return false
                  }
                  if (res.code == 153) {
                    this.messageFn(this.$t('tips_view.msg105'))
                    return false
                  }
                  if (res.code == 154) {
                    this.messageFn(this.$t('tips_view.msg165'))
                    return false
                  }

                  this.messageFn(this.$t('tips_view.msg108'))

                });

          })
          .catch((res) => {
            this.isAjax = false
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            if (res.code == 120) {
              this.messageFn(this.$t('tips_view.msg106'))
              return false
            }
            if (res.code == 121) {
              this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
              return false
            }
            if (res.code == 123) {
              this.messageFn(this.$t('tips_view.msg107'))
              return false
            }
            if (res.code == 151) {
              // 价格变动
              this.isShowTips2 = true
              this.messageFn(this.$t('tips_view.msg124'))
              return false
            }
            if (res.code == 152) {
              this.messageFn(this.$t('tips_view.msg125'))
              return false
            }
            if (res.code == 153) {
              this.messageFn(this.$t('tips_view.msg105'))
              return false
            }
            if (res.code == 154) {
              this.messageFn(this.$t('tips_view.msg165'))
              return false
            }

            this.messageFn(this.$t('tips_view.msg108'))
          });



    },
     showConfirmFn () {
      this.fastErrorTx = ''
      let num = parseFloat(this.num)
      let base_amount = parseFloat(this.fastCoinData.min_buy_coin)
      let max_buy_coin = parseFloat(this.fastCoinData.max_buy_coin)
      if (num < base_amount) {
        this.fastErrorTx = this.$t('tips_view.msg109') + this.fastCoinData.min_buy_coin + this.fastCoinData.code
        return false
      }
      if (num > max_buy_coin) {
        this.fastErrorTx = this.$t('tips_view.msg110') + this.fastCoinData.max_buy_coin + this.fastCoinData.code
        return false
      }
      if (new window.windowBigNumber(num).mod(base_amount).toString() != 0) {
        this.fastErrorTx = this.$t('tips_view.msg111') + this.fastCoinData.min_buy_coin + this.$t('tips_view.msg24')
        return false
      }
     if (this.storeChainType != this.fastCoinData.type.toUpperCase()) {
       this.messageFn(this.$t('tips_view.msg78') + this.fastCoinData.type.toUpperCase() + this.$t('tips_view.msg170'))
       return false
     }


      this.status = 'loading'
      this.isShowLoading = true
      this.isShowConfirm = false
      this.text = ''
      if (this.isAjax) {
        return false
      }
      this.isAjax = true
       this.payTypePriceFn()




    },
     payTypePriceFn () {
      let req = {
        chain_type: this.fastCoinData.type,
        coin_id: this.fastCoinData.id,
        currency: this.fastCurrencyData.currency,
        amount: parseFloat(this.num),
        price: parseFloat(this.fastCurrencyData.price),
      }

      this.payPriceData = []
       this.$ajax(`${this.Utils.URL.goAPPURL}buy/order_pay_list`, req, "get")
      .then((res) => {
        let payPriceData = res.list ? res.list : []
        this.payPriceData = payPriceData
        let price = true
        payPriceData.forEach((val, index) => {

          if ( price && val.price && val.price != 0) {
            this.fastCurrencyData.price = val.price
            this.fastCurrencyIndex = index
            price = false
          }

        })

        this.isAjax = false
        this.isShowLoading = false
        this.isShowConfirm = true

      })
      .catch((res) => {
        this.isAjax = false
        this.isShowLoading = false

        if (res.code == 161) {
          this.searchCountryFn()
          this.isShowContact = true
          return false
        }
        if (res.code == 120) {
          this.messageFn(this.$t('tips_view.msg106'))
          return false
        }
        if (res.code == 121) {
          this.messageFn(this.resData.currency + this.$t('tips_view.msg112'))
          return false
        }
        if (res.code == 123) {
          this.isShowConfirm = true
          this.messageFn(this.$t('tips_view.msg107'))
          return false
        }
        if (res.code == 151) {
          // 价格变动
          this.messageFn(this.$t('tips_view.msg124'))
          this.isShowConfirm = true
          return false
        }
        if (res.code == 152) {
          this.messageFn(this.$t('tips_view.msg125'))
          return false
        }
        if (res.code == 153) {
          this.messageFn(this.$t('tips_view.msg105'))
          return false
        }
        if (res.code == 154) {
          this.messageFn(this.$t('tips_view.msg165'))
          return false
        }

        this.messageFn(this.$t('tips_view.msg108'))

      });
    },
    linkRouterFn (path, query = {}) {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      if (!this.storeChangeAddressId) {

        this.isShowWallet = true
        return false
      }
      this.goRouter(path, query)
    },
    fastCurrencyFn (id) {
      let req = {
        coin_id: id,
        version: '2.1.5'
      };
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/select_pay_currency`, req, "get")
          .then((res) => {
            this.fastCurrencyList = res.list ? res.list : []
            window.sessionStorage.setItem('fastCurrencyList', JSON.stringify(this.fastCurrencyList))

              this.fastCurrencyList.forEach(val => {
                if (this.storeLang == 'cn') {
                  if (val.currency == 'CNY') {
                    this.fastCurrencyData = val
                  }
                } else {
                  if (val.currency == 'USD') {
                    this.fastCurrencyData = val
                  }
                }
              })


            this.resInit()
            this.searchBankFn()
            window.sessionStorage.setItem('fastCurrencyData', JSON.stringify(this.fastCurrencyData))
          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    fastCoinListFn () {
      let req = {
        version: '2.1.5'
      };
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/select_buy_coin`, req, "get")
          .then((res) => {
            this.fastCoinList = res.list ? res.list : []
            window.sessionStorage.setItem('fastCoinList', JSON.stringify(this.fastCoinList))

            if (!this.fastCoinData.code) {
              this.fastCoinData = this.fastCoinList[0]
              window.sessionStorage.setItem('fastCoinData', JSON.stringify(this.fastCoinData))
            }
            this.fastCurrencyFn(this.fastCoinData.id)
          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    resInit (type) {

      let req = {
        size: this.size,
        page: this.page,
        currency: this.fastCurrencyData.currency,
      };
      if (this.searchChainType) {
        req.chain_type =  this.searchChainType
      }
      if (this.searchCheckCoin.length) {
        let arr = this.searchCheckCoin.filter(val => {
          return val && val.trim()
        })
        if (arr.length) {
          req.coin_id_arr = arr.join(',')
        }
      }
      if (this.checkBankData.pay_way && this.fastCurrencyData.currency != 'CNY') {
        req.pay_way = this.checkBankData.pay_way
      }
      if (this.searchPayArr.length && this.fastCurrencyData.currency == 'CNY') {
        let arr = this.searchPayArr.filter(val => {
          return val && val.trim() && val != 1
        })
        if (arr.length) {
          req.pay_way = arr.join(',')
        }
      }
      if (type == 'search') {
        /*if (!this.searchCheckCoin.length) {
          this.messageFn(this.$t('tips_view.msg104'))
          return false
        }*/
        this.page = 1
        req.page = 1
        this.loadInfinite = false
        this.isShowSearch = false
      }
      if (type == 'reset') {

        this.page = 1
        req.page = 1
        this.loadInfinite = false
        this.isShowSearch = false
      }

      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/free_buy_coin_list`, req, "get")
          .then((res) => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search' ||  type == 'reset') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
  }
}
</script>

<style lang="scss">
.h5_otc_index_view{
  padding: 0.08rem 0.16rem;
  .phone_main{
    .area_box{
      padding: 0 0.12rem 0 0rem;
      margin-right: 0.1rem;
      width: 0.9rem;
      border-right: 1px solid $BR4;
    }
    .com_popup_input_tx{
      width: 2.2rem !important;
    }
  }
  .select_bank_box{
    .bank_scroll{
      height: calc(100vh - 1rem);
      overflow-y: scroll;
    }
    .search_bank_input{
      background: $BG2;
      border-radius: 0.16rem;
      margin: 0.04rem 0.16rem;
      padding: 0 0.12rem;

      .icon_search_gray{
        width: 0.16rem;
        height: 0.16rem;
        margin-right: 0.05rem;
      }
      .search_bank{
        font-size: 0.12rem;
        line-height: 0.32rem;
        width: 2.2rem;
      }
    }
    .select_bank_item{

      .tab_bank{
        background: $BG2;
        padding: 0.08rem 0.16rem;
        margin-bottom: 0.12rem;
      }
      .bank_name{
        padding: 0.1rem 0.16rem;
        margin-bottom: 0.12rem;
      }
      .bank_round{
        border-radius: 0.03rem;
        width: 0.06rem;
        height: 0.2rem;
      }
    }

  }
  .tab_box{
   padding-bottom: 0.16rem;
    .tab_l{
      border-radius: 0.06rem;
      border: 1px solid $T8;
      .item{
        padding: 0.05rem 0.25rem;
        color: $T8;
      }
      .item_cur{
        background: $T8;
        color: $FFF;
      }
    }
    .tab_r{
      padding: 0.05rem 0.16rem;
      border-radius: 0.06rem;
      background: $T8;
    }
  }
  .user_box{
    .user_l{
      padding: 0.04rem 0.16rem 0.04rem 0.04rem;
      background: $BG2;
      border-radius: 0.14rem;
    }
    .curreny_info{
      background: $BG2;
      margin-left: 0.3rem;
      border-radius: 0.14rem;
      padding: 0 0.12rem;
      .curreny_tx{
        line-height: 0.28rem;
        min-width: 0.5rem;
        text-align: center;
        margin-right: 0.1rem;
        border-right: 1px solid $BR;
      }
    }
    .icon_orange_arrow{
      width: 0.12rem;
      height: 0.12rem;
    }
  }
  .form_main{
    margin: 0.24rem auto;
    padding: 0.2rem 0.16rem 0.16rem;
    border-radius: 0.06rem;
    background: $BG2;
    .select_input_box{
      background: $FFF;
      border-radius: 0.06rem;
      padding: 0.14rem 0.12rem;
    }
    .input_box{
      background: $BG4;
      border: 1px solid $BR4;
      border-radius: 0.06rem;
      padding: 0.14rem 0.12rem;
      .input_num{
        font-size: 0.2rem;
        font-weight: bold;
        color: $T6;
        width: 2.1rem;
        line-height: 0.28rem;
      }
    }
    .input_sub{
      margin-top: 0.36rem;
      background: $BR2;
      color: $T4;
      border-radius: 0.06rem;
      line-height: 0.44rem;
    }
    .input_sub_cur{
      background: $T8;
      color: $FFF;
    }
  }
  .buy_tip{
    background: $T9_01;
    border-radius: 0.06rem;
    color: $T9;
    padding: 0.14rem 0.16rem;
  }
  .buy_otc_main{
    .price_tx{
      font-size: 0.26rem;
      margin-bottom: 0.24rem;
    }
    .payment_list1:last-child{
      margin-bottom: 0;
    }
    .payment_list1{
      padding: 0.15rem 0.16rem;
      border: 1px solid $BG2;
      background: $BG2;
      border-radius: 0.06rem;
      margin-bottom: 0.1rem;
      .payment_30{
        width: 0.06rem;
        height: 0.2rem;
        border-radius: 0.03rem;
      }
    }
    .payment_list_cur{
      border: 1px solid $T8;
      background: $T11;
    }
  }
  .otc_order_main{
    margin-top: 0.24rem;
    .otc_item{
      background: $BG2;
      border-radius: 0.06rem;
      padding: 0.16rem 0.12rem;
      margin-bottom: 0.16rem;
      .otc_head{
        padding-bottom: 0.16rem;
        border-bottom: 1px solid $BR2;
      }
      .item_price{
        font-size: 0.2rem;
      }
      .buy_btn{
        padding: 0.1rem 0.16rem;
        border-radius: 0.06rem;
        background: $T8;
        min-width: 0.72rem;
        text-align: center;
      }
      .type_box{
        margin-right: 0.16rem;
        .type_round{
          border-radius: 0.03rem;
          margin-right: 0.05rem;
          width: 0.06rem;
          height: 0.14rem;
        }
        .bank_bg{
          background: $T8;
        }
        .wechat_bg{
          background: rgba(9, 187, 7, 1);
        }
        .alipay_bg{
          background: rgba(23, 119, 255, 1);
        }
      }

    }
  }
  .search_main{
    .search_chain_box{
      border-radius: 0.06rem;
      background: $BG2;
      overflow: hidden;
      .search_item:last-child{
        border-right: 0;
      }
      .search_item{
        line-height: 0.36rem;
        width: 25%;
        text-align: center;
        font-size: 0.14rem;
        color: $T6;
        font-weight: bold;
        border-right: 1px solid $BR;
      }
      .search_item_cur{
        background: $T8;
        color: $FFF;
      }
    }
    .search_payment_box{
      flex-wrap: wrap;
      .search_item{
        line-height: 0.4rem;
        width: 48%;
        text-align: center;
        font-size: 0.14rem;
        color: $T6;
        font-weight: bold;
        margin-bottom: 0.1rem;
        border-radius: 0.06rem;
        background: $BG2;
      }
      .search_item_cur{
        background: $T8;
        color: $FFF;
      }
    }
    .search_bank_box{
      border-radius: 0.06rem;
      background: $BG2;
      line-height: 0.5rem;
      padding: 0 0.14rem;
    }
    .search_input_box{
      border-radius: 0.06rem;
      background: $BG2;
      overflow: hidden;
      padding: 0 0.14rem;
      .search_tx{
        width: 2.4rem;
        font-size: 0.16rem;
        font-weight: bold;
        line-height: 0.5rem;
        padding: 0 0.14rem;
      }
    }
    .search_coin_box{
      flex-wrap: wrap;
      .search_item{
        position: relative;
        width: 48%;
        margin-bottom: 0.1rem;
        border-radius: 0.06rem;
        background: $BG2;
        padding: 0.1rem 0.12rem;
        border: 1px solid $BG2;
      }
      .search_item .icon_list_check{
        display: none;
      }
      .search_item_cur{
        background: $FFF;
        border: 1px solid $T8;
      }
      .search_item_cur .icon_list_check{
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .search_nodata{
      border-radius: 0.06rem;
      background: $BG2;
      padding: 0.1rem 0.14rem;
      text-align: center;
      color: $T7;
      font-size: 0.14rem;
    }
  }

  .search_main_coun{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 996;
    .bg{
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100vw;
      height: 100vh;
    }
    .search_box{
      z-index: 997;
      position: absolute;
      width: 100vw;
      bottom: 0;
      background: $FFF;
    }
    .search_title_tx{
      padding: 0.14rem 0.16rem;
      font-weight: bold;
      font-size: 0.16rem;
      color: $T6;
    }
    .search_input_box{
      margin: 0 0.16rem 0rem;
      padding: 0 0.12rem ;
      background: $BG2;
      border-radius: 0.16rem;

    }
    .input_tx{
      line-height: 0.32rem;
      font-size: 0.12rem;
      width: 2rem;
    }
    .search_img{
      margin-left: 0.1rem;
    }
    .item_box{
      margin-top: 0.12rem;
      border-top: 1px solid $BR2;
      padding: 0.1rem 0;
      height: 3.5rem;
      overflow-y: scroll;
    }
    .item{
      font-size: 0.16rem;
      color: $T6;
      padding: 0.1rem 0.16rem;
    }
    .item_cur{
      background: $T11;
    }
  }
}
</style>
