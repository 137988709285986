<template>
  <article class="h5_scf_view">
    <section class="tab " :class="tabIndex == 2 ? 'br2' : ''">
      <div class="d_flex_space">
        <p class="tx1_box">
          <span class="tx1 " :class="tabIndex == 1 ? 'tx1_cur' : ''" @click="tabFn(1)">Quick Pledge</span>
          <!-- <span class="tx1 " :class="tabIndex == 2 ? 'tx1_cur' : ''"  @click="tabFn(2)">{{ $t('self_selection') }}</span> -->
        </p>
      </div>
    </section>
    <section class="wrapper">
      <div class="box">
        <h2>质押数量</h2>
        <div class="input_box d_flex_space">
          <input type="number" pattern="[0-9]*" v-model="num" class="input_tx" placeholder="请输入金额" />
          <el-popover popper-class="box_popper" :width="180" :visible="visible">
            <template #reference>
              <div class=" d_flex" @click="visible = !visible">
                <div class="com_icon_img_box3">
                  <img :src="coinDataObj[currIndexCoin].icon_url" class="com_coin_img" />
                  <i class="h5_icon com_icon_subscript"
                    :class="coinDataObj[currIndexCoin].type == 'tron' ? 'icon_subscript_tron' : coinDataObj[currIndexCoin].type == 'bsc' ? 'icon_subscript_bsc' : coinDataObj[currIndexCoin].type == 'eth' ? 'icon_subscript_eth' : coinDataObj[currIndexCoin].type == 'mainnet' ? 'icon_subscript_mainnet' : ''"></i>
                </div>
                <span class="code_tx ">{{ coinDataObj[currIndexCoin].code }}</span>
                <span class="type_tx code_type" :class="coinDataObj[currIndexCoin].type + '_color'"><span
                    class="fz_10 font_bold">{{
                      coinChainType[coinDataObj[currIndexCoin].type] }}</span></span>
                <i class="h5_icon icon_gray_arrow_down ml_5"></i>
              </div>
            </template>
            <template #default>
              <div class=" d_flex" v-for="(item, index) in coinDataObj" :key="item.value"
                @click="handleChoosenCoin(index)">
                <div class="com_icon_img_box3">
                  <img :src="item.icon_url" class="com_coin_img" />
                  <i class="h5_icon com_icon_subscript"
                    :class="item.type == 'tron' ? 'icon_subscript_tron' : item.type == 'bsc' ? 'icon_subscript_bsc' : item.type == 'eth' ? 'icon_subscript_eth' : item.type == 'mainnet' ? 'icon_subscript_mainnet' : ''"></i>
                </div>
                <span class="code_tx ">{{ item.code }}</span>
                <span class="type_tx code_type" :class="item.type + '_color'"><span class="fz_10 font_bold">{{
                  coinChainType[item.type] }}</span></span>
                <span style="line-height: 1;"><i class="h5_icon"
                    :class="currIndexCoin == index ? 'icon_check' : 'icon_uncheck'"></i></span>
              </div>
            </template>
          </el-popover>
        </div>
        <p class="balance">
          <span>可质押数量：100-10000 USDT </span>
          <span>余额：{{ balance }} </span>
        </p>
        <h2>预计获得</h2>
        <div class="box_estimate">
          <p class="box_estimate_icon">
            <span class="icon"><img src="/icon/scf_main.svg" alt=""></span>
            <span>FIN-LP</span>
          </p>
          <p>
            <span>{{ estimate }}</span>
            <span>LP</span>
          </p>
        </div>
        <p class="balance">
          <span>跨链矿工费（预估）： </span>
          <span>0.0001890 BNB</span>
        </p>
        <p class="balance" style="margin-top: 0;">
          <span>质押矿工费（预估）： </span>
          <span>0.0001890 FTC</span>
        </p>
        <h2>质押周期</h2>
        <div class="period" @click="drawer = true">
          <p class="tx_bold">{{currentPledge[this.currentType].date}}day</p>
          <p>Daily Return {{currentPledge[this.currentType].rate}}%</p>
          <p class="period_type">
            <span class="icon">
              <img :src="this.currentType == 'fusd' ? '/icon/fusd.svg' : '/icon/scf_main.svg'" alt="">
            </span>
            <span style="text-transform: uppercase; font-weight: bold;">{{this.currentType}}</span>
            <i class="h5_icon icon_gray_arrow_down ml_5"></i>
          </p>
        </div>
      </div>
    </section>
    <div class="wrapper">
      <p class="submit_btn" @click="submitPledge" :disabled="!num && +num > 100 && +num <= 10000"
        :class="(num != '' && +num > 100 && +num <= 10000) ? 'active' : ''">快捷质押</p>
    </div>
    <section class="scf_list_main">
      <div class="list_title d_flex_space">
        <p class="tx1">我的质押记录</p>
        <p class="tx2 d_flex" @click="linkRouterFn()"><span>查看更多</span><i class="h5_icon ml_3 icon_orange_arrow"></i></p>
      </div>
      <div class="list_box">
        <div class="com_scroll" v-infinite-scroll="orderInit" :infinite-scroll-distance="50"
          :infinite-scroll-immediate="false">

          <div class="list_item" v-for="(list, index) in resOrderData" :key="list.id">
            <div @click="goRouter('/invest_order_details', { id: list.id })">
              <div class="coin_box d_flex_space">
                <div class="l_box d_flex">
                  <div class="com_icon_img_box3">
                    <img :src="list.coin_data.icon_url" class="com_coin_img" />
                    <i class="h5_icon com_icon_subscript"
                      :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : list.coin_data.type == 'eth' ? 'icon_subscript_eth' : list.coin_data.type == 'mainnet' ? 'icon_subscript_mainnet' : ''"></i>
                  </div>
                  <span class="tx1 ">{{ list.symbol }}</span>
                  <span class="tx2 ml_5" :class="list.chain_type + '_color'"><span class="fz_10 font_bold">{{
                    coinChainType[list.chain_type] }}</span></span>
                </div>
                <div class="r_box d_flex">
                  <span class="tx2 tx7"><span class="fz_10 font_bold">15 {{ $t('day') }}</span></span>
                  <span class="tx2 tx6 ml_5"><span class="fz_10 font_bold">1%</span></span>
                  <i class="h5_icon ml_5 icon_green_arrow"></i>
                </div>
              </div>
              <div class="tx3 mb_10 color_T6">质押数量：2000</div>
              <div class="tx3 tx3_br color_T6 d_flex_space">
                <span>质押时间：2023/07/06 16:27:06</span>
                <span class="font_bold "
                  :class="(list.status == 8 || list.status == 2 || list.status == 1) ? 'color_T8' : (list.status == 3 || list.status == 6 || list.status == 7) ? 'color_T9' : 'color_T7'">{{
                    (list.is_order_repeat
                      && list.status == 4) ? $t('re_cast') : statusTxFn(list) }}</span>
                <!-- <span class="font_bold color_T8">处理中</span> -->
              </div>
            </div>
            <div class="tx3 color_T6 d_flex_space">
              <p>USDT快捷质押: <span class="" :class="list.repeat_status == 1 ? 'color_T8' : 'color_T6'">{{ checkTxFn(list)
              }}</span></p>
              <i class="h5_icon" @click="showCheckFn(index)" :class="list.is_repeat ? 'icon_swipe_yes' : 'icon_swipe_no'"
                v-show="list.order_type != 2 && (list.status == 1 || list.status == 2 || list.status == 5 || list.status == 8)"></i>
            </div>
          </div>

        </div>
        <div class="com_no_data_box" v-show="!resOrderData.length">
          <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
          <p class="com_no_data_tx">{{ $t('nodata') }}</p>
        </div>
      </div>
    </section>
    <el-drawer v-model="drawer" :with-header="false" direction="btt" size="auto">
      <div class="box_drawer">
        <div class="head">
          <h3>选择周期</h3>
          <i class="h5_icon icon_close" @click="drawer = false"></i>
        </div>
        <div class="body">
          <div class="item">
            <div class="content">
              <p class="item_head">
                <span>质押收益币种</span>
                <span class="item_head_icon">
                  <img src="/icon/fusd.svg" class="com_coin_img" />
                  FUSD
                </span>
              </p>
              <div class="item_content" v-for="(item, index) in objPledge.fusd" :key="item.value" :class="item.active ? 'current' :  ''" @click="handleChoosePledge('fusd', index)">
                <p class="date">{{ item.date }}天</p>
                <p class="rate">日收益率{{ item.rate }}%</p>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="content">
              <p class="item_head">
                <span>质押收益币种</span>
                <span class="item_head_icon extra">
                  <img src="/icon/scf_main.svg" class="com_coin_img" />
                  SCF
                </span>
              </p>
              <div class="item_content" v-for="(item, index) in objPledge.scf" :key="item.value" :class="item.active ? 'current' :  ''" @click="handleChoosePledge('scf', index)">
                <p class="date">{{ item.date }}天</p>
                <p class="rate">日收益率{{ item.rate }}%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <index-footer :index="2"></index-footer>
  </article>
</template>


<script>
import indexFooter from '../../components/index_footer'
export default {
  name: "scf",
  data() {
    return {
      tabIndex: 1,
      num: '',
      resAssets: {
        invest_base: 100
      },
      currIndexCoin: 0,
      coinDataObj: [
        {
          code: 'USDT',
          icon_url: '/icon/usdt.svg',
          type: 'bsc',
          value: 'bsc'
        },
        {
          code: 'USDT',
          icon_url: '/icon/usdt.svg',
          type: 'eth',
          value: 'eth'
        },
        {
          code: 'USDT',
          icon_url: '/icon/usdt.svg',
          type: 'tron',
          value: 'tron'
        },
        {
          code: 'FUSD',
          icon_url: '/icon/fusd.svg',
          type: 'mainnet',
          value: 'fusd'
        },
      ],
      balance: '',
      estimate: '',
      resOrderData: [
        {
          coin_data: {
            code: 'USDT',
            icon_url: '/icon/scf_symbol.svg',
            type: 'bsc'
          },
          symbol: 'FIN-LP',
          repeat_status: 1,
          is_repeat: true,
          order_type: 1,
          status: 1,
        },
        {
          coin_data: {
            code: 'USDT',
            icon_url: '/icon/scf_symbol.svg',
            type: 'bsc'
          },
          symbol: 'FIN-LP',
          repeat_status: 1,
          is_repeat: true,
          order_type: 1,
          status: 1,
        }
      ],
      curIndex: 0,
      isCheck: false,
      isShowCheckTips: false,
      visible: false,
      drawer: false,
      objPledge: {
        fusd: [
          {
            date: 30,
            rate: 1.2,
            active: true
          },
          {
            date: 15,
            rate: 1.1,
            active: false
          },
          {
            date: 7,
            rate: 1,
            active: false
          },
        ],
        scf: [
          {
            date: 30,
            rate: 1.4,
            active: false
          },
          {
            date: 15,
            rate: 1.2,
            active: false
          },
          {
            date: 7,
            rate: 1.1,
            active: false
          },
        ]
      },
      currentType: 'fusd',  
      currentPledge: {
        fusd: {
          date: 30,
          rate: 1.2,
          active: true
        },
      }
    }
  },
  created() {
  },
  components: {
    indexFooter,
  },
  methods: {
    handleChoosePledge(type, index) {
      this.objPledge.fusd.forEach(item => {
        item.active = false
      })
      this.objPledge.scf.forEach(item => {
        item.active = false
      })
      if (type == 'fusd') {
        this.objPledge.fusd[index].active = true
        this.currentType = 'fusd'
        this.currentPledge = {
          fusd: this.objPledge.fusd[index]
        }
      } else {
        this.objPledge.scf[index].active = true
        this.currentType = 'scf'
        this.currentPledge = {
          scf: this.objPledge.scf[index]
        }
      }
      // this.drawer = false
    },
    handleChoosenCoin(index) {
      this.currIndexCoin = index
      this.visible = false
    },
    tabFn(index) {
      this.tabIndex = index
    },
    submitPledge() {
      // window.sessionStorage.setItem('listData', JSON.stringify(listData))
      this.goRouter('/scf-confirm')
    },
    orderInit() {
      // this.page++
      // this.getOrderList()
    },
    statusTxFn(obj) {
      if (obj.order_type == 2) {
        return this.$t('experience_coupon')
      }
      if (obj.status == 1) {
        return this.$t('under_subscription')
      }
      if (obj.status == 2) {
        return this.$t('investing')
      }
      if (obj.status == 3) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 4) {
        return this.$t('completed')
      }
      if (obj.status == 5) {
        return this.$t('re_cast')
      }
      if (obj.status == 6) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 7) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 8) {
        return this.$t('under_subscription')
      }
    },
    checkTxFn(obj) {
      if (obj.status == 1 || obj.status == 2 || obj.status == 5 || obj.status == 8) {
        if (obj.is_repeat) {
          return this.$t('reinvestment_on')
        } else {
          return this.$t('reinvestment_off')
        }
      } else {
        if (obj.repeat_status == 1) {
          return this.$t('re_cast')
        }
        if (obj.repeat_status == 2) {
          return this.$t('not_reinvested')
        }
        if (obj.repeat_status == 3) {
          return this.$t('yes_reinvested')
        }
      }

    },
    showCheckFn(index) {
      this.curIndex = index
      // this.isCheck = this.resOrderData[index].is_repeat
      this.resOrderData[index].is_repeat = !this.resOrderData[index].is_repeat
      this.isShowCheckTips = true
    },
  }
}
</script>

<style lang="scss" scoped>
.h5_scf_view {

  // padding: 0.16rem;
  .tab {
    padding: 0.16rem 0.16rem 0.12rem 0.16rem;

    .tx1_box {
      border: 1px solid $T8;
      border-radius: 0.06rem;
      font-size: 0.13rem;
      font-weight: bold;
      color: $T8;
      overflow: hidden;
    }

    .tx1 {
      display: inline-block;
      padding: 0.05rem 0.1rem;

      text-align: center;
      min-width: 0.9rem;
    }

    .tx1_cur {
      background: $T8;
      color: $FFF;
    }

    .tx2 {
      border: 1px solid $T8;
      display: inline-block;
      padding: 0.05rem 0.1rem;
      border-radius: 0.06rem;
      text-align: center;
      min-width: 0.6rem;
      font-size: 0.14rem;
      background: $T8;
      color: $FFF;
    }

  }

  .wrapper {
    padding: 0.16rem;
  }

  .box {
    padding: 0 0.16rem 0.16rem;
    border-radius: 0.1rem;
    background: #F7F7F7;

    h2 {
      text-align: center;
      font-size: 0.15rem;
      margin-bottom: 0.1rem;
      padding-top: 0.18rem;
    }

    .input_box {
      background: $BG4;
      border-radius: 0.06rem;
      padding: 0.1rem;
      border: 0.5px solid rgba(119, 142, 153, 0.25);
    }

    .input_tx {
      font-weight: bold;
      font-size: 0.23rem;
      width: 75%;
    }

    .input_tx::-webkit-input-placeholder {
      font-size: 0.16rem;
    }

    .tx3 {
      font-size: 0.14rem;
    }

    .tx4 {
      font-size: 0.13rem;
    }

    .tx4_br {
      padding-bottom: 0.16rem;
      border-bottom: 1px solid $BR2;
      margin-bottom: 0.16rem;
    }

    .code_tx {
      font-weight: bold;
      margin-right: 0.05rem;
    }

    .code_type {
      border-radius: 0.5rem;
      // background: rgba(244, 187, 11, 0.1);
      // color: rgb(244, 187, 11);
      padding: 0.03rem 0.05rem;
      font-size: 0.1rem;
      line-height: 1;
    }

    .balance {
      display: flex;
      justify-content: space-between;
      color: rgba(119, 142, 153, 1);
      font-size: 0.12rem;
      margin-top: 0.05rem;
    }

    .box_estimate {
      display: flex;
      justify-content: space-between;
      place-items: center;
      background: white;
      padding: 0.1rem;
      border-radius: 0.06rem;
      font-weight: bold;

      &_icon {
        display: flex;
        place-items: center;
        line-height: 0;

        .icon {
          width: 0.3rem;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .period {
      display: flex;
      justify-content: space-between;
      place-items: center;
      background: white;
      padding: 0.1rem;
      border-radius: 0.06rem;

      &_type {
        display: flex;
        place-items: center;
        line-height: 0;

        .icon {
          width: 0.3rem;
        }

        img {
          max-width: 100%;
        }
      }
    }
  }

  .tx_bold {
    font-weight: bold;
  }

  .submit_btn {
    background: rgba(119, 142, 153, 0.08);
    text-align: center;
    border-radius: 0.06rem;
    font-weight: bold;
    font-size: 0.16rem;
    color: rgba(119, 142, 153, 0.5);
    line-height: 0.44rem;

    &.active {
      background: $T8;
      color: $FFF;
    }
  }

  .scf_list_main {
    margin-top: 0.2rem;
    padding: 0 0.16rem;

    .list_title {
      margin-bottom: 0.12rem;

      .tx1 {
        font-size: 0.15rem;
        color: $T6;
        font-weight: bold;
      }

      .tx2 {
        font-size: 0.13rem;
        color: $T8;
      }

      .icon_orange_arrow {
        width: 0.12rem;
        height: 0.12rem;
      }
    }

    .list_box {

      .list_item {
        border-radius: 0.06rem;
        padding: 0.16rem;
        background: $BG4;
        margin-bottom: 0.12rem;
      }

      .coin_box {
        margin-bottom: 0.16rem;

        .icon_green_arrow {
          width: 0.12rem;
          height: 0.12rem;
        }
      }

      .tx1 {
        font-size: 0.16rem;
        font-weight: bold;
      }

      .tx2 {
        font-size: 0.12rem;
        padding: 0.01rem 0.04rem;
        border-radius: 0.1rem;
        display: inline-block;
        min-width: 0.36rem;
        text-align: center;
      }

      .tx6 {
        color: $T5;
        background: $T1;
      }

      .tx5 {
        color: $T12;
        background: $T11;
      }

      .tx7 {
        color: $T8;
        background: $T10;
      }

      .tx8 {
        color: $T14;
        background: $T13;
      }

      .tx9 {
        color: $T16;
        background: $T15;
      }

      .tx3 {
        font-size: 0.12rem;

      }

      .tx3_br {
        margin-bottom: 0.16rem;
        border-bottom: 1px solid $BR2;
        padding-bottom: 0.12rem;
      }

    }
  }

}

.el-popover {
  &.box_popper {
    box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px;
    padding: 0.1rem 0.1rem 0 0.1rem;
    border-radius: 0.06rem;

    .d_flex {
      justify-content: space-between;
      // margin-bottom: 0.05rem;
      padding: 0.1rem 0;
      place-items: center;
      border-top: 1px solid #E5E5E5;

      &:first-child {
        border-top: none;
        padding-top: 0;
      }
    }

    .code_tx {
      font-weight: bold;
      margin-right: 0.05rem;
      color: #515a6e;
    }

    .code_type {
      border-radius: 0.5rem;
      // background: rgba(244, 187, 11, 0.1);
      // color: rgb(244, 187, 11);
      padding: 0.03rem 0.05rem;
      font-size: 0.1rem;
      line-height: 1;
    }
  }
}

.box_drawer {
  .head {
    display: flex;
    place-items: center;
    justify-content: space-between;
    padding-bottom: 0.13rem;
    border-bottom: 1px solid #E5E5E5;
    padding: 0.16rem 0.1rem;
  }

  .body {
    display: flex;
    justify-content: center;
    // padding-top: 0.1rem;

    .item {
      flex-basis: 50%;
      padding: 0.08rem;
      align-self: stretch;

      &:first-child {
        border-right: 1px solid #E5E5E5;
      }

      .content {
        background: rgba(240, 240, 240, 1);
        height: 100%;
        padding: 0.06rem;
        border-radius: 0.1rem;
        
      }
      &_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        margin-bottom: 0.1rem;
        padding: 0 0.06rem;
        &_icon {
          display: flex;
          align-items: center;

          &.extra {
            img {
              width: 0.23rem;
              
            }
          }
          img {
            margin-right: 0.03rem;
          }
        }
      }
      &_content {
        background: white;
        padding: 0.04rem;
        border-radius: 0.08rem;
        text-align: center;
        &.current {
          background: rgba(238, 153, 34, 0.08);
          border: 1.5px solid rgba(238, 153, 34, 0.5);
          color: rgba(238, 153, 34, 1);
        }
        .date {
          font-weight: bold;
          font-size: 0.16rem;
          line-height: 1.2;
        }
        .rate {
          font-size: 0.12rem;
        }
        &+.item_content {
          margin-top: 0.05rem;
        }
      }
    }
  }
}</style>