<template>
  <article class="h5_fth_profit_view">
    <section class="banner_box">
      <!-- <p class="tx1 d_flex_cen" @click="goLinkFn(profitData.fth_link + '?langId=' + storeLang, {}, true)"><span>{{$t('order_view.introduction_FTH_benefits')}}</span><i class="h5_icon icon_white_arrow"></i></p> -->
      <p class="tx1 d_flex_cen"><span>{{$t('order_view.introduction_FTH_benefits')}}</span>
        <!-- <i class="h5_icon icon_white_arrow"></i> -->
      </p>
    </section>
    <section class="profit_box">
      <div class="fth_box d_flex">
        <p class="tx1 d_flex">
          <i class="h5_icon icon_scf_logo"></i>
          <span class="ml_5">SCF</span>
        </p>
        <p class="tx2">{{$t('order_view.current_price')}}: ${{fthPrice}}</p>
      </div>
      <div class="fth_market_box d_flex_space">
        <div>
          <p class="tx1 color_T7">{{ $t('fth_subscription_view.msg21') }}</p>
          <p class="tx1 color_T6 font_bold">${{numFormat(fthMarketTotal)}}</p>
        </div>
        <!-- <div class="tx_r color_T7">
          <p class="tx1">{{ $t('fth_subscription_view.msg22') }}</p>
          <p class="tx1 color_T6 font_bold">{{fthPoolAmount}}</p>
        </div> -->
      </div>
      <div class="chart_area_main">
        <chart-area class="chart_area" :height="'3.7rem'" :id="'chartArea'" :width="'100vw'" :xArrData="xArrData" :seriesData="seriesData"></chart-area>

      </div>

      <!-- <div class="balance_box">
        <div class="num_box">
          <div class="l_box">
            <p class="tx3">{{$t('order_view.amount_to_withdrawn')}}</p>
            <p><span class="tx4">{{profitData.fth_amount}}</span><span class="tx5"> FTC</span></p>
          </div>
          <div class="r_box" @click="showWithdrawFn">{{$t('extract')}}</div>
        </div>
      </div> -->
      <div class="balance_box">
        <div class="num_box">
          <div class="l_box">
            <!-- <p class="tx3" v-show="storeChainType == 'BSC'">FTC {{$t('wallet_balance')}}({{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}})</p> -->
            <p class="tx3">SCF {{$t('wallet_balance')}}({{substr(ftcAddress, 0, 5)}}...{{substr(ftcAddress, -5)}})</p>
            <p><span class="tx4">{{fthBalance}}</span><span class="tx5"> SCF</span></p>
          </div>
          <div class="r_box" @click="showExchangeFn">{{$t('exchange')}}</div>
        </div>
        <p class="tx6">
          <span>{{$t('cumulative_exchange_amount')}}</span>
          <span class="font_bold">{{profitData.ftc_exchange}} SCF</span>
        </p>
      </div>
    </section>
    <section class="tab_box">
      <!-- <p class="item" :class="tabIndex == 0 ? 'cur' : ''" @click="tabFn(0)">{{$t('order_view.gift_details')}}</p> -->
      <p class="item" :class="tabIndex == 1 ? 'cur' : ''" @click="tabFn(1)">{{$t('order_view.exchange_details')}}</p>
      <!-- <p class="item" :class="tabIndex == 2 ? 'cur' : ''" @click="tabFn(2)">{{$t('order_view.exchange_details')}}</p> -->
    </section>


    <!-- <article class="profit_main" v-show="tabIndex == 0">
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <section class="profit_box" v-for="(list, index) in resData" :key="'f'+index">
          <p class="tx1_box">
            <span class="tx1">{{list.status == 1 ? $t('issue') : $t('take_back')}}: {{ list.amount }} {{list.symbol}}</span>
            <span class="tx4">{{ typeTxFn(list.type) }}</span>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ $t('times') }}: {{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </section>
      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article> -->


    <article class="profit_main" v-show="tabIndex == 1">
      <div class="com_scroll" v-infinite-scroll="oneResInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <!-- <section class="profit_box" v-for="(list, index) in oneResData" :key="'t'+index">
          <p class="tx1_box">
            <span class="tx1">{{ list.sto_amount }} {{list.symbol}}</span>
            <span class="tx4">{{list.status == 1 ? $t('extracted') : $t('recycled') }}</span>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ $t('times') }}: {{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </section> -->
        <section class="profit_box" v-for="(list, index) in oneResData" :key="'m'+index">
          <p class="tx5_box" @click="goLinkFn('https://bscscan.com/tx/' + list.hash + '?langId=' + storeLang, {}, true)">
            <span class="tx5">Hash: {{substr(list.hash, 0, 10)}}...{{substr(list.hash, -10)}} </span>
            <i class="h5_icon icon_green_arrow"></i>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ $t('number') }}: {{ list.fth_amount }} {{list.symbol}}</span>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ $t('times') }}: {{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </section>
      </div>
      <div class="com_no_data_box" v-show="!oneResData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article>


    <!-- <article class="profit_main" v-show="tabIndex == 2">
      <div class="com_scroll" v-infinite-scroll="twoResInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <section class="profit_box" v-for="(list, index) in twoResData" :key="'m'+index">
          <p class="tx5_box" @click="goLinkFn('https://bscscan.com/tx/' + list.hash + '?langId=' + storeLang, {}, true)">
            <span class="tx5">Hash: {{substr(list.hash, 0, 10)}}...{{substr(list.hash, -10)}} </span>
            <i class="h5_icon icon_green_arrow"></i>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ $t('number') }}: {{ list.fth_amount }} {{list.symbol}}</span>
          </p>
          <p class="tx2_box">
            <span class="tx2">{{ $t('times') }}: {{dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
          </p>
        </section>
      </div>
      <div class="com_no_data_box" v-show="!twoResData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </article> -->

    <article class="com_popup_main" v-show="isShowWithdraw">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('extract')}}</span>
          <i class="h5_icon icon_close" @click="isShowWithdraw = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{$t('extract_number')}}</p>
              <p class="color_T7 tx4">{{$t('order_view.amount_to_withdrawn')}}:<span class="color_T8 ml_5">{{profitData.ftc_amount}} FTH</span></p>
            </div>
            <div class="com_popup_input_box mb_20" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div class="d_flex_space">
                <input type="number" @keyup="(e) => {this.withdrawNum = e.target.value.replace(/[^\d.]/g, '')}" v-model="withdrawNum" class="com_popup_input_tx" :placeholder="$t('tips_view.msg33') + profitData.min_amount + 'FTH'"/>
                <span class="color_T8">FTH</span>
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <div class="item">
              <div class="left_box">{{$t('order_view.to_wallet')}}</div>
              <div class="right_box">{{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}}</div>
            </div>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn " :class="withdrawNum > 0 ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="subWithdrawFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowExchange">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('order_view.automatic_exchange_AMM_contracts')}}</span>
          <i class="h5_icon icon_close" @click="isShowExchange = false"></i>
        </div>
        <!-- <div class="comp_popup_link_box">
          <div class="tx2 d_flex_space color_T8" @click="goLinkFn('https://bscscan.com/address/' + profitData.fth_addr + '?langId=' + storeLang, {}, true)">
            <p>{{$t('order_view.AMM_contract_address')}}</p>
            <p class="d_flex">
              <span class="color_T5">{{substr(profitData.fth_addr, 0, 6)}}...{{substr(profitData.fth_addr, -6)}}</span><i class="h5_icon icon_green_arrow ml_5"></i>
            </p>

          </div>
        </div> -->
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="tx1 mb_10">
              <p class="color_T6">{{$t('exchange_amount')}}</p>
              <p class="color_T7 tx4">{{$t('wallet_balance')}}:<span class="color_T8 ml_5">{{ fthBalance }} SCF</span></p>
            </div>
            <div class="com_popup_input_box mb_20" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div class="d_flex_space">
                <input type="number" @keyup="(e) => {this.num = e.target.value.replace(/[^\d.]/g, '')}" v-model="num" @blur="eachUsdtAmountFn" class="com_popup_input_tx" :placeholder="$t('tips_view.msg34') + profitData.ftc_min_amount + 'SCF'"/>
                <span class="color_T8">SCF</span>
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <div class="item">
              <div class="left_box d_flex">
                <span>{{$t('order_view.expected_to_get')}}</span>
              </div>
              <div class="right_box">{{usdtAmount}} USDT</div>
            </div>
            <div class="item">
              <div class="left_box">{{$t('order_view.to_wallet')}}</div>
              <div class="right_box">{{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}}</div>
              <!-- <div class="right_box">{{storeChangeAddress}}</div> -->
            </div>
            <div class="com_popup_btn_box">
              <!-- <input type="button" class="com_popup_input_btn" :class="num > 0 ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="subExchangeFn"/> -->
              <input type="button" class="com_popup_input_btn" :class="isDisabled ? 'com_popup_input_btn_cur' : ''" :value="$t('confirm')" @click="subExchangeFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('tips_view.msg35')}}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{$t('tips_view.msg36')}}</p>
            <p class="tx5 color_T6">{{$t('tips_view.msg37')}}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="isShowTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
</template>

<script>
import comLoading from "@/components/loading";
import fth_exchange_abi from '../../assets/abi/fth_exchange_abi.json'
import BigNumber from 'bignumber.js'
import chartArea from '@/components/charts_area'
import socket from '@/utils/socket'
export default {
  name: "fth_profit",
  data () {
    return {
      isShowWithdraw: false,
      isShowExchange: false,
      isShowTips: false,
      num: '',
      errorTx: '',
      fthBalance: 0,
      profitData: {},
      resData: [],
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      tabIndex: 1,
      oneResData: [],
      oneLoadInfinite: false,
      oneIsLoad: false,
      onePage: 1,
      twoResData: [],
      twoLoadInfinite: false,
      twoIsLoad: false,
      twoPage: 1,
      usdtAmount: 0,
      withdrawNum: '',
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isShowLoading: false,
      seriesData: [],
      socket: null,
      unMount: false,
      socketTimeOut: null,
      timeSec: 5000,
      uuid: 0,
      fthMarketTotal: '',
      fthPoolAmount: '',
      fthPrice: '',
      xArrData: [],
      ftcAddress: '',
      isDisabled: false
    }
  },
  created () {
    this.uuid = parseInt(new Date().getTime() / 1000)
    this.socketInit()
    this.profitInit()
    // this.resInit()
    this.oneResInit()
    // this.twoResInit()
  },
  beforeUnmount () {
    this.unMount = true
    if (this.socket) {
      this.socket.doClose()
      this.socket = null
    }

  },
  components: {
    comLoading,
    chartArea
  },
  mounted () {

  },
  methods: {
    socketInit() {
      this.socket = new socket(`wss://app.jhgsddev.com/app_api/invest/app_fsp_day_kline`)
      this.socket.doOpen()

      this.socket.on('open', (data) => {
        this.socket.send({lang: this.storeLang})
      })
      this.socket.on('message', (res) => {
        this.fthPrice = res.fth_price
        this.fthMarketTotal = res.market_value
        this.fthPoolAmount = res.pool_amount
        let arr = res.list ? res.list : []
        let xArr = []
        arr.forEach((val, index )=> {
          arr[index].y = parseFloat(val.close)
          // arr[index].x = parseFloat(val.created_at * 1000 - (8 * 60 * 60 * 1000))
          // base on date_str
          arr[index].x = new Date(val.date_str).getTime()
          xArr[index] = this.dataFormat(parseFloat(val.created_at * 1000), 'MM-dd')
        })
        
        if (res.type == 1) {
          arr.push(arr[arr.length - 1])
          arr[arr.length - 1].x = arr[arr.length - 1].x
          arr[arr.length - 1].isLength = true
          /*if (arr.length > 30) {
            arr = arr.reverse()
            arr = arr.slice(0, 30)
          }*/
          this.seriesData = arr
          this.xArrData = xArr
        }
        if (res.type == 2) {
          this.seriesData[this.seriesData.length - 1] = arr
        }
      })
      let slef = this
      this.socket.on('close', (res) => {
        slef.socketResetFn()

      })

    },
    socketResetFn () {
      if (this.socket && !this.unMount) {
        this.socket = null
        clearTimeout(this.socketTimeOut)
        this.socketTimeOut = setTimeout(() => {
          this.socketInit()
          this.timeSec = this.timeSec + 5000
        }, this.timeSec)
      }
    },
    eachUsdtAmountFn () {
      let num = parseFloat(this.num)
      let time = parseInt(new Date().getTime() / 1000)
      let features = time + '' + num + '' + this.storeChangeAddress
      if (num > 0) {
        let req = {
          amount: num,
          features: features,
        }
        this.$ajax(`${this.Utils.URL.goAPPURL}profit/exchange_num`, req, 'get')
            .then(res => {
              // if (res.features == features) {
                this.usdtAmount = res.tokens ? res.tokens : 0
              // }
              this.isDisabled = true

            })
            .catch(res => {

              this.messageFn(res.msg)
            })
      } else {
        this.usdtAmount = 0
      }
    },
    showExchangeFn () {
      if (this.storeChainType != 'BSC') {
        this.messageFn(this.$t('tips_view.msg38'))
        return false
      }
      this.isShowExchange = true
      this.errorTx = ''
      this.num = ''
      this.usdtAmount = 0
    },
    async subExchangeFn () {
      let num = parseFloat(this.num)
      let min = parseFloat(this.profitData.ftc_min_amount)
      let max = parseFloat(this.profitData.usdt_pool_amount)
      let fth_amount = parseFloat(this.fthBalance)
      if (num > fth_amount) {
        this.errorTx = this.$t('tips_view.msg28')
        return false
      }
      if (num < min) {
        this.errorTx = this.$t('tips_view.msg41')
        return false
      }
      // if (num > max) {
      //   this.errorTx = this.$t('tips_view.msg40')
      //   return false
      // }
      // if (!(this.usdtAmount > 0)) {
      //   this.errorTx = this.$t('tips_view.msg51')
      //   return false
      // }


      this.isShowExchange = false
      let time = parseInt(new Date().getTime() / 1000)
      let features = time + '' + num + '' + this.storeChangeAddress
      let sing = ''
      /*let sing = await this.dappSingFn(features)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }*/
      let req = {
        // features: time,
        amount: num,
        out_desired: this.usdtAmount,
        // out_desired: this.fthPrice,
        // wallet_addr: this.storeChangeAddress,
        // signature: sing
      }

      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')
      // await this.ethConnect()
      // let contract = new window.web3.eth.Contract(fth_exchange_abi, this.profitData.fth_addr)

      // try {
      //   contract.methods.swapForUSDT(
      //       (window.web3.utils.toWei((num + ''), 'ether')),
      //       (window.web3.utils.toWei((this.usdtAmount + ''), 'ether'))).send({from: this.storeChangeAddress}).then(res => {
      //     this.isLoad = false
      //     this.status = 'success'
      //     this.text = this.$t('tips_view.msg11')
      //     setTimeout(() => {
      //       this.isShowLoading = false
      //       this.profitInit()
      //     }, 2000)
      //   }).catch(res => {
      //     this.isLoad = false
      //     this.status = 'error'
      //     this.text = this.$t('tips_view.msg12')
      //     setTimeout(() => {
      //       this.isShowLoading = false
      //     }, 2000)

      //   })
      // } catch (e) {

      //   this.isLoad = false
      //   this.status = 'error'
      //   this.text = this.$t('tips_view.msg12')
      //   setTimeout(() => {
      //     this.isShowLoading = false
      //   }, 2000)

      // }


      this.$ajax(`${this.Utils.URL.goAPPURL}profit/ftc_exchange`, req, 'post')
          .then(res => {
            
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')

            this.isShowLoading = false
            
            setTimeout(() => {
              window.location.reload()
            }, 250)
          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 500)
            this.messageFn(res.msg)
          })
    },
    async subWithdrawFn () {
      let withdrawNum = parseFloat(this.withdrawNum)
      let min = parseFloat(this.profitData.ftc_min_amount)
      let max = parseFloat(this.profitData.fth_pool_amount)
      // let fth_amount = parseFloat(this.profitData.fth_amount)
      let ftc_amount = parseFloat(this.profitData.ftc_amount)
      if (withdrawNum > ftc_amount) {
        this.errorTx = this.$t('tips_view.msg28')
        return false
      }if (withdrawNum < min) {
        this.errorTx = this.$t('tips_view.msg39')
        return false
      }
      if (withdrawNum > max) {
        this.errorTx = this.$t('tips_view.msg40')
        return false
      }
      this.isShowWithdraw = false
      let time = parseInt(new Date().getTime() / 1000)
      let features = time + '' + withdrawNum + '' + this.storeChangeAddress
      let sing = await this.dappSingFn(features)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }
      let req = {
        features: time,
        amount: withdrawNum,
        wallet_addr: this.storeChangeAddress,
        signature: sing
      }

      this.isLoad = true
      // this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/create_sto_order`, req, 'post')
          .then(res => {
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')
            setTimeout(() => {
              // this.isShowLoading = false
              this.profitInit()
            }, 2000)

          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              // this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })
    },
    showWithdrawFn () {
      /*if (this.storeChainType != 'BSC') {
        this.messageFn(this.$t('tips_view.msg38'))
        return false
      }*/
      this.isShowWithdraw = true
      this.errorTx = ''
      this.withdrawNum = ''
    },
    statusTxFn (status) {
      if (status == 1) {
        return this.$t('creating_transaction')
      }
      if (status == 2) {
        return this.$t('transaction_broadcast')
      }
      if (status == 3) {
        return this.$t('success')
      }
      if (status == 4) {
        return this.$t('fail')
      }
    },
    typeTxFn (status) {
      if (status == 1) {
        return this.$t('investment_income')
      }
      if (status == 2) {
        return this.$t('direct_invitation')
      }
      if (status == 3) {
        return this.$t('indirect_invitation')
      }
      if (status == 4) {
        return this.$t('team_benefits')
      }
    },
    tabFn (index) {

      this.tabIndex = index

    },
    profitInit () {
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/fth_profit`, req, "get")
          .then((res) => {
            this.profitData = res ? res : {}
            this.profitData.ftc_price = this.profitData.ftc_price ? this.padRight(this.profitData.ftc_price, 2) : 0
            this.fthBalance = res.ftc_amount ? res.ftc_amount : 0
            this.ftcAddress = res.ftc_addr ? res.ftc_addr : ''
            // if (this.storeChainType == 'BSC') {
            //   this.getUSDTBalanceFn('BSC', res.ftc_addr, res.wallet_addr).then(res => {
            //     this.fthBalance = res ? res : 0
            //   }).catch(res => {
            //     this.fthBalance = 0
            //     this.messageFn(res.msg)
            //   })

            // } else {
            //   this.fthBalance = 0
            //   this.messageFn(this.$t('tips_view.msg38'))
            // }
          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    
    oneResInit (type) {
      let req = {
        page: this.onePage,
        size: this.size,
      }
      if (this.oneLoadInfinite) {
        return false
      }
      if (this.oneIsLoad) {
        return false
      }
      this.oneIsLoad = true
      // this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/fth_exchange_list`, req, 'get')
          .then(res => {
            this.oneIsLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.oneLoadInfinite = true
            }

            this.onePage = this.onePage + 1
            if (type == 'search') {
              this.oneResData = arr
            } else {
              this.oneResData = this.oneResData.concat(arr)
            }
            this.total = parseFloat(res.total)
            // this.isShowLoading = false
          })
          .catch(res => {
            // this.isShowLoading = false
            this.oneIsLoad = false
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_fth_profit_view{
  .chart_area_main{

    height: 3.7rem;
    width: 3.43rem;

    margin-bottom: 0.15rem;
    .chart_area{
      position: absolute;
      left: 0;
      width: 100vw;
    }
  }

  .banner_box{
    background: url("../../assets/img/banner/scf_banner.png");
    height: 1.25rem;
    background-size: cover;
    text-align: center;
    padding-top: 0.33rem;
    .tx1{
      font-size: 0.23rem;
      font-weight: bold;
      color: $FFF;
    }
    .icon_white_arrow{
      width: 0.18rem;
      height: 0.18rem;
    }
  }
  .profit_box{
    padding: 0 0.16rem;
    .fth_box{
      background: $FFF;
      box-shadow: 0px 4px 9px rgba(34, 68, 85, 0.05);
      border-radius: 0.06rem;
      padding: 0.12rem 0.16rem;
      justify-content: space-between;
      margin-top: -0.28rem;
      margin-bottom: 0.16rem;
      .tx1{
        font-size: 0.17rem;
        color: $T6;
        font-weight: bold;
      }
      .tx2{
        font-size: 0.14rem;
        color: $T6;
        font-weight: bold;
      }
    }
    .fth_market_box{
      margin-bottom: 0.14rem;
      .tx1{
        font-size: 0.13rem;
      }

    }
    .balance_box{
      background: $BG3;
      padding: 0.14rem 0.16rem;
      margin-bottom: 0.16rem;
      border-radius: 0.06rem;
      .num_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .l_box{}
        .r_box{
          padding: 0.08rem 0.16rem;
          min-width: 0.7rem;
          text-align: center;
          border-radius: 0.06rem;
          font-size: 0.13rem;
          color: $FFF;
          background: $T8;
          font-weight: bold;
        }
      }
      .tx3{
        font-size: 0.13rem;
        color: $T7;
        margin-bottom: 0.05rem;
      }
      .tx4{
        font-size: 0.2rem;
        color: $T8;
        font-weight: bold;
      }
      .tx5{
        font-size: 0.14rem;
        color: $T8;
        font-weight: bold;
      }
      .tx6{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 0.14rem;
        margin-top: 0.12rem;
        border-top: 1px solid $BR2;
        font-size: 0.13rem;
        color: $T6;
      }
    }
  }
  .tab_box{
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BR;
    .item{
      width: 33.33%;
      font-size: 0.14rem;
      color: $T7;
      padding: 0.12rem 0;
      text-align: center;
    }
    .cur{
      color: $T8;
      font-weight: bold;
      border-bottom: 1px solid $T8;
    }
  }
  .profit_main{

    padding: 0.12rem 0.16rem;
    .profit_box{
      padding: 0.14rem 0.16rem;
      margin-bottom: 0.12rem;
      border-radius: 0.06rem;
      background: $BG3;
      .tx1_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .tx5_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $BR2;
        padding-bottom: 0.12rem;
        .icon_green_arrow{
          width: 0.12rem;
          height: 0.12rem;
        }
      }
      .tx1{
        font-size: 0.13rem;
        color: $T6;
      }
      .tx5{
        font-size: 0.13rem;
        color: $T5;
      }
      .tx2_box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.07rem;
      }
      .tx2{
        color: $T7;
        font-size: 0.13rem;
      }
      .tx4{
        color: $T8;
        font-size: 0.13rem;
      }

    }
  }
}
</style>