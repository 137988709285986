import { createRouter, createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()


import home from './view/home.vue'
import link_wallet_introduce from './view/home/link_wallet_introduce'
// import index from './view/index'
import share_wallet from './view/share_wallet'


import notice_list from './view/article/notice_list'



import profit from './view/profit/profit'
import node_voucher_transfer_history from './view/profit/node_voucher_transfer_history'
import transfer_history from './view/profit/transfer_history'
import income_profit from './view/profit/income_profit'
import income_profit_details from './view/profit/income_profit_details'
import team_profit from './view/profit/team_profit'
import team_md_revenue from './view/profit/team_md_revenue'
import team_md_invite from './view/profit/team_md_invite'
import yesterday_profit from './view/profit/yesterday_profit'
import my_team_profit from './view/profit/my_team_profit'
import fth_profit from './view/profit/fth_profit'
import loan_pool_profit from './view/profit/loan_pool_profit'
import fth_subscription from './view/profit/fth_subscription'
import fth_subscription_history from './view/profit/fth_subscription_history'



import coupon from './view/coupon/coupon'
import coupon_list from './view/coupon/coupon_list'
import coupon_details from './view/coupon/coupon_details'



import loan from './view/loan/loan'
import invest_order from './view/loan/invest_order'
import invest_confirm from './view/loan/invest_confirm'
import invest_order_details from './view/loan/invest_order_details'
import invest_order_profit_details from './view/loan/invest_order_profit_details'
import loan_details from './view/loan/loan_details'
import loan_order from './view/loan/loan_order'
import loan_order_details from './view/loan/loan_order_details'
import repayment_confirm from './view/loan/repayment_confirm'
import loan_form from './view/loan/loan_form'
import loan_confirm from './view/loan/loan_confirm'



import loan_wallet from './view/loan_wallet/loan_wallet'
import coin_list from './view/loan_wallet/coin_list'
import coin_transfer_history from './view/loan_wallet/coin_transfer_history'
import collection_qrcode from './view/loan_wallet/collection_qrcode'
import loan_wallet_list from './view/loan_wallet/loan_wallet_list'




import setting from './view/setting/setting'
import abouts from './view/setting/abouts'
import language from './view/setting/language'



import otc_index from './view/otc/otc_index'
import otc_currency from './view/otc/otc_currency'
import otc_coin from './view/otc/otc_coin'
import otc_buy_order from './view/otc/buy_order'
import buy_order_list from './view/otc/buy_order_list'
import otc_appeal from './view/otc/otc_appeal'
import otc_appeal_submit from './view/otc/otc_appeal_submit'
import otc_free_form from './view/otc/otc_free_form'


import node_index_fin_chain from './view/super_activity/node_index_fin_chain'
import fin_chain_node_token_details from './view/super_activity/node_token_details_fin_chain'
import vote_created_node_fin_chain from './view/super_activity/vote_created_node_fin_chain'
import vote_node_record_fin_chain from './view/super_activity/vote_node_record_fin_chain'
import vote_rule_fin_chain from './view/super_activity/vote_rule_fin_chain'


import scf from './view/scf/index'
import scf_confirm from './view/scf/confirm'

import fsp_trading from './view/trading/fsp_trading.vue'
import fsp_trading_remove_liquidity from './view/trading/fsp_trading_remove_liquidity.vue'
import fsp_trading_swap_record from './view/trading/fsp_trading_swap_record.vue'
import fsp_trading_liquidity_record from './view/trading/fsp_trading_liquidity_record.vue'
const router = createRouter({
    history: routerHistory,
    routes: [
        {
            path: '/',
            component: home
        },
        {
            path: '/home',
            component: home
        },
        {
            path: '/link_wallet_introduce',
            component: link_wallet_introduce
        },
        {
            path: '/share_wallet',
            component: share_wallet
        },
        {
            path: '/profit',
            component: profit
        },
        {
            path: '/node_voucher_balance',
            component: node_voucher_transfer_history
        },
        {
            path: '/transfer_history',
            component: transfer_history
        },
        {
            path: '/income_profit',
            component: income_profit
        },
        {
            path: '/income_profit_details',
            component: income_profit_details
        },
        {
            path: '/team_profit',
            component: team_profit
        },
        {
            path: '/team_md_revenue',
            component: team_md_revenue
        },
        {
            path: '/team_md_invite',
            component: team_md_invite
        },
        {
            path: '/yesterday_profit',
            component: yesterday_profit
        },
        {
            path: '/my_team_profit',
            component: my_team_profit
        },
        {
            path: '/fth_profit',
            component: fth_profit
        },
        {
            path: '/fth_subscription',
            component: fth_subscription
        },
        {
            path: '/fth_subscription_history',
            component: fth_subscription_history
        },
        {
            path: '/loan_pool_profit',
            component: loan_pool_profit
        },
        {
            path: '/coupon',
            component: coupon
        },
        {
            path: '/coupon_list',
            component: coupon_list
        },
        {
            path: '/coupon_details',
            component: coupon_details
        },
        {
            path: '/loan',
            component: loan
        },
        {
            path: '/invest_order',
            component: invest_order
        },
        {
            path: '/invest_confirm',
            component: invest_confirm
        },
        {
            path: '/invest_order_details',
            component: invest_order_details
        },
        {
            path: '/invest_order_profit_details',
            component: invest_order_profit_details
        },
        {
            path: '/loan_details',
            component: loan_details
        },
        {
            path: '/loan_order',
            component: loan_order
        },
        {
            path: '/loan_order_details',
            component: loan_order_details
        },
        {
            path: '/repayment_confirm',
            component: repayment_confirm
        },
        {
            path: '/loan_form',
            component: loan_form
        },
        {
            path: '/loan_confirm',
            component: loan_confirm
        },
        {
            path: '/setting',
            component: setting
        },
        {
            path: '/abouts',
            component: abouts
        },
        {
            path: '/language',
            component: language
        },
        {
            path: '/loan_wallet',
            component: loan_wallet
        },
        {
            path: '/coin_list',
            component: coin_list
        },
        {
            path: '/coin_transfer_history',
            component: coin_transfer_history
        },
        {
            path: '/collection_qrcode',
            component: collection_qrcode
        },
        {
            path: '/loan_wallet_list',
            component: loan_wallet_list
        },
        {
            path: '/notice_list',
            component: notice_list
        },
        {
            path: '/otc_index',
            component: otc_index
        },
        {
            path: '/otc_currency',
            component: otc_currency
        },
        {
            path: '/otc_coin',
            component: otc_coin
        },
        {
            path: '/otc_buy_order',
            component: otc_buy_order
        },
        {
            path: '/buy_order_list',
            component: buy_order_list
        },
        {
            path: '/otc_appeal',
            component: otc_appeal
        },
        {
            path: '/otc_appeal_submit',
            component: otc_appeal_submit
        },
        {
            path: '/otc_free_form',
            component: otc_free_form
        },
        {
            path: '/node_index_fin_chain',
            component: node_index_fin_chain
        },
        {
            path: '/vote_created_fin_chain_node',
            component: vote_created_node_fin_chain
        },
        {
            path: '/vote_node_record_fin_chain',
            component: vote_node_record_fin_chain
        },
        {
            path: '/vote_rule_fin_chain',
            component: vote_rule_fin_chain
        },
        {
            path: '/fin_chain_node_token_details',
            component: fin_chain_node_token_details
        },
        {
            path: '/scf',
            component: scf
        },
        {
            path: '/scf-confirm',
            component: scf_confirm
        },
        {
            path: '/fsp_trading',
            component: fsp_trading
        },
        {
            path: '/fsp_trading_remove_liquidity',
            component: fsp_trading_remove_liquidity,
        },
        {
            path: '/fsp_trading_swap_record',
            component: fsp_trading_swap_record
        },
        {
            path: '/fsp_trading_liquidity_record',
            component: fsp_trading_liquidity_record
        },
    ]
})

export default router