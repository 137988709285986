<template>
  <article class="h5_loan_view">

    <section class="loan_tab " :class="tabIndex == 2 ? 'br2' : ''">
      <div class="d_flex_space">
        <p class="tx1_box">
          <span class="tx1 " :class="tabIndex == 1 ? 'tx1_cur' : ''" @click="tabFn(1)">{{ $t('quick_zone') }}</span>
          <span class="tx1 " :class="tabIndex == 2 ? 'tx1_cur' : ''"  @click="tabFn(2)">{{ $t('self_selection') }}</span>
        </p>
        <p class="tx2"  @click="linkRouterFn('/loan_details')">{{ $t('loaned') }}</p>
      </div>
      <div class="search_box d_flex" v-show="tabIndex == 2">
        <i class="h5_icon icon_orange_search"></i>
        <input class="search_tx" @input="searchFn" v-model="searchTx" :placeholder="$t('fth_subscription_view.msg20')"/>
      </div>
    </section>
    <article class="tab_box1" v-show="tabIndex == 1">
      <div class="chain_tab d_flex">
        <span class="chain_item " @click="tabChainFn(key)" :class="checkChainType == key ? 'chain_cur' : ''" v-for="(list, key) in coinMian">{{key}}{{$t('chain')}}</span>
      </div>
      <section class="loan_from_box">

        <div class="tx1_box d_flex_space mb_10">
          <p class="tx1 font_bold color_T6">{{ $t('investment_amount') }}</p>
          <div class="tx2 color_T7 d_flex">
            <span>{{$t('tips_view.msg19')}}</span>
            <div class="h5_icon ml_5 icon_question" @click="showToastFn">
              <div class="tips_question_box" v-show="isShowToasts">
                <p class="title_tx">{{$t('tips_view.msg20')}}</p>
                <p class="title_msg">{{$t('tips_view.msg58')}}</p>
                <i class="h5_icon add_top_arrow"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="input_box d_flex_space mb_10">
          <input type="number" pattern="[0-9]*" v-model="num" class="input_tx" :placeholder="$t('tips_view.msg23') + resAssets.invest_base + $t('tips_view.msg24')"/>
          <div class=" d_flex" @click="isShowCoin = true">
            <div class="com_icon_img_box3">
              <img :src="coinDataObj.coin_data.icon_url" class="com_coin_img"/>
              <i class="h5_icon com_icon_subscript" :class="coinDataObj.coin_data.type == 'tron' ? 'icon_subscript_tron' : coinDataObj.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
            </div>
            <span class="code_tx ">{{coinDataObj.coin_data.code}}</span>
            <span class="type_tx ml_5" :class="coinDataObj.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[coinDataObj.coin_data.type] }}</span></span>
            <i class="h5_icon icon_gray_arrow_down ml_5"></i>
          </div>
        </div>
        <p class="tx4 d_flex_space tx4_br color_T6">
          <span>{{ $t('balance') }}: {{ balance }} {{ coinDataObj.coin_data.code }}</span>
          <span>{{substr(storeChangeAddress, 0, 8)}}...{{substr(storeChangeAddress, -8)}}</span>
        </p>
        <p class="tx4 d_flex_space color_T7">
          <span>{{ $t('investment_days') }}: {{ resAssets.days ? resAssets.days : '-' }} {{$t('day')}}</span>
          <span>{{ $t('daily_rate') }}: {{ resAssets.daily_rate ? resAssets.daily_rate : '-' }}</span>
        </p>
        <p class="submit_btn" @click="subOrderFn">{{ $t('invest_now') }}</p>
      </section>

      <section class="loan_list_main">
        <div class="list_title d_flex_space">
          <p class="tx1">{{ $t('my_investment_history') }}</p>
          <p class="tx2 d_flex"  @click="linkRouterFn('/invest_order')"><span>{{ $t('see_more') }}</span><i class="h5_icon ml_3 icon_orange_arrow"></i></p>
        </div>
        <div class="list_box">
          <div class="com_scroll" v-infinite-scroll="orderInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">

            <div class="list_item" v-for="(list, index) in resOrderData" :key="list.id">
            <div  @click="goRouter('/invest_order_details', {id: list.id})">
              <div class="coin_box d_flex_space" >
                <div class="l_box d_flex">
                  <div class="com_icon_img_box3">
                    <img :src="list.coin_data.icon_url" class="com_coin_img"/>
                    <i class="h5_icon com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                  </div>
                  <span class="tx1 ">{{list.symbol}}</span>
                  <span class="tx2 ml_5" :class="list.chain_type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.chain_type] }}</span></span>
                </div>
                <div class="r_box d_flex">
                  <span class="tx2 tx7"><span class="fz_10 font_bold">{{list.invest_days}} {{$t('day')}}</span></span>
                  <span class="tx2 tx6 ml_5"><span class="fz_10 font_bold">{{list.daily_profit}}</span></span>
                  <i class="h5_icon ml_5 icon_green_arrow"></i>
                </div>
              </div>
              <div class="tx3 mb_10 color_T6">{{ $t('investment_amount') }}: {{list.invest_amount}}</div>
              <div class="tx3 tx3_br color_T6 d_flex_space">
                <span>{{ $t('subscription_time') }}: {{dataFormat(list.invest_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
                <span class="font_bold " :class="(list.status == 8 || list.status == 2 || list.status == 1 )? 'color_T8' : (list.status == 3 || list.status == 6 || list.status == 7 ) ? 'color_T9' : 'color_T7'">{{ (list.is_order_repeat && list.status == 4) ? $t('re_cast') :  statusTxFn(list)}}</span>
              </div>
            </div>
              <div class="tx3 color_T6 d_flex_space">
                <p>{{ $t('loan_financing') }}: <span class="" :class="list.repeat_status == 1 ? 'color_T8' : 'color_T6'">{{checkTxFn(list)}}</span></p>
                <i class="h5_icon" @click="showCheckFn(index)" :class="list.is_repeat ? 'icon_swipe_yes' : 'icon_swipe_no'" v-show="list.order_type != 2 &&(list.status == 1 || list.status == 2  || list.status == 5 || list.status == 8)"></i>
              </div>
            </div>

          </div>
          <div class="com_no_data_box" v-show="!resOrderData.length">
            <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
            <p class="com_no_data_tx">{{$t('nodata')}}</p>
          </div>
        </div>
      </section>
    </article>

    <article class="tab_box2" v-show="tabIndex == 2">
      <div class="list_box">
        <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
          <div class="list_item" v-for="(list, index) in resData" :key="list.contract_number">
            <div class="coin_box d_flex_space">
              <div class="l_box d_flex">
                <div class="com_icon_img_box3">
                  <img :src="list.coin_data.icon_url" class="com_coin_img"/>
                  <i class="h5_icon com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
                </div>
                <span class="tx1 ">{{ list.coin_data.code }}</span>
                <span class="tx2 ml_5" :class="list.chain_type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.chain_type] }}</span></span>
              </div>
              <div class="r_box d_flex">
                <span class="tx4">{{ $t('daily_rate') }}: {{list.daily_profit}}</span>
              </div>
            </div>
            <div class="tx3 mb_10">{{ $t('contract_number') }}: {{list.contract_number}} </div>
            <div class="tx3 mb_10">{{ $t('financing_amount') }}: {{list.borrow_amount}} {{ list.coin_data.code }}</div>
            <div class="tx3">{{ $t('loan_day') }}: {{list.borrow_days}} {{$t('day')}}</div>
            <div class="tx3 d_flex_space">
              <p>
                <span>{{ $t('fundraising_progress') }}: </span><span class="tx7">{{list.investment_progress}}</span>
              </p>
              <p class="submit_btn" v-show="list.is_invest" @click="linkInvestFn(list.chain_type, '/invest_confirm', {ad_id: list.contract_number, is_add: 1})">{{ $t('invest_now') }}</p>
            </div>

          </div>


        </div>

        <div class="com_no_data_box" v-show="!resData.length">
          <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
          <p class="com_no_data_tx">{{$t('nodata')}}</p>
        </div>



      </div>
    </article>
    <index-footer :index="2"></index-footer>
    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('tips_view.msg20')}}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6">{{$t('tips_view.msg21')}} 2500USDT,{{$t('tips_view.msg22')}}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowTips = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="isShowTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_confirm_main" v-show="isShowCheckTips">
      <div class="com_confirm_bg"></div>
      <section class="com_confirm_box">
        <div class="com_confirm_head">
          <span class="title_tx">{{ $t('tips_view.msg30') }}</span>
        </div>
        <div class="com_confirm_info_box">
          <p class="com_confirm_tx color_T6">{{isCheck ? $t('tips_view.msg31') : $t('tips_view.msg32')}} </p>

          <div class="com_confirm_from_box">
            <div class="com_confirm_btn_box1 d_flex_space">
              <input type="button" class="com_confirm_input_btn com_confirm_input_btn_close" :value="$t('cancel')" @click="isShowCheckTips = false"/>
              <input type="button" class="com_confirm_input_btn com_confirm_input_btn_cur" :value="isCheck ? $t('turn_off') : $t('turn_on')" @click="subWithdrawFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    <com-bind-wallet-id :isShow="isShowWallet" @on-hide="()=>{this.isShowWallet = false}"></com-bind-wallet-id>
    <article class="select_coin_main" v-show="isShowCoin">
      <header class="select_coin_header">
        <span class="back_box" @click="isShowCoin = false"><i class="h5_icon icon_head_arrow_left"></i></span>
        <span class="header_tx">{{ $t('select_coin') }}</span>
      </header>
      <section class="select_coin_box">
        <div class="select_item d_flex" @click="checkChainTypeFn(list)" v-for="list in coinList">
          <div class="com_icon_img_box3">
            <img :src="list.coin_data.icon_url" class="com_coin_img"/>
            <i class="h5_icon_1 com_icon_subscript" :class="list.coin_data.type == 'tron' ? 'icon_subscript_tron' : list.coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
          </div>
          <span class="tx3 color_T6 font_bold ml_5">{{ list.coin_data.code }}</span>
          <span class="tx2 ml_5 bsc_color" v-show="list.coin_data.contract_addr" :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>
        </div>

      </section>
    </article>
  </article>
</template>

<script>
import indexFooter from '../../components/index_footer'
import comLoading from "@/components/loading";
import comBindWalletId from '@/components/bind_wallet_id'
export default {
  name: "loan",
  data () {
    return {
      isShowWallet: false,
      num: '',
      isShowTips: false,
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      tabIndex: 1,
      resData: [],
      balance: 0,
      resAssets: {
        invest_base: 100
      },
      isShowToasts: false,
      status: 'loading',
      text: '',
      isShowLoading: false,
      isShowCheckTips: false,
      loadOrderInfinite: false,
      isOrderLoad: false,
      orderPage: 1,
      resOrderData: [],
      isCheck: false,
      isAjax: false,
      curIndex: '',
      searchTx: '',
      coinBanlace: 0,
      checkChainType: '',
      coinId: '',
      isShowCoin: false,
      coinDataObj: {
        coin_data: {
          code: 'USDT'
        }
      },
      coinList: [],
    }
  },
  components: {
    indexFooter,
    comLoading,
    comBindWalletId
  },
  created () {
    this.resInit()
    if (this.storeChainType) {
      this.checkChainType = this.storeChainType
      let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'

      this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
        addr: this.storeChangeAddress,
      }, "post").then(res => {
        this.coinBanlace =  res.quantity ? parseFloat(res.quantity) : 0
      })
      this.orderInit()
      this.coinInit(this.storeChainType.toLocaleLowerCase())
    }

  },
  mounted () {

  },
  methods: {
    tabChainFn (type) {
      if (this.storeChainType != type) {
        this.messageFn(this.$t('tips_view.msg78') + type.toLocaleUpperCase() + this.$t('tips_view.msg83'))
        return false
      }
      this.checkChainType = type
    },
    coinBalanceFn (contract_addr) {
      this.getUSDTBalanceFn(this.storeChainType, contract_addr, this.storeAddress).then(res => {
        this.balance = res
      }).catch(res => {
        this.balance = 0
        this.messageFn(res.msg)
      })

    },
    checkChainTypeFn (obj) {
      this.coinDataObj = obj
      this.coinId = obj.coin_data.id
      this.isShowCoin = false
      if (this.storeChainType) {
        this.balance = 0
        if (obj.coin_data.contract_addr) {
          this.coinBalanceFn(obj.coin_data.contract_addr)
        } else {
          let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'

          this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
            addr: this.storeChangeAddress,
          }, "post").then(res => {
            this.balance =  res.quantity ? parseFloat(res.quantity) : 0
          })
        }
      }

      this.resAssetsFn()
    },
    coinInit (type) {
      let req = {
        size: 10000
      }
      if (type) {
        req.chain_type = type
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/many_coin_list`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            let code = this.envDev ? 'UDT' : 'USDT'
            this.coinList = arr
            arr.forEach(val => {

              if (val.coin_data.type == this.storeChainType.toLocaleLowerCase() && (val.coin_data.code == code)) {
                this.coinDataObj = val
                this.coinId = val.coin_data.id
                this.coinBalanceFn(val.coin_data.contract_addr)
              }
            })

            this.resAssetsFn()


          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    searchFn () {
      this.loadInfinite = false
      this.isLoad = false
      this.page = 1
      this.resInit('search')
    },
    subOrderFn () {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }

      if (!this.storeChangeAddressId) {

        this.isShowWallet = true
        return false
      }
      if (this.checkChainType != this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg78') + this.checkChainType + this.$t('tips_view.msg83'))

        return false
      }
      let num = parseFloat(this.num)
      let lowest_amount = parseFloat(this.resAssets.invest_base)
      let min_invest_amount = parseFloat(this.resAssets.min_invest_amount)
      let balance = parseFloat(this.balance)
      if (!(balance > 0)) {
        this.messageFn(this.$t('tips_view.msg28'))
        return false
      }
      if (num > balance) {
        this.messageFn(this.$t('tips_view.msg28'))
        return false
      }
      if (num < min_invest_amount) {
        this.messageFn(`${this.$t('tips_view.msg29_1')} ${min_invest_amount}`)
        return false
      }
      if (new window.windowBigNumber(num).mod(lowest_amount).toString() != 0) {
        this.messageFn(`${this.$t('tips_view.msg23')} ${lowest_amount} ${this.$t('tips_view.msg24')}`)
        return false
      }
      if (this.isAjax) {
        return  false
      }
      this.isAjax = true
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/match_invest_order`, {
        amount: num,
        chain_type: this.storeChainType.toLocaleLowerCase(),
        coin_id: this.coinId
      }, 'get')
          .then(res => {
            this.isAjax = false
            if (res.order_id) {
              this.goRouter('/invest_confirm', {ad_id: res.order_id, num: num, is_add: 0})

            } else {
              this.messageFn(this.$t('tips_view.msg73'))
              return false
            }

          })
          .catch(res => {
            this.isAjax = false
            this.messageFn(res.msg)
          })
    },
    showToastFn () {
      this.isShowToasts = true
      setTimeout(() => {
        this.isShowToasts = false
      }, 3000)
    },
    resAssetsFn () {
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/invest_home_page`, {
        chain_type: this.storeChainType.toLocaleLowerCase(),
        coin_id: this.coinId
      }, 'get')
          .then(res => {
            this.resAssets = res ? res : {}
            // this.num = res.min_invest_amount
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    tabFn (index) {
      this.tabIndex = index
    },
    linkInvestFn (chainType, path, query = {}) {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }

      if (!this.storeChangeAddressId) {

        this.isShowWallet = true
        return false
      }
      if (this.storeChainType.toLocaleLowerCase() != chainType ) {
        this.messageFn(this.$t('tips_view.msg78') + chainType.toUpperCase() + this.$t('tips_view.msg83'))

        return false
      }
      this.goRouter(path, query)
    },
    linkRouterFn (path, query = {}) {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      if (!this.storeChangeAddressId) {

        this.isShowWallet = true
        return false
      }
      this.goRouter(path, query)
    },
    resInit (type) {
      let req = {
        reason_type: 1,
        // chain_type: this.storeChainType.toLocaleLowerCase(),
        page: this.page,
        size: this.size,
        // device: 'h5'

      }
      if (this.searchTx !== '') {
        req.contract_number =  this.searchTx
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/loan_finance_order_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    async subWithdrawFn () {
      if (this.storeChainType != this.resOrderData[this.curIndex].chain_type.toUpperCase()) {
        this.messageFn(this.$t('tips_view.msg78') + this.resOrderData[this.curIndex].chain_type.toUpperCase() + this.$t('tips_view.msg88'))
        return false
      }

      let tx_fee = parseFloat(this.resOrderData[this.curIndex].tx_fee)

      if ((tx_fee > this.coinBanlace) || (this.coinBanlace == 0)) {
        this.messageFn(this.coinMian[this.storeChainType] + this.$t('tips_view.msg89'))
        return false
      }

      let str = this.resOrderData[this.curIndex].id + '|' + (this.isCheck ? 'close' : 'open')
      if (this.isAjax) {
        return false
      }
      this.isShowCheckTips = false
      this.isAjax = true

      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')

      let txId

      await this.cancelReinvestTokenCoinFn(this.resOrderData[this.curIndex].pool_addr, str).then(res => {
        txId = res
      }).catch(res => {
        txId = ''

        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false

        }, 2000)
        return false
      })
      if (!txId) {
        this.isLoad = false
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('tips_view.msg12')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        return false
      } else {
        this.$ajax(`${this.Utils.URL.goAPPURL}borrow/notice`, {
          chain_type: this.storeChainType.toLocaleLowerCase(),
          tx_id: txId
        }, 'get')
            .then(res => {

            }).catch(res => {


        })
        this.isAjax = false
        this.isLoad = false
        this.status = 'success'
        this.text = !this.resOrderData[this.curIndex].is_repeat ? this.$t('tips_view.msg74') : this.$t('tips_view.msg75')
        this.resOrderData[this.curIndex].is_repeat = !this.resOrderData[this.curIndex].is_repeat
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)


      }



      /*let time = (parseInt(new Date().getTime() / 1000)) + ''
      let sing = await this.dappSingFn(time + (this.isCheck ? '2' : '1') + this.resOrderData[this.curIndex].id)
      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }
      let req = {
        id: this.resOrderData[this.curIndex].id,
        opt_type: this.isCheck ? '2' : '1',
        features: time,
        signature: sing
      }
      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/repeat_invest`, req, 'post')
          .then(res => {

            this.isLoad = false
            this.status = 'success'
            this.text = !this.resOrderData[this.curIndex].is_repeat ? this.$t('tips_view.msg74') : this.$t('tips_view.msg75')
            this.isShowCheckTips = false
            this.resOrderData[this.curIndex].is_repeat = !this.resOrderData[this.curIndex].is_repeat
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          })
          .catch(res => {
            this.isLoad = false
            this.status = 'error'
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            this.messageFn(res.msg)
          })*/
    },
    showCheckFn (index) {
      this.curIndex = index
      this.isCheck = this.resOrderData[index].is_repeat
      this.isShowCheckTips = true
    },
    checkTxFn (obj) {
      if ( obj.status == 1 || obj.status == 2  || obj.status == 5 || obj.status == 8) {
        if (obj.is_repeat) {
          return this.$t('reinvestment_on')
        } else {
          return this.$t('reinvestment_off')
        }
      } else {
        if (obj.repeat_status == 1) {
          return this.$t('re_cast')
        }
        if (obj.repeat_status == 2) {
          return this.$t('not_reinvested')
        }
        if (obj.repeat_status == 3) {
          return this.$t('yes_reinvested')
        }
      }

    },
    statusTxFn (obj) {
      if (obj.order_type == 2) {
        return this.$t('experience_coupon')
      }
      if (obj.status == 1) {
        return this.$t('under_subscription')
      }
      if (obj.status == 2) {
        return this.$t('investing')
      }
      if (obj.status == 3) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 4) {
        return this.$t('completed')
      }
      if (obj.status == 5) {
        return this.$t('re_cast')
      }
      if (obj.status == 6) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 7) {
        return this.$t('subscription_failed')
      }
      if (obj.status == 8) {
        return this.$t('under_subscription')
      }
    },
    orderInit (type) {

      let req = {
        page: this.orderPage,
        size: this.size,
      }
      if (this.loadOrderInfinite) {
        return false
      }
      if (this.isOrderLoad) {
        return false
      }
      this.isOrderLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/my_investment_order_list`, req, 'get')
          .then(res => {
            this.isOrderLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadOrderInfinite = true
            }

            this.orderPage = this.orderPage + 1
            if (type == 'search') {
              this.resOrderData = arr
            } else {
              this.resOrderData = this.resOrderData.concat(arr)
            }
          })
          .catch(res => {
            this.isOrderLoad = false
            this.messageFn(res.msg)
          })
    },
  },
}
</script>

<style lang="scss">
.h5_loan_view{
  .code_tx{
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
  }
  .type_tx{
    font-size: 0.12rem;
    padding: 0.01rem 0.04rem;
    border-radius: 0.1rem;
    display: inline-block;
    min-width: 0.36rem;
    text-align: center;
  }
  .br2{
    border-bottom: 1px solid $BR;
  }
  .loan_tab{
    padding: 0.16rem 0.16rem 0.12rem 0.16rem;

    .tx1_box{
      border: 1px solid $T8;
      border-radius: 0.06rem;
      font-size: 0.13rem;
      font-weight: bold;
      color: $T8;
      overflow: hidden;
    }
    .tx1{
      display: inline-block;
      padding: 0.05rem 0.1rem;

      text-align: center;
      min-width: 0.9rem;
    }
    .tx1_cur{
      background: $T8;
      color: $FFF;
    }
    .tx2{
      border: 1px solid $T8;
      display: inline-block;
      padding: 0.05rem 0.1rem;
      border-radius: 0.06rem;
      text-align: center;
      min-width: 0.6rem;
      font-size: 0.14rem;
      background: $T8;
      color: $FFF;
    }
    .search_box{
      padding: 0rem 0.12rem;
      border-radius: 0.15rem;
      background: $BG2;
      margin-top: 0.16rem;
      .search_tx{
        line-height: 0.32rem;
        margin-left: 0.05rem;
        font-size: 0.13rem;
        width: 2.8rem;
      }
    }

  }
  .tab_box1{
    .chain_tab{
      border-bottom: 1px solid $BR;
      .chain_item{
        width: 33.33%;
        text-align: center;
        font-size: 0.14rem;
        font-weight: bold;
        padding: 0rem 0 0.1rem;
        color: $T7;
      }
      .chain_cur{
        color: $T8;
        border-bottom: 1px solid $T8;
      }
    }
    .loan_from_box{

      padding: 0.24rem 0.16rem 0.32rem;
      .tx1_box{}
      .tx1{
        font-size: 0.15rem;
      }
      .tx2{
        font-size: 0.13rem;
      }
      .input_box{
        background: $BG4;
        border-radius: 0.06rem;
        padding: 0.16rem;
      }
      .input_tx{
        font-weight: bold;
        font-size: 0.23rem;
        width: 75%;
      }
      .input_tx::-webkit-input-placeholder{
        font-size: 0.16rem;
      }
      .tx3{
        font-size: 0.14rem;
      }
      .tx4{
        font-size: 0.13rem;
      }
      .tx4_br{
        padding-bottom: 0.16rem;
        border-bottom: 1px solid $BR2;
        margin-bottom: 0.16rem;
      }
      .submit_btn{
        background: $T8;
        text-align: center;
        border-radius: 0.06rem;
        font-weight: bold;
        font-size: 0.16rem;
        color: $FFF;
        line-height: 0.44rem;
        margin-top: 0.32rem;

      }
      .tips_question_box{
        position: absolute;
        background: $T6;
        padding: 0.12rem;
        right: -0.12rem;
        top: 0.22rem;
        border-radius: 0.06rem;
        .title_tx{
          text-align: center;
          font-size: 0.13rem;
          font-weight: bold;
          color: $FFF;
          margin-bottom: 0.1rem;
        }
        .title_msg{
          min-width: 2.2rem;
          color: $FFF;
          font-size: 0.12rem;
        }
        .add_top_arrow{
          position: absolute;
          top: -0.12rem;
          right: 0.12rem;
          border: 0.06rem solid #000;
          border-top-color: transparent;
          border-bottom-color: $T6;
          border-left-color: transparent;
          border-right-color: transparent;
        }
      }
    }
    .loan_list_main{
      padding: 0 0.16rem 0.16rem 0.12rem;
      .list_title{
        margin-bottom: 0.12rem;
        .tx1{
          font-size: 0.15rem;
          color: $T6;
          font-weight: bold;
        }
        .tx2{
          font-size: 0.13rem;
          color: $T8;
        }
        .icon_orange_arrow{
          width: 0.12rem;
          height: 0.12rem;
        }
      }
      .list_box{

        .list_item{
          border-radius: 0.06rem;
          padding: 0.16rem;
          background: $BG4;
          margin-bottom: 0.12rem;
        }
        .coin_box{
          margin-bottom: 0.16rem;
          .icon_green_arrow{
            width: 0.12rem;
            height: 0.12rem;
          }
        }
        .tx1{
          font-size: 0.16rem;
          font-weight: bold;
        }
        .tx2{
          font-size: 0.12rem;
          padding: 0.01rem 0.04rem;
          border-radius: 0.1rem;
          display: inline-block;
          min-width: 0.36rem;
          text-align: center;
        }
        .tx6{
          color: $T5;
          background: $T1;
        }
        .tx5{
          color: $T12;
          background: $T11;
        }
        .tx7{
          color: $T8;
          background: $T10;
        }
        .tx8{
          color: $T14;
          background: $T13;
        }
        .tx9{
          color: $T16;
          background: $T15;
        }
        .tx3{
          font-size: 0.12rem;

        }
        .tx3_br{
          margin-bottom: 0.16rem;
          border-bottom: 1px solid $BR2;
          padding-bottom: 0.12rem;
        }

      }
    }
  }

  .tab_box2{
    .list_box{
      padding: 0.16rem;
      .list_item{
        border-radius: 0.06rem;
        padding: 0.16rem 0.16rem 0.08rem;
        background: $BG4;
        margin-bottom: 0.16rem;
      }
      .coin_box{
        margin-bottom: 0.12rem;
        padding-bottom: 0.16rem;
        border-bottom: 1px solid rgba(133, 128, 122, 0.12);

      }
      .tx1{
        font-size: 0.16rem;
        font-weight: bold;
      }
      .tx2{
        font-size: 0.12rem;
        padding: 0.01rem 0.04rem;
        border-radius: 0.1rem;
        display: inline-block;
        min-width: 0.36rem;
        text-align: center;
      }
      .tx6{
        color: $T5;
        background: $T1;
      }
      .tx5{
        color: $T12;
        background: $T11;
      }
      .tx7{
        color: $T8;
        font-size: 0.14rem;
        font-weight: bold;
      }
      .tx8{
        color: $T14;
        background: $T13;
      }
      .tx9{
        color: $T16;
        background: $T15;
      }
      .tx3{
        font-size: 0.14rem;
        color: $T19;
      }
      .tx4{
        font-size: 0.13rem;
        color: $T18;
      }
      .submit_btn{
        background: $T8;
        padding: 0.1rem 0.15rem;
        font-size: 0.14rem;
        font-weight: bold;
        color: $FFF;
        border-radius: 0.04rem;
        margin-right: -0.08rem;
        position: relative;
      }
    }
  }

}
</style>