<template>
  <article class="h5_loan_order_details_view">
    <p class="contract_tx d_flex"><span>{{ $t('contract_number') }}: {{ $route.query.id }}</span> <i class="h5_icon icon_copy ml_5" @click="copyText($route.query.id)"></i></p>
    <section class="order_coin_info_box">
      <div class="coin_box d_flex_space">
        <div class="l_box d_flex">
          <div class="com_icon_img_box3">
            <img :src="coin_data.icon_url" class="com_coin_img"/>
            <i class="h5_icon com_icon_subscript" :class="coin_data.type == 'tron' ? 'icon_subscript_tron' : coin_data.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
          </div>
          <span class="tx1">{{ coin_data.code }}</span>
          <span class="tx2 ml_5" :class="coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[coin_data.type] }}</span></span>
        </div>
        <div class="tx3" :class="(resData.status == 1 || resData.status == 2 || resData.status == 3 || resData.status == 4 || resData.status == 5 || resData.status == 11)? 'color_T8' : (resData.status == 7 || resData.status == 8 || resData.status == 9 || resData.status == 10  ) ? 'color_T9' : 'color_T7'">
           {{statusTxFn(resData)}}<span v-show="resData.is_advance">({{$t('pepayment')}})</span>
        </div>
      </div>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{ $t('application_time') }}</span>
        <span>{{dataFormat(resData.apply_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{ $t('loan_time') }}</span>
        <span>{{dataFormat(resData.loan_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
      <p class="tx4 color_T6 d_flex_space">
        <span>{{ $t('settlement_time') }}</span>
        <span>{{dataFormat(resData.settle_at, 'yyyy/MM/dd hh:mm:ss')}}</span>
      </p>
    </section>
    <section class="order_info_box">
      <div class="d_flex_space">
        <p class="tx1 font_bold color_T6">{{ $t('Loan_contract_details') }}</p>
        <p class="tx3 d_flex_space color_T8" @click="goRouter('/loan_wallet')" v-show="resData.status != 6 && resData.status != 12 && resData.status != 13">
          <span>{{ $t('loan_coin_wallet') }}</span>
          <i class="h5_icon icon_orange_arrow ml_3"></i>
        </p>
      </div>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('loan_amount') }}</span>
        <span>{{borrow_details.borrow_amount ? borrow_details.borrow_amount : '-'}} {{ coin_data.code }}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('loan_period') }}</span>
        <span>{{borrow_details.borrow_days ? borrow_details.borrow_days : '-'}} {{ $t('day') }}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('borrowing_day_interest_rate') }}</span>
        <span>{{borrow_details.daily_rate ? borrow_details.daily_rate : '-'}}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('investment_channel') }}</span>
        <span>{{borrow_details.channel_name ? borrow_details.channel_name : '-'}}</span>
      </p>
    </section>
    <section class="order_info_box">
      <p class="tx1 font_bold color_T6">{{ $t('payment_details') }}</p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('security_deposit') }}</span>
        <span>{{pay_details.pledge_amount ? pay_details.pledge_amount + coin_data.code : '-' }} </span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('create_coin_wallet_miner_fee') }}</span>
        <span>{{pay_details.miner_fee ? pay_details.miner_fee : '-'}}</span>
      </p>
      <div class="tx2 color_T6 d_flex_space">
        <span>{{ $t('payment_hash') }}</span>
        <p class="d_flex_space" @click="openLinkFn(pay_details.hash)">
          <i class="h5_icon  " :class="pay_details.status == 2 ? 'icon_round_success' : pay_details.status == 3 ? 'icon_round_error' : 'icon_round_pending'" v-show="pay_details.hash"></i>
          <span class="ml_5 color_T5">{{pay_details.hash ? substr(pay_details.hash, 0, 8) +'...'+ substr(pay_details.hash, -8) : '-'}}</span>
          <i v-show="pay_details.hash" class="h5_icon icon_green_arrow ml_5"></i>
        </p>
      </div>
    </section>
    <section class="order_info_box">
      <p class="tx1 font_bold color_T6">{{ $t('loaning_details') }}</p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('fundraisings_progress') }}</span>
        <span>{{loan_details.loan_progress ? loan_details.loan_progress : '-'}}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('fundraising_amount') }}</span>
        <span>{{loan_details.loan_amount ? loan_details.loan_amount + coin_data.code : '-'}}/{{loan_details.borrow_amount ? loan_details.borrow_amount + coin_data.code : '-'}}</span>
      </p>

    </section>
    <section class="order_info_box">
      <div class="d_flex_space">
        <p class="tx1 font_bold color_T6">{{ $t('repayment_details') }}</p>
        <p class="tx3 d_flex_space color_T8" v-show="repayment_details.repayment_status == 2" @click="goRouter('/repayment_confirm', {id: resData.contract_number})">
          <span>{{$t('status_view.status9')}}</span>
          <i class="h5_icon icon_orange_arrow ml_3"></i>
        </p>
        <p class="tx3 d_flex_space color_T8" v-show="repayment_details.repayment_status == 3" >
          <span>{{$t('status_view.status10')}}</span>
        </p>
      </div>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('interest_period') }}</span>
        <span>{{repayment_details.each_interest ? repayment_details.each_interest + coin_data.code  : '-'}} </span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('repayment_period') }}</span>
        <span>{{repayment_details.surplus_days ? repayment_details.surplus_days + $t('day') : '-'}}/{{repayment_details.revert_days ? repayment_details.revert_days + $t('day') : '-'}}</span>
      </p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('interest_paid') }}</span>
        <span>{{repayment_details.revert_interest ? repayment_details.revert_interest + coin_data.code : '-'}} </span>
      </p>

    </section>
    <section class="order_info_box">
      <p class="tx1 font_bold color_T6">{{ $t('billing_details') }}</p>
      <p class="tx2 color_T6 d_flex_space">
        <span>{{ $t('redemption_margin_and_yield') }}</span>
        <span>{{success_details.bond_amount ? success_details.bond_amount + coin_data.code  : '-'}} </span>
      </p>
      <div class="tx2 color_T6 d_flex_space">
        <span>{{ $t('redemption_hash') }}</span>
      <p class="d_flex_space" @click="openLinkFn(success_details.redeem_hash)">
        <i class="h5_icon  " :class="success_details.redeem_status == 3 ? 'icon_round_success' : success_details.redeem_status == 4 ? 'icon_round_error' : 'icon_round_pending'" v-show="success_details.redeem_hash"></i>
        <span class="ml_5 color_T5">{{success_details.redeem_hash ? substr(success_details.redeem_hash, 0, 8) +'...'+ substr(success_details.redeem_hash, -8) : '-'}}</span>
        <i v-show="success_details.redeem_hash" class="h5_icon icon_green_arrow ml_5"></i>
      </p>
      </div>

    </section>
  </article>
</template>

<script>
export default {
  name: "loan_order_details",
  data () {
    return {
      resData: {},
      pay_details: {},
      borrow_details: {},
      repayment_details: {},
      loan_details: {},
      success_details: {},
      coin_data: {},
    }
  },
  created () {
    this.resInit()
  },
  methods: {
    openLinkFn (hash) {
      let url = ''
      if (!hash) {
        return false
      }
      if (this.coin_data.type == 'bsc') {
        url = 'https://bscscan.com/tx/' + hash
      } else if (this.coin_data.type == 'eth') {
        url = 'https://etherscan.io/tx/' + hash
      } else {
        url = 'https://tronscan.org/#/transaction/' + hash
      }
      this.goLinkFn(url, {}, true)
    },

    statusTxFn (obj) {
      if (obj.status == 1) {
        return this.$t('status_view.status1')
      }
      if (obj.status == 2) {
        return this.$t('status_view.status2')
      }
      if (obj.status == 3) {
        return this.$t('status_view.status2')
      }
      if (obj.status == 4) {
        return this.$t('status_view.status3')
      }
      if (obj.status == 5) {
        return this.$t('status_view.status3')
      }
      if (obj.status == 6) {
        return this.$t('status_view.status4')
      }
      if (obj.status == 7) {
        return this.$t('status_view.status5')
      }
      if (obj.status == 8) {
        return this.$t('status_view.status6')
      }
      if (obj.status == 9) {
        return this.$t('status_view.status6')
      }
      if (obj.status == 10) {
        return this.$t('status_view.status7')
      }
      if (obj.status == 11) {
        return this.$t('status_view.status3')
      }
      if (obj.status == 12) {
        return this.$t('status_view.status8')
      }
      if (obj.status == 13) {
        return this.$t('status_view.status8')
      }
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/my_borrow_order_details`, {
        id: this.$route.query.id
      }, 'get')
          .then(res => {
            this.resData = res ? res : {}
            this.pay_details = res.pay_details ? res.pay_details : {}
            this.borrow_details = res.borrow_details ? res.borrow_details : {}
            this.repayment_details = res.repayment_details ? res.repayment_details : {}
            this.loan_details = res.loan_details ? res.loan_details : {}
            this.success_details = res.success_details ? res.success_details : {}
            this.coin_data = res.coin_data ? res.coin_data : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_loan_order_details_view{
  padding-top: 0.2rem;
  .contract_tx{
    padding: 0 0.16rem;
    color: $T7;
    font-size: 0.12rem;
    margin-bottom: 0.12rem;
  }
  .order_coin_info_box{
    margin: 0rem 0.16rem 0.24rem;
    background: $BG2;
    padding: 0.16rem;
    border-radius: 0.06rem;
    .coin_box{
      margin-bottom: 0.12rem;
      border-bottom: 1px solid $BR2;
      padding-bottom: 0.16rem;
    }
    .tx1{
      font-size: 0.16rem;
      font-weight: bold;
    }
    .tx2{
      font-size: 0.12rem;
      padding: 0.01rem 0.04rem;
      border-radius: 0.1rem;
      display: inline-block;
      min-width: 0.36rem;
      text-align: center;
    }
    .tx6{
      color: $T5;
      background: $T1;
    }
    .tx5{
      color: $T12;
      background: $T11;
    }
    .tx7{
      color: $T8;
      background: $T10;
    }
    .tx8{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
    .tx4:last-child{
      margin-bottom: 0;
    }
    .tx4{
      margin-bottom: 0.12rem;
      font-size: 0.12rem;
    }
    .tx3{
      font-size: 0.13rem;
      font-weight: bold;
    }
  }
  .order_info_box{
    padding: 0.2rem 0.16rem;
    border-top: 1px solid $BR2;
    .tx1{
      font-size: 0.14rem;
      margin-bottom: 0.16rem;
    }
    .tx2:last-child{
      margin-bottom: 0;
    }
    .tx2{
      margin-bottom: 0.16rem;
      font-size: 0.13rem;
    }
    .tx3{
      margin-bottom: 0.16rem;
      font-size: 0.13rem;
    }

    .icon_green_arrow{
      width: 0.12rem;
      height: 0.12rem;
    }
  }
}
</style>