import { createApp, h, resolveComponent } from 'vue'
import { createStore } from 'vuex'
import "./style/style.scss"
import "./style/icon.scss"
import utils from './utils'
import socket from './utils/socket'
import axios from 'axios'
import qs from 'qs'
import './rem/rem.js'
import VueI18n from './i18n'
import Web3 from 'web3'

import {ElInfiniteScroll,ElMessage, ElPopover, ElCarousel, ElCarouselItem, ElLoading, ElImage, ElUpload, ElSelect, ElOption, ElDrawer, ElDialog, ElButton} from 'element-plus';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import router from './router'
import WalletConnectProvider from "@walletconnect/web3-provider"
import BigNumber from 'bignumber.js'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css';

window.windowBigNumber = BigNumber
NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
window.oris = window.location.origin
window.Wallet_Uuid = ''

import bnb_abi from './assets/abi/bnb_abi.json'
import trx_abi from './assets/abi/trx_abi.json'
import bsc_pofit_coin_abi from './assets/abi/pofit_coin_abi.json'
import trx_pofit_coin_abi from './assets/abi/trx_pofit_coin_abi.json'
import bsc_loan_coin_abi from './assets/abi/loan_coin_abi.json'
import trx_loan_coin_abi from './assets/abi/trx_loan_coin_abi.json'
// window.bnbUSDTContract = '0x55d398326f99059ff775485246999027b3197955' // prod
window.bnbUSDTContract = '0x4Ad30990eA0A97A1f4EE67C49f98E6cb2E9cEEC9'
window.ethUSDTContract = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
window.trxUSDTContract = 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
window.feeLimit = 1000000000;

window.envDev = !(process.env.NODE_ENV == 'production')
window.walletConnectProvider = {}
if (window.envDev) {
    window.bnbUSDTContract = '0x4Ad30990eA0A97A1f4EE67C49f98E6cb2E9cEEC9'
    window.ethUSDTContract = '0x17046e026364527BF5BD9626Ee6B1eC0fdD36C56'
    window.trxUSDTContract = 'TFndb2GNEXb6czjJN6dwEUX74G9PSS2eiz'
    window.feeLimit = 1000000000;
}
const plugins = [
    ElInfiniteScroll,
    ElPopover,
    ElCarousel,
    ElCarouselItem,
    ElMessage,
    ElLoading,
    ElImage,
    ElUpload,
    ElSelect,
    ElOption,
    ElDrawer,
    ElDialog,
    ElButton
]

const app = createApp({
   created () {

   },
    mounted () {
        /*window.onpageshow = function(event) {
            if (event.persisted) {

                window.location.reload()
            }
        };
        let SERVER_TIME = '${((SERVER_TIME!0)?long)?c}';

        if(SERVER_TIME && SERVER_TIME != '0'){
            let LOCAL_TIME = sessionStorage && sessionStorage['LOCAL_TIME' + '${pageName!}'];

            if (LOCAL_TIME && parseInt(LOCAL_TIME) >= parseInt(SERVER_TIME)) {
                window.location.reload();
            }else{
                sessionStorage['LOCAL_TIME' + '${pageName!}'] = SERVER_TIME;
            }
        }*/


       this.walletChange()
        // let exchangeSocket = new socket(`${this.Utils.URL.wsURL}currency_exchange_list_wSocket`)
        // exchangeSocket.doOpen()

        // exchangeSocket.on('open', (data) => {
        //     exchangeSocket.send({currency: 'USD'})
        // })
        // exchangeSocket.on('message', (res) => {
        //     if (res.code == 0) {
        //         this.$store.commit('setCurrencyList', res.data)
        //     }
        // })


    },
    methods: {
        async walletChange () {
            if (window.ethereum) {
                let self = this
                window.ethereum.on('accountsChanged', async function (accounts) {
                    // Time to reload your interface with accounts[0]!
                    try {
                        await self.selectChain()
                        self.eachWallet()

                    } catch (e) {
                        // console.log(e)
                    }



                });
            }
        }

},

    render() {
        return h(resolveComponent('router-view'))
    }

})
const store = createStore({
    state () {
        return {
            address: window.sessionStorage.getItem('storeAddress') ? window.sessionStorage.getItem('storeAddress') : '',
            addressId: window.sessionStorage.getItem('storeWalletId') ? window.sessionStorage.getItem('storeWalletId') : '',
            singAuth: window.sessionStorage.getItem('storeSingAuth') ? window.sessionStorage.getItem('storeSingAuth') : '',
            currencyList: [],
        }
    },
    mutations: {
        setAddress (state, addr) {
            state.address = addr
        },
        setAddressId (state, addressId) {
            state.addressId = addressId
        },
        setSingAuth (state, singAuth) {
            state.singAuth = singAuth
        },
        setCurrencyList (state, currencyList) {
            state.currencyList = currencyList
        }
    }
})
app.config.globalProperties.NProgress = NProgress
app.config.globalProperties.$axios = axios
app.config.globalProperties.Utils = utils
app.mixin({
    data() {
        return {
            routeLang: false,
            isShowRoot: false,
            storeLang: 'cn',
            chainType: '',
            ajaxAxiosArray: [],
            storeAddress: '',
            storeWalletId: '',
            storeChainType: '',
            storeCoinChainType: '',
            errorTX: '',
            envDev: window.envDev,
            isShowWallet: false,
            langObj: {
                'en': { label: 'English', value: 'en' },
                'es': { label: 'España', value: 'es' },
                'fr': { label: 'Français', value: 'fr' },
                'ko': { label: '한국인', value: 'ko' },
                'ja': { label: '日語', value: 'ja' },
                'vi': { label: 'Việt Nam', value: 'vi' },
                'ms': { label: 'Malaysia', value: 'ms' },
                'cn': { label: '中文简体', value: 'cn' },
                'zh': { label: '中文繁體', value: 'zh' },
            },
            coinChainType: {
                'tron': 'TRC20',
                'eth': 'ERC20',
                'bsc': 'BEP20',
                'mainnet': 'Main'
            },
            coinMian: {
                'BSC': 'BNB',
                'TRON': 'TRX',
                'ETH': 'ETH',
            },
            exchangePriceDefaulDecimal: 8,
        }
    },
    async created() {

        let storeLangId = window.localStorage.getItem('storeLangId') ? window.localStorage.getItem('storeLangId') : 'en'
        this.storeAddress = window.sessionStorage.getItem('storeAddress') ? window.sessionStorage.getItem('storeAddress') : ''
        this.storeWalletId = window.sessionStorage.getItem('storeWalletId') ? window.sessionStorage.getItem('storeWalletId') : ''
        this.storeChainType = window.sessionStorage.getItem('storeChainType') ? window.sessionStorage.getItem('storeChainType') : ''
        this.storeCoinChainType = window.sessionStorage.getItem('storeCoinChainType') ? window.sessionStorage.getItem('storeCoinChainType') : ''
        window.Wallet_Uuid = this.storeWalletId
        this.$i18n.locale = storeLangId
        this.storeLang = storeLangId
        this.NProgress.done()

        

    },
     async mounted () {

        this.isShowRoot = true
        if (window.ethereum) {
          window.ethereum.on('accountsChanged', this.handleAccountsChanged);
          window.ethereum.on('chainChanged', this.handleChainChanged);
          

        }
    },
    computed: {
        storeChangeAddress() {
            return this.$store.state.address
        },
        storeChangeAddressId() {
            return this.$store.state.addressId
        },
        storeChangeCurrencyList() {
            return this.$store.state.currencyList
        },
        storeSingAuth() {
            return this.$store.state.singAuth
        }
    },
    methods: {
      handleChainChanged(chainId) {
        const networkDecimal = parseInt(chainId, 16)
        if(this.envDev) {
          switch(networkDecimal) {
            case 97:
              this.storeChainType = 'BSC'
              this.storeCoinChainType = 'BEP'
              window.sessionStorage.setItem('storeChainType', 'BSC')
              window.sessionStorage.setItem('storeCoinChainType', 'BEP')
            break
            default: 
              this.storeChainType = ''
              this.storeCoinChainType = ''
              window.sessionStorage.setItem('storeChainType', '')
              window.sessionStorage.setItem('storeCoinChainType', '')
              break
          }
        } else {
          switch(networkDecimal) {
            case 97:
              // case 56:
              this.storeChainType = 'BSC'
              this.storeCoinChainType = 'BEP'
            break
            default: 
              this.storeChainType = ''
              this.storeCoinChainType = ''
              break
          }
        }
  
      },
      handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
          window.sessionStorage.setItem('storeAddress', '')
          window.sessionStorage.setItem('storeChainType', '')
          window.sessionStorage.setItem('storeCoinChainType', '')

          setTimeout(() => {
            window.location.reload()
          }, 500)
        }
      },
        eachWallet () {

            let req = {
                addr: this.storeAddress,
                chain_type: this.storeChainType.toLocaleLowerCase(),

            };
            let url = `${this.Utils.URL.goAPPURL}dapp/wallet_id`
            this.$ajax(url, req, "get", 25000, false, false)
                .then((res) => {
                    if (!res) {
                        this.isShowWallet = true
                        this.storeWalletId = ''
                        window.sessionStorage.setItem('storeWalletId', '')
                        this.$store.commit('setAddressId', 0)
                        window.Wallet_Uuid = ''
                    } else {
                        this.isShowWallet = false
                        this.storeWalletId = res
                        window.sessionStorage.setItem('storeWalletId', this.storeWalletId)
                        this.$store.commit('setAddressId', res)
                        window.Wallet_Uuid = res

                    }
                })
                .catch((res) => {
                    window.sessionStorage.setItem('storeWalletId', '')
                    this.$store.commit('setAddressId', 0)
                    this.messageFn(res.msg)
                });
        },
        async selectChain () {
            let promise
            try {

                if (window.tronWeb) {

                    this.storeChainType = 'TRON'
                    this.storeCoinChainType = 'TRC'
                    this.storeAddress = await window.tronWeb.defaultAddress.base58


                    if (this.storeAddress) {
                        // tokenpocket wallet
                        window.sessionStorage.setItem('storeAddress', this.storeAddress)
                        window.sessionStorage.setItem('storeCoinChainType', this.storeCoinChainType)
                        window.sessionStorage.setItem('storeChainType', this.storeChainType)
                        this.$store.commit('setAddress', this.storeAddress)
                        promise = new Promise((resolve, reject) => {

                            if (this.storeAddress) {
                                resolve(this.storeAddress)
                            } else {
                                reject({ msg: this.$t('tips_view.msg2') })
                            }
                        })

                        return promise
                    }
                }

                if (window.ethereum) {


                    await this.ethConnect()



                    let chainId = await window.ethereum.request({method: 'eth_chainId'})
                    const chainIdDecimal = parseInt(chainId, 16);
                    if (this.envDev) {

                      switch(chainIdDecimal) {
                        case 3:
                        case 4:
                        case 5:
                          this.storeChainType = 'ETH'
                          this.storeCoinChainType = 'ETH'
                          break
                        case 97:
                          this.storeChainType = 'BSC'
                          this.storeCoinChainType = 'BEP'
                          break;
                        default:
                          this.storeChainType = ''
                          this.storeCoinChainType = ''
                          break;
                      }
                        

                    } else {
                      switch(chainIdDecimal) {
                        case 1:
                          this.storeChainType = 'ETH'
                          this.storeCoinChainType = 'ETH'
                          break
                        case 97:
                          // case 56: prod
                          this.storeChainType = 'BSC'
                          this.storeCoinChainType = 'BEP'
                          break;
                        default:
                          this.storeChainType = ''
                          this.storeCoinChainType = ''
                          break;
                      }
                    }
                    window.sessionStorage.setItem('storeAddress', this.storeAddress)
                    window.sessionStorage.setItem('storeCoinChainType', this.storeCoinChainType)
                    window.sessionStorage.setItem('storeChainType', this.storeChainType)
                    this.$store.commit('setAddress', this.storeAddress)
                }


            } catch (e) {

                this.storeAddress = ''
                this.storeCoinChainType = ''
                this.storeChainType = ''
                window.sessionStorage.setItem('storeAddress', this.storeAddress)
                window.sessionStorage.setItem('storeCoinChainType', this.storeCoinChainType)
                window.sessionStorage.setItem('storeChainType', this.storeChainType)
                this.$store.commit('setAddress', this.storeAddress)
            }



            promise = new Promise((resolve, reject) => {

                if (this.storeAddress) {
                    resolve(this.storeAddress)
                } else {
                    reject({ msg: this.$t('tips_view.msg2') })
                }
            })

            return promise



        },
        async walletConnectFn () {
           /* window.localStorage.removeItem('walletconnect')

            window.walletConnectProvider = new WalletConnectProvider({
                infuraId: "c7aee69e9f5f7a9d0ce22f86d2fde6e6",
                rpc: {
                    97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
                    56: 'https://bsc-dataseed.binance.org/'
                },
                'chainId': this.envDev ? 97 : 56,
                'networkId': this.envDev ? 97 : 56,
                qrcode: true
            })
            await window.walletConnectProvider.enable()
            window.web3 = new Web3(window.walletConnectProvider)
            try {
                let account = await window.web3.eth.getAccounts()
                this.$store.commit('setAddress', account[0])
                this.storeAddress = account[0]
                let self = this
                window.walletConnectProvider.on("disconnect", (code, reason) => {
                    this.storeAddress = ''
                    self.$store.commit('setAddress', '')
                    window.sessionStorage.setItem('storeAddress', '')
                    window.localStorage.removeItem('walletconnect')
                })
                this.storeChainType = 'BSC'
                this.storeCoinChainType = 'BEP'
                window.sessionStorage.setItem('storeAddress', this.storeAddress)
                window.sessionStorage.setItem('storeCoinChainType', this.storeCoinChainType)
                window.sessionStorage.setItem('storeChainType', this.storeChainType)
                this.eachWallet()
            } catch (e) {
                this.messageFn(this.$t('tips_view.msg2'))
            }*/

        },

        async ethConnect () {
            // Modern dapp browsers...

            let account = ''
            try {
                if (window.ethereum) {

                    /*if (!window.sessionStorage.getItem('windowEthereum')) {
                        window.sessionStorage.setItem('windowEthereum', JSON.stringify(window.ethereum))
                    } else {
                        alert(window.sessionStorage.getItem('windowEthereum'))
                        window.ethereum = JSON.parse(window.sessionStorage.getItem('windowEthereum'))

                    }*/
                    window.web3 = new Web3(window.ethereum)

                    /*if (!window.sessionStorage.getItem('windowweb3')) {
                        window.web3 = new Web3(window.ethereum)
                        window.sessionStorage.setItem('windowweb3', JSON.stringify(window.web3))
                    } else {
                        window.web3 = JSON.parse(window.sessionStorage.getItem('windowweb3'))
                    }*/

                    try {
                        // Request account access if needed

                        await window.ethereum.request({ method: 'eth_requestAccounts' })

                        // Acccounts now exposed

                        account = await window.ethereum.request({ method: 'eth_accounts' })

                        this.$store.commit('setAddress', account[0])

                        this.storeAddress = account[0]

                        // let result1 = await  (window.web3.eth.sign(window.web3.utils.sha3('hello imToken!'),account[0])) 不安全
                        // let result = await window.web3.eth.personal.sign("hello imToken!", account[0])


                        // this.messageFn(result)
                    } catch (error) {
                        this.errorTX = error

                        // User denied account access...
                    }
                }
                /*// Legacy dapp browsers...
                else if (window.web3) {

                    window.web3 = new Web3(window.web3.currentProvider);
                    // Acccounts always exposed
                    account = await window.web3.eth.getAccounts()

                }*/
            } catch (e) {

                this.errorTX = e
            }


        },
        async transactionCoinFn (amount, toAddress) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let Tx = ''
            let result = ''
            let usdtContract = ''
            let decimals = ''
            let value
            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                await this.ethConnect()

                decimals = 18
                try {

                    value = new BigNumber(amount).multipliedBy(10 ** decimals).toString(10)

                    result = await window.web3.eth.sendTransaction({
                        from:this.storeAddress,
                        to: toAddress,
                        value: value
                    })
                    /*if (result.transactionHash) {
                        result = result.transactionHash
                    }*/
                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {
                decimals = 6
                try {

                    value = new BigNumber(amount).multipliedBy(10 ** decimals).toString(10)

                    result = await window.tronWeb.trx.sendTransaction(toAddress, value)

                } catch (e) {
                    this.errorTX = e
                    result = ''
                }
            }
            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async transferUSDTFn (amount, toAddress, tokenAddr) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let result = ''

            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                await this.ethConnect()
                if (!tokenAddr) {
                    tokenAddr = this.storeChainType == 'BSC' ? window.bnbUSDTContract : window.ethUSDTContract
                }
                try {
                    contract = new window.web3.eth.Contract(bnb_abi, tokenAddr)
                    let decimals = await contract.methods.decimals().call()
                    amount = new BigNumber(amount).multipliedBy(10 ** decimals).toString(10)
                    /*if (value != 0) {
                        value = new BigNumber(value).multipliedBy(10 ** 18).toString(10)

                    }*/
                    result = await contract.methods.transfer(toAddress, amount).send({from:this.storeAddress})
                    if (result.transactionHash) {
                        result = result.transactionHash
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {
                if (!tokenAddr) {
                    tokenAddr = window.trxUSDTContract
                }

                try {

                    contract = await window.tronWeb.contract(trx_abi, tokenAddr)
                    let decimals = await contract.methods.decimals().call()
                    amount = new BigNumber(amount).multipliedBy(10 ** decimals).toString(10)

                    result = await contract.methods.transfer(toAddress, amount).send({from:this.storeAddress})


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async getUSDTBalanceFn (chainType, abiContract, address) {
            if (this.storeChainType != 'BSC' && this.storeChainType != 'ETH' && this.storeChainType != 'TRON') {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            let balance = ''

            if (!chainType) {
                chainType = this.storeChainType
            }
            if (!address) {
                address = this.storeAddress
            }
            let url = chainType == 'BSC' ? 'bsc/balance' : chainType == 'ETH' ? 'eth/balance' : 'tron/balance'
            if (!abiContract) {
                abiContract = chainType == 'BSC' ? window.bnbUSDTContract : chainType == 'ETH' ? window.ethUSDTContract : window.trxUSDTContract
            }
            try {
                balance =  await this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
                    addr: address,
                    contract_addr: abiContract
                }, "post")
                balance = balance.quantity ? balance.quantity : '0'
            } catch (e) {
                balance = ''
            }


            /*
            let contract = ''
            if (chainType == 'BSC') {


                try {
                    contract = new window.web3.eth.Contract(bnb_abi, abiContract)
                    let decimals = await contract.methods.decimals().call()
                    await contract.methods.balanceOf(address).call({from: abiContract}, (err, res)=>{
                         if (!err) {
                             balance = new BigNumber(res).div(10 ** decimals)
                         } else {
                             balance = ''
                         }

                     })

                    // await contract.methods.transfer("0xA78D4ac0CbFB805aa1416baA6dc4cdC282E9151C", window.web3.utils.toWei('0.0001')).send({from:address}).then().catch()
                    // balance = await window.web3.eth.getBalance(this.storeAddress)
                    // balance = window.web3.utils.fromWei(balance, 'ether')
                } catch (e) {
                      balance = ''
                }

            }
            if (chainType == 'TRON') {


                try {
                    contract = await window.tronWeb.contract(trx_abi, abiContract)
                    balance = await contract.balanceOf(address).call()
                    balance = new BigNumber(balance).div(1000000)
                } catch (e) {
                    balance = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }*/

            const promise = new Promise((resolve, reject) => {
                if (balance !== '') {
                    resolve(balance)
                } else {
                    reject({ msg: this.$t('tips_view.msg13') })
                }
            })

            return promise
        },
        async approveUSDTBalanceFn (toAddress, amount, address, chainType, abiContract) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }

            let contract = ''
            let Tx = ''
            let result = ''
            let max256 =  (new BigNumber(2).exponentiatedBy(256)).minus(1)
            if (!amount) {
                amount = max256
            }

            if (!chainType) {
                chainType = this.storeChainType
            }
            if (!address) {
                address = this.storeAddress
            }
            if (chainType == 'BSC' || chainType == 'ETH') {
                await this.ethConnect()
                if (!abiContract) {
                    abiContract = chainType == 'BSC' ? window.bnbUSDTContract : window.ethUSDTContract
                }
                try {
                    contract = new window.web3.eth.Contract(bnb_abi, abiContract)
                    let data = await contract.methods.allowance(address, toAddress).call()

                    if (BigNumber.minimum(max256, data) == data) {

                        result = await contract.methods.approve(toAddress, max256.toString(10)).send({from:address})

                        if (result.transactionHash) {
                            result = result.transactionHash
                        }
                    } else {

                        result = true
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (chainType == 'TRON') {
                if (!abiContract) {
                    abiContract = window.trxUSDTContract
                }

                try {

                    contract = await window.tronWeb.contract().at(abiContract);

                    let data = await contract.allowance(address, toAddress).call()

                    if (data.remaining) {
                        data = data.remaining._hex
                    } else if(data._hex) {
                        data = data._hex
                    } else {
                        data = 0
                    }



                    if (!new BigNumber(max256).isEqualTo(new BigNumber(data))) {
                        result = await contract.approve(
                            toAddress, //address _spender
                            window.tronWeb.BigNumber(max256.toString()).toString(10) //amount
                        ).send({from:address}).catch(res => {

                            result = ''
                        });
                    } else {
                        result = true
                    }





                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async loanTokenCoinFn (abiContract, pledgeAddr, pledgeAmount, owners, required, borrowInfo_tuple, payload,  tokenAddr) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let Tx = ''
            let result = ''
            let usdtContract = ''

            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                await this.ethConnect()
                if (!tokenAddr) {
                    tokenAddr = this.storeChainType == 'BSC' ? window.bnbUSDTContract : window.ethUSDTContract
                }
                try {
                    contract = new window.web3.eth.Contract(bsc_loan_coin_abi, abiContract)
                    usdtContract = new window.web3.eth.Contract(bnb_abi, tokenAddr)
                    let decimals = await usdtContract.methods.decimals().call()
                    pledgeAmount = new BigNumber(pledgeAmount).multipliedBy(10 ** decimals).toString(10)
                    borrowInfo_tuple[1] = new BigNumber(borrowInfo_tuple[1]).multipliedBy(10 ** decimals).toString(10)
                    /*if (value != 0) {
                        value = new BigNumber(value).multipliedBy(10 ** 18).toString(10)

                    }*/
                    result = await contract.methods.borrowPledge(tokenAddr, pledgeAddr, pledgeAmount, owners, required, borrowInfo_tuple, payload).send({from:this.storeAddress})
                    if (result.transactionHash) {
                        result = result.transactionHash
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {
                if (!tokenAddr) {
                    tokenAddr = window.trxUSDTContract
                }

                try {


                    contract = await window.tronWeb.contract(trx_loan_coin_abi, abiContract);
                    usdtContract = await window.tronWeb.contract(trx_abi, tokenAddr)
                    let decimals = await usdtContract.methods.decimals().call()
                    pledgeAmount = new BigNumber(pledgeAmount).multipliedBy(10 ** decimals).toString(10)
                    borrowInfo_tuple[1] = new BigNumber(borrowInfo_tuple[1]).multipliedBy(10 ** decimals).toString(10)
                    // borrowInfo_tuple[1] = borrowInfo_tuple[1] * Math.pow(10,decimals)

                    result = await contract.methods.borrowPledge(tokenAddr, pledgeAddr, pledgeAmount, owners, required, borrowInfo_tuple, payload).send({from:this.storeAddress, feeLimit: window.feeLimit})


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async loanMainCoinFn (abiContract, pledgeAddr, pledgeAmount, owners, required, borrowInfo_tuple, payload) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let Tx = ''
            let result = ''
            let usdtContract = ''
            let decimals = ''
            let value
            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                await this.ethConnect()
                decimals = 18

                try {
                    contract = new window.web3.eth.Contract(bsc_loan_coin_abi, abiContract)
                    borrowInfo_tuple[1] = new BigNumber(borrowInfo_tuple[1]).multipliedBy(10 ** decimals).toString(10)
                    value = new BigNumber(pledgeAmount).multipliedBy(10 ** decimals).toString(10)
                    /*if (value != 0) {
                        value = new BigNumber(value).multipliedBy(10 ** 18).toString(10)

                    }*/
                    result = await contract.methods.borrowPledgeETH(pledgeAddr, owners, required, borrowInfo_tuple, payload).send({from:this.storeAddress, value: value})
                    if (result.transactionHash) {
                        result = result.transactionHash
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {
                decimals = 6

                try {


                    contract = await window.tronWeb.contract(trx_loan_coin_abi, abiContract);
                    value = new BigNumber(pledgeAmount).multipliedBy(10 ** decimals).toString(10)
                    borrowInfo_tuple[1] = new BigNumber(borrowInfo_tuple[1]).multipliedBy(10 ** decimals).toString(10)
                    // borrowInfo_tuple[1] = borrowInfo_tuple[1] * Math.pow(10,decimals)

                    result = await contract.methods.borrowPledgeETH(pledgeAddr, owners, required, borrowInfo_tuple, payload).send({from:this.storeAddress, callValue: value, feeLimit: window.feeLimit})


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async redemptionTokenCoinFn (abiContract, address, amount,  tokenAddr) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let Tx = ''
            let result = ''
            let usdtContract = ''

            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                let decimals = 18
                await this.ethConnect()
                if (tokenAddr) {
                    usdtContract = new window.web3.eth.Contract(bnb_abi, tokenAddr)
                    decimals = await usdtContract.methods.decimals().call()
                }
                try {
                    contract = new window.web3.eth.Contract(bsc_pofit_coin_abi, abiContract)

                    amount = new BigNumber(amount).multipliedBy(10 ** decimals).toString(10)
                    /*if (value != 0) {
                        value = new BigNumber(value).multipliedBy(10 ** 18).toString(10)

                    }*/
                    console.log(amount)
                    result = await contract.methods.redemption(address, amount).send({from:this.storeAddress})
                    if (result.transactionHash) {
                        result = result.transactionHash
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {
                let decimals = 6
                if (tokenAddr) {
                    usdtContract = await window.tronWeb.contract(trx_abi, tokenAddr)
                    decimals = await usdtContract.methods.decimals().call()
                }

                try {

                    contract = await window.tronWeb.contract(trx_pofit_coin_abi, abiContract);
                    amount = new BigNumber(amount).multipliedBy(10 ** decimals).toString(10)

                    result = await contract.methods.redemption(address, amount).send({from:this.storeAddress, feeLimit: window.feeLimit})


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async withdrawalIncomeTokenCoinFn (abiContract,  amount) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let Tx = ''
            let result = ''
            let usdtContract = ''
            amount = []
            amount.push(new BigNumber('18446744073709551615').toString(10))

            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                await this.ethConnect()

                try {
                    contract = new window.web3.eth.Contract(bsc_pofit_coin_abi, abiContract)

                    /*if (value != 0) {
                        value = new BigNumber(value).multipliedBy(10 ** 18).toString(10)

                    }*/
                    result = await contract.methods.withdrawalIncome(amount).send({from:this.storeAddress})
                    if (result.transactionHash) {
                        result = result.transactionHash
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {

                try {

                    contract = await window.tronWeb.contract(trx_pofit_coin_abi, abiContract);
                    result = await contract.methods.withdrawalIncome(amount).send({from:this.storeAddress, feeLimit: window.feeLimit})


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },
        async cancelReinvestTokenCoinFn (abiContract,  str) {
            if (!this.storeChainType) {
                this.messageFn(this.$t('tips_view.msg3'))
                return ''
            }
            if (!this.storeChangeAddressId) {
                this.messageFn(this.$t('tips_view.msg17'))
                return ''
            }
            let contract = ''
            let Tx = ''
            let result = ''
            let usdtContract = ''


            if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                await this.ethConnect()

                try {
                    contract = new window.web3.eth.Contract(bsc_pofit_coin_abi, abiContract)

                    /*if (value != 0) {
                        value = new BigNumber(value).multipliedBy(10 ** 18).toString(10)

                    }*/
                    result = await contract.methods.cancelReinvest(str).send({from:this.storeAddress})
                    if (result.transactionHash) {
                        result = result.transactionHash
                    }


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

            }
            if (this.storeChainType == 'TRON') {

                try {
                    contract = await window.tronWeb.contract(trx_pofit_coin_abi, abiContract);
                    result = await contract.methods.cancelReinvest(str).send({from:this.storeAddress, feeLimit: window.feeLimit})


                } catch (e) {
                    this.errorTX = e
                    result = ''
                }

                // sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
            }

            const promise = new Promise((resolve, reject) => {
                if (result !== '') {

                    resolve(result)
                } else {
                    reject({ msg: this.$t('tips_view.msg12') })
                }
            })

            return promise
        },

        async  dappSingFn (tx) {
            let sing = ''
            try {
                if (!this.storeChainType) {
                    this.messageFn(this.$t('tips_view.msg3'))
                    return false
                }

                if (this.storeChainType == 'BSC' || this.storeChainType == 'ETH') {
                    await this.ethConnect()
                    sing = await window.web3.eth.personal.sign(window.web3.utils.utf8ToHex(tx), this.storeAddress)
                }
                if (this.storeChainType == 'TRON') {
                    sing = await window.tronWeb.trx.sign(window.tronWeb.toHex(tx))
                }
                return sing
            } catch (e) {

                this.errorTX = e
                return  sing
            }

        },
        messageFn (tx) {
            this.$message({
                'custom-class': 'com_ajax_error',
                'message': tx,
                'type': 'info',
            })
        },
        scientificNotationToString(param) {
            if (!param) {
                return '0'
            }
            let strParam = String(param)
            let flag = /e/.test(strParam)
            if (!flag) return param

            //  true: +，false: -
            let sysbol = true
            if (/e-/.test(strParam)) {
                sysbol = false
            }
            //
            let index = Number(strParam.match(/\d+$/)[0])
            //
            let basis = strParam.match(/^[\d\.]+/)[0].replace(/\./, '')

            if (sysbol) {
                return basis.padEnd(index + 1, 0)
            } else {
                return basis.padStart(index + basis.length, 0).replace(/^0/, '0.')
            }
        },
        padRight(val, lens) {
            if (lens == 0) {
                return parseInt(val)
            }

            if (lens == 2) {
                if (parseFloat(val) > 0.0001) {
                    lens = parseFloat(val) < 0.01 ? 4 : 2
                }
            }

            let str = val ? val : 0
            str = this.scientificNotationToString(str)
            str = (str ? (str + '') : '0').split('.')
            let lena = parseInt(lens ? lens : this.exchangePriceDefaulDecimal)
            let len = parseInt(lens ? lens : this.exchangePriceDefaulDecimal)
            if (str.length > 1) {
                len = str[1].length >= len ? len : len - str[1].length
                if (len < 0) {
                    return str[0] + '.' + str[1]
                }
                if (str[1].length >= lena) {
                    return (str[0] + '.' + str[1].substring(0, len))
                }
                return (str[0] + '.' + str[1] + (new Array(len + 1)).join('0'))
            } else {
                if (str[0] == 0) {
                    return ('0.' + (new Array(len + 1)).join('0'))
                }
                return (str + '.' + (new Array(len + 1)).join('0'))
            }
        },
        substr(str, startNum, endNum) {
            if (!str) {
                return ''
            }
            if (endNum) {
                return str.slice(startNum, endNum)
            } else {
                return str.slice(startNum)
            }
        },
        goRouter(path, query = {}, replace = false) {
            if (!path) {
                return false
            }
            if (!replace) {
                this.$router.push({ path: path, query: query })
            } else {
                window.open(path, '_blank');
            }
        },
        goLinkFn (path) {

            if (!path) {
                return false
            }
            window.location.href = path
            // window.open(path, '_blank');
        },
        numFormat(num) {
            if (!num) {
                return '0'
            }
            num = parseFloat(num) + ''
            let source = num.replace(/\,|\￥/g, "").split(".");
            source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'), "$1,");
            return source.join(".");
        },
         strToUtf8Bytes(str) {
            const utf8 = [];
            for (let ii = 0; ii < str.length; ii++) {
                let charCode = str.charCodeAt(ii)
                if (charCode < 0x80) utf8.push(charCode)
                else if (charCode < 0x800) {
                    utf8.push(0xc0 | (charCode >> 6), 0x80 | (charCode & 0x3f))
                } else if (charCode < 0xd800 || charCode >= 0xe000) {
                    utf8.push(0xe0 | (charCode >> 12), 0x80 | ((charCode >> 6) & 0x3f), 0x80 | (charCode & 0x3f))
                } else {
                    ii++;
                    charCode = 0x10000 + (((charCode & 0x3ff) << 10) | (str.charCodeAt(ii) & 0x3ff))
                    utf8.push(
                        0xf0 | (charCode >> 18),
                        0x80 | ((charCode >> 12) & 0x3f),
                        0x80 | ((charCode >> 6) & 0x3f),
                        0x80 | (charCode & 0x3f),
                    )
                }
            }
            for(let jj=0;jj<utf8.length;jj++){
                let code = utf8[jj]
                if(code>127){
                    utf8[jj] = code - 256
                }
            }
            return utf8;
        },
        strToHexCharCode(str){
            let hexCharCode = [];
            let chars = ["0","1","2","3","4","5","6","7","8","9","A","B","C","D","E","F"];
            for(let i = 0; i < str.length; i++) {
                let bit = (str[i] & 0x0f0) >> 4
                    hexCharCode.push(chars[bit])
                    bit = str[i] & 0x0f
                    hexCharCode.push(chars[bit])

            }
            return hexCharCode.join("");
        },
        stringToHex (str) {
            return this.strToHexCharCode(this.strToUtf8Bytes(str))
        },
        dataFormat(time, type = 'yyyy-MM-dd hh:mm:ss') {
            if (!time || time == 0) {
                return '-'
            }
            time = parseFloat(time * 1000)

            return this.Utils.tool.date.dateFormat(type, time)
        },
        copyText(str) {
            let input = document.createElement('SPAN')
            input.textContent = str
            input.style.position = 'absolute'
            input.style.left = '-999px'
            input.style.top = '-999px'
            input.style.zIndex = '-999'
            document.body.appendChild(input)
            if (document.selection) {
                let range = document.body.createTextRange();
                range.moveToElementText(input);
                range.select();
            } else {
                let range = document.createRange();
                range.selectNode(input);
                let selection = window.getSelection();
                if (selection.rangeCount > 0) selection.removeAllRanges();
                selection.addRange(range);
            }
            document.execCommand('copy');
            document.body.removeChild(input)
            this.messageFn(this.$t('copy_success'))

        },
        $ajax(url, json, method = 'post', timeout = 25000, isFormData = false, jsonHead = false) {
            const promise = new Promise((resolve, reject) => {
                if (!url || !json) {
                    reject({
                        status: 0,
                        msg: `url or josn is null`
                    })
                }
                let req = {
                    url: url,
                    method: method,
                    timeout: timeout,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                        'XXX-DOMAIN': window.oris,
                        'Wallet-Uuid': window.Wallet_Uuid,
                        // 'Wallet-Uuid': 'F07e93252e4d6266C4f5614333866bf0B0dfb094c',
                        'Xxx-Authentication': this.storeSingAuth,
                        'version': '2.1.6',
                    },
                    cancelToken: new this.$axios.CancelToken(c => { //  cancel token
                        this.ajaxAxiosArray.push(c)
                    }),
                    transformResponse: [function (data) {
                        if (data) {

                            try {
                                let str = data.replace(/\:(\d+)(\,|\})/g, (a, b, c, d, e) => {
                                    return (':"' + b + '"' + c)
                                });
                                const s = JSON.parse(str)
                                return s
                            } catch (e) {
                                return JSON.parse(data)
                            }
                        }
                        return data
                    }]
                }
                // req.headers.lang = this.storeLang == 'zh' ? 'en' : 'en'
                req.headers.lang = this.storeLang
                req.headers.device = 'web'
                json.lang = this.storeLang
                if (!isFormData) {
                    if (method === 'get') {
                        req.params = json
                    } else {
                        if (jsonHead) {
                            req.headers['Content-Type'] = 'application/json'
                            req.data = json
                        } else {
                            req.data = qs.stringify(json)
                        }
                        // req.data = json
                    }
                } else {
                    req.headers['Content-Type'] = 'multipart/form-data'
                    req.data = json
                }
                this.$axios(req)
                    .then((response) => {


                        if (response.data.code == '0') {
                            resolve(response.data.data)
                        } else {
                            if (response.data.code == '20000') {
                                window.sessionStorage.setItem('storeSingAuth', '')
                                this.$store.commit('setSingAuth', '')
                                response.data.msg = this.$t('login_fail')
                                reject(response.data)
                                this.$router.push('/home')

                            }
                            reject(response.data)
                        }
                    })
                    .catch((error) => {
                        if (error.msg) {
                            reject(error)
                        } else {
                            if (error.message == 'webExit') {
                                return false
                            }
                            reject({ msg: this.$t('tips_view.msg108') })
                        }
                        console.error(`ajax error: ${url} ### ${error}`)
                    })
            })
            return promise
        }
    },
    beforeUnmount() {
        this.ajaxAxiosArray.forEach((ele, index) => {
            ele('webExit')
            delete this.ajaxAxiosArray[index]
        })
        if (window.ethereum) {
          window.ethereum.removeListener('accountsChanged', this.handleAccountsChanged);
          window.ethereum.removeListener('chainChanged', this.handleChainChanged);
        }
    },
})

plugins.forEach(plugin => {
    app.use(plugin)
})
app.use(store)
app.use(router)
    .use(VueI18n)
    .use(ViewUIPlus)
app.mount('#app')
