<template>
  <article class="comp_index_footer_view">
    <div class="com_index_footer_item_box">
      <div class="item" @click="goRouter('/home')">
        <p><i class="h5_icon" :class="index == 1 ? 'icon_home_index_cur' : 'icon_home_index'"></i></p>
        <p class="tx1" :class="index == 1 ? 'color_T8' : 'color_T7'"><span class="fz_10">{{ $t('home') }}</span></p>
      </div>
      <div class="item" @click="goRouter('/scf')">
        <p><i class="h5_icon" :class="index == 2 ? 'icon_home_scf_cur' : 'icon_home_scf'"></i></p>
        <p class="tx1" :class="index == 2 ? 'color_T8' : 'color_T7'"><span class="fz_10">{{ $t('scf_home') }}</span></p>
      </div>
      <!-- <div class="item" @click="goRouter('/loan')">
        <p><i class="h5_icon " :class="index == 2 ? 'icon_home_loan_cur' : 'icon_home_loan'"></i></p>
        <p class="tx1" :class="index == 2 ? 'color_T8' : 'color_T7'"><span class="fz_10">{{ $t('loan') }}</span></p>
      </div> -->
      <div class="item" @click="goRouter('/profit')">
        <p><i class="h5_icon " :class="index == 3 ? 'icon_home_profit_cur' : 'icon_home_profit'"></i></p>
        <p class="tx1" :class="index == 3 ? 'color_T8' : 'color_T7'"><span class="fz_10">{{ $t('income') }}</span></p>
      </div>
      <div class="item" @click="goRouter('/setting')">
        <p><i class="h5_icon " :class="index == 4 ? 'icon_home_setting_cur' : 'icon_home_setting'"></i></p>
        <p class="tx1" :class="index == 4 ? 'color_T8' : 'color_T7'"><span class="fz_10">{{ $t('setting') }}</span></p>
      </div>
    </div>
    <div class="h49"></div>

  </article>
</template>

<script>
export default {
  name: "comIndexFooter",
  props: {
    index: {
      type: Number,
      default: 1,
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
.comp_index_footer_view{
  z-index: 100;

  .com_index_footer_item_box{
    left: 0;
    position: fixed;
    background: $FFF;
    box-shadow: 0px -4px 9px rgba(34, 68, 85, 0.03);
    bottom: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    height: 0.6rem;
    min-width: 0.3rem;
    padding: 0 0.36rem 0.1rem;
    font-size: 0;
    .tx1{
      font-size: 0.12rem;
    }
  }

  .h49{
    height: 0.71rem;
  }
}

</style>