<template>
  <article class="comp_charts_area_view" >
    <section class="chart_box" >
      <section class="chart_canvas" :style="{width: width, height: height}"  :id="id"></section>
    </section>

  </article>
</template>

<script>
import Highcharts from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import Highcharts3D from 'highcharts/highcharts-3d';
HighchartsMore(Highcharts)
HighchartsDrilldown(Highcharts);
Highcharts3D(Highcharts);
export default {
  name: "index",
  data () {
    return {
      chart: null
    }
  },
  props: {
    id: {
      type: String,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    seriesData: {
      default: new Array(),
      type: Array,
    },
    xArrData: {
      default: new Array(),
      type: Array,
    },
  },
  mounted () {
    let self = this
    this.chart = new Highcharts.chart(self.id, {
      chart: {
        zoomType: 'x',

      },
      accessibility: {
        enabled: false
      },
      credits: {
        enabled: false
      },

      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        tickInterval:  24 * 3600 * 1000,
        tickWidth: 0,
        gridLineWidth: 0,

        labels: {
          format: '{value:%m-%d}'
        },
        lineColor: 'rgba(119, 142, 153, 0.12)',
      },

      yAxis: {
        title: '',
        opposite: true,
        tickWidth: 0,
        gridLineWidth: 0,
      },
      tooltip:{
        shared:true,//共享提示框,整个绘图区都将捕捉鼠标指针的移动,显示提示框,同时将多个数据列的信息展示在同一个提示框里
        useHTML:true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderColor: 'rgba(238, 153, 34, 1)',
        formatter: function(){
          let options = this.points[0].point.options
          let rate = parseFloat(options.rate)
          let    html = '<div class="myTooltip"><p class="d_flex_space"><span>'+ self.$t('times')+':</span><span class="h5_icon ml_20">'+options.date_str+'</span></p>';

          html += '<p class="d_flex_space" style="display: '+ (options.isLength ? "none" : "flex") +'"><span>'+ self.$t('open_price')+':</span><span class="tx_r">'+options.open+'</span></p>'
          html += '<p class="d_flex_space"><span>'+ (options.isLength ? self.$t('current_price') : self.$t('close_price'))+':</span><span class="tx_r">'+options.close+'</span></p>'
          html += '<p class="d_flex_space  "><span>'+ self.$t('rate')+':</span><span class="tx_r '+ (rate < 0 ? 'color_T9' : 'color_T5') +'">'+options.rate+'</span></p>'
          html += '</div>'
          return html;
        }
      },
      plotOptions: {
        area: {

          lineColor: 'rgba(238, 153, 34, 1)',
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, 'rgba(238, 153, 34, 0.3)'],
              [1, 'rgba(238, 153, 34, 0)']
            ]
          },
          marker: {
            radius: 0
          },
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 0
            }
          },
          threshold: null
        }
      },
      legend: {
        enabled: false
      },
      series: [{
        type: 'area',
        name: '',
        data: self.seriesData
      }]
    })
  },
  watch: {
    seriesData () {
      this.chart.update({
        /*xAxis: {

          min: 40,
          max: this.xArrData.length - 1,
          categories: this.xArrData,
          scrollbar:{// 设置滚动条样式
            enabled: true,
            height: 8,
            barBackgroundColor: "#d1d1d1",
            barBorderColor: "#d1d1d1",
            rifleColor: "#d1d1d1",
            barBorderRadius: 5,
            buttonBorderWidth: 0,
            buttonArrowColor: "rgba(0,0,0,0)",
          },
        },*/
        series: [{
          type: 'area',
          data: this.seriesData
        }]
      })


    }
  }
}
</script>

<style lang="scss">
.comp_charts_area_view{
  .highcharts-reset-zoom{
    display: none !important;
  }

  .highcharts-area-series{
    opacity: 1 !important;
  }
  .highcharts-selection-marker{
    opacity: 1 !important;
  }
}
</style>