<template>
  <article class="h5_collection_qrcode_view">
    <section class="qrcode_main">
      <div class="title_box">{{$t('tips_view.msg66')}} {{$t('tips_view.msg190')}} {{$route.query.chain_type == 'bsc' ? 'BNB' : $route.query.chain_type == 'tron' ? 'TRX' : $route.query.chain_type == 'eth' ? 'ETH' : ''}}</div>
      <p class="tx0"><i class="h5_icon icon_br_bg"></i></p>
      <div class="qrcode_box">
        <p><img :src="qrcodeImg" class="img_qrcode"/></p>
        <p class="tx1">{{ $t('wallet_address') }}</p>
        <p class="tx2">{{ $route.query.addr }}</p>
        <p><span class="copy_btn" @click="copyText($route.query.addr)">{{ $t('copy_address') }}</span></p>
      </div>
    </section>

  </article>
</template>

<script>
import QRCode  from 'qrcode'
export default {
  name: "collection_qrcode",
  data () {
    return {
      resData: {},
      qrcodeImg: '',
    }
  },
  created () {

  },

  mounted () {
    QRCode.toDataURL(this.$route.query.addr).then(img => {
      this.qrcodeImg = img
    })
  },
  methods: {

  }
}
</script>

<style lang="scss">
.h5_collection_qrcode_view{
  background: $T8;
  height: 100vh;
  padding-top: 0.6rem;
  .qrcode_main{

   width: 3.11rem;
    text-align: center;
    margin: 0rem auto 0;
    .tx0{
      font-size: 0;
    }
    .title_box{
      background: $FFF;
      border-radius: 0.12rem 0.12rem 0 0;
      padding: 0.3rem 0.2rem 0.18rem;
      color: $T6;
      font-weight: bold;
      font-size: 0.16rem;
    }
    .qrcode_box{
      background: $FFF;
      border-radius: 0 0 0.12rem 0.12rem ;
      padding: 0.18rem 0.2rem 0.2rem;
    }
    .img_qrcode{
      width: 1.9rem;
      height: 1.9rem;

    }
    .tx1{
      margin: 0.27rem 0 0.03rem;
      font-size: 0.12rem;
      color: $T7;
    }
    .tx2{
      color: $T6;
      margin-bottom: 0.24rem;
      word-break: break-all;
    }
    .copy_btn{
      width: 2.7rem;
      display: inline-block;
      line-height: 0.44rem;
      font-size: 0.14rem;
      font-weight: bold;
      color: $FFF;
      background: $T8;
      border-radius: 0.06rem;
    }
  }
}
</style>