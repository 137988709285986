<template>
  <article class="h5_coin_list_view">
    <section class="tab_box d_flex">
      <div class="item " :class="curType == 'bsc' ? 'item_cur' : ''" @click="tabFn('bsc')">BSC</div>
      <div class="item" :class="curType == 'eth' ? 'item_cur' : ''" @click="tabFn('eth')">ETH</div>
      <div class="item" :class="curType == 'tron' ? 'item_cur' : ''" @click="tabFn('tron')">TRX</div>
    </section>
    <section class="coin_main">

      <section class="coin_box">
        <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">

          <div class="item d_flex_space" v-for="(list, index) in resData" >
            <div class="left_box">
              <div class="com_icon_img_box">
                <img :src="list.icon_url" class="com_coin_img"/>
                <i class="h5_icon com_icon_subscript" :class="list.type == 'tron' ? 'icon_subscript_tron' : list.type == 'bsc' ? 'icon_subscript_bsc' : 'icon_subscript_eth' "></i>
              </div>
            </div>
            <div class="right_box d_flex_space">
              <div>
                <p class="d_flex">
                  <span class="tx1">{{ list.code }}</span>
                  <span class="tx3  " :class="list.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.type] }}</span></span>
                </p>
                <p class="tx2">{{substr(list.contract_addr, 0, 10)}}...{{substr(list.contract_addr, -10)}}</p>
              </div>
              <i class="h5_icon icon_round_copy" @click="copyText(list.contract_addr)"></i>
            </div>
          </div>
        </div>
      </section>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </section>

  </article>
</template>

<script>

export default {
  name: "coin_list",
  data () {
    return {
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      resData: [],
      curType: 'bsc'
    }
  },
  created () {
    this.resInit()
  },

  mounted () {

  },
  methods: {
    tabFn (type) {
      if (this.curType == type) {
        return false
      }
      this.page = 1
      this.loadInfinite = false
      this.isLoad = false
      this.resData = []
      this.curType = type
      this.resInit('search')
    },
    resInit (type) {

      let req = {
        chain_type: this.curType,
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/credit_white_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    }
  }
}
</script>

<style lang="scss">
.h5_coin_list_view{
  .tab_box{
    margin-bottom: 0.1rem;
    .item{
      font-size: 0.14rem;
      font-weight: bold;
      color: $T7;
      border-bottom: 1px solid $BR;
      padding: 0.1rem 0;
      text-align: center;
      width: 33.33%;
    }
    .item_cur{
      color: $T8;
      border-bottom: 1px solid $T8;
    }
  }
  .coin_main{
    padding: 0rem 0.16rem 0;

    .coin_box{
      .item:last-child{
        .right_box{
          border-bottom: 0px;
        }
      }
      .item{
        .coin_img{
          width: 0.4rem;
          height: 0.4rem;
        }
        .right_box{
          padding: 0.16rem 0;
          margin-left: 0.16rem;
          flex: 1;
          border-bottom: 1px solid $BR2;
        }
        .tx1{
          color: $T6;
          font-size: 0.17rem;
          font-weight: bold;
        }
        .tx2{
          font-size: 0.12rem;
          color: $T7;
        }
        .tx3{
          font-size: 0.12rem;
          margin-left: 0.1rem;
          padding: 0.01rem 0.03rem;
          border-radius: 0.08rem;
          font-weight: bold;
          display: inline-block;
        }
        .tx4{
          color: $T12;
          background: $T11;
        }
        .tx5{
          color: $T14;
          background: $T13;
        }
        .tx9{
          color: $T16;
          background: $T15;
        }
      }
    }
  }
}
</style>