<template>
  <article class="h5_language_view">
    <div class="lang_item d_flex_space" @click="selectLang(list.value)" v-for="list in langObj">
      <p class="" :class="storeLang == list.value ? 'lang_cur' : ''">{{ list.label }}</p>
      <i class="h5_icon " :class="storeLang == list.value ? 'icon_check_yes_1' : ''"></i>
    </div>

  </article>
</template>

<script>
export default {
  name: "language",
  methods: {
    selectLang (lang) {
      window.localStorage.setItem('storeLangId', lang)
      this.$i18n.locale = lang
      this.storeLang = lang
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
.h5_language_view{
  padding: 0 0.16rem;
  .lang_item{
    padding: 0.16rem 0;
    font-size: 0.14rem;
    border-bottom: 1px solid $BR2;
    color: $T6;
  }
  .lang_cur{
    color: $T8;
  }
}

</style>