
import { createI18n } from 'vue-i18n'		//
import langCN from './lang/cn.json'
import langZH from './lang/zh.json'
import langEn from './lang/en.json'
import langFr from './lang/fr.json'
import langJa from './lang/ja.json'
import langKo from './lang/ko.json'
import langEs from './lang/es.json'
import langMs from './lang/ms.json'
import langVi from './lang/vi.json'


const i18n = createI18n({
    locale: 'en',		//
    messages: {
        'cn': langCN,
        'zh': langZH,
        'en': langEn,
        'fr': langFr,
        'ja': langJa,
        'ko': langKo,
        'es': langEs,
        'ms': langMs,
        'vi': langVi,
    }
})
export default i18n;
