<template>
  <article class="h5_fth_subscription_history_view">

    <div>
      <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
        <div class="info_main mb_10" v-for="(list, index) in resData" :key="index">
          <div class="d_flex_space">
            <p class="tx1 ">
              <span class="color_T6">{{substr(storeChangeAddress, 0, 8)}}...{{substr(storeChangeAddress, -8)}}</span>
              <span class="color_T8 ml_5" v-show="list.level !== ''">({{levelArr[list.level]}})</span>
            </p>
            <p class="tx1 color_T8 font_bold d_flex">{{ list.amount }} {{ list.coin_data.code }}</p>
          </div>
        </div>

      </div>
      <div class="com_no_data_box" v-show="!resData.length">
        <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
        <p class="com_no_data_tx">{{$t('nodata')}}</p>
      </div>
    </div>



  </article>
</template>

<script>
export default {
  name: "fth_subscription_history",
  data () {
    return{
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      resData: [],
      levelArr: [this.$t('general_user'), 'GM', 'VP', 'SVP', 'ED', 'MD', 'MD+'],
    }
  },
  created () {
    this.resInit('search')
  },
  methods: {
    resInit (type) {

      let req = {
        page: this.page,
        size: this.size,
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/direct_record_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            if (type == 'search') {
              this.resData = arr
            } else {
              this.resData = this.resData.concat(arr)
            }
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
  }
}
</script>

<style lang="scss">
.h5_fth_subscription_history_view{
  padding: 0.2rem 0.16rem;

  .info_main{
    background: $BG2;
    border-radius: 0.06rem;
    padding: 0.14rem 0.16rem;
    position: relative;
    .tx1{
      font-size: 0.14rem;
    }
  }

}

</style>