<template>
  <article class="h5_fsp_trading_view">
    <div class="container">
      <!-- <p style="word-break: break-all">{{ testObj }}</p> -->
      <div class="record_tab d_flex">
        <p class="item tx13 " :class="tabIndex == 1 ? 'item_cur' : ''" @click="tabFn(1)">{{ $t('fsp_trading.msg1') }}
        </p>
        <p class="item tx13" :class="tabIndex == 2 ? 'item_cur' : ''" @click="tabFn(2)">{{ $t('fsp_trading.msg2') }}</p>
        <p class="item tx13" :class="tabIndex == 3 ? 'item_cur' : ''" @click="tabFn(3)">{{ $t('fsp_trading.msg3') }}</p>
      </div>
      <div v-show="tabIndex == 1">
        <div class="d_flex_space box_connect_wallet">
          <p class="title">钱包地址</p>
          <div class="d_flex">
            <div class="address_box" v-if="storeChangeAddress" @click="isShowAddress = true">
              <span class="tx1" v-show="storeChainType == 'BSC'" :class="storeChainType == 'BSC' ? 'tx3' : 'tx4'">
                {{ storeChainType }}
              </span>
              <span class="tx2" v-show="storeChainType == 'BSC'">{{ substr(storeChangeAddress, 0, 5) }}...{{
                substr(storeChangeAddress, -5) }}</span>
              <span v-show="!storeChainType" style="color: #224455">暂不支持当前钱包类型</span>
            </div>
            <span @click="showWalletFn" v-else class="tx5">链接</span>
          </div>
        </div>

        <div class="form_exchange">
          <div class="title">
            <p class="text">{{ $t('fsp_trading.msg4') }}</p>
            <p class="right">
              <span class="icon">
                <img :src="exchange_form.from.icon" alt="" :class="{ 'active': exchange_form.from.isIconFsp }">
              </span>
              <span class="currency">{{ exchange_form.from.currency }}</span>
              <span class="tag">{{ exchange_form.from.tag }}</span>
            </p>
          </div>
          <div class="info_box">
            <div class="input_box d_flex_space">
              <input type="number" v-model="exchange_form.from.amount" class="input_tx1"
                :placeholder="$t('fsp_trading.msg5')" @input="handleInputSwapFrom" />
            </div>
            <div class="error_tx" v-show="errorTxSwapF">{{ errorTxSwapF }}</div>
            <div class="flex">
              <p class="balance">{{ $t('fsp_trading.msg50') }}：{{ exchange_form.from.balance }} {{
                exchange_form.from.coin
              }}</p>
            </div>
          </div>
          <div class="miner_fee">
            <p class="icon" @click="swapCurrency">
              <img src="../../assets/img/icon/icon_exchange.png" alt="">
            </p>
            <p class="text">{{ $t('fsp_trading.msg7') }}：{{ swapFee }} BNB</p>
          </div>
          <div class="title">
            <p class="text">{{ $t('fsp_trading.msg8') }}</p>
            <p class="right">
              <span class="icon">
                <img :src="exchange_form.to.icon" alt="" :class="{ 'active': exchange_form.to.isIconFsp }">
              </span>
              <span class="currency">{{ exchange_form.to.currency }}</span>
              <span class="tag">{{ exchange_form.to.tag }}</span>
            </p>
          </div>
          <div class="info_box">
            <div class="input_box d_flex_space">
              <input type="number" v-model="exchange_form.to.amount" class="input_tx1"
                :placeholder="$t('fsp_trading.msg5')" @input="handleInputSwapTo" />
            </div>
            <div class="error_tx" v-show="errorTxSwapU">{{ errorTxSwapU }}</div>
            <div class="flex">
              <p class="balance">{{ $t('fsp_trading.msg50') }}：{{ exchange_form.to.balance }} {{ exchange_form.to.coin
                }}
              </p>
            </div>
          </div>
          <p class="box_setting" @click="is_open_setting = true;">
            <span>{{ $t('fsp_trading.msg80') }}</span>
            <img src="../../assets/img/icon/icon_setting.png" alt="">
          </p>
          <el-button type="primary" class="input_sub " @click="submitSwapFn" :loading="isLoadingButton"
            :class="storeChainType == 'BSC' ? 'input_sub_cur' : ''">{{ storeChainType == 'BSC' ? $t('fsp_trading.msg51')
              :
            '暂不支持当前钱包类型' }}</el-button>
        </div>
        <div class="box_market"
          @click="goRouter('https://m.ave.ai/token/0xdc84096074269d8f304d476124101249d105b60d-bsc', {}, true)">
          <div class="left">
            <p class="icon">
              <img src="../../assets/img/icon/icon_fsp_bnb_2.png" alt="">
            </p>
            <div style="padding-left: 0.05rem;">
              <p class="tx_16">{{ $t('fsp_trading.msg79') }}</p>
              <p>
                <span class="tx_17">FSP</span>
                <span class="tx_13">≈ {{ lpInfoData.fsp_price !== undefined ? Number(lpInfoData.fsp_price).toFixed(4) :
                  0 }} USDT</span>
              </p>
            </div>
          </div>
          <p class="right">
            <img src="../../assets/img/icon/icon_arrow_next_orange.png" alt="">
          </p>
        </div>
        <div class="box_record_exchange">
          <div class="title">
            <p class="text">{{ $t('fsp_trading.msg9') }}</p>
            <p class="link" @click="goRouter('/fsp_trading_swap_record', {}, false)">
              <span>{{ $t('fsp_trading.msg52') }}</span>
              <img src="../../assets/img/icon/icon_arrow_right_1.svg" alt="">
            </p>
          </div>
          <div v-if="list_record.length > 0">
            <div class="item" v-for="(item, index) in list_record" :key="index">
              <div class="top">
                <p class="date">{{ dataFormat(item.time, 'yyyy/MM/dd hh:mm:ss', false) }}</p>
                <p class="hyperlink" :class="item.status == 1 ? 'review' : item.status == 3 ? 'fail' : 'success'"
                  @click="openLinkFn(item.tx_id)">
                  <span>{{ item.status == 1 ? $t('fsp_trading.msg10') : item.status == 3 ? $t('fsp_trading.msg11') :
                    $t('fsp_trading.msg12') }}</span>
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 9.5L5 5L0.5 0.5" stroke="#EE9922" stroke-linecap="round" />
                  </svg>
                </p>
              </div>
              <div class="box_currency">
                <div class="inner">
                  <p class="left">
                    <span class="icon">
                      <img :src="item.pay_coin.icon_url" alt="" style="width: 0.25rem;">
                    </span>
                    <span class="currency">{{ item.pay_coin.code }}</span>
                    <span class="tag">BEP20</span>
                  </p>
                  <p class="arrow">
                    <img src="../../assets/img/icon/icon_right_exchange.png" alt="">
                  </p>
                  <p class="right">
                    <span class="icon">
                      <img :src="item.rec_coin.icon_url" alt="" style="width: 0.25rem;">
                    </span>
                    <span class="currency">{{ item.rec_coin.code }}</span>
                    <span class="tag">BEP20</span>
                  </p>
                </div>
              </div>
              <div class="box_amount">
                <p>{{ item.pay_amt }}</p>
                <p>{{ item.rec_amt }}</p>
              </div>
            </div>
          </div>
          <div class="com_no_data_box" v-else>
            <p class="tx_cen"><img src="../../assets/img/icon/no_data.svg" class="com_no_data_img"></p>
            <p class="tx_cen com_no_data_tx">{{ $t('nodata') }}</p>
          </div>
        </div>
      </div>
      <div v-show="tabIndex == 2">
        <div class="box_information_liquidity">
          <div class="top">
            <div class="mine">
              <div class="mine_left">
                <p class="text">{{ $t('fsp_trading.msg13') }}</p>
                <p>{{ coin1 }}/USDT LP</p>
              </div>
              <div>
                <p class="tx_r">{{ lpInfoData.lp_amt !== undefined ? Number(lpInfoData.lp_amt) : 0 }} </p>
                <p class="tx_r text_convert">≈ {{ lpInfoData.usdt_amt !== undefined ? Number(+lpInfoData.usdt_amt *
                  2).toFixed(2) : 0 }} USDT</p>
              </div>
            </div>
            <p class="mine custom">
              <span class="text">{{ $t('fsp_trading.msg14') }}</span>
              <span class="right">
                <span class="tx_r" style="flex: 0 0 46%; white-space: nowrap">{{ lpInfoData.fsp_liquidity_reward }} {{
                  coin1 }}</span>
                <span class="tx_r" style="flex: 0 0 50%;">{{ lpInfoData.usdt_liquidity_reward !== undefined ?
                  Number(lpInfoData.usdt_liquidity_reward).toFixed(2) : 0 }} USDT</span>
              </span>
            </p>
            <p class="remove_available">
              <span class="text">{{ $t('fsp_trading.msg16') }}</span>
              <span class="tx_r">{{ lpInfoData.fsp_amt !== undefined ? Number(lpInfoData.fsp_amt).toFixed(6) : 0 }} FSP
              </span>
              <span class="tx_r"> {{ lpInfoData.usdt_amt }} USDT</span>
            </p>
          </div>
          <div class="bottom">
            <p class="title">{{ $t('fsp_trading.msg17') }}</p>
            <ul>
              <li>
                <div class="left">
                  <p class="icon">
                    <img src="../../assets/img/icon/icon_fsp.png" alt="" style="width: 0.2rem;">
                  </p>
                  <p class="text">FSP</p>
                </div>
                <div class="right">{{ lpInfoData.fsp_reserve }}</div>
              </li>
              <li>
                <div class="left">
                  <p class="icon">
                    <img src="../../assets/img/icon/icon_usdt_bnb.png" alt="">
                  </p>
                  <p class="text">USDT</p>
                </div>
                <div class="right">{{ lpInfoData.usdt_reserve }}</div>
              </li>
            </ul>
          </div>
        </div>
        <div class="box_exchange_liquidity form_exchange">
          <div class="title">
            <p class="right">
              <span class="icon">
                <img :src="exchange_form_liquidity.from.icon" alt="" style="width: 0.25rem;">
              </span>
              <span class="currency">{{ coin1 }}</span>
              <span class="tag">{{ exchange_form_liquidity.from.tag }}</span>
            </p>
            <p class="balance">{{ $t('fsp_trading.msg50') }}：{{ exchange_form_liquidity.from.balance }} FSP</p>
          </div>

          <div class="info_box">
            <div class="input_box d_flex_space">
              <input type="number" v-model="exchange_form_liquidity.from.amount" class="input_tx1"
                :placeholder="$t('fsp_trading.msg18', { x: 0.000001, y: 'FSP' })" @input="handleInputPay" />
            </div>
            <div class="error_tx" v-show="errorTxAddLiquidityF">{{ errorTxAddLiquidityF }}</div>
          </div>

          <div class="miner_fee">
            <p class="icon">
              <img src="../../assets/img/icon/icon_fsp.png" alt="" style="width: ;">
            </p>
            <p class="text">{{ $t('fsp_trading.msg19') }}：{{ add_fee }} BNB</p>
          </div>

          <div class="title">
            <p class="right">
              <span class="icon">
                <img :src="exchange_form_liquidity.to.icon" alt="">
              </span>
              <span class="currency">{{ coin2 }}</span>
              <span class="tag">{{ exchange_form_liquidity.to.tag }}</span>
            </p>
            <p class="balance">{{ $t('fsp_trading.msg50') }}：{{ exchange_form_liquidity.to.balance }} USDT</p>
          </div>
          <div class="info_box">
            <div class="input_box d_flex_space">
              <input type="number" v-model="exchange_form_liquidity.to.amount" class="input_tx1"
                :placeholder="$t('fsp_trading.msg18', { x: 0.0001, y: 'USDT' })" @input="handleInputReceive" />

            </div>
            <div class="error_tx" v-show="errorTxAddLiquidityU">{{ errorTxAddLiquidityU }}</div>
          </div>
          <div class="grp_button">
            <p class="input_sub green" @click="removeLiquidity">{{ $t('fsp_trading.msg20') }}</p>
            <p class="input_sub orange" @click="addLiquidity">{{ $t('fsp_trading.msg21') }}</p>
          </div>
        </div>

        <div class="box_record_exchange box_record_exchange_liquidity">
          <div class="title">
            <p class="text">{{ $t('fsp_trading.msg22') }}</p>
            <p class="link" @click="goRouter('/fsp_trading_liquidity_record', {}, false)">
              <span>{{ $t('fsp_trading.msg52') }}</span>
              <img src="../../assets/img/icon/icon_arrow_right_1.svg" alt="">
            </p>
          </div>
          <div v-if="list_record_liquidity.length > 0">
            <div class="item" v-for="(item, index) in list_record_liquidity" :key="index">
              <div class="top">
                <p class="date">{{ dataFormat(item.time, 'yyyy/MM/dd hh:mm:ss', false) }}</p>

                <p class="hyperlink" @click="openLinkFn(item.tx_id)">
                  <span>{{ $t('fsp_trading.msg53') }}</span>
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.5 9.5L5 5L0.5 0.5" stroke="#EE9922" stroke-linecap="round" />
                  </svg>
                </p>
              </div>
              <div class="box_currency">
                <div class="inner">
                  <p class="icon">
                    <img :src="item.coin1.icon_url" alt="">
                    <img :src="item.coin2.icon_url" alt="">

                  </p>
                  <div class="pair_currency">
                    <p>{{ item.coin1.code }}/{{ item.coin2.code }}</p>
                    <p class="pl_10">LP</p>
                    <p class="pl_20">{{ item.lp_amt }}</p>
                  </div>
                </div>
                <div class="box_status">
                  <!-- add tx_type = 1-->
                  <p v-show="item.tx_type == 1" class="status"
                    :class="item.status == 2 ? 'added' : item.status == 3 ? 'removed' : 'removing'">
                    {{ item.status == 2 ? $t('fsp_trading.msg24') : item.status == 3 ? $t('fsp_trading.msg23') :
                      $t('fsp_trading.msg54') }}
                  </p>
                  <!-- remove  tx_type = 2 -->
                  <p v-show="item.tx_type == 2" class="status"
                    :class="item.status == 2 ? 'added' : item.status == 3 ? 'removed' : 'removing'">{{
                      item.status == 2 ? $t('fsp_trading.msg68') : item.status == 3 ? $t('fsp_trading.msg69') :
                        $t('fsp_trading.msg67') }}</p>
                  <div class="flex">
                    <p>{{ item.token1_amt }} {{ item.coin1.code }}</p>
                    <p class="amount_exchange">{{ Number(item.token2_amt).toFixed(2) || '-' }} {{ item.coin2.code }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="com_no_data_box" v-else>
            <p class="tx_cen"><img src="../../assets/img/icon/no_data.svg" class="com_no_data_img"></p>
            <p class="tx_cen com_no_data_tx">{{ $t('nodata') }}</p>
          </div>
        </div>
      </div>
      <div v-show="tabIndex == 3">
        <div class="box_record_liquidity">
          <div class="box_introduce">
            <div class="top">
              <p class="heading">{{ $t('fsp_trading.msg38') }}</p>
              <p class="exchange">
                <span class="amount">
                  {{ curObjInvestMyFspInfo.total_amount }}
                  <span class="currency">FSP</span>
                </span>≈ $ {{ curObjInvestMyFspInfo.total_amount_in_usdt }}
              </p>
            </div>
            <div class="flex">
              <div class="left">
                <p class="text">{{ $t('fsp_trading.msg39') }}</p>
                <p class="value">{{ curObjInvestMyFspInfo.total_casted }} FSP</p>
              </div>
              <div class="right">
                <p class="text">{{ $t('fsp_trading.msg40') }}</p>
                <p class="value">$ {{ fsp_price }}</p>
              </div>
            </div>
            <div style="position: relative; margin: 0 -0.28rem 0 -0.1rem;">
              <div ref="chartContainer" class="chart-container">
              </div>
              <div ref="tooltipRef" class="tooltip"></div>
            </div>
            <div class="flex custom">
              <div class="left">
                <p class="text">{{ $t('fsp_trading.msg41') }}</p>
                <p class="value">{{ curObjInvestMyFspInfo.picked }} FSP</p>
              </div>
              <div class="right">
                <p class="text">{{ $t('fsp_trading.msg42') }}</p>
                <p class="value">{{ curObjInvestMyFspInfo.unpicked }} FSP</p>
              </div>
            </div>
          </div>
          <button class="input_sub " @click="submitPickMiningProfit"
            :class="curObjInvestMyFspInfo.unpicked == 0 || curObjInvestMyFspInfo.unpicked == undefined ? '' : 'input_sub_cur'"
            :disabled="curObjInvestMyFspInfo.unpicked == 0 || curObjInvestMyFspInfo.unpicked == undefined">{{
              $t('fsp_trading.msg43') }}</button>
          <div class="box_tab">
            <p class="box_tab_item" :class="tabIndexRecord == 1 ? 'active' : ''" @click="triggerTab(1)">
              {{ $t('fsp_trading.msg55') }}</p>
            <p class="box_tab_item" :class="tabIndexRecord == 2 ? 'active' : ''" @click="triggerTab(2)">
              {{ $t('fsp_trading.msg44') }}</p>
          </div>


          <div v-show="tabIndexRecord == 1">
            <div v-if="list_fsp_mining_profit.length > 0" class="box_record_reward">
              <div class="item" v-for="(item, index) in list_fsp_mining_profit" :key="index">
                <div class="top">
                  <p class="heading">{{ $t('fsp_trading.msg3') }}</p>
                  <p class="amount">{{ item.amount }} {{ item.coin_symbol }}</p>
                </div>
                <div class="bottom">
                  <p class="date">{{ $t('fsp_trading.msg56') }}：
                    {{ item.picked ? dataFormat(item.picked_at, 'yyyy/MM/dd hh: mm: ss', false) :
                      dataFormat(item.created_at, 'yyyy/MM/dd hh:mm:ss', false) }}</p>
                  <p class="status">{{ item.picked ? $t('fsp_trading.msg57') : $t('fsp_trading.msg45') }}</p>
                </div>
              </div>
            </div>
            <div class="com_no_data_box" v-else>
              <p class="tx_cen"><img src="../../assets/img/icon/no_data.svg" class="com_no_data_img"></p>
              <p class="tx_cen com_no_data_tx">{{ $t('nodata') }}</p>
            </div>
          </div>
          <div v-show="tabIndexRecord == 2">
            <div v-if="list_fsp_mining_profit_pick_record.length > 0">
              <div class="item" v-for="(item, index) in list_fsp_mining_profit_pick_record" :key="index">
                <div v-show="item.tx_id" class="hash" @click="openLinkFn(item.tx_id)">
                  <p class="hash_id">Hash：{{ textEllispsis(item.tx_id, 10, -10) }} </p>
                  <p>
                    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0.5 9.5L5 5L0.5 0.5" stroke="#00968D" stroke-linecap="round" />
                    </svg>
                  </p>
                </div>
                <div class="content">
                  <div class="left">
                    <p>{{ $t('fsp_trading.msg58') }}: {{ item.amount }} {{ item.coin_symbol }}</p>
                    <p>{{ $t('fsp_trading.msg56') }}: {{ dataFormat(item.created_at, 'yyyy/MM/dd hh:mm:ss', false) }}
                    </p>
                  </div>
                  <div class="right">
                    <p :class="item.status == 4 ? 'fail' : 'success'"> {{ item.status == 3 ? $t('fsp_trading.msg35') :
                      item.status == 2 ?
                        $t('fsp_trading.msg36') : $t('fsp_trading.msg37') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="com_no_data_box" v-else>
              <p class="tx_cen"><img src="../../assets/img/icon/no_data.svg" class="com_no_data_img"></p>
              <p class="tx_cen com_no_data_tx">{{ $t('nodata') }}</p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- confirm add liquididy -->
    <transition>
      <article class="com_popup_main" v-show="isConfirm">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('fsp_trading.msg26') }}</span>
            <i class="h5_icon icon_close" @click="isConfirm = false"></i>
          </div>
          <div class="com_popup_info_box">
            <p class="title">{{ $t('fsp_trading.msg2') }}</p>
            <div class="item">
              <p class="left_box">{{ $t('fsp_trading.msg27') }}</p>
              <!-- <p class="right_box d_flex">{{ shortAddress }}</p> -->
              <p class="right_box d_flex">{{ textEllispsis(lpInfoData.addr, 10, 0) }}</p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('fsp_trading.msg28') }}</p>
              <p class="right_box">
                <span>{{ shortUAddress }}</span>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('fsp_trading.msg29') }}</p>
              <p class="right_box">
                <span>{{ $t('fsp_trading.msg2') }}</span>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('fsp_trading.msg30') }}</p>
              <p class="right_box d_flex">{{ lpInfoData.add_fee }} BNB</p>
            </div>
            <div class="com_popup_from_box">

              <div class="com_popup_btn_box">
                <input type="button" class="com_popup_input_btn custom" :value="$t('fsp_trading.msg59')"
                  @click="liquidityCreateAdd" />
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>

    <el-dialog v-model="isAlert" title="" :close-on-click-modal="false" width="60%" top="40vh">
      <section class="com_from_box">
        <p>{{ $t('fsp_trading.msg77', { x: 10 }) }}</p>
      </section>
      <template #footer>
        <span class="dialog_footer">
          <el-button @click="isAlert = false" class="dialog_footer_button">知道了</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="is_open_setting" title="" width="70%" :close-on-click-modal="false" top="33vh"
      modal-class="box_dialog_setting" :show-close="false">
      <div class="inner">
        <p class="title">{{ $t('fsp_trading.msg80') }}</p>
        <p class="sub">{{ $t('fsp_trading.msg81') }}</p>
        <div class="setting_index">
          <p v-for="(item, index) in slippage_list" :key="index" @click="update_slippage(index)">
            <span :class="{ active: item.active }">{{ item.value }}%</span>
          </p>
        </div>
        <p class="sub">{{ $t('fsp_trading.msg82') }}</p>
        <div class="box_input">
          <input type="number" v-model="cur_slippage" placeholder="" @input="handleInputSetting"
            pattern="^\d*\.?\d+$" />
          <span class="symbol"> %</span>
        </div>
        <p class="note">{{ $t('fsp_trading.msg83', { x: '5.0' }) }}</p>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="default" @click="confirmSetting" :disabled="is_disabled_setting">
            {{ $t('fsp_trading.msg84') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </article>
  <!--  dapp -->
  <article class="com_popup_main" v-show="isShowCheck">
    <div class="com_popup_bg"></div>
    <section class="com_popup_box">
      <div class="com_popup_head">
        <span class="title_tx">连接钱包</span>
        <i class="h5_icon icon_close" @click="isShowCheck = false"></i>
      </div>
      <div class="com_popup_info_box">
        <div class="exchange_item_main ">
          <div class="exchange_item_box" @click="linkWalletFn('metamask')">
            <p><i class="h5_icon icon_metamask"></i></p>
            <p class="tx1">Metamask</p>
          </div>
          <div class="exchange_item_box" @click="linkWalletFn('trust_wallet')">
            <p><i class="h5_icon icon_trust_wallet"></i></p>
            <p class="tx1">Trust Wallet</p>
          </div>
          <div class="exchange_item_box" @click="linkWalletFn('token_pocket')">
            <p><i class="h5_icon icon_tokenpocket"></i></p>
            <p class="tx1">TokenPocket</p>
          </div>

          <div class="exchange_item_box" @click="linkWalletFn('imtoken')">
            <p><i class="h5_icon icon_imtoken"></i></p>
            <p class="tx1">Imtoken</p>
          </div>

          <div class="exchange_item_box" @click="linkWalletFn('wallet_connect')" v-show="false">
            <p><i class="h5_icon icon_walletconnect"></i></p>
            <p class="tx1">WalletConnect</p>
          </div>

        </div>
      </div>
    </section>
  </article>
  <article class="com_popup_main" v-show="isShowAuthTips">
    <div class="com_popup_bg"></div>
    <section class="com_popup_box">
      <div class="com_popup_head">
        <span class="title_tx">{{ $t('wallet_connect') }}</span>
        <i class="h5_icon icon_close" @click="isShowAuthTips = false"></i>
      </div>
      <div class="com_popup_info_box">
        <div class="com_popup_from_box">
          <p class="tx5 color_T6 mb_10">{{ $t('wallet_connect_tip1') }}</p>
          <p class="tx5 color_T9">{{ $t('wallet_connect_tip2') }}</p>
          <div class="com_popup_btn_box1 d_flex_space">
            <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')"
              @click="isShowAuthTips = false" />
            <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')"
              @click="singauthFn" />
          </div>
        </div>
      </div>
    </section>
  </article>
  <article class="com_popup_main" v-show="isShowAddress">
    <div class="com_popup_bg"></div>
    <section class="com_popup_box">
      <div class="com_popup_head">
        <span class="title_tx">{{ $t('you_wallet') }}</span>
        <i class="h5_icon icon_close" @click="isShowAddress = false"></i>
      </div>
      <div class="com_popup_info_box wallet_address_box">
        <div class="wallet_address_item">
          <div class="tx1">{{ $t('wallet_address') }}</div>
          <div class="tx2 d_flex">
            <p class="tx3">{{ storeChangeAddress }}</p>
            <p class="r_box"><i class="h5_icon icon_green_copy" @click="copyText(storeChangeAddress)"></i></p>
          </div>
        </div>
        <div class="wallet_address_item">
          <div class="tx1">{{ $t('wallet_id') }}</div>
          <div class="tx2 d_flex">
            <p class="tx3">{{ storeChangeAddressId != 0 ? storeChangeAddressId : '' }}</p>
            <p class="r_box"><i class="h5_icon icon_green_copy"
                @click="copyText(storeChangeAddressId != 0 ? storeChangeAddressId : '')"></i></p>
          </div>
        </div>
      </div>
    </section>
  </article>
  <com-bind-wallet-id :isShow="isShowWallet" @on-hide="() => { this.isShowWallet = false }"></com-bind-wallet-id>
  <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
</template>
<script>
import socket from '@/utils/socket'
import comLoading from '../../components/loading/index'
import icon_usdt_bnb from '../../assets/img/icon/icon_usdt_bnb.png'
import icon_fsp_bnb from '../../assets/img/icon/icon_fsp.png'
import { createChart } from 'lightweight-charts';
import { ref, onMounted, onUnmounted, onBeforeUnmount } from 'vue';
import { ColorType } from 'lightweight-charts';
import comBindWalletId from '../../components/bind_wallet_id'
import CryptoJS from 'crypto-js';
export default {
  name: 'FspTrading',
  components: {
    comLoading,
    comBindWalletId
  },
  setup() {
    const chartContainer = ref(null);
    const tooltipRef = ref(null);
    let type1List = []

    let chart;
    let lineSeries;
    let areaSeries;

    // let priceLine
    let socketX;
    let unMount,
      timeSec = 5000,
      socketTimeOut = null

    let fsp_price = ref(null)
    const toolTipWidth = 200;
    const toolTipMargin = 15;

    function initChart() {

      // reset chart
      if (chart) {
        chart.remove()
      }

      chart = createChart(chartContainer.value, {
        width: chartContainer.value.clientWidth,
        // width: 400,
        height: 300,
        layout: {
          background: { type: ColorType.Solid, color: 'white' },
          textColor: '#778E99',
          fontSize: 9.5,
        },


        grid: {
          vertLines: {
            color: '#778E991F',
          },
          horzLines: {
            color: '#778E991F',
          },
        },
        rightPriceScale: {
          borderColor: 'transparent',
        },
        timeScale: {
          borderVisible: false,
          timeVisible: true,
          secondsVisible: false,
          tickMarkFormatter: (time, tickMarkType, locale) => {
            const date = new Date(time * 1000);
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${month}-${day}`;
          },
          rightOffset: 0,
          barSpacing: 13.25,
          fixRightEdge: true,
          lockVisibleTimeRangeOnResize: true,
          rightBarStaysOnScroll: true,
        },
        crosshair: {
          vertLine: {
            color: 'rgba(119, 142, 153, 0.3)',
            width: 6,
            style: 0,
            visible: true,
            labelVisible: false,
            labelBackgroundColor: '#EE9922',
          },
          horzLine: {
            color: '#EE9922',
            width: 1,
            style: 3,
            visible: true,
            labelVisible: true,
            labelBackgroundColor: '#EE9922',
          },
        },
      });

      // Create a line series and set initial data
      lineSeries = chart.addLineSeries({
        color: '#EE9922',
        lineWidth: 2,
      });

      // create area series
      areaSeries = chart.addAreaSeries({
        lastValueVisible: false,
        crosshairMarkerVisible: false,
        lineColor: 'transparent',
        topColor: '#EE99224D',
        bottomColor: '#EE992200',
      });

      // Set up custom tooltip
      chart.subscribeCrosshairMove((param) => {
        if (param.point === undefined || !param.time || param.point.x < 0 || param.point.x > chartContainer.value.clientWidth || param.point.y < 0 || param.point.y > chartContainer.value.clientHeight) {
          tooltipRef.value.style.display = 'none';
        } else {
          const data = param.seriesData.get(lineSeries);
          let selectData = type1List.find((item) => +item.created_at === data.time)

          tooltipRef.value.style.display = 'block';

          let left = param.point.x + toolTipMargin;
          if (left > chartContainer.value.clientWidth - toolTipWidth) {
            left = param.point.x - toolTipMargin - (toolTipWidth / 2);
          }

          tooltipRef.value.style.left = left + 'px';
          tooltipRef.value.style.top = '0px';
          let t = this.$t.bind(this);
          tooltipRef.value.innerHTML = `
              <div style="display: flex; justify-content: space-between;">
                <p>${t('fsp_trading.msg73')} </p>
                <p>${selectData.date_str}</p>
              </div>
              <div style="display: flex; justify-content: space-between; margin-top: 3px;">
                <p>${t('fsp_trading.msg74')}</p>
                <p> ${selectData.open}</p>
              </div>
              <div style="display: flex; justify-content: space-between;  margin-top: 3px;">
                <p>${t('fsp_trading.msg75')}</p>
                <p>${selectData.close}</p>
              </div>
              <div style="display: flex; justify-content: space-between;  margin-top: 3px;">
                <p>${t('fsp_trading.msg76')}% </p>
                ${selectData.rate.includes('-') ? `<p style="color: #FF0000">${selectData.rate}</p>` : `<p style="color: #00968D">${selectData.rate}</p>`}
              </div>
            `;
        }
      });

      socketInit()

      chart.timeScale().fitContent();
    }
    const addDataPoint = (data, type) => {

      const linePoint = {
        time: parseFloat(data.created_at),   // Unix timestamp or date in seconds
        value: +data.close, // Use 'close' value for the line point
        rate: +data.rate,
      };


      // Update chart with the new data
      lineSeries.update(linePoint);
      areaSeries.update(linePoint);
    };
    function socketInit() {
      // socketX = new socket(`wss://app.jhgsddev.com/app_api/invest/app_fsp_day_kline`) // dev
      socketX = new socket(`wss://website.scfchain.io/app_api/invest/app_fsp_day_kline`)
      socketX.doOpen()

      socketX.on('open', (data) => {
        socketX.send({ test: 1 })
      })
      socketX.on('message', (res) => {
        let type = res.type
        if (type == 1) {
          type1List = res.list
        } else {
          let lastItem = type1List[type1List.length - 1]
          if (lastItem.created_at !== res.list[0].created_at) {
            type1List = type1List.concat(res.list)
          } else {
            return false
          }
        }

        type1List.forEach((list) => {
          addDataPoint(list, type);
        });
        fsp_price.value = res.fsp_price

        socketX.on('close', (res) => {
          socketResetFn()

        })
      })
    }
    function socketResetFn() {
      if (socketX && !unMount) {
        socketX = null
        clearTimeout(socketTimeOut)
        socketTimeOut = setTimeout(() => {
          socketInit()
          timeSec = timeSec + 5000
        }, timeSec)
      }
    }
    onMounted(() => {

    });

    onUnmounted(() => {
      // Destroy chart on unmount
      if (chart) {

        chart.remove()
      }

    });
    onBeforeUnmount(() => {
      // close socket
      if (socketX) {
        socketX.close()
      }
    });

    return {
      fsp_price,
      chartContainer,
      tooltipRef,
      initChart,
    };
  },

  data() {

    const t = this.$t.bind(this);
    return {
      isLoadingButton: false,
      icon_usdt_bnb,
      icon_fsp_bnb,
      tabIndex: 1,
      isShowLoading: false,
      status: 'loading',
      text: t('loading'),
      isGetAuth: false,
      page: 1,
      size: 10,
      storeChainType: 'BSC',
      // addressData: {},
      UAddress: process.env.VUE_APP_ADDRESS_U,
      FAddress: process.env.VUE_APP_ADDRESS_F,
      typingTime: null,
      currentAction: '',
      Wallet_Uuid: '',
      isuid: false,
      isAjax: false,
      // swap
      exchange_form: {
        from: {
          currency: process.env.VUE_APP_COIN2,
          tag: 'BEP20',
          amount: '',
          balance: '',
          icon: icon_usdt_bnb,
          coin: 'USDT',
          isIconFsp: false
        },
        to: {
          currency: 'FSP',
          tag: 'BEP20',
          amount: '',
          balance: '',
          icon: icon_fsp_bnb,
          coin: 'FSP',
          isIconFsp: true
        }
      },
      swapFee: '',
      isSwap: false,
      list_record: [],
      errorTxSwapF: '',
      errorTxSwapU: '',
      // end swap
      // signFn
      isShowPassword: false,
      password: '',
      errorTx: '',
      signTx: '',
      // end signFn
      // addLiquidity
      errorTxAddLiquidityF: '',
      errorTxAddLiquidityU: '',
      exchange_form_liquidity: {
        from: {
          tag: 'BEP20',
          amount: '',
          balance: '',
          icon: icon_fsp_bnb
        },
        to: {
          tag: 'BEP20',
          amount: '',
          balance: '',
          icon: icon_usdt_bnb
        }
      },
      list_record_liquidity: [],
      isConfirm: false,
      // isShowRemoveLiquidiy: false,
      amount_liquidity: 25,
      list_fsp_mining_profit_pick_record: [],
      // end addLiquidity
      // mining
      tabIndexRecord: 1,
      list_fsp_mining_profit: [],
      // end mining

      lpInfoData: {},
      curActLiquidity: '',
      coin1: 'FSP',
      coin2: process.env.VUE_APP_COIN2,
      curList: [],
      list_signTx: [],
      list_liquidity_create_add: [],
      list_signTxRm: [],
      list_liquidity_create_rm: [],
      list_signTxSwap: [],
      list_create_swap: [],
      exact_coin_id: '',
      lengthTxn: 0,
      // mining
      curObjInvestMyFspInfo: {},
      // end mining
      isAlert: false,
      bnb_balance: 0,
      curIndex: 0,
      add_fee: null,
      is_open_setting: false,
      cur_slippage: '',
      slippage_list: [
        {
          value: '0.1',
          active: false
        },
        {
          value: '0.5',
          active: false
        },
        {
          value: '1.0',
          active: false
        },
        {
          value: '3.0',
          active: true
        }
      ],
      final_val_slippage: '',
      is_disabled_setting: false,
      // for new dapp
      isShowCheck: false,
      isShowWallet: false,
      isShowAuthTips: false,
      isShowAddress: false,
      isLoad: false,
      // testObj: {}
    };
  },
  computed: {
    shortUAddress() {
      return this.UAddress.split('|')[1].slice(0, 10) + '...' + this.UAddress.split('|')[1].slice(0)
    },
  },
  beforeUnmount() {
  },
  async created() {
    this.walletInit()
    if (this.storeChangeAddressId && !this.storeSingAuth && !this.isShowWallet) {
      this.isShowAuthTips = true
    }
  },
  methods: {
    singauthFn() {
      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('connect_ing')

      if (this.isAjax) {
        return false
      }
      this.isAjax = true

      this.$ajax(`${this.Utils.URL.goAPPURL}now`, {}, "get")
        .then(async (res) => {
          let srcs = CryptoJS.enc.Utf8.parse(res);
          let encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse('ftcchaincrypto00'), {
            iv: CryptoJS.enc.Utf8.parse('ftcchaincrypto00'),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          })
          let hx = CryptoJS.enc.Hex.stringify(encrypted.ciphertext)
          let sing = await this.dappSingFn(hx)

          if (!sing) {
            this.messageFn(this.$t('tips_view.msg5'))
            return false
          }
          let singAuth = this.storeChainType.toLocaleLowerCase() + '.' + hx + '.' + sing
          window.sessionStorage.setItem('storeSingAuth', singAuth)
          this.$store.commit('setSingAuth', singAuth)
          this.isShowAuthTips = false
          this.isAjax = false
          this.status = 'success'
          this.text = this.$t('connect_success')
          setTimeout(() => {
            this.isShowLoading = false
          }, 2000)
        }).catch((res) => {
          this.isAjax = false
          this.status = 'error'
          this.text = this.$t('connect_fail')
          setTimeout(() => {
            this.isShowLoading = false
          }, 2000)
          this.messageFn(res.msg)
        });
    },
    async linkWalletFn(type) {
      this.isShowCheck = false
      let url = ''
      let dappUrl = this.Utils.URL.dappUrl + '/?isLink=1'


      // this.Utils.URL.dappUrl + '/?isLink=1'
      if (type == 'wallet_connect') {
        return false
      }
      if (type == 'metamask') {
        url = 'https://metamask.app.link/dapp/' + dappUrl.replace('https://', '')
      }
      if (type == 'trust_wallet') {
        url = 'https://link.trustwallet.com/open_url?coin_id=60&url=' + dappUrl
      }
      if (type == 'imtoken') {
        url = 'imtokenv2://navigate/DappView?url=' + dappUrl
      }
      if (type == 'token_pocket') {
        let params = JSON.stringify({
          url: encodeURI(dappUrl),
          chain: 'BSC',
          source: 'FSP'
        })

        url = `tpdapp://open?params=${params}`

      }
      window.open(url, '_blank')

    },
    walletInit() {
      this.selectChain().then(res => {


        // this.signUSDTBalanceFn('TYp8fXsbrLFpFwVyMJM4STxeDhRZhDd97A', '0.0001')

        this.eachWallet()
        this.getContractAddress('fsp')
        this.getContractAddress('udt') // need change when deploy prod
      }).catch(res => {
        if (this.$route.query.isLink == 1) {
          this.isShowTips = true
        }


      })
    },
    async getContractAddress(token) {
      try {
        token = token.toUpperCase()
        let req = {
          symbol: token,
          page: 1,
          size: 500,
        }
        let list_token = await this.$ajax(`${this.Utils.URL.goAPPURL}coin`, req, 'get')

        if (!list_token) return false

        let contract_address
        list_token.list.forEach(token => {
          if (token.chain_type === 'bsc') {
            contract_address = token.contract_addr
          }
        })
        this.getBalanceToken(token, contract_address)
      } catch (e) {
        this.messageFn(e)
      }
    },
    async getBalanceToken(token, contract_addr) {
      try {
        let quantity = await this.getUSDTBalanceFn(this.storeChainType, contract_addr, this.storeAddress)
        if (!quantity) return false
        console.log(token, quantity)

        if (this.exchange_form.from.currency === token) {
          this.exchange_form.from.balance = quantity
        } else {
          this.exchange_form.to.balance = quantity
        }
      } catch (e) {
        this.messageFn(e)
      }
    },

    showWalletFn() {
      this.isShowCheck = true
    },

    // from here are old functions
    update_slippage(index) {
      this.is_disabled_setting = false
      this.cur_slippage = ''
      this.slippage_list.forEach((i) => {
        i.active = false
      })
      this.slippage_list[index].active = true
    },
    handleInputSetting(e) {
      let val = e.target.value
      if (!val) {
        this.slippage_list[3].active = true
        return false
      }
      if (Number(val) < 0) {
        this.is_disabled_setting = true
        return false
      } else {
        this.is_disabled_setting = false
      }

      this.slippage_list.forEach(i => i.active = false)
      if (val > 5) {
        this.is_disabled_setting = true
      } else {
        this.is_disabled_setting = false
      }
      this.cur_slippage = val
      this.slippage_list.forEach(i => {
        if (Number(i.value) === Number(val)) i.active = true
      })
    },
    confirmSetting() {
      let val_active
      if (!this.cur_slippage) {
        val_active = this.slippage_list.find(i => i.active).value
      } else {
        val_active = this.cur_slippage
      }
      this.final_val_slippage = val_active
      this.messageFn(this.$t('fsp_trading.msg85', { x: this.final_val_slippage }), 'success')
      this.is_open_setting = false
    },
    async tabFn(index) {
      // this.tabIndex = index;

      // this.status = 'loading'
      // this.text = this.$t('loading')
      // this.lengthTxn = 0
      // this.curList = []

      // await this.lpInfo()

      // switch (index) {
      //   case 1:
      //     this.swapFee = ''
      //     this.list_signTxSwap = []
      //     this.list_create_swap = []
      //     this.exchange_form.from.amount = ''
      //     this.exchange_form.to.amount = ''
      //     this.swapRecord()
      //     break;
      //   case 2:
      //     this.list_signTx = []
      //     this.list_liquidity_create_add = []
      //     this.exchange_form_liquidity.from.amount = ''
      //     this.exchange_form_liquidity.to.amount = ''
      //     this.lpRecord()
      //     break;
      //   case 3:
      //     this.investMyFspInfo()
      //     this.fspMiningProfit()

      //     setTimeout(() => {
      //       if (this.chartContainer) {
      //         this.initChart();
      //       } else {
      //         // Wait for the chart container to be mounted
      //         this.$nextTick(() => {
      //           this.initChart();
      //         });
      //       }
      //     }, 350)

      //     break;
      //   default:
      //     break;
      // }
    },
    textEllispsis(str, start, end) {
      if (!str) {
        return ''
      }
      return str.slice(0, start) + '...' + str.slice(end)
    },
    openLinkFn(hash) {
      let url = ''
      if (!hash) {
        return false
      }
      url = 'https://bscscan.com/tx/' + hash // production
      // url = 'https://testnet.bscscan.com/tx/' + hash // dev
      this.goRouter(url, {}, true)
    },
    handleInputPay(e) {
      this.add_fee = null
      if (+e.target.value == 0 || e.target.value === '' || +e.target.value < 0.000001) {
        this.errorTxAddLiquidityF = this.$t('fsp_trading.msg18', { x: 0.000001, y: 'FSP' })
        this.exchange_form_liquidity.to.amount = ''
        return false
      } else if (+e.target.value > +this.exchange_form_liquidity.from.balance) {
        this.errorTxAddLiquidityF = this.$t('fsp_trading.msg49')
        return false
      } else {
        let price = this.lpInfoData.fsp_price
        let fixed = +e.target.value * price
        if (fixed.toString().split('.')[1] && fixed.toString().split('.')[1].length > 6) {
          this.exchange_form_liquidity.to.amount = fixed.toFixed(6)
        } else {
          this.exchange_form_liquidity.to.amount = fixed
        }
        this.errorTxAddLiquidityF = ''
        this.errorTxAddLiquidityU = ''
      }
    },
    handleInputReceive(e) {
      this.add_fee = null
      if (+e.target.value == 0 || e.target.value === '' || +e.target.value < 0.0001) {
        this.errorTxAddLiquidityU = this.$t('fsp_trading.msg18', { x: 0.0001, y: 'USDT' })
        this.exchange_form_liquidity.from.amount = ''
        return false
      } else if (+e.target.value > +this.exchange_form_liquidity.to.balance) {
        this.errorTxAddLiquidityU = this.$t('fsp_trading.msg49')
        return false
      } else {
        let price = this.lpInfoData.fsp_price
        let fixed = +e.target.value / price
        if (fixed.toString().split('.')[1] && fixed.toString().split('.')[1].length > 6) {
          this.exchange_form_liquidity.from.amount = fixed.toFixed(6)
        } else {
          this.exchange_form_liquidity.from.amount = fixed
        }
        this.errorTxAddLiquidityU = ''
        this.errorTxAddLiquidityF = ''
      }
    },
    async lpInfo() {

      let url = 'liquidity/lp_info'
      try {
        let req = {
          chain_type: 'bsc',
          coin: 'FSP',
        }
        let res = await this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, req, 'get')
        this.lpInfoData = res


        this.getQuantity(this.lpInfoData.addr)
        this.getBNBBalance(this.lpInfoData.addr)
        this.getFspBalance(this.lpInfoData.addr)
      } catch (res) {
        console.log(res.msg)
      }
    },
    lpRecord() {
      let req = {
        page: this.page,
        size: this.size,
        chain_type: 'bsc',
        coin: 'FSP',
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/lp_record`, req, 'get')
        .then(res => {
          this.list_record_liquidity = res.list ? res.list : []
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    triggerTab(index) {
      this.tabIndexRecord = index
      switch (index) {
        case 1:
          this.fspMiningProfit()
          break;
        case 2:
          this.fspMiningProfitPickRecord()
          break;
        default:
          break;
      }
    },

    addLiquidity() {
      // check if this.exchange_form_liquidity.to.amount or this.exchange_form_liquidity.from.amount is empty
      if (!this.exchange_form_liquidity.to.amount || !this.exchange_form_liquidity.from.amount) {
        this.errorTxAddLiquidityU = this.$t('fsp_trading.msg6')
        this.errorTxAddLiquidityF = this.$t('fsp_trading.msg6')
        return false
      } else if (+this.exchange_form_liquidity.to.amount > +this.exchange_form_liquidity.to.balance) {
        this.messageFn(this.$t('fsp_trading.msg49'))
      } else {
        this.isConfirm = true
        this.currentAction = 'add'
      }
    },
    swapCurrency() {
      if (!this.exchange_form.from.balance || !this.exchange_form.to.balance) {
        return false
      }

      const { from, to } = this.exchange_form

      this.exchange_form.from = to
      this.exchange_form.to = from
      this.exchange_form.from.amount = ''
      this.exchange_form.to.amount = ''
      this.isSwap = !this.isSwap
      this.errorTxSwapF = ''
      this.errorTxSwapU = ''
    },
    liquidityCreateAdd() {
      // always reset list_signTx
      this.list_signTx = []
      this.curList = []
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('loading')
      let req = {
        addr: this.lpInfoData.addr,
        chain_type: 'bsc',
        coin: 'FSP',
        deadline: '',
        ftc_amount: this.exchange_form_liquidity.from.amount,
        slippage: 0.4,
        // to: this.UAddress,
        usdt_amount: this.exchange_form_liquidity.to.amount
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/create_add?addr=${req.addr}&chain_type=${req.chain_type}&coin=${req.coin}&ftc_amount=${req.ftc_amount}&slippage=${req.slippage}&usdt_amount=${req.usdt_amount}`, {}, 'get')
        .then(res => {
          this.isShowLoading = false
          this.list_liquidity_create_add = res.txs
          this.lengthTxn = this.list_liquidity_create_add.length
          this.add_fee = res.total_fee
          this.curIndex = 0
          // check bnb balance
          if (+this.bnb_balance < +this.add_fee) {
            this.messageFn(this.$t('fsp_trading.msg78'))
            this.isConfirm = false
          } else {
            // call dappSingFn
          }
        })
        .catch(res => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        })
    },
    removeLiquidity() {
      // remove localStorage
      localStorage.removeItem('lpInfoData')
      localStorage.removeItem('bnb_balance')
      if (this.lpInfoData.lp_amt == 0 || this.lpInfoData.lp_amt === undefined) {
        this.messageFn(this.$t('fsp_trading.msg61'))
        return false
      } else {
        this.goRouter('/fsp_trading_remove_liquidity')
        localStorage.setItem('lpInfoData', JSON.stringify(this.lpInfoData))
        localStorage.setItem('bnb_balance', this.bnb_balance)
      }
    },
    // swap
    handleInputSwapFrom(e) {
      if (this.storeChainType !== 'BSC') return
      if (this.typingTime) {
        clearTimeout(this.typingTime)
      }
      this.isLoadingButton = true
      this.typingTime = setTimeout(() => {

        if (!this.isSwap) {
          if (+e.target.value === 0 || e.target.value === '') {
            this.errorTxSwapF = this.$t('fsp_trading.msg6')
            this.exchange_form.to.amount = ''
            this.isLoadingButton = false
            return false
          } else {
            if (+e.target.value > +this.exchange_form.from.balance) {
              this.errorTxSwapF = this.$t('fsp_trading.msg49')
              this.exchange_form.to.amount = ''
              this.isLoadingButton = false
              return false
            }
          }
        } else {
          if (+e.target.value === 0 || e.target.value === '') {
            this.errorTxSwapF = this.$t('fsp_trading.msg6')
            this.exchange_form.to.amount = ''
            this.isLoadingButton = false
            return false
          } else {
            if (+e.target.value > +this.exchange_form.from.balance) {
              this.errorTxSwapF = this.$t('fsp_trading.msg49')
              this.exchange_form.to.amount = ''
              this.isLoadingButton = false
              return false
            }
          }
        }

        this.errorTxSwapF = ''
        this.exact_coin_id = ''
        // call api
        let req = {
          chain_type: 'bsc',
          pay_amount: +e.target.value,
          pay_coin_id: !this.isSwap ? this.UAddress : this.FAddress,
          rec_amount: 0,
          rec_coin_id: !this.isSwap ? this.FAddress : this.UAddress,
        }

        this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/swap_estimate?chain_type=${req.chain_type}&pay_amount=${req.pay_amount}&pay_coin_id=${req.pay_coin_id}&rec_amount=${req.rec_amount}&rec_coin_id=${req.rec_coin_id}`, {}, 'get')
          .then(res => {
            this.swapFee = Number(res.fee).toFixed(6)
            this.exact_coin_id = res.exact_coin_id
            this.exchange_form.to.amount = res.rec_amount
            this.isLoadingButton = false
          })
          .catch(res => {
            this.messageFn(res.msg)
            this.exchange_form.to.amount = ''
          })

      }, 1000);
    },
    handleInputSwapTo(e) {
      if (this.storeChainType !== 'BSC') return
      if (this.typingTime) {
        clearTimeout(this.typingTime)
      }
      this.isLoadingButton = true
      this.typingTime = setTimeout(() => {
        if (+e.target.value === 0 || e.target.value === '') {
          this.errorTxSwapF = this.$t('fsp_trading.msg6')
          this.exchange_form.from.amount = ''
          this.isLoadingButton = false
          return false
        }
        this.errorTxSwapU = ''
        this.exact_coin_id = ''
        this.isLoadingButton = true
        // call api
        let req = {
          chain_type: 'bsc',
          pay_amount: 0,
          pay_coin_id: !this.isSwap ? this.UAddress : this.FAddress,
          rec_amount: +e.target.value,
          rec_coin_id: !this.isSwap ? this.FAddress : this.UAddress,
        }

        this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/swap_estimate?chain_type=${req.chain_type}&pay_amount=${req.pay_amount}&pay_coin_id=${req.pay_coin_id}&rec_amount=${req.rec_amount}&rec_coin_id=${req.rec_coin_id}`, {}, 'get')
          .then(res => {
            // this.isShowLoading = false
            this.swapFee = res.fee
            this.exact_coin_id = res.exact_coin_id
            this.exchange_form.from.amount = res.pay_amount
            if (this.exchange_form.from.amount > +this.exchange_form.from.balance) {
              this.errorTxSwapF = this.$t('fsp_trading.msg49')
            } else {
              this.errorTxSwapF = ''
            }
            this.isLoadingButton = false
          })
          .catch(res => {
            // this.isShowLoading = false
            this.messageFn(res.msg)
          })
      }, 1000);
    },
    submitSwapFn() { // swap-step 1
      if (this.storeChainType !== 'BSC') return
      // always reset list_signTxSwap
      this.list_signTxSwap = []
      this.curList = []
      if (!this.exchange_form.from.amount || !this.exchange_form.to.amount) {
        this.messageFn(this.$t('fsp_trading.msg6'))
        return false
      }
      // if amount > balance
      if (+this.exchange_form.from.amount > +this.exchange_form.from.balance) {
        this.messageFn(this.$t('fsp_trading.msg49'))
        return false
      }
      // check bnb balance
      if (Number(this.bnb_balance) < Number(this.swapFee)) {
        this.messageFn(this.$t('fsp_trading.msg78'))
        return false
      }

      let slippage = Number(this.final_val_slippage / 100).toFixed(4)
      // call api
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('loading')
      let req = {
        addr: this.lpInfoData.addr,
        chain_type: 'bsc',
        exact_coin_id: this.exact_coin_id,
        pay_amount: this.exchange_form.from.amount,
        pay_coin_id: !this.isSwap ? this.UAddress : this.FAddress,
        rec_amount: this.exchange_form.to.amount,
        rec_coin_id: !this.isSwap ? this.FAddress : this.UAddress,
        slippage: +slippage,
        to: this.UAddress,
      }
      this.messageFn('click')
      // this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/create_swap?addr=${req.addr}&chain_type=${req.chain_type}&exact_coin_id=${req.exact_coin_id}&pay_amount=${req.pay_amount}&pay_coin_id=${req.pay_coin_id}&rec_amount=${req.rec_amount}&rec_coin_id=${req.rec_coin_id}&slippage=${req.slippage}`, {}, 'get')
      //   .then(res => {
      //     this.isShowLoading = false
      //     this.list_create_swap = res.txs
      //     this.lengthTxn = this.list_create_swap.length

      //     this.curIndex = 0
      //     // call dappSingFn
      //   })
      //   .catch(res => {
      //     this.isShowLoading = false
      //     this.messageFn(res.msg)
      //   })
    },
    async swapRecord() {

      let url = 'liquidity/swap_record_fsp'
      try {
        let req = {
          page: this.page,
          size: this.size,
          chain_type: 'bsc',
        }
        let res = await this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, req, 'get')
        this.list_record = res.list ? res.list : []
      } catch (res) {
        console.log(res.msg)
      }
    },
    // mining
    investMyFspInfo() {
      this.$ajax(`${this.Utils.URL.goAPPURL}invest/my_fsp_mining_profit_info`, {}, 'get')
        .then(res => {
          this.curObjInvestMyFspInfo = res
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },

    submitPickMiningProfit() {
      let fsp_p = this.fsp_price || this.curObjInvestMyFspInfo.price
      if (Number(this.curObjInvestMyFspInfo.unpicked) * Number(fsp_p) < 1) {
        this.messageFn(this.$t('fsp_trading.msg77', { x: Number(1 / fsp_p).toFixed(4) }))
      } else {
        this.curActLiquidity = 'pick'
        this.sbPickMiningProfit()
      }
    },
    sbPickMiningProfit() {

      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}invest/pick_fsp_mining_profit`, {}, 'post')
        .then(res => {
          if (res.result) {
            this.isShowLoading = false
            this.messageFn(this.$t('fsp_trading.msg64'), 'success')
            // reset invest, mining profit, mining profit pick record
            this.tabFn(3)
          } else {
            this.isShowLoading = false
            this.messageFn(res.msg)
          }

        })
        .catch(res => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        })
    },
    fspMiningProfit() {
      let req = {
        page: this.page,
        size: this.size,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}invest/fsp_mining_profit`, req, 'get')
        .then(res => {
          this.list_fsp_mining_profit = res.list ? res.list : []
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    fspMiningProfitPickRecord() {
      this.NProgress.start()
      let req = {
        page: this.page,
        size: this.size,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}invest/fsp_mining_profit_pick_record`, req, 'get')
        .then(res => {
          this.list_fsp_mining_profit_pick_record = res.list ? res.list : []
          this.NProgress.done()
        })
        .catch(res => {
          this.NProgress.done()
          this.messageFn(res.msg)
        })
    },
  },
  watch: {
    storeChangeAddressId(n, o) {
      if (n == '') {
        this.isShowWallet = true
      } else {
        this.isShowWallet = false
        if (!this.storeSingAuth && !this.isShowAuthTips) {
          this.isShowAuthTips = true
        }
      }
    },
    storeChainType(n, o) {
      this.exchange_form.from.amount = ''
      this.exchange_form.to.amount = ''
      if (n == '') {
        this.exchange_form.from.balance = '-'
        this.exchange_form.to.balance = '-'
      } else {
        this.getContractAddress('fsp')
        this.getContractAddress('udt') // need change when deploy prod
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.h5_fsp_trading_view {
  padding-bottom: 0.2rem;
  overflow: hidden;

  .container {
    padding: 0.12rem 0.16rem;
  }

  .record_tab {
    display: flex;
    justify-content: space-between;

    .item {
      flex-basis: calc(100% / 3);
      text-align: center;
      border: 1px solid #EE9922;
      border-left: 0;
      padding: 0.03rem;
      font-weight: 500;
      color: #EE9922;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      min-height: 33.24px;
      box-sizing: border-box;

      &:nth-child(1) {
        border-left: 1px solid #EE9922;
        border-radius: 0.05rem 0 0 0.05rem;
      }

      &:nth-child(3) {
        border-radius: 0 0.05rem 0.05rem 0;
      }

      &.item_cur {
        background: #EE9922;
        color: #fff;
      }
    }
  }

  .form_exchange {
    margin-top: 0.1rem;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .1rem;

      .text {
        font-size: 0.15rem;
        font-weight: 600;
        color: #224455;
      }

      .right {
        display: flex;
        align-items: center;

        .icon {
          line-height: 0;

          img {
            width: 0.3rem;

            &.active {
              width: 0.25rem;
            }
          }
        }

        .currency {
          font-size: 0.16rem;
          font-weight: 500;
          color: #224455;
          text-transform: uppercase;
          margin-left: 0.05rem;
        }

        .tag {
          font-size: 0.1rem;
          color: #F4BB0B;
          background: #F4BB0B1A;
          text-transform: uppercase;
          line-height: 0.16rem;
          padding: 0 0.06rem;
          border-radius: 0.1rem;
          font-weight: 500;
          margin-left: 0.06rem;
        }
      }
    }

    .info_box {
      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.13rem;
        color: #778E99;
        margin-top: 0.06rem;
        margin-top: 0.18rem;
      }
    }

    .input_sub {
      background: #D2D8DA;
      color: #98A8B2;
      font-size: 0.16rem;
      text-align: center;
      line-height: 0.44rem;
      // margin-top: 0.25rem;
      font-weight: 500;
      border-radius: 0.06rem;
      cursor: pointer;
      width: 100%;
      border: 0;
      padding: 0;

      &.input_sub_cur {
        background: #FFA94D;
        color: #fff;
      }
    }


  }

  .input_box {
    background: $BG4;
    border-radius: 0.06rem;
    padding: 0 0.12rem;
    border: 0.5px solid #778E9940;

    .input_tx1 {
      display: inline-block;
      font-size: 0.2rem;
      line-height: 0.54rem;
      font-weight: 500;
      width: 100%;

    }
  }

  .miner_fee {
    margin: 0.16rem -0.16rem 0.13rem;
    padding: 0 0.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: 0.28rem;
      background: #F7F7F7;
      z-index: -1;
    }

    .icon {
      line-height: 0;
      border: 0.07rem solid #F7F7F7;
      border-radius: 50%;

      img {
        width: 0.3rem;
      }
    }

    .text {
      font-size: .13rem;
      color: #778E99;
    }
  }

  .box_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F7F7F7;
    padding: 0.15rem 0.18rem 0.13rem 0.14rem;
    margin: 0.2rem 0 0;
    border-radius: 0.06rem;

    .left {
      display: flex;
      align-items: center;

      .icon {
        img {
          width: 0.5rem;
        }
      }

      .title {
        color: #224455;
        margin-left: 0.1rem;

        .tx1 {
          font-size: 0.16rem;
          font-weight: 400;
        }

        .tx2 {
          font-size: 0.13rem;
          font-weight: 400;
          color: #778E99;
          margin-top: 0.02rem;

          span {
            font-size: 0.17rem;
            font-weight: 600;
            color: #224455;
            margin-right: 0.1rem;
          }
        }
      }
    }

    .right {
      img {
        width: 0.25rem;
      }
    }
  }

  .box_record_exchange {
    margin-top: 0.3rem;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.1rem;

      .text {
        font-size: 0.15rem;
        color: #224455;
        font-weight: 600;
      }

      span {
        margin-right: 0.05rem;
      }

      .link {
        display: flex;
        align-items: center;
        color: #EE9922;
        font-size: 0.13rem;

        img {
          width: 0.06rem;
        }
      }
    }

    .item {
      background: #F7F7F7;
      border-radius: .06rem;
      padding: 0.1rem 0.16rem;
      margin-bottom: 0.08rem;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .date {
          font-size: 0.13rem;
          color: #224455;
        }

        .hyperlink {
          display: flex;
          align-items: center;

          span {
            font-size: 0.13rem;
            margin-right: 0.05rem;
            font-weight: 500;
          }

          &.review {
            span {
              color: #EE9922;
            }

            svg {
              path {
                stroke: #EE9922;
              }
            }
          }

          &.fail {
            span {
              color: #DD4466;
            }

            svg {
              path {
                stroke: #DD4466;
              }
            }
          }

          &.success {
            span {
              color: #778E99;
            }

            svg {
              path {
                stroke: #778E99;
              }
            }
          }
        }
      }

      .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.15rem;

        .left {
          display: flex;
          align-items: center;

          .icon {
            line-height: 0;

            img {
              width: 0.3rem;
            }
          }

          .currency {
            font-size: 0.16rem;
            font-weight: 500;
            color: #224455;
            text-transform: uppercase;
            margin-left: 0.05rem;
          }

          .tag {
            font-size: 0.1rem;
            color: #F4BB0B;
            background: #F4BB0B1A;
            text-transform: uppercase;
            line-height: 0.16rem;
            padding: 0 0.06rem;
            border-radius: 0.1rem;
            font-weight: 500;
            margin-left: 0.06rem;
          }
        }

        .right {
          display: flex;
          align-items: center;

          .icon {
            line-height: 0;

            img {
              width: 0.3rem;
            }
          }

          .currency {
            font-size: 0.16rem;
            font-weight: 500;
            color: #224455;
            text-transform: uppercase;
            margin-left: 0.05rem;
          }

          .tag {
            font-size: 0.1rem;
            color: #F4BB0B;
            background: #F4BB0B1A;
            text-transform: uppercase;
            line-height: 0.16rem;
            padding: 0 0.06rem;
            border-radius: 0.1rem;
            font-weight: 500;
            margin-left: 0.06rem;

            &.main {
              background: #1BA27A1A;
              color: #1BA27A;
              text-transform: capitalize;
            }
          }
        }

        .arrow {
          line-height: 0;

          img {
            width: 0.16rem;
          }
        }
      }

      .box_amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.15rem;

        p {
          font-size: 0.16rem;
          font-weight: 600;
          color: #224455;
        }
      }
    }
  }

  .com_popup_main .com_popup_box .com_popup_from_box {
    padding-top: 0.06rem;
  }

  .com_popup_main .com_popup_box .com_popup_from_box .com_popup_input_box {
    background: #F0F0F0;
  }

  .box_information_liquidity {
    background: #EE9922;
    padding: 0.05rem;
    margin-top: 0.23rem;
    border-radius: 0.06rem;

    .top {
      background: #fff;
      padding: 0.1rem 0.05rem 0.1rem;
      border-radius: 0.06rem;

      .mine {
        color: #224455;
        font-size: 0.14rem;
        // line-height: 0.196rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #2244551A;
        padding: 0 0.05rem 0.1rem;
        line-height: 0.196rem;

        .text {
          color: #3E5B6A;
          border-right: 0.5px solid #2244551A;
          padding-right: 0.1rem;
          flex: 0 0 27%;
        }

        .text_convert {
          font-size: 0.12rem;
          color: #224455;
          font-weight: 400;
        }

        &.custom {
          padding-top: 0.1rem;
          padding-bottom: 0.1rem;
          line-height: 1;

          .right {
            display: flex;
            justify-content: space-between;
            flex: 1;
            padding-left: 0.13rem;
          }
        }

        &_left {
          flex: 0 0 45%;
          padding-right: 0.1rem;
          border-right: 0.5px solid #2244551A;
          text-align: center;

          .text {
            padding-right: 0;
            border: 0;
          }

        }
      }

      .remove_available {
        margin-top: 0.12rem;
        font-size: 0.12rem;
        color: #224455;
        display: flex;
        padding: 0 0.05rem;
        justify-content: space-between;

        .text {
          flex: 0 0 25%;
        }

        &+.remove_available {
          margin-top: 0.1rem;
        }
      }
    }

    .bottom {
      padding: 0 0.05rem;
      margin-top: 0.1rem;

      .title {
        color: #fff;
        font-size: 0.12rem;
        font-weight: 600;
      }

      ul {
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0.05rem;

          .left {
            display: flex;
            align-items: center;

            .icon {
              line-height: 0;

              img {
                width: 0.24rem;
              }
            }

            .text {
              color: #fff;
              font-size: 0.12rem;
              font-weight: 500;
              margin-left: 0.05rem;
              padding-top: 0.02rem;
            }
          }

          .right {
            color: #fff;
            font-size: 0.12rem;
            font-weight: 400;
          }
        }
      }
    }
  }

  .box_exchange_liquidity {
    .title {
      .balance {
        color: #778E99;
        font-size: 0.13rem;
        text-align: right;
      }
    }

    .miner_fee {
      margin-top: 0.2rem;
    }

    .grp_button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;

      .input_sub {
        flex: 0 0 48%;
        margin: 0;

        &.green {
          background: #00968D;
          color: #fff;
        }

        &.orange {
          background: #FFA94D;
          color: #fff;
        }
      }
    }

    .input_box {
      border: 0;

      .input_tx1 {
        line-height: 0.64rem;

        &::placeholder {
          font-size: 0.16rem;
        }
      }
    }
  }

  .box_record_exchange_liquidity {
    margin-top: 0.55rem;

    .item {
      .top {
        .hyperlink {
          color: #EE9922;
        }
      }

      .inner {
        .icon {
          line-height: 0;

          img {
            // width: 0.3rem;
            width: 0.2rem;
            margin-right: 0.05rem;
          }
        }

        .pair_currency {
          display: flex;
          align-items: center;
          flex: 1;
          font-size: 0.16rem;
          color: #224455;
          font-weight: 500;
          padding-left: 0.05rem;

          .pl_10 {
            padding-left: 0.1rem;
          }

          .pl_20 {
            padding-left: 0.20rem;
          }
        }
      }

      .box_status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.14rem;
        color: #224455;
        margin-top: 0.1rem;

        .status {
          font-weight: 600;
          padding-right: 0.1rem;
          font-size: 0.13rem;

          &.removed {
            color: #DD4466;
          }

          &.removing {
            color: #FFA94D;
          }

          &.added {
            color: #1BA27A;
          }
        }

        .flex {
          flex: 1;
          display: flex;
          align-items: center;
          line-height: .18rem;

          .amount_exchange {
            padding-left: 0.1rem;
            margin-left: 0.15rem;
            border-left: 1px solid #B1BDC2;
          }
        }
      }
    }
  }

  .com_popup_main {
    .com_popup_info_box {
      padding-top: 0.2rem;

      .title {
        font-size: 0.18rem;
        font-weight: 600;
        color: #224455;
        margin-bottom: 0.25rem;
        text-align: center;
      }
    }
  }

  .com_popup_main .com_popup_box .com_popup_from_box .com_popup_btn_box .com_popup_input_btn.custom {
    margin-top: 0;
    background: #EE9922;
    color: #fff;
  }

}

.box_nav {
  display: flex;
  align-items: center;
  position: relative;
  height: 0.25rem;
  text-align: center;

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .title {
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 20px;
    font-size: 16px;
    color: #17233d;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.box_add_liquidity {
  .heading {
    background: #F4F4F4;
    padding: 0.15rem 0.05rem 0.14rem;
    border-radius: 0.06rem;

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.05rem 0.16rem;
      border-bottom: 1px solid #2244551A;

      .left {
        display: flex;
        align-items: center;

        .icon {
          line-height: 0;
          border-right: 1px solid #2244551A;
          padding-right: 0.05rem;

          img {
            width: 0.35rem;
            margin-right: 0.02rem;
          }
        }

        .text {
          font-size: 0.14rem;
          color: #224455;
          font-weight: 500;
          padding-top: 0.02rem;
          padding-left: 0.08rem;
        }
      }

      .right {
        font-size: 0.14rem;
        color: #224455;
        font-weight: 500;
        padding-top: 0.02rem;
        flex: 0 0 40%;
        word-break: break-all;
        text-align: right;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.12rem;
      padding: 0 0.05rem;

      .left {
        font-size: 0.12rem;
        color: #224455;
        flex: 0 0 25%;
      }

      .right {
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        color: #224455;
        flex: 1;

        p {
          padding-left: 0.1rem;
          line-height: 0.16rem;
          flex: 0 0 50%;

          span {
            word-break: break-all;
          }

          &:nth-child(1) {
            border-right: 1px solid #2244551A;
            padding: 0 0.1rem 0 0;
          }
        }
      }
    }
  }

  .box_amount_liquidity_button {
    font-size: 0.16rem;
    text-align: center;
    line-height: 0.44rem;
    margin-top: 0.8rem;
    font-weight: 500;
    border-radius: 0.06rem;
    cursor: pointer;
    background: #FFA94D;
    color: #fff;
    width: 100%;
  }
}

.box_amount_liquidity {
  margin-top: 0.2rem;

  .title {
    font-size: 0.15rem;
    color: #224455;
    font-weight: 500;
    margin-bottom: 0.1rem;
  }

  .inner {
    background: #F4F4F4;
    padding: 0.08rem 0.07rem 0.12rem;
    border-radius: 0.06rem;

    .value_in_percent {
      background: #fff;
      text-align: center;
      border-radius: 0.05rem;
      height: 0.42rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.28rem;
      color: #224455;
      font-weight: 500;
    }

    .group_button {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        border: 0;
        background: #E4E4E4;
        flex: 0 0 23%;
        color: #B6C2C8;
        font-size: 0.14rem;
        font-weight: 600;
        height: 0.25rem;
        border-radius: 0.05rem;

        &.active {
          background: #FFA94D;
          color: #fff;
        }
      }
    }
  }

}

.expect_receive {
  margin-top: 0.17rem;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.1rem;

    .text {
      font-size: 0.15rem;
      color: #224455;
      font-weight: 500;
    }

    .fee {
      font-size: 0.13rem;
      color: #778E99;
    }
  }

  .inner {
    padding: 0.1rem;
    background: #F4F4F4;
    border-radius: 0.06rem;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;

      &:nth-child(1) {
        margin-top: 0;
      }

      .left {
        display: flex;
        align-items: center;

        .icon {
          line-height: 0;

          img {
            width: 0.3rem;
          }
        }

        .text {
          font-size: 0.16rem;
          color: #224455;
          font-weight: 500;
          margin-left: 0.05rem;
        }
      }

      .right {
        font-size: 0.16rem;
        color: #224455;
        font-weight: 500;
      }
    }
  }
}

.box_price {
  margin-top: 0.2rem;

  .title {
    font-size: 0.15rem;
    color: #224455;
    font-weight: 500;
    margin-bottom: 0.1rem;
  }

  .item {
    padding: 0.1rem;
    background: #F4F4F4;
    border-radius: 0.06rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.16rem;
    color: #224455;
    font-weight: 500;

    .left {
      flex: 1;
    }

    .right {
      flex: 0 0 50%;
      text-align: right;
      word-break: break-all;
    }
  }
}

.box_record_liquidity {
  margin-top: 0.25rem;

  .title {
    font-size: 0.16rem;
    color: #224455;
    font-weight: 600;
    margin-bottom: 0.15rem;
  }

  .item {
    background: #F4F4F4;
    margin-bottom: 0.1rem;
    padding: 0.14rem;
    border-radius: 0.06rem;

    .hash {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #00968D;
      font-size: 0.13rem;
      padding-bottom: 0.12rem;
      border-bottom: 1px solid #2244551A;
      margin-bottom: 0.12rem;

      .hash_id {
        word-break: break-all;
      }
    }

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        p {
          font-size: 0.13rem;
          color: #778E99;

          &+p {
            margin-top: 0.07rem;
          }
        }
      }

      .right {
        font-size: 0.13rem;

        .fail {
          color: #DD4466;
        }

        .success {
          color: #EE9922;
        }
      }
    }
  }

  .box_tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.28rem -0.16rem 0.15rem;

    &_item {
      text-align: center;
      flex: 0 0 50%;
      height: 0.44rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #778E9929;
      font-size: 0.14rem;
      color: #778E99;

      &.active {
        color: #EE9922;
        border-color: #EE9922;
      }
    }
  }

  .input_sub {
    font-size: 0.16rem;
    text-align: center;
    line-height: 0.44rem;
    margin-top: 0.25rem;
    font-weight: 500;
    border-radius: 0.06rem;
    cursor: pointer;
    background: #D2D8DA;
    color: #98A8B2;
    width: 100%;
    border: 0;

    &.input_sub_cur {
      background: #FFA94D;
      color: #fff;
    }
  }
}

.box_record_reward {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.13rem;

    .heading {

      color: #224455;
      font-weight: 600;
    }

    .amount {
      color: #EE9922;
      font-weight: 600;
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.12rem;
    color: #778E99;
    margin-top: 0.07rem;
  }
}

.box_introduce {
  border: 0.03rem solid #F7F7F7;
  border-radius: 0.06rem;
  padding: 0.16rem;
  overflow: hidden;

  .top {
    padding-bottom: 0.05rem;
    border-bottom: 1px solid #778E991F;

    .heading {
      font-size: 0.13rem;
      color: #778E99;
      font-weight: 600;
    }

    .exchange {
      font-size: 0.14rem;
      color: #778E99;
      font-weight: 600;
    }

    .amount {
      color: #EE9922;
      font-size: 0.2rem;
      padding-right: 0.1rem;

      .currency {
        font-size: 0.14rem;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 0.16rem;

    .text {
      font-size: 0.13rem;
      color: #778E99;
    }

    .value {
      font-size: 0.13rem;
      color: #224455;
      font-weight: 600;
    }

    .right {
      text-align: right;
    }

    &.custom {
      margin-top: 0.2rem;

      .left {
        flex: 0 0 50%;
        border-right: 1px solid #778E991F;
        padding: 0.05rem 0;
      }

      .right {
        flex: 0 0 50%;
        padding: 0.05rem 0;
      }

      &.custom_1 {
        margin-top: 0.05rem;
        padding-top: 0.07rem;
        border-top: 0.5px solid #778E991F;
      }
    }
  }
}

.chart-container {
  // height: 2.6rem;
  // margin-right: -0.1rem;
}

.error_tx {
  margin-top: 0.05rem;
  font-size: 0.12rem;
  color: $T9;
}

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  padding: 5px;
  background: rgba(255, 255, 255, 1);
  border: 0.5px solid #EE9922;
  border-radius: 5px;
  font-size: 11px;
  pointer-events: none;
  z-index: 10;
  min-width: 120px;
}

.box_market {
  background: #F7F7F7;
  border-radius: 0.06rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.13rem 0.2rem 0.12rem;
  margin-top: 0.24rem;

  .left {
    display: flex;
    align-items: center;

    .icon {
      img {
        width: 0.5rem;
      }
    }

    .tx_16 {
      font-size: 0.16rem;
      line-height: 0.22rem;
      margin-bottom: 0.02rem;
    }

    .tx_17 {
      font-weight: 500;
      font-size: 0.17rem;
      line-height: 0.22rem;
    }

    .tx_13 {
      padding-left: 0.1rem;
      color: #778E99;
      font-size: 0.13rem;
    }
  }

  .right {
    img {
      width: 0.25rem;
    }
  }
}

.box_setting {
  display: inline-flex;
  align-items: center;
  margin: 0.2rem 0;

  span {
    color: #224455;
    font-size: 0.15rem;
    font-weight: 500;
    padding-right: 0.03rem;
  }

  img {
    width: 0.22rem;

  }
}

.box_dialog_setting {
  .inner {
    margin-top: -0.4rem;

    .title {
      font-size: 0.18rem;
      color: #224455;
      font-weight: 500;
      text-align: center;
      margin-bottom: 0.18rem;
    }

    .sub {
      font-size: 0.16rem;
      color: #224455;
      margin-bottom: 0.06rem;
    }

    .setting_index {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.22rem;

      p {
        flex-basis: calc(22%);
        text-align: center;

        span {
          background: #F0F0F0;
          border-radius: 0.05rem;
          width: 100%;
          height: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.14rem;
          color: #B6C2C8;
          font-weight: 600;

          &.active {
            background: #EE9922;
            color: #fff;
          }
        }
      }
    }

    .box_input {
      display: flex;
      align-items: center;
      border: 1px solid #EE9922;
      border-radius: 0.05rem;
      color: #EE9922;
      background: #FFF5E8;

      input {
        flex: 0 0 92%;
        height: 0.24rem;
        padding: 0 0.12rem 0 0.1rem;
      }
    }

    .note {
      font-size: 0.13rem;
      color: #778E99;
      margin-top: 0.02rem;
    }
  }

  .dialog-footer {
    margin-top: -0.15rem;
  }

  .el-button {
    width: 100%;
    background: #EE9922;
    color: #fff;
    border: 0;
    font-size: 0.16rem;

    &.is-disabled {
      background: #D2D8DA;
      color: #98A8B2;
    }
  }
}

:deep(.el-dialog) {
  border-radius: 0.05rem;
}

.box_connect_wallet {
  padding: 0.18rem 0 0.12rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: calc(100% + 0.32rem);
    height: 0.01rem;
    border-bottom: 0.01rem solid #2244551A;
    position: absolute;
    bottom: 0;
    left: -0.16rem;
    right: -0.16rem;
  }

  .title {
    color: #EE9922;
    font-size: 0.13rem;
  }
  .tx5 {
    display: inline-block;
    color: $FFF;
    font-size: 0.13rem;
    border-radius: 0.13rem;
    font-weight: bold;
    background: $T8;
    padding: 0.02rem 0.12rem .03rem;
    min-width: 0.8rem;
    text-align: center;
  }
}

.address_box {
  background: $BG2;
  padding: 0.03rem 0.1rem 0.03rem 0.03rem;
  border-radius: 0.13rem;
  font-size: 0.13rem;
  display: flex;
  align-items: center;
  text-align: center;

  .tx1 {

    border-radius: 0.13rem;
    min-width: 0.47rem;
    line-height: 0.2rem;
    color: $FFF;
    margin-right: 0.05rem;
  }

  .tx2 {
    font-size: 0.13rem;
    color: $T6;
  }

  .tx3 {
    background: $T8;
  }

  .tx4 {
    background: $T14;
  }

  .tx6 {
    background: $T16;
  }
}



.exchange_item_main {
  display: flex;
  align-items: center;

  flex-wrap: wrap;

  // padding-bottom: 0.5rem;
  .exchange_item_box {
    width: 50%;
    text-align: center;
    margin-bottom: 0.3rem;

    .tx1 {
      color: $T6;
      font-size: 0.16rem;
      // font-weight: bold;
      margin-top: 0.05rem;
    }

  }

}

.wallet_address_box {
  .wallet_address_item {
    color: $T6;
    font-size: 0.15rem;
    margin-bottom: 0.24rem;

    .tx1 {
      font-weight: bold;
      margin-bottom: 0.1rem;
    }

    .tx2 {
      padding: 0.12rem 0 0.12rem 0.12rem;
      justify-content: space-between;
      background: $BG3;
      border: 0.5px solid rgba(119, 142, 153, 0.25);
      border-radius: 0.06rem;

      .r_box {
        padding: 0.1rem 0.12rem 0.1rem 0.1rem;
      }
    }

    .tx3 {
      word-wrap: break-word;
      word-break: break-word;
    }
  }
}

:deep(.com_popup_main) {
  .com_popup_box {
    .com_popup_info_box {
      padding-top: 0;
    }

    .comp_popup_link_box {
      background: transparent;

      .d_flex_cen {
        justify-content: flex-end;
      }
    }
  }
}
</style>