<template>
  <article class="h5_loan_details_view">
    <section class="assets_main">
      <div class="assets_box">
        <p class="tx1 mb_5">{{ $t('my_total_loan_amount') }}({{$t('folded')}})</p>
        <p class="tx2 tx2_br mb_10">{{ resData.borrow_total ? resData.borrow_total : '-' }} {{ resData.symbol }}</p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('pledge_amount') }}({{$t('folded')}})</span>
          <span class="tx2">{{ resData.pledge_amount ? resData.pledge_amount : '-' }} {{ resData.symbol }}</span>
        </p>
        <p class="d_flex_space mb_10">
          <span class="tx1">{{ $t('loan_interest') }}({{$t('folded')}})</span>
          <span class="tx2">{{ resData.interest_amount ? resData.interest_amount : '-' }} {{ resData.symbol }}</span>
        </p>
        <p class="d_flex_space">
          <span class="tx1">{{ $t('amount_returned') }}({{$t('folded')}})</span>
          <span class="tx2">{{ resData.wait_amount ? resData.wait_amount : '-' }} {{ resData.symbol }}</span>
        </p>
      </div>
      <div class="btn_box d_flex_space">
        <span class="btn_input" @click="goRouter('/loan_order')">{{ $t('loan_records') }}</span>
        <span class="btn_input" @click="goLinkFn">{{ $t('loan_wallet') }}</span>
      </div>


    </section>
    <section class="loan_box">
      <p class="tx1">{{ $t('margin_Loan') }}</p>
      <div class="icon_box d_flex_space">
        <div class="item">
          <p><i class="h5_icon icon_loan1"></i></p>
          <p class="tx2">{{ $t('loan_details_view.tips1') }}</p>
        </div>
        <div class="item">
          <p><i class="h5_icon icon_loan2"></i></p>
          <p class="tx2">{{ $t('loan_details_view.tips2') }}%</p>
        </div>
        <div class="item">
          <p><i class="h5_icon icon_loan3"></i></p>
          <p class="tx2">{{ $t('loan_details_view.tips3') }}</p>
        </div>
      </div>
      <p class="btn_input" @click="goRouter('/loan_form')">{{ $t('loaned') }}</p>
    </section>
  </article>
</template>

<script>
export default {
  name: "loan_details",
  data () {
    return {
      num: '',
      isShowTips: false,
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      tabIndex: 2,
      resData: {},

    }
  },

  created () {
    this.resInit()

  },
  methods: {
    goLinkFn () {
      if (!this.resData.is_wallet_addr) {
        this.messageFn(this.$t('tips_view.msg63'))
        return false
      }
      this.goRouter('/loan_wallet')
    },
    resInit () {
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/borrow_home_page`, {}, 'get')
          .then(res => {
            this.resData = res ? res : {}
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
  },
}
</script>

<style lang="scss">
.h5_loan_details_view{
  padding: 0.16rem;
  .assets_main{
    padding: 0.16rem;
    background: $BG2;
    border-radius: 0.06rem;
    margin-bottom: 0.32rem;
    .tx1{
      font-size: 0.13rem;
      color: $T6;
    }
    .tx2{
      color: $T6;
      font-size: 0.17rem;
      font-weight: bold;
    }
    .tx2_br{
      border-bottom: 1px solid $BR2;
      padding-bottom: 0.1rem;
    }
    .btn_box{
      padding-top: 0.2rem;
      .btn_input{
        width: 1.5rem;
        line-height: 0.38rem;
        color: $FFF;
        font-size: 0.13rem;
        text-align: center;
        background: $T8;
        border-radius: 0.06rem;
      }
    }
  }

  .loan_box{
    .tx1{
      font-size: 0.15rem;
      font-weight: bold;
      color: $T6;
      margin-bottom: 0.2rem;
      text-align: center;
    }
    .tx2{
      font-size: 0.12rem;
      font-weight: bold;
      color: $T6;

      text-align: center;
    }
    .item{
      width: 33.33%;
      text-align: center;
    }
    .btn_input{
      margin-top: 0.3rem;
      text-align: center;
      line-height: 0.44rem;
      color: $FFF;
      font-weight: bold;
      border-radius: 0.06rem;
      background: $T8;
      font-size: 0.16rem;
    }
  }

}
</style>