<template>
  <article class="comp_bind_wallet_id_view">
    <article class="com_popup_main" v-show="isShow">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">

        <div class="com_popup_head">
          <span class="title_tx">{{$t('index_view.msg2')}}</span>
          <i class="h5_icon icon_close" @click="this.$emit('on-hide')" ></i>
        </div>
        <div class="comp_popup_link_box">
          <p class="tx1 d_flex_cen" @click="goLinkFn(this.Utils.URL.h5URL + '/created_wallet_id?langId=' + storeLang)"><span>{{$t('index_view.msg3')}}</span> <i class="h5_icon icon_orange_arrow ml_3"></i></p>
        </div>
        <div class="com_popup_info_box">

          <div class="com_popup_from_box">

            <div class="com_popup_input_box mb_20" :class="errorTx ? 'com_popup_input_error' : ''" >
              <div>
                <input type="text" style="width: 100%" v-model="walletId" class="com_popup_input_tx" :placeholder="$t('index_view.msg4')"/>
              </div>
              <div class="error_tx" v-show="errorTx">{{errorTx}}</div>
            </div>
            <p class="tx2 mb_10">{{$t('index_view.msg5')}}</p>
            <p class="tx3 mb_5">{{$t('index_view.msg6')}}</p>
            <p class="tx3 mb_5">{{$t('index_view.msg7')}}</p>
            <p class="tx3 mb_5">{{$t('index_view.msg8')}}</p>

            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('bind')" @click="subWalletFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>
  </article>

</template>

<script>
export default {
  name: "compBindWalletId",
  data () {
    return {
      walletId: '',
      errorTx: '',
    }
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async subWalletFn () {
      if (!this.walletId) {
        this.messageFn(this.$t('index_view.msg4'))
        return false
      }
      this.storeChainType = this.storeChainType ? this.storeChainType : window.sessionStorage.getItem('storeChainType') ? window.sessionStorage.getItem('storeChainType') : ''

      let time = parseInt(new Date().getTime() / 1000)
      let sing = await this.dappSingFn('bind:' + this.walletId)

      if (!sing) {
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }

      let req = {
        wallet_id: this.walletId,
        wallets: [
          {
            addr: this.storeChangeAddress,
            chain_type: this.storeChainType.toLocaleLowerCase(),
            features: time + '',
            signature: sing
          }
        ]
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/create_wallet`, req, 'post', 25000, false, true)
          .then(res => {
            this.isLoad = false

            this.$emit('on-hide')
            window.sessionStorage.setItem('storeWalletId', this.walletId)
            this.$store.commit('setAddressId', this.walletId)
            window.Wallet_Uuid = this.walletId
            this.messageFn(this.$t('tips_view.msg14'))
          })
          .catch(res => {
            this.isLoad = false
            if (res.code == 101) {
              this.messageFn(this.$t('tips_view.msg16'))
              return false
            }
            if (res.code == 102) {
              this.messageFn(this.$t('tips_view.msg17'))
              return false
            }
            if (res.code == 103) {
              this.messageFn(this.$t('tips_view.msg18'))
              return false
            }
            this.messageFn(this.$t('tips_view.msg15'))
          })
    },
  }
}
</script>

<style lang="scss">


</style>