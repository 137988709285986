<template>
  <article class="h5_fsp_trading_remove_liquidity_view">
    
    <div class="container">
      <div class="box_add_liquidity">
        <div class="heading">
          <div class="top">
            <div class="left">
              <p class="icon">
                <img src="../../assets/img/icon/icon_fsp.png" alt="" style="width: 0.28rem; margin-right: 0.05rem;">
                <img src="../../assets/img/icon/icon_usdt_bnb.png" alt="">
              </p>
              <p class="text">FSP-USDT </p>
              <p class="text">LP</p>
            </div>
            <p class="right tx_r">{{ Number(lpInfoData.lp_amt).toFixed(6) }} </p>
          </div>
          <div class="bottom">
            <p class="left">{{ $t('fsp_trading.msg32') }}</p>
            <div class="right">
              <p class="tx_r"><span>{{ Number(lpInfoData.fsp_amt).toFixed(6) }} FSP</span> </p>
              <p class="tx_r"><span>{{ lpInfoData.usdt_amt }}</span> USDT</p>
            </div>
          </div>
        </div>

        <div class="box_amount_liquidity">
          <p class="title">{{ $t('fsp_trading.msg33') }}</p>
          <div class="inner">
            <p class="value_in_percent">{{ amount_liquidity }}%</p>
            <div class="custom-slider">
              <Slider v-model="amount_liquidity"></Slider>
            </div>
            <div class="group_button">
              <button v-for="item in 4" :key="item" @click="handleClickAddPercentLiquidity(item)"
                :class="{ active: amount_liquidity == item * 25 }">
                {{ item * 25 }}%
              </button>
            </div>
          </div>
        </div>
        <div class="expect_receive">
          <div class="title">
            <p class="text">{{ $t('fsp_trading.msg8') }}</p>
            <p class="fee tx_r">{{ $t('fsp_trading.msg19') }}：{{ rm_fee }} BNB</p>
          </div>
          <div class="inner">
            <div class="item">
              <p class="left">
                <span class="icon">
                  <img src="../../assets/img/icon/icon_fsp.png" alt="" style="width: 0.24rem;">
                </span>
                <span class="text">FSP</span>
              </p>
              <div class="right">
                <p>{{ Number((+lpInfoData.fsp_amt * amount_liquidity) / 100).toFixed(6) }} FSP</p>
              </div>
            </div>
            <div class="item">
              <p class="left">
                <span class="icon">
                  <img src="../../assets/img/icon/icon_usdt_bnb.png" alt="">
                </span>
                <span class="text">{{ coin2 }}</span>
              </p>
              <div class="right">
                <p>{{ Number((+lpInfoData.usdt_amt * amount_liquidity) / 100).toFixed(2) }} USDT</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box_price">
          <p class="title">{{ $t('fsp_trading.msg60') }}</p>
          <div class="item">
            <p class="left">1 FSP =</p>
            <p class="right">{{ Number(lpInfoData.fsp_price).toFixed(4) }} USDT</p>
          </div>
        </div>
        <input type="button" class="box_amount_liquidity_button" :value="$t('fsp_trading.msg20')"
          @click="liquidityCreateRm" />
      </div>
    </div>
  </article>
  <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
</template>
<script>
import comLoading from '../../components/loading/index'
import icon_usdt_bnb from '../../assets/img/icon/icon_usdt_bnb.png'
import icon_fsp_bnb from '../../assets/img/icon/icon_fsp_bnb.png'
export default {
  name: 'FspTradingRemoveLiquidityView',
  components: {
    comLoading,
  },

  data() {

    const t = this.$t.bind(this);
    return {
      icon_usdt_bnb,
      icon_fsp_bnb,
      isShowLoading: false,
      status: 'loading',
      text: t('loading'),
      isAjax: false, 
      coin2: process.env.VUE_APP_COIN2,
      lpInfoData: {},
      amount_liquidity: 25,
      list_signTxRm: [],
      list_liquidity_create_rm: [],
      lengthTxn: 0,
      curList: [],
      Wallet_Uuid: '',
      isuid: false,
      bnb_balance: 0,
      curIndex: 0,
      rm_fee: null,
      isGetAuth: false,
      
    }
  },
  beforeUnmount() {
    window.getUUID = null
    window.getAuth = null
    window.signFn = null
    window.xxx_Authentication = null
  },
  created() {
    window.getUUID = this.getUUID
    window.getAuth = this.getAuth
    window.signFn = this.signFn
    this.getLangFn().then((res) => {
      this.Utils.tool.app.setHeader({ title: this.$t('fsp_trading.msg31') });
      this.Utils.tool.app.getUUID({ jsCallBack: 'getUUID' })
      // get lpInfoData from localstorage
      this.lpInfoData = JSON.parse(localStorage.getItem('lpInfoData'))
      this.bnb_balance = localStorage.getItem('bnb_balance')
      setTimeout(() => {
        if (this.Wallet_Uuid == '' && !this.isuid) {
          this.messageFn(this.$t('community_view.msg_190'))
        }
      }, 2000)
    });
    
// this.getUUID('ID1cf9118141e9ccee214190f6a15e476c87abbcc8') // joseph wallet 
  },
  methods: {
    getUUID(res) {
      this.isuid = true
      if (res) {
        this.Wallet_Uuid = res
        window.Wallet_Uuid = res
        this.Utils.tool.app.getAuth({ jsCallBack: 'getAuth' })
        setTimeout(() => {this.getAuth()}, 200)
      } else {
        this.messageFn(this.$t('voteActivityView.placeholder_tips7'))
        return false
      }
    },
    getAuth (res) {
      if (!this.isGetAuth) {
        window.xxx_Authentication = res
      }
      this.isGetAuth = true
    },
    handleClickAddPercentLiquidity(item) {
      this.amount_liquidity = item * 25
    },
    liquidityCreateRm() {
      // check bnb balance
      // if (Number(this.bnb_balance) < Number(this.lpInfoData.rm_fee)) {
      //   this.messageFn(this.$t('fsp_trading.msg78'))
      //   return false
      // }
      // always reset list_signTxRm
      this.list_signTxRm = []
      this.curList = []
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('loading')
      let req = {
        addr: this.lpInfoData.addr,
        chain_type: 'bsc',
        coin: 'FSP',
        deadline: '',
        lp_amt: (+this.lpInfoData.lp_amt * this.amount_liquidity) / 100,
        ftc_amount: (+this.lpInfoData.fsp_amt * this.amount_liquidity) / 100,
        slippage: 0.4,
        usdt_amount: (+this.lpInfoData.usdt_amt * this.amount_liquidity) / 100
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/create_rm?addr=${req.addr}&chain_type=${req.chain_type}&coin=${req.coin}&lp_amt=${req.lp_amt}&ftc_amount=${req.ftc_amount}&slippage=${req.slippage}&usdt_amount=${req.usdt_amount}`, {}, 'get')
        .then(res => {
          this.isShowLoading = false
          this.list_liquidity_create_rm = res.txs
          this.lengthTxn = this.list_liquidity_create_rm.length
          this.rm_fee = res.total_fee
          this.curIndex = 0

          // check bnb balance
          if (Number(this.bnb_balance) < Number(this.rm_fee)) {
            this.messageFn(this.$t('fsp_trading.msg78'))
            return false
          } else {
            this.submitSignRmFn(this.list_liquidity_create_rm[this.curIndex].hash)
          }


        })
        .catch(res => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        })
    },
    submitSignRmFn(sign) {
      this.Utils.tool.app.signStr({ chain_type: 'bsc', value: sign, jsCallBack: 'signFn' }) // show pw -> error -> 
    },
    signFn(res) {
      this.list_signTxRm.push(res)
      this.curList = []
      if (this.lengthTxn > (this.curIndex + 1)) {
        this.curIndex += 1
        this.submitSignRmFn(this.list_liquidity_create_rm[this.curIndex].hash)
      } else {
        this.list_signTxRm.forEach((item, index) => {
          this.curList.push({
            raw_data: this.list_liquidity_create_rm[index].raw_data,
            signature: item
          })
        })
        this.liquilidyBroadcast(this.curList)
      }

    },
    liquilidyBroadcast(list) { // use for both add and remove liquidity and swap
      let list_data = list.length > 0 ? list : []
      if (list_data.length == 0) {
        this.messageFn(this.$t('fsp_trading.msg62'))
        return false
      }
      this.isAjax = true
      this.isShowLoading = true
      let req = {
        'chain_type': 'bsc',
        'coin': 'FSP',
        'list': list_data
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}liquidity/broadcast`, req, 'post', 25000, false, true)
        .then(res => {
          this.status = 'success'
          this.text = this.$t('fsp_trading.msg63')
          setTimeout(() => {
            this.goRouter('/fsp_trading')
          }, 1000)

          
        })
        .catch(res => {
          this.isShowLoading = false
          this.isAjax = false
          this.messageFn(res.msg)
        })
    },
  },
};
</script>
<style lang="scss" scoped>
.h5_fsp_trading_remove_liquidity_view {
  .container {
    padding: 0.12rem 0.16rem;
  }

  .box_add_liquidity {
    .heading {
      background: #F4F4F4;
      padding: 0.15rem 0.05rem 0.14rem;
      border-radius: 0.06rem;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.05rem 0.16rem;
        border-bottom: 1px solid #2244551A;

        .left {
          display: flex;
          align-items: center;

          .icon {
            line-height: 0;
            border-right: 1px solid #2244551A;
            padding-right: 0.05rem;

            img {
              width: 0.35rem;
              margin-right: 0.02rem;
            }
          }

          .text {
            font-size: 0.14rem;
            color: #224455;
            font-weight: 500;
            padding-top: 0.02rem;
            padding-left: 0.08rem;
          }
        }

        .right {
          font-size: 0.14rem;
          color: #224455;
          font-weight: 500;
          padding-top: 0.02rem;
          flex: 0 0 40%;
          word-break: break-all;
          text-align: right;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.12rem;
        padding: 0 0.05rem;

        .left {
          font-size: 0.12rem;
          color: #224455;
          flex: 0 0 25%;
        }

        .right {
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          color: #224455;
          flex: 1;

          p {
            padding-left: 0.1rem;
            line-height: 0.16rem;
            flex: 0 0 50%;

            span {
              word-break: break-all;
            }

            &:nth-child(1) {
              border-right: 1px solid #2244551A;
              padding: 0 0.1rem 0 0;
            }
          }
        }
      }
    }

    .box_amount_liquidity_button {
      font-size: 0.16rem;
      text-align: center;
      line-height: 0.44rem;
      margin-top: 0.8rem;
      font-weight: 500;
      border-radius: 0.06rem;
      cursor: pointer;
      background: #FFA94D;
      color: #fff;
      width: 100%;
    }
  }

  .box_amount_liquidity {
    margin-top: 0.2rem;

    .title {
      font-size: 0.15rem;
      color: #224455;
      font-weight: 500;
      margin-bottom: 0.1rem;
    }

    .inner {
      background: #F4F4F4;
      padding: 0.08rem 0.07rem 0.12rem;
      border-radius: 0.06rem;

      .value_in_percent {
        background: #fff;
        text-align: center;
        border-radius: 0.05rem;
        height: 0.42rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.28rem;
        color: #224455;
        font-weight: 500;
      }

      .group_button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          border: 0;
          background: #E4E4E4;
          flex: 0 0 23%;
          color: #B6C2C8;
          font-size: 0.14rem;
          font-weight: 600;
          height: 0.25rem;
          border-radius: 0.05rem;

          &.active {
            background: #FFA94D;
            color: #fff;
          }
        }
      }
    }

  }

  .expect_receive {
    margin-top: 0.17rem;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.1rem;

      .text {
        font-size: 0.15rem;
        color: #224455;
        font-weight: 500;
      }

      .fee {
        font-size: 0.13rem;
        color: #778E99;
      }
    }

    .inner {
      padding: 0.1rem;
      background: #F4F4F4;
      border-radius: 0.06rem;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.2rem;

        &:nth-child(1) {
          margin-top: 0;
        }

        .left {
          display: flex;
          align-items: center;

          .icon {
            line-height: 0;

            img {
              width: 0.3rem;
            }
          }

          .text {
            font-size: 0.16rem;
            color: #224455;
            font-weight: 500;
            margin-left: 0.05rem;
          }
        }

        .right {
          font-size: 0.16rem;
          color: #224455;
          font-weight: 500;
        }
      }
    }
  }

  .box_price {
    margin-top: 0.2rem;

    .title {
      font-size: 0.15rem;
      color: #224455;
      font-weight: 500;
      margin-bottom: 0.1rem;
    }

    .item {
      padding: 0.1rem;
      background: #F4F4F4;
      border-radius: 0.06rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 0.16rem;
      color: #224455;
      font-weight: 500;

      .left {
        flex: 1;
      }

      .right {
        flex: 0 0 50%;
        text-align: right;
        word-break: break-all;
      }
    }
  }
}
</style>