<template>
  <article class="h5_otc_appeal_submit_view">
    <section class="form_main">
<!--      <p class="tx14 color_T6 mb_10">{{ $t('name_1') }}</p>
      <div class="select_input_box d_flex_space mb_25">
        <input class="input_tx" v-model="name" type="text" :placeholder="$t('tips_view.msg141')"/>
      </div>
      <p class="tx14 color_T6 mb_10">{{ $t('phone') }}</p>
      <div class="select_input_box d_flex_space mb_25">
        <p class="area_box d_flex_space" @click="isShowCountry = true">
          <span class="tx16 font_bold" :class="country.area_code ? 'color_T6' : 'color_T7'">{{country.area_code ? '+' + country.area_code : $t('please_1')}}</span>
          <i class="h5_icon icon_gray_arrow_down_small"></i>
        </p>
        <input class="input_phone" type="number" pattern="[0-9]*" v-model="phone" :placeholder="$t('tips_view.msg162')"/>
      </div>-->
      <p class="tx14 color_T6 mb_10">{{ $t('reason_appeal') }}</p>
      <div class="select_box d_flex_space mb_25" @click="isShowAppeal = true">
        <span class="tx16 font_bold color_T6">{{ statusTx(appealId) }}</span>
        <i class="h5_icon icon_gray_arrow_down_small"></i>
      </div>
      <p class="tx14 color_T6 mb_10">{{ $t('subject_appeal') }}</p>
      <div class="msg_box mb_25">
        <textarea v-model="msg" class="color_T6 tx16 input_textarea" :placeholder="$t('tips_view.msg156')"></textarea>
        <p class="tx13 color_T4 tx_r">{{msg.length}}/200</p>
      </div>

      <p class="tx14 color_T6 mb_10">{{ $t('submit_data') }}</p>
      <div class="upload_main d_flex">
        <p class="img_item d_flex_cen" v-for="(list, index) in imgList" :key="index">
          <i class="h5_icon icon_loading" v-show="list.isShow"></i>
          <img class="upload_img" v-show="list.src" :src="Utils.URL.imgURL + list.src"/>
          <i class="h5_icon icon_del_red" v-show="list.src" @click="delUploadFn('img', index)"></i>
        </p>
        <el-upload
            v-show="imgList.length < 6"
            class="avatar-uploader"
            accept="image/*"
            action="''"
            :auto-upload="false"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-change="(val => beforeAvatarUpload('img',  val))"
        >
          <i class="h5_icon icon_upload_add"></i>
        </el-upload>
      </div>

      <p class="submit_box d_flex_space mt_40" >
        <span class="input_sub" :class="msg && appealId && imgList.length && phone && name ? 'input_con1' : ''" @click="submitFn">{{ $t('submit') }}</span>
      </p>

    </section>

    <article class="com_popup_main" v-show="isShowAppeal">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{ $t('reason_appeal') }}</span>
          <i class="h5_icon icon_close" @click="isShowAppeal = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <div class="appeal_select_box">
              <p class="appeal_item " :class="appealId == list ? 'appeal_item_cur' : ''" @click="checkAppealFn(list)" v-for="list in resData">{{statusTx(list)}}</p>

            </div>


          </div>
        </div>
      </section>
    </article>

    <article class="search_main" v-show="isShowCountry">
      <div class="bg"></div>
      <div class="search_box">
        <p class="search_title_tx d_flex_space"><span>{{$t('select_code')}}</span> <i class="h5_icon icon_close" @click="isShowCountry = false"></i></p>
        <div class="search_input_box d_flex_space">
          <input type="text" class="input_tx" v-model="searchCountryTx" @input="searchCodeFn($event)" :placeholder="$t('search_code')"/>
          <img src="../../assets/img/icon/search.svg" class="search_img" />
        </div>
        <div class="item_box">
          <div class="item  d_flex_space" :class="list.area_code == country.area_code ? 'item_cur' : ''" v-for="list in countryData" v-show="list.isShow"  @click="checkAreaFn('country', list)">
            <p class="d_flex">
              <span class="mr_10">{{ list.country }}</span>
              <i class="h5_icon icon_orange_success" v-show="list.area_code == country.area_code"></i>
            </p>
            <span>{{list.area_code}}</span>

          </div>
        </div>
      </div>
    </article>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
</template>

<script>
import comLoading from "@/components/loading/index.vue";

export default {
  name: "otc_appeal_submit",
  data() {
    return {
      country: {
        area_code: '',
        country: '',
        id: '',
      },
      isShowCountry: false,
      isShowCity: false,
      countryData: [],
      searchCountryTx: '',
      resData: [],
      size: 30,
      page: 1,
      isShowAppeal: false,
      bank_card: '',
      imgShow: false,
      imgList: [],
      name: '',
      phone: '',
      appealId: '',
      msg: '',
      status: 'loading',
      text: '',
      isAjax: false,
      isShowLoading: false,
    }
  },
  components: {
    comLoading,
  },
  created() {
    this.resInit()
    this.searchCountryFn()
  },
  mounted() {
  },
  methods: {
    checkAppealFn (type) {
      this.appealId = type
      this.isShowAppeal = false
    },
    statusTx (type) {
      if (this.$route.query.type == 'buy') {
        if (type == 1) {
          return this.$t('tips_view.msg157')
        }
      }

    },
    submitFn () {
      if (this.isAjax) {
        return  false
      }
      if (this.appealId === '') {
        this.messageFn(this.$t('tips_view.msg158'))
        return  false
      }
      /*if (this.name === '') {
        this.messageFn(this.$t('tips_view.msg141'))
        return  false
      }
      if (this.phone === '') {
        this.messageFn(this.$t('tips_view.msg162'))
        return  false
      }
      if (this.phone.length <7 || this.phone.length > 11) {
        this.messageFn(this.$t('tips_view.msg163'))
        return  false
      }*/
      if (this.msg === '') {
        this.messageFn(this.$t('tips_view.msg156'))
        return  false
      }
      if (this.msg.length > 200) {
        this.messageFn(this.$t('tips_view.msg159'))
        return  false
      }
      if (!this.imgList.length) {
        this.messageFn(this.$t('tips_view.msg160'))
        return  false
      }

      this.isAjax = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = ''
      let url = ''
      if (this.$route.query.type == 'buy') {
        url = 'buy/appeal_submit'
      }
      let imgList = []
      this.imgList.forEach(val => {
        imgList.push(val.src)
      })
      let req = {
        order_id: this.$route.query.id,
        area_code: this.country.area_code,
        appeal_type: this.appealId,
        reason: this.msg,
        name: this.name,
        phone: this.phone,
        pic_link: imgList.join(','),
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, req, "post")
          .then((res) => {
            this.resInit()
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg161')
            setTimeout(() => {
              this.isShowLoading = false
              this.$router.go(-1)
            }, 2000)
          })
          .catch((res) => {
            this.isAjax = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg108')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)

          });
    },
    resInit() {
      let req = {
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}buy/appeal_reason_list`, req, "get")
          .then((res) => {
            this.resData = res.reason_arr ? res.reason_arr : []
            this.appealId = this.resData[0]

          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    delUploadFn (type, index) {

      if (type == 'img') {
        this.imgList.splice(index, 1)
      }

    },
    handleAvatarSuccess(res, file) {
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(type,file) {
      this.uploadFn(file, type)
    },
    uploadFn(file, type) {
      let nameFile = ''

      const formData = new FormData()
      let str = file.name.split('.')
      let last = str[str.length - 1].toLowerCase()
      let isLt2M = file.size / 1024 / 1024 < 5
      isLt2M = file.size / 1024 / 1024 < 10
      let imgIndex
      if (!/png|jpg|jpeg/.test(last)) {
        this.messageFn(this.$t('tips_view.msg154'))
        return;
      }
      if (!isLt2M) {
        this.messageFn(this.$t('tips_view.msg155'))
      }
      this.imgList.push(
          {
            isShow: true,
            src: '',
          }
      )
      imgIndex = this.imgList.length - 1
      nameFile = 'otc_appeal'
      let time = parseInt(new Date().getTime())
      str = nameFile + time + '.' + last
      formData.append('files', file.raw, str)
      formData.append('dir', 'otc_appeal')
      this.$ajax(`${this.Utils.URL.goAPPURL}upload`, formData, 'post', 25000000, true)
          .then(res => {

            this.imgList[imgIndex].isShow = false
            this.imgList[imgIndex].src = res.url

          })
          .catch(res => {
            this.imgList.splice(imgIndex, 1)
            this.messageFn(res.msg)
          })
    },
    checkAreaFn (type, data) {
      if (type == 'city') {


      } else {
        this.country = data
        this.isShowCountry = false

      }
    },
    searchCodeFn () {
      if (this.searchCountryTx) {
        this.countryData.forEach((val, index) => {
          if (val.area_code.indexOf(this.searchCountryTx) >= 0) {
            this.countryData[index].isShow = true
          } else {
            this.countryData[index].isShow = false
          }
        })
      } else {
        this.countryData.forEach((val, index) => {
          this.countryData[index].isShow = true
        })
      }
    },
    searchCountryFn (eve) {

      let req = {
        page: 1,
        size: 1000,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}buy/area_code`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            arr.forEach((val, i) => {
              arr[i].isShow = true
              if (this.storeLang == 'cn') {
                if (val.area_code == 86) {
                  this.country = val
                }
              } else {
                if (val.area_code == 1) {
                  this.country = val
                }
              }

            })
            this.countryData = arr
            // this.searchCityFn()

          })
          .catch(res => {

            this.messageFn(res.msg)
          })

    },

  }
}
</script>

<style lang="scss">
.h5_otc_appeal_submit_view{
  padding: 0.2rem 0.16rem;
  position: relative;
  .icon_gray_arrow_down_small{
    width: 0.14rem;
    height: 0.14rem;
  }
  .form_main{
    .select_box{
      background: $BG4;
      padding: 0.16rem 0.12rem;
      border-radius: 0.06rem;
    }
    .select_input_box{
      background: $BG4;
      border-radius: 0.06rem;
    }

    .input_tx{
      width: 100%;
      line-height: 0.56rem;
      padding: 0 0.12rem;
      font-size: 0.16rem;
    }
    .area_box{
      padding: 0 0.12rem;
      width: 0.9rem;
    }
    .input_phone{
      width: 2.5rem;
      line-height: 0.56rem;
      padding: 0 0.12rem;
      font-size: 0.16rem;
      border-left: 1px solid $BR4;
    }
    .msg_box{
      background: $BG4;
      padding: 0.16rem 0.12rem;
      border-radius: 0.06rem;
      color: $T6;
      font-size: 0.16rem;
      .input_textarea{
        width: 100%;
        min-height: 1.4rem;
      }

    }
    .upload_main{

      flex-wrap: wrap;

      .upload_img{
        width: 1.07rem;
        height: 1.07rem;
        border-radius: 0.06rem;
      }
      .img_item:nth-child(3n){
        margin-right: 0;
      }
      .img_item{
        font-size: 0;
        position: relative;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        width: 1.07rem;
        height: 1.07rem;
        background-color:  $BG2;
      }
      .icon_del_red{
        position: absolute;
        right: -0.05rem;
        top: -0.05rem;
      }
      .icon_upload_add{
        width: 1.07rem;
        height: 1.07rem;
        background-color:  $BG2;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: 0.38rem 0.36rem;
        border-radius: 0.06rem;
      }
      .icon_loading{
        animation: roundRotate 3s infinite linear;
      }
    }
  }

  .appeal_select_box{
    .appeal_item:last-child{
      margin-bottom: 0;
    }
    .appeal_item{
      background: $BG4;
      padding: 0.15rem 0.12rem;
      font-size: 0.16rem;
      color: $T6;
      border: 1px solid $BG4;
      border-radius: 0.06rem;
      text-align: center;
      margin-bottom: 0.15rem;
    }
    .appeal_item_cur{
      color: $T8;
      border: 1px solid $T8;
      background: $T10;
    }

  }

  .submit_box{
    .input_sub{
      border-radius: 0.06rem;
      line-height: 0.44rem;
      font-weight: bold;
      font-size: 0.16rem;
      text-align: center;
      width: 3.43rem;
      background: $BG1;
      color: $T4;
    }

    .input_con1{

      background: $T8;
      color: $FFF;
    }
  }

  .search_main{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 996;
    .bg{
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      width: 100vw;
      height: 100vh;
    }
    .search_box{
      z-index: 997;
      position: absolute;
      width: 100vw;
      bottom: 0;
      background: $FFF;
    }
    .search_title_tx{
      padding: 0.14rem 0.16rem;
      font-weight: bold;
      font-size: 0.16rem;
      color: $T6;
    }
    .search_input_box{
      margin: 0 0.16rem 0rem;
      padding: 0 0.12rem ;
      background: $BG2;
      border-radius: 0.16rem;

    }
    .input_tx{
      line-height: 0.32rem;
      font-size: 0.12rem;
      width: 2rem;
    }
    .search_img{
      margin-left: 0.1rem;
    }
    .item_box{
      margin-top: 0.12rem;
      border-top: 1px solid $BR2;
      padding: 0.1rem 0;
      height: 3.5rem;
      overflow-y: scroll;
    }
    .item{
      font-size: 0.16rem;
      color: $T6;
      padding: 0.1rem 0.16rem;
    }
    .item_cur{
      background: $T11;
    }
  }
}

</style>