<template>
  <article class="h5_invest_confirm_view">


    <section class="loan_info_box">
      <p class="tx1">{{ $t('investment_amount') }}</p>
      <div class="tx2_box d_flex_cen mb_10" v-show="$route.query.is_add == 0">
        <p class="tx2">{{ num }} {{coin_data.code}}</p>
        <p class="tx3  ml_5" :class="resData.chain_type + '_color'">{{coinChainType[resData.chain_type]}}</p>
      </div>
      <div class="tx6_box d_flex_space mb_10"  v-show="$route.query.is_add == 1">
        <i class="h5_icon icon_subtraction" @click="changeNumFn('sub')"></i>
        <input type="number" v-model="num" @blur="blurNumFn" pattern="[0-9]*" class="input_tx" :placeholder="$t('tips_view.msg23') + resData.lowest_amount + $t('tips_view.msg24')"/>
        <p class="d_flex"><span class="tx6_1 mr_10">{{coin_data.code}}</span><i class="h5_icon icon_addition" @click="changeNumFn('add')"></i></p>
      </div>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('balance') }}: {{balance}} {{coin_data.code}}</span>
        <span>{{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}}</span>
      </p>
      <p class="tx5">{{ $t('number_investments') }}: {{ resData.min_invest_amount }} {{coin_data.code}} ~ {{ resData.surplus_amount }} {{coin_data.code}}</p>
    </section>
    <section class="loan_info_box">
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('lock_day') }}</span>
        <span>{{resData.borrow_days}} {{ $t('day') }}</span>
      </p>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('daily_rate') }}</span>
        <span>{{resData.daily_profit}}</span>
      </p>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('daily_income') }}</span>
        <span>{{num ? (num / resData.min_invest_amount) * resData.each_profit : resData.each_profit}} {{coin_data.code}}</span>
      </p>
      <p class="tx4 d_flex_space mb_10">
        <span>{{ $t('redemption_method') }}</span>
        <span>{{ $t('tips_view.msg7') }}</span>
      </p>
      <p class="tx4 d_flex_space">
        <span>{{ $t('estimated_total_revenue') }}</span>
        <span>{{ num ? resData.borrow_days * ((num / resData.min_invest_amount) * resData.each_profit) : resData.borrow_days * resData.each_profit}} {{coin_data.code}}</span>
      </p>
    </section>
    <section class="loan_info_box">
      <p class="tx6 d_flex_space mb_10">
        <span>{{ $t('reinvestment_function') }}</span>
        <i class="h5_icon " :class="check ? 'icon_swipe_yes' : 'icon_swipe_no'" @click="check = !check"></i>
      </p>
      <p class="tx5 mb_10">{{ $t('tips_view.msg25') }}</p>
      <p class="tx5 mb_10">{{ $t('tips_view.msg26') }}</p>
      <p class="tx5">{{ $t('tips_view.msg27') }}</p>
    </section>
    <div class="sub_btn" v-show="resData.is_invest" @click="submitFn">{{ $t('confirm') }}</div>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    <div class="bottom_loading_box" v-show="isApproveShow">
      <i class="h5_icon icon_small_loading"></i>
      <p>{{ $t('tips_view.msg57') }}</p>
    </div>
  </article>
</template>

<script>
import comLoading from "@/components/loading";

export default {
  name: "invest_confirm",
  data() {
    return {
      walletId: '',
      errorTx: '',
      status: 'loading',
      text: this.$t('tips_view.msg8'),
      isShowLoading: false,
      isLoad: false,
      isApproveShow: false,
      resData: {},
      coin_data: {},
      balance: '',
      num: '',
      check: true,
      isAjax: false,
      coinBanlace: 0
    }
  },
  components: {
    comLoading,
  },
  created() {
    this.resInit()
    if (this.$route.query.num) {
      this.num = this.$route.query.num
    }
  },
  mounted () {

    let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'


    this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
      addr: this.storeChangeAddress,
    }, "post").then(res => {
      this.coinBanlace =  res.quantity ? parseFloat(res.quantity) : 0
     })

  },
  methods: {
    coinBalanceFn (contract_addr) {
      this.getUSDTBalanceFn(this.storeChainType,contract_addr, this.storeAddress).then(res => {
        this.balance = res
      }).catch(res => {
        this.balance = 0
        this.messageFn(res.msg)
      })
    },
    blurNumFn () {
      let num = parseFloat(this.num)
      let min_invest_amount = parseFloat(this.resData.min_invest_amount)
      let surplus_amount = parseFloat(this.resData.surplus_amount)
      if (num > surplus_amount) {
        num = surplus_amount
      }
      if (num < min_invest_amount) {
        num = min_invest_amount
      }
      this.num = num
    },
    changeNumFn (type) {
      let num = parseFloat(this.num)
      let lowest_amount = parseFloat(this.resData.lowest_amount)
      let surplus_amount = parseFloat(this.resData.surplus_amount)
      if (type == 'add') {
        num = (num + lowest_amount) > surplus_amount ? surplus_amount : num + lowest_amount
      } else {
        num = num - lowest_amount <= 0 ? this.resData.min_invest_amount : num - lowest_amount
      }
      this.num = num
    },
    resInit () {
      let req = {
        id: this.$route.query.ad_id
      };
      this.$ajax(`${this.Utils.URL.goAPPURL}loan/loan_finance_order_details`, req, "get")
          .then((res) => {
            this.resData = res ? res : {}

            this.coin_data = res.coin_data ? res.coin_data : {}
            if (this.coin_data.contract_addr) {
              this.coinBalanceFn(this.coin_data.contract_addr)
            } else {
              let url = this.storeChainType == 'BSC' ? 'bsc/balance' : this.storeChainType == 'ETH' ? 'eth/balance' : 'tron/balance'


              this.$ajax(`${this.Utils.URL.goAPPURL}${url}`, {
                addr: this.storeChangeAddress,
              }, "post").then(res => {
                this.balance =  res.quantity ? parseFloat(res.quantity) : 0
              })
            }

            if (!this.$route.query.num) {
              this.num = this.resData.min_invest_amount
            }
          })
          .catch((res) => {
            this.messageFn(res.msg)
          });
    },
    async submitFn () {
      let num = parseFloat(this.num)
      let lowest_amount = parseFloat(this.resData.lowest_amount)
      let miner_fee = parseFloat(this.resData.miner_fee)
      let balance = parseFloat(this.balance)
      if (!this.storeChangeAddressId) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      if (!this.$route.query.ad_id) {
        return false
      }

      if (!(balance > 0)) {
        this.messageFn(this.$t('tips_view.msg28'))
        return false
      }
      if (num > balance) {
        this.messageFn(this.$t('tips_view.msg28'))
        return false
      }

      if (new window.windowBigNumber(num).mod(lowest_amount).toString() != 0) {
        this.messageFn(`${this.$t('tips_view.msg23')} ${lowest_amount} ${this.$t('tips_view.msg24')}`)
        return false
      }
      if (num > parseFloat(this.resData.surplus_amount)) {
        this.messageFn(this.$t('tips_view.msg29') + this.resData.surplus_amount)
        return false
      }
      if (num < parseFloat(this.resData.min_invest_amount)) {
        this.messageFn(this.$t('tips_view.msg29_1') + this.resData.min_invest_amount)
        return false
      }
      if ((miner_fee > this.coinBanlace) || (this.coinBanlace == 0)) {
        this.messageFn(this.coinMian[this.storeChainType] + this.$t('tips_view.msg89'))
        return false
      }
      if (!this.resData.is_invest) {
        this.messageFn(this.$t('tips_view.msg4'))
        return false
      }

      if (!this.coin_data.contract_addr) {
        this.messageFn(this.$t('tips_view.msg90'))
        return false
      }

      if (this.isAjax) {
        return  false
      }
      this.isAjax = true
      let time = parseInt(new Date().getTime() / 1000)
      let sing
      let txId = ''
      this.isApproveShow = true

      /*setTimeout(() => {

          this.isApproveShow = false

          this.isAjax = false


      }, 3000)*/

      await this.approveUSDTBalanceFn(this.resData.cold_wallet, num + '', this.storeAddress, this.storeChainType, this.coin_data.contract_addr).then(res => {
        this.isApproveShow = false
        txId = res
      }).catch(res => {
        txId = ''
        this.isApproveShow = false
        this.isAjax = false
        this.messageFn(this.coin_data.code + this.$t('tips_view.msg55'))

        return false
      })

      if (!txId) {
        this.isApproveShow = false

        this.isAjax = false
        return false
      }

      sing = await this.dappSingFn(time + ':' + this.$route.query.ad_id)
      if (!sing) {
        this.isAjax = false
        this.messageFn(this.$t('tips_view.msg5'))
        return false
      }



      let req = {
        order_id: this.$route.query.ad_id,
        invest_amount: this.num,
        is_repeat: this.check ? 1 : 2,
        use_pool: this.$route.query.num ? true : false,
        use_ticket: false,
        features: time,
        signature: sing,
        chain_type: this.storeChainType.toLocaleLowerCase(),

      }


      this.isLoad = true
      this.isShowLoading = true
      this.status = 'loading'
      this.text = this.$t('tips_view.msg8')
      this.$ajax(`${this.Utils.URL.goAPPURL}dapp/new_loan_order`, req, 'post')
          .then(res => {
            this.isAjax = false
            this.isLoad = false
            this.status = 'success'
            this.text = this.$t('tips_view.msg11')

            setTimeout(() => {
              this.isShowLoading = false
              this.$router.replace('/invest_order')
            }, 2000)

          })
          .catch(res => {
            this.isAjax = false
            this.isLoad = false
            this.status = 'error'
            this.text = this.$t('tips_view.msg12')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
            // this.messageFn(res.msg)
          })
    }
  }
}
</script>

<style lang="scss">
.h5_invest_confirm_view{
  padding: 0.16rem 0.16rem 0.8rem;
  .loan_info_box{
    padding: 0.16rem;
    background: $BG3;
    border-radius: 0.06rem;
    margin-bottom: 0.16rem;
    .tx1{
      text-align: center;
      font-weight: bold;
      font-size: 0.13rem;
      margin-bottom: 0.16rem;
    }
    .tx2_box{
      background: $FFF;
      padding: 0.14rem 0.16rem;
      border-radius: 0.06rem;
      text-align: center;
    }
    .tx6_box{
      background: $FFF;
      padding: 0.14rem 0.16rem;
      border-radius: 0.06rem;
      text-align: center;
    }
    .input_tx::-webkit-input-placeholder{
      font-size: 0.16rem;
    }
    .input_tx{
      text-align: center;
      font-size: 0.2rem;
      font-weight: bold;
      width: 1.5rem;
    }
    .tx6_1{
      font-size: 0.13rem;
      font-weight: bold;

      color: $T7;
    }
    .tx2{
      font-weight: bold;
      font-size: 0.2rem;
      color: $T6;
    }
    .tx3{
      padding: 0.02rem 0.06rem;
      border-radius: 0.1rem;
      font-size: 0.13rem;
      font-weight: bold;
    }
    .tx4{
      font-size: 0.12rem;
      color: $T6;
    }
    .tx5{
      font-size: 0.12rem;
      color: $T7;
    }
    .tx6{
      font-size: 0.13rem;
      color: $T6;
    }
    .tx7{
      color: $T12;
      background: $T11;
    }
    .tx8{
      color: $T14;
      background: $T13;
    }
    .tx9{
      color: $T16;
      background: $T15;
    }
  }
  .sub_btn{
    position: absolute;
    bottom: 0.32rem;
    width: 3.43rem;
    left: 0.16rem;
    line-height: 0.44rem;
    border-radius: 0.06rem;
    color: $FFF;
    background: $T8;
    font-size: 0.16rem;
    font-weight: bold;
    text-align: center;
  }

}

</style>