<template>
  <div>
    <article class="h5_share_wallet_view" v-show="resBindData.is_user_bind == 1">
    <div class="wallet_box">
      <div class="address_box">
        <p class="tx1">{{ $t('share_wallet_view.msg1') }}</p>
        <p class="tx2 "><span class="tx3">{{ storeChangeAddressId }}</span><i class="h5_icon icon_copy ml_5"
            @click="copyText(storeWalletId)"></i></p>
      </div>
    </div>
    <div class="qrcode_main">
      <img :src="qrcodeImg" class="qrcode_img" />
      <p class="tx1">{{ $t('share_wallet_view.msg2') }}</p>
      <p class="btn" @click="copyText(resData.link)">{{ $t('copy_link') }}</p>
    </div>
    <p class="tx2">{{ $t('share_wallet_view.msg3') }}</p>
  </article>

  <article class="h5_unbind_view" v-show="resBindData.is_user_bind == 0">
    <div class="box-info">
      <p class="title">{{ $t('share_wallet_view.msg4') }}</p>
      <p>
        {{ wallet_addr }}
      </p>
      <div class="inner">
        <p class="title">{{ $t('share_wallet_view.msg5') }}</p>
        <div class="wrap-input">
          <input type="text" v-model="parent_id" :placeholder="$t('share_wallet_view.msg6')">
        </div>
      </div>
      <div class="next-step">
        <button class="submit_btn" @click="submit" :disabled="parent_id == ''"
          :class="parent_id == '' ? 'disabled' : ''">{{ $t('share_wallet_view.msg7') }}</button>
      </div>

      <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    </div>
  </article>
  </div>
  
</template>

<script>
import comLoading from "@/components/loading";
import QRCode from 'qrcode'
export default {
  name: "h5_share_wallet_view",
  components: {
    comLoading,
  },
  data() {
    return {
      resData: {},
      qrcodeImg: '',
      resBindData: {},
      wallet_addr: '',
      parent_id: ''
    }
  },
  created() {
    this.wallet_addr = window.sessionStorage.getItem('storeWalletId') ? window.sessionStorage.getItem('storeWalletId') : ''
    this.checkUserHasBind()
  },
  mounted() {

  },
  methods: {
    checkUserHasBind() {
      let req = {

      };
      this.$ajax(`${this.Utils.URL.goAPPURL}project_content_data`, req, "get")
        .then((res) => {
          this.resBindData = res ? res : {}
          if (this.resBindData.is_user_bind == 1) {
            this.resInit()
          }
        })
        .catch((res) => {
          this.messageFn(res.msg)
        });
    },
    resInit() {
      let req = {

      };
      this.$ajax(`${this.Utils.URL.goAPPURL}wallet_link`, req, "get")
        .then((res) => {
          this.resData = res ? res : {}
          QRCode.toDataURL(this.resData.link).then(img => {
            this.qrcodeImg = img
          })
        })
        .catch((res) => {
          this.messageFn(res.msg)
        });
    },
    // for unbind
    checkWalletLength(value) {
      if (!value.startsWith('ID') || value.length != 42) {
        this.messageFn($t('share_wallet_view.msg8'))
        return false
      }
      return true
    },
    submit() {
      const isValid = this.checkWalletLength(this.parent_id)
      if (!isValid) return
      if (this.parent_id == '') {
        this.messageFn($t('share_wallet_view.msg9'))
        return
      }

      this.isShowLoading = true
      const req = {
        parent_id: this.parent_id
      }
      
      this.$ajax(`${this.Utils.URL.goAPPURL}bind_parent`, req, "post")
        .then((res) => {
          if (res.result) {
            this.isShowLoading = false
            this.messageFn(this.$t('success'))
            this.$router.push('/')
          } else {
            this.isShowLoading = false
            this.messageFn(res.msg)
          }
        })
        .catch((res) => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        });
    },
  }
}
</script>

<style lang="scss">
.h5_share_wallet_view {
  min-height: 100vh;
  background: $BG3;

  .wallet_box {
    padding-top: 0.28rem;

    background: $T8;

    .address_box {
      margin: 0rem 0.28rem 0;
      background: rgba(255, 255, 255, 0.95);
      color: $T6;
      text-align: center;
      padding: 0.24rem 0.16rem;
      border-radius: 0.06rem 0.06rem 0 0;

      .tx1 {
        font-size: 0.15rem;
        font-weight: bold;
        margin-bottom: 0.05rem;
      }

      .tx2 {
        font-size: 0.14rem;
      }

      .tx3 {
        word-wrap: break-word;
        word-break: break-word;
      }
    }

  }

  .qrcode_main {
    padding: 0.24rem 0.16rem 0.16rem 0.16rem;
    border-radius: 0 0 0.06rem 0.06rem;
    background: $FFF;
    margin: 0 0.28rem 0.15rem;
    text-align: center;

    .qrcode_img {
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 auto 0.16rem;
    }

    .tx1 {
      font-size: 0.13rem;
      color: $T6;
      margin-bottom: 0.3rem;
    }

    .btn {
      border-radius: 0.06rem;
      background: $T8;
      color: $FFF;
      text-align: center;
      font-size: 0.16rem;
      font-weight: bold;
      line-height: 0.44rem;
    }
  }

  .tx2 {
    margin: 0 0.28rem;
    font-size: 0.12rem;
    color: $T7;
  }
}
.h5_unbind_view {
  min-height: 100vh;
  background: white;
  padding: 0.16rem 0.16rem;
  .next-step {
    .submit_btn {
      background: #e92;
      text-align: center;
      border-radius: 0.06rem;
      font-weight: 700;
      font-size: .16rem;
      color: #fff;
      line-height: .44rem;
      margin-top: 0.32rem;
      border: 0;
      width: 100%;

      &.disabled {
        background: #ccc;
      }
    }
  }
  .box-info {
    .inner {
      margin-top: 0.2rem;
      // padding: 0.14rem 0.16rem;
      margin-bottom: 0.16rem;
      border-radius: 0.06rem;
      // background: #f7f7f7;
      .wrap-input {
        margin-top: 0.1rem;
        padding: 0.14rem 0.16rem;
        background: #f7f7f7;
        input {
          width: 100%;
        }
      }
    }
  }
  .title {
    font-weight: 700;
  }
}
</style>