<template>
  <article class="h5_transfer_history_view">
    <div class="form_transfer_history">
      <div class="inner">
        <p class="title">{{$t('tips_view.msg182')}}</p>
        <input type="text" v-model="to_user_id" :placeholder="$t('tips_view.msg183')">
      </div>
      <div class="inner">
        <p class="title">{{$t('tips_view.msg184')}}</p>
        <input type="number" pattern="[0-9]*" class="input_tx" placeholder="0.00" v-model="amount">
        <p class="available">{{$t('tips_view.msg185')}}: {{ coinVoucher.quantity }} <span @click="spendAll">{{$t('tips_view.msg186')}}</span></p>
      </div>
    </div>
    <div class="next-step">
      <button class="submit_btn" @click="nextStep" :disabled="to_user_id == '' || amount == ''"
        :class="(to_user_id == '' || amount == '') ? 'disabled' : ''">{{$t('tips_view.msg187')}}</button>
    </div>

    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
  </article>
  
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "transfer_history",
  components: {
    comLoading,
  },  
  data() {
    return {
      to_user_id: '',
      amount: '',
      coinVoucher: {},
      isShowInputPassword: false,
      password: '',
      errorTx: '',
      isShowLoading: false,
      status: 'loading',
      text: this.$t('tips_view.msg8')
    }
  },
  created() {
    this.getVoucherBalance()
  },

  mounted() {

  },
  methods: {
    submitFn() {
      this.isShowInputPassword = false
      this.isShowLoading = true
      const req = {
        to_user_id: this.to_user_id,
        amount: this.amount
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}super/ticket_transfer`,req, 'post')
        .then(res => {
          if (res.result) {
            this.password = ''
            this.amount = ''
            this.isShowLoading = false
            setTimeout(() => {
              this.messageFn(this.$t('success'))
              this.$router.push('/node_voucher_balance')
            }, 300)
          } else {
            this.isShowLoading = false
            this.messageFn(res.msg)
          }
          
        })
        .catch(res => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        })
      
    },
    getVoucherBalance() {

      this.$ajax(`${this.Utils.URL.goAPPURL}super/ticket_num`, {}, 'get')
        .then(res => {
          this.coinVoucher = res ? res : {}

        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    spendAll() {
      this.amount = this.coinVoucher.quantity
    },
    checkWalletLength(value) {
      if (!value.startsWith('ID') || value.length != 42) {
        this.messageFn(this.$t('tips_view.msg188'))
        return false
      }
      return true
    },
    nextStep() {
      const isValid = this.checkWalletLength(this.to_user_id)
      if (!isValid) return
      if (this.amount == '') {
        this.messageFn(this.$t('tips_view.msg189'))
        return
      }
      this.submitFn()
    }
  }
}
</script>

<style lang="scss">
.h5_transfer_history_view {
  // .coin_main {
  padding: 0.16rem 0.16rem;

  // }
  .form_transfer_history {
    .inner {
      padding: 0.14rem 0.16rem;
      margin-bottom: 0.16rem;
      border-radius: 0.06rem;
      background: #f7f7f7;

      input {
        width: 100%;
      }

      .title {
        font-size: 0.14rem;
        color: #333;
        margin-bottom: 0.08rem;
      }

      .available {
        font-size: 0.12rem;
        color: #999;

        span {
          color: #e92;
          font-size: 0.12rem;
        }
      }
    }
  }

  .next-step {
    .submit_btn {
      background: #e92;
      text-align: center;
      border-radius: 0.06rem;
      font-weight: 700;
      font-size: .16rem;
      color: #fff;
      line-height: .44rem;
      margin-top: 0.32rem;
      border: 0;
      width: 100%;

      &.disabled {
        background: #ccc;
      }
    }
  }

  .input {
    width: 100%;
  }

  .com_popup_btn_box {
    padding: 0.24rem 0.16rem;

    .com_popup_input_btn {
      background: #ccc;
      text-align: center;
      border-radius: 0.06rem;
      font-weight: 700;
      font-size: 0.16rem;
      color: #fff;
      line-height: 0.44rem;
      border: 0;
      width: 100%;

      &.com_popup_input_btn_cur {
        background: #e92;
      }
    }
  }
}</style>