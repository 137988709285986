<template>
  <article class="h5_income_profit_view">
    <section class="profit_overview">
      <div class="profit_overview__inner">
        <div class="item">
          <p>{{ $t('income_profit.tips1') }}</p>
          <p class="value">{{ scientificNotationToString(wsData.mining_profit_data?.block_count) }}</p>
        </div>
        <div class="item">
          <p>{{ $t('income_profit.tips2') }}</p>
          <p class="value">{{ scientificNotationToString(wsData.mining_profit_data?.total_profit) }} SCF</p>
        </div>
      </div>
      <div class="profit_overview__inner">
        <div class="item">
          <p>{{ $t('income_profit.tips3') }}</p>
          <p class="value">{{ scientificNotationToString(wsData.mining_profit_data?.total_release_profit) }} SCF</p>
        </div>
        <div class="item">
          <p>{{ $t('income_profit.tips4') }}</p>
          <p class="value">{{ scientificNotationToString(wsData.mining_profit_data?.total_un_release_profit) }} SCF</p>
        </div>
      </div>
    </section>

    <section class="profit_box">
      <div class="fin_box">
        <p class="tx1">{{ $t('income_profit.tips5') }}</p>
        <p class="tx2_box">
          <span
            class="tx2">{{ scientificNotationToString(wsData.personal_mining_profit_data?.personal_total_profit) }}</span>
          <span class="tx3"> SCF</span>
          <span class="tx4"> ≈ ${{ numFormat(wsData.personal_mining_profit_data?.personal_usd_amount) }}</span>
        </p>
        <div class="item_box no-border">
          <div class="inner">
            <p class="tx5">{{ $t('income_profit.tips6') }}</p>
            <p class="tx6">
              <!-- {{ scientificNotationToString(wsData.personal_mining_profit_data?.personal_total_release_profit) }} FTC</p> -->
              {{ scientificNotationToString(totalAmount?.total_release_amount) }} SCF</p>
          </div>
          <div class="tx_r inner">
            <p class="tx5">{{ $t('income_profit.tips7') }}</p>
            <p class="tx6">
              {{ scientificNotationToString(wsData.personal_mining_profit_data?.personal_total_un_release_profit) }} SCF</p>
          </div>
        </div>
        <div class="item_box">
          <div class="inner">
            <p class="tx5">{{ $t('extracted') }}</p>
            <p class="tx6">{{ totalAmount.total_withdraw_amount }} {{ staticSymbol }}</p>
          </div>
          <div class="tx_r inner">
            <p class="tx5">{{ $t('not_extracted') }}</p>
            <p class="tx6">{{ un_release }} {{ staticSymbol }}</p>
          </div>
        </div>
      </div>
    </section>
    <p class="btn">
      <button @click="handleMiningExtraction">{{ $t('income_profit.tips10') }}</button>
    </p>
    <div class="chain_tab d_flex">
      <span class="chain_item " @click="tabChainFn(key)" :class="checkChainType == key ? 'chain_cur' : ''"
        v-for="(list, key) in detaiList" :key="key">{{ list }}</span>
    </div>

    <div class="com_scroll" v-infinite-scroll="resInit" :infinite-scroll-distance="50" :infinite-scroll-immediate="false">
      <section v-for="(list) in resData" :key="list.contract_number">
        <div class="profit_details" v-show="checkChainType == 0">
          <div class="left tx_bottom">
            <p class="tx1">{{ list.amount }} {{ list.symbol }}</p>
            <p class="tx6">
              <span>{{ dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss') }}</span>
            </p>
          </div>
          <div class="right">
            <p>
              <span class="tx2"> {{ list.symbol }} {{ $t('release') }}</span>
            </p>
          </div>
        </div>

        <div v-show="checkChainType == 1">
          <div class="profit_details" v-if="list.tx_id?.length > 0" @click="goRouter('https://scan.scfchain.io/transactions_details/?hash=' + list.tx_id, {}, true)">
            <div class="hash">
              <p>Hash：{{ substr(list.tx_id, 0, 10) }}...{{ substr(list.tx_id, -10) }} </p>
              <i class="h5_icon icon_green_arrow"></i>
            </div>
            <div class="left tx_bottom">
              <p>{{ $t('income_profit.tips43') }}{{ list.amount }} {{ list.symbol }}</p>
              <p class="tx6">
                <span>{{ dataFormat(list.created_at, 'yyyy/MM/dd hh:mm:ss') }}</span>
              </p>
            </div>
            <div class="right">
              <p class="status" :class="(list.status == 1 || list.status == 2 || list.status == 3) ? 'success' : 'fail'">{{
                (list.status == 1 || list.status == 2) ? $t('extracting') : list.status == 3 ? $t('extract_success') :
                $t('extract_fail') }}</p>
            </div>
          </div>
          
          <div class="profit_details" v-else>
            <div class="left tx_bottom">
              <p>{{ $t('income_profit.tips43') }}{{ list.amount }} {{ list.symbol }}</p>
              <p class="tx6">
                <span>{{ dataFormat(list.created_at,'yyyy/MM/dd hh:mm:ss')  }}</span>
              </p>
            </div>
            <div class="right">
              <p class="status" :class="(list.status == 1 || list.status == 2 || list.status == 3) ? 'success' : 'fail'">{{
                (list.status == 1 || list.status == 2) ? $t('extracting') : list.status == 3 ? $t('extract_success') :
                $t('extract_fail') }}</p>
            </div>
          </div>
        </div>

      </section>
    </div>

    <div class="com_no_data_box" v-show="!resData.length">
      <p class="mb_10"><i class="h5_icon icon_no_data"></i></p>
      <p class="com_no_data_tx">{{ $t('nodata') }}</p>
    </div>
    <transition>
      <article class="com_popup_main" v-show="isMiningExtraction">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('income_profit.tips10') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="head">
              <p class="ml-5">{{ $t('voteActivityView.payment_address') }}</p>
            </div>
            <div class="input text">
              <input type="text" class="input_tx" :placeholder="$t('income_profit.tips52')" v-model="receive_address">
            </div>
            <div class="head has-icon">
              <i class="h5_icon icon_ftc"></i>
              <p class="ml-5">SCF</p>
            </div>
            <div class="input">
              <input type="number" pattern="[0-9]*" class="input_tx" :placeholder="$t('income_profit.tips16')"
                v-model="amount" disabled>
              <!-- <span @click="spendAll">{{ $t('tips_view.msg186') }}</span> -->
            </div>
            <div class="detail">
              <p>{{ $t('income_profit.tips17') }}</p>
              <p>{{ un_release }} SCF</p>
            </div>
            <div class="detail">
              <p>{{ $t('fee') }}</p>
              <p>0</p>
            </div>
            <div class="button">
              <button @click="hideFn">{{ $t('cancel') }}</button>
              <button @click="nextStep" class="btn-default"
                :class="(receive_address != '' && un_release > 0) ? 'btn-cur' : ''"
                :disabled="(receive_address == '' || un_release <= 0) ? true : false">{{
                  $t('extract') }}</button>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <com-loading :isShow="isShowLoading" text="loading"></com-loading>
  </article>
</template>

<script>
import socket from '@/utils/socket'
import comLoading from "@/components/loading";
export default {
  name: "income_profit",
  components: {
    comLoading
  },
  data() {
    return {
      isShowLoading: false,
      address: '',
      receive_address: '',
      isMiningExtraction: false,
      resData: [],
      profitData: {},
      loadInfinite: false,
      isLoad: false,
      page: 1,
      total: 0,
      size: 30,
      staticSymbol: 'SCF',
      detaiList: [this.$t('income_profit.tips11'), this.$t('income_profit.tips12')],
      checkChainType: 0,
      balance: '',
      totalAmount: {},
      unMount: false,
      Wallet_Uuid: '',
      socketTimeOut: null,
      timeSec: 5000,
      wsData: [],
      socket: null
    }
  },
  computed: {
    un_release() {
      // take the 8 digits after the decimal point
      let result = this.totalAmount?.total_release_amount - this.totalAmount?.total_withdraw_amount
      if (isNaN(result) == false) {
        result = result.toString()
          if (result.indexOf('.') > -1) {
            let arr = result.split('.')
            if (arr[1].length > 8) {
              return arr[0] + '.' + arr[1].substr(0, 8)
            } else {
              // add 0 to the end
              let len = 8 - arr[1].length
              for (let i = 0; i < len; i++) {
                result += '0'
              }

              return result || 0
            }
          } else {
            // add 0 to the end
            result += '.'
            for (let i = 0; i < 8; i++) {
              result += '0'
            }
            return result || 0
          }
      } else {
        return 0
      }
      
    },
    amount() {
      return this.un_release < 0 ? 0 : this.un_release
    },
  },
  beforeUnmount() {
    window.Wallet_Uuid = null
    this.unMount = true
    if (this.socket) {
      this.socket.doClose()
      this.socket = null
    }
  },
  created() {
    this.Wallet_Uuid = window.Wallet_Uuid
    this.uuid = parseInt(new Date().getTime() / 1000)
    this.socketInit()

    this.getMiningTotalWithdraw()
  },
  methods: {
    socketInit() {
      this.socket = new socket(`wss://appfast.fintoch.io/mining_api/all_mining_data_ws_h5`)
      
      this.socket.doOpen()

      this.socket.on('open', (data) => {
        
        this.socket.send(`${window.Wallet_Uuid},fasdasdfasfd`)
      })
      this.socket.on('message', (res) => {

        this.wsData = res ? res : []
        this.resInit(0, this.wsData.mining_profit_data?.block_count)

      })
      let slef = this
      this.socket.on('close', (res) => {
        slef.socketResetFn()

      })

    },
    socketResetFn() {
      if (this.socket && !this.unMount) {
        this.socket = null
        clearTimeout(this.socketTimeOut)
        this.socketTimeOut = setTimeout(() => {
          this.socketInit()
          this.timeSec = this.timeSec + 5000
        }, this.timeSec)
      }
    },
    getMiningTotalWithdraw() {
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/mining_total_withdraw`, {}, 'get')
        .then(res => {
          this.totalAmount = res
        })
        .catch(res => {
          this.messageFn(res.msg)
        })
    },
    nextStep() {
      // if (this.amount > this.totalAmount.amount) {
      //   this.messageFn(this.$t('income_profit.tips53'))
      //   return false
      // }
      if (this.amount <= 0) {
        this.messageFn(this.$t('income_profit.tips54'))
        return false
      }
      // if (this.receive_address.length < 42) {
      //   this.messageFn(this.$t('income_profit.tips55'))
      //   return false
      // }
      const req = {
        // amount: this.amount,
        addr: this.receive_address
      }
      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}profit/create_extract_node_order`, req, 'post')
        .then(res => {
          if (res.result) {
            this.amount = ''
            this.isMiningExtraction = false
            this.messageFn(this.$t('success'))
            this.checkChainType = 0
            this.resInit(this.checkChainType, this.wsData.mining_profit_data?.block_count)
            this.isShowLoading = false
          } else {
            this.isShowLoading = false
            this.messageFn(this.$t('fail'))
          }
        })
        .catch(res => {
          this.isShowLoading = false
          this.messageFn(res.msg)
        })
    },
    hideFn() {
      this.isMiningExtraction = false
      this.amount = ''
    },
    spendAll() {
      this.amount = this.un_release
    },
    tabChainFn(index) {
      this.checkChainType = index
      this.page = 1
      this.loadInfinite = false
      this.resInit(index, this.wsData.mining_profit_data?.block_count)
    },
    handleMiningExtraction() {
      this.isMiningExtraction = true
    },
    resInit(type, block_height) {
      // if (block_height == undefined) {
      //   this.messageFn('websocket error')
      // }
      let req = {
        page: this.page,
        size: this.size,
        block_height: block_height
      }
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }

      this.isLoad = true
      this.resData = []
      if (type == 0) {
        this.$ajax(`${this.Utils.URL.goAPPURL}profit/mining_profit_release_detail`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            this.resData = this.resData.concat(arr)
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
      } else {
        const req = {
          page: this.page,
          size: this.size,
          type: 1
        }
        this.$ajax(`${this.Utils.URL.goAPPURL}profit/withdraw_record_list`, req, 'get')
          .then(res => {
            this.isLoad = false
            let arr = res.list ? res.list : []

            if (arr.length < this.size) {
              this.loadInfinite = true
            }

            this.page = this.page + 1
            this.resData = this.resData.concat(arr)
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
      }

    },
  }
}
</script>

<style lang="scss">
.h5_income_profit_view {
  padding: 0.16rem;

  .com_popup_info_box {
    padding-top: 0.12rem !important;

    .head {
      display: flex;
      place-items: center;
      margin-bottom: 0.1rem;
      &.has-icon {
          padding-left: 0.02rem;
        }
      .h5_icon {
        margin-right: 0.05rem;
        
      }

      p {
        font-size: 0.15rem;
        font-weight: bold;
      }
    }

    .input {
      display: flex;
      place-items: center;
      justify-content: space-between;
      background: #F0F0F0;
      border-radius: .05rem;
      padding: 0.2rem;
      margin-bottom: .1rem;

      input {
        flex-basis: 80%;
      }

      span {
        color: #EE9922;
      }

      &.text {
        input {
          flex-basis: 100%;
        }
      }
    }

    .detail {
      display: flex;
      place-items: center;
      justify-content: space-between;

      p {
        margin: .1rem 0;

        &:first-child {
          flex-basis: 65%;
          color: #778E99;
        }
      }
    }

    .button {
      display: flex;
      place-items: center;
      margin-top: 0.2rem;

      button {
        flex-basis: 50%;
        background: rgba(119, 142, 153, 0.08);
        text-align: center;
        border-radius: 0.06rem;
        font-weight: 700;
        font-size: 0.16rem;
        color: #778E99;
        line-height: 0.44rem;
        width: 100%;
        border: 0;

        &:first-child {
          margin-right: 0.08rem;
        }

        &:last-child {
          margin-left: 0.08rem;
        }

        &.btn-cur {
          background: #EE9922;
          color: white;
        }
      }
    }
  }

  .chain_tab {
    margin: 0.2rem -0.16rem;

    .chain_item {
      width: 50%;
      text-align: center;
      font-size: .14rem;
      font-weight: 700;
      padding: 0 0 0.1rem;
      color: #778e99;
      border-bottom: 0.5px solid rgba(119, 142, 153, 0.2);
    }

    .chain_cur {
      color: #e92;
      border-bottom: 1px solid #e92;
    }
  }

  .icon_white_close {
    filter: invert(1);
  }

  .profit_overview {
    background: #EE9922;
    color: white;
    border-radius: .1rem;
    padding: .1rem;
    margin-bottom: .1rem;

    &__inner {
      display: flex;
      // place-items: center;
      justify-content: center;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: .1rem 0;

      &:first-child {
        border-top: none;
      }

      .item {
        flex-basis: 50%;
        text-align: center;
        border-left: 0.5px solid rgba(255, 255, 255, 0.2);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 .1rem;
        .value {
          font-weight: 700;
          font-size: 0.15rem;
        }

        &:first-child {
          border-left: none;
        }
      }
    }
  }

  .btn {
    margin-bottom: .1rem;

    button {
      background: #e92;
      text-align: center;
      border-radius: 0.06rem;
      font-weight: 700;
      font-size: .16rem;
      color: #fff;
      line-height: .44rem;
      width: 100%;
      border: 0;
      margin-top: .1rem;
    }
  }

  .profit_box {
    background: $BG3;
    border-radius: 0.06rem;
    padding: 0.03rem 0;

    .fin_box {
      margin: 0 0.03rem;
      border-radius: 0.03rem;
      background: $FFF;
      padding: 0.16rem 0.13rem 0.08rem;

      .tx2_box {
        padding-bottom: 0.12rem;
        border-bottom: 1px solid $BR2;
        margin-bottom: 0.12rem;
      }


    }

    .item_box {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 0.5px solid rgba(119, 142, 153, 0.12);
      padding: .05rem 0;

      &.no-border {
        border-top: none;
      }

      .inner {
        flex-basis: 50%;
        text-align: left;
        border-left: 0.5px solid rgba(119, 142, 153, 0.12);

        &:first-child {
          border-left: none;
        }

        &.tx_r {
          text-align: right;
        }
      }
    }

    .tx1 {
      font-size: 0.13rem;
      color: $T7;
      margin-bottom: 0.03rem;
    }

    .tx2 {
      font-size: 0.2rem;
      color: #e92;
      font-weight: bold;
    }

    .tx3 {
      font-size: 0.14rem;
      color: #e92;
      font-weight: bold;
    }

    .tx4 {
      font-size: 0.14rem;
      color: $T7;
    }

    .tx5 {
      font-size: 0.12rem;
      color: $T7;
      margin-bottom: 0.03rem;
    }

    .tx6 {
      font-size: 0.13rem;
      font-weight: bold;
      color: $T6;
    }

    .tx7_box {
      padding: 0.14rem 0.16rem 0.16rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .tx7 {
      font-size: 0.13rem;
      color: $T6;
    }
  }

  .yesterday_profit {
    padding: 0.14rem 0.16rem;
    background: $BG3;
    font-size: 0.13rem;
    font-weight: bold;
    color: $T6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.06rem;
    margin-bottom: 0.24rem;
  }

  .profit_tx {
    font-size: 0.14rem;
    font-weight: bold;
    color: $T6;
    margin-bottom: 0.12rem;
  }

  .profit_details {
    background: $BG3;
    padding: 0.14rem 0.16rem;
    margin-bottom: 0.12rem;
    border-radius: 0.06rem;
    display: flex;
    justify-content: space-between;
    // place-items: center;
    flex-wrap: wrap;

    .hash {
      display: flex;
      justify-content: space-between;
      place-items: center;
      width: 100%;
      border-bottom: 1px solid $BR2;
      padding-bottom: .05rem;
      margin-bottom: .05rem;
      color: #00968D;
    }

    .tx_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
    }

    .tx_bottom {
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      font-size: 0.12rem;
      color: $T7;
    }

    .right {
      p {
        margin: 0.05rem 0;
      }

      .status {
        &.success {
          color: #EE9922;
        }

        &.fail {
          color: #DD4466;
        }
      }
    }

    .tx1 {
      font-size: 0.13rem;
      color: $T6;
    }

    .tx2 {
      font-size: 0.13rem;
      color: $T8;
      font-weight: bold;
    }

    .tx3 {
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.05rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }

    .tx4 {
      color: $T12;
      background: $T11;
    }

    .tx5 {
      color: $T14;
      background: $T13;
    }

    .tx9 {
      color: $T16;
      background: $T15;
    }

    .tx6 {}

    .tx7 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon_gray_arrow {
        width: 0.12rem;
        height: 0.12rem;
      }
    }

  }
}</style>