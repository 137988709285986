<template>
  <article class="h5_index_view">

    <section class="logo_main">
      <div class="logo_box">
        <i class="h5_icon icon_logo_scf"></i>
        <span class="tx1">SCF</span>
      </div>
      <div class="d_flex_space">
        <div class="address_box" v-show="storeChangeAddress" @click="isShowAddress = true">
          <span class="tx1" :class="storeChainType == 'BSC' ? 'tx3' : storeChainType == 'ETH' ? 'tx6' : 'tx4'">{{storeChainType}}</span>
          <span class="tx2">{{substr(storeChangeAddress, 0, 5)}}...{{substr(storeChangeAddress, -5)}}</span>
        </div>
        <i class="h5_icon icon_select_wallet ml_5" @click="showWalletFn" v-if="false"></i>
        <span @click="showWalletFn" v-show="!storeChangeAddress" class="tx5">{{$t('connect')}}</span>
      </div>

    </section>
    <section class="banner_box">
      <div class="swiper swiper_banner">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(banner, index) in bannerList" :key="index" @click="goLinkFn(banner.img_link_addr + '?langId='+storeLang + '&langId='+storeLang)">
            <img :src="banner.img_addr" class="img_banner">
          </div>

        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <section class="notice_main" >
      
      <div class="notice_box">
        <i class="h5_icon icon_notice"></i>
        <div class="tx_box">

          <div class="swiper notice_banner">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(list, index) in noticeList" :key="index" @click="goLinkFn(list.link + '?langId='+storeLang + '&langId='+storeLang)">
                <p class="tx1 tx_over">{{list.title}}</p>
              </div>
            </div>

          </div>
        </div>
      </div>
      <i class="h5_icon icon_menu" @click="goRouter('/notice_list')"></i>
    </section>
    
    <section class="main_box">
      <div class="link_box1">
        <div class="item mb_20" @click="goLinkFn(indexData.official_website_addr, {}, true)">
          <p><i class="h5_icon icon_home_website"></i></p>
          <p class="tx1">{{$t('website')}}</p>
        </div>
        <div class="item mb_20" @click="goLinkFn('https://www.scfchain.io/?ref=infoBox6', {}, true)">
          <p><i class="h5_icon icon_home_node"></i></p>
          <p class="tx1">{{$t('node_1')}}</p>
        </div>
        <div class="item mb_20" @click="goLinkFn(indexData.git_addr, {}, true)">
          <p><i class="h5_icon icon_home_github"></i></p>
          <p class="tx1">{{$t('source_code')}}</p>
        </div>
        <div class="item mb_20" @click="goLinkFn(indexData.audit_addr, {}, true)">
          <p><i class="h5_icon icon_home_pdf"></i></p>
          <p class="tx1">{{$t('audit_report')}}</p>
        </div>
        <div class="item" @click="goLinkFn(whiteData[storeLang], {}, true)">
          <p><i class="h5_icon icon_home_white_pdf"></i></p>
          <p class="tx1">{{$t('white_pdf')}}</p>
        </div>
        <div class="item" @click="goLinkFn(indexData.help_center_addr + '?langId='+storeLang)">
          <p><i class="h5_icon icon_home_help"></i></p>
          <p class="tx1">{{$t('help_center')}}</p>
        </div>
        <!-- <div class="item" @click="goLinkFn('/node_index_fin_chain')">
          <p><i class="h5_icon icon_home_fin_chain"></i></p>
          <p class="tx1" style="white-space: nowrap;">{{$t('fin_chain_node')}}</p>
        </div> -->
        <div class="item" @click="goRouter('/fsp_trading')">
          <p><i class="h5_icon icon_home_fin_chain"></i></p>
          <p class="tx1" style="white-space: nowrap;">FSP trading</p>
        </div>
      </div>
      <div class="link_box2">
        <div class="item" @click="goLinkFn(indexData.sto_link + '?langId='+storeLang)">
          <i class="h5_icon icon_home_partner" ></i>
          <p class="tx1">{{$t('partner')}}</p>
        </div>
        <div class="item" @click="linkRouterFn('/share_wallet')">
          <i class="h5_icon icon_home_share"></i>
          <p class="tx1">{{$t('to_invite')}}</p>
        </div>
      </div>
      <div class="link_box2">
        <a class="item" :href="indexData.super_node_link" target="_blank">
          <i class="h5_icon icon_super_node" ></i>
          <p class="tx1">{{$t('super_node')}}</p>
        </a>
        <!-- <div class="item" @click="linkRouterFn('/otc_index')" >
          <i class="h5_icon icon_home_otc"></i>
          <p class="tx1">{{$t('buy_coin_1')}}</p>
        </div> -->
      </div>

      <div class="link_box4" @click="goLinkFn(indexData.introduce_link + '?langId='+storeLang)">
        <i class="h5_icon icon_home_introduction_scf"></i>
        <div class="r_box">
          <div class="tx_box">
            <p class="tx1">SCF {{$t('introduce')}}</p>
            <p class="tx2">{{$t('index_view.msg1')}}</p>
          </div>
          <i class="h5_icon icon_round_arrow"></i>
        </div>
      </div>
      <div class="link_box5">
        <div  class="r_box d_flex_space"  @click="linkRouterFn('/loan_details')">
          <div class="d_flex">
            <i class="h5_icon icon_home_fin2"></i>
            <p class="tx1">{{$t('tips_view.msg68')}}</p>
          </div>
          <i class="h5_icon icon_gray_arrow"></i>
        </div>
        <div  class="l_box d_flex_space"  @click="goRouter('/loan')">
          <div class="d_flex">
            <i class="h5_icon icon_home_fin1"></i>
            <p class="tx1">{{$t('to_manage_money')}}</p>
          </div>
          <i class="h5_icon icon_gray_arrow"></i>
        </div>

      </div>
    </section>

    <section class="tab_box d_flex">
      <div class="item d_flex_cen" :class="curType == 1 ? 'item_cur' : ''" @click="tabFn(1)">
        <span>{{ $t('swap_coin_rank') }}</span><i class="h5_icon icon_question ml_5" @click="isShowCoinRankTips = true"></i>
      </div>
      <div class="item d_flex_cen" :class="curType == 0 ? 'item_cur' : ''" @click="tabFn(0)">
        <span>{{ $t('swap_trade_rank') }}</span><i class="h5_icon icon_question ml_5"  @click="isShowRankTips = true"></i>
      </div>

    </section>

    <section class="rank_box" v-show="curType == 0">
      <div class="rank_item rank_head d_flex">
        <div class="item">{{ $t('loan_wallet') }}</div>
        <div class="item">{{ $t('main_loan_coin') }}</div>
        <div class="item tx_r">{{ $t('loan_rate') }}</div>
      </div>
      <div class="rank_item rank_box d_flex" v-for="(list, index) in tradeRankArr" :key="index" @click="goLinkFn((list.coin_data.type == 'bsc' ? 'https://bscscan.com/address/' : list.coin_data.type == 'tron' ? 'https://tronscan.org/#/address/' : 'https://etherscan.io/address/') + list.address, {}, true)">
        <div class="item">{{substr(list.address, 0, 3)}}...{{substr(list.address, -3)}}</div>
        <div class="item d_flex">
          <span>{{ list.coin_data.code }}</span>
          <span v-show="list.coin_data.code != 'TRX' && list.coin_data.code != 'BNB' && list.coin_data.code != 'ETH'" class="tx3  " :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>

        </div>
        <div class="item   tx_r">
          <span class="rate_box rate_box1">{{ list.profit_rate }}</span>
        </div>
      </div>
    </section>
    <section class="rank_box" v-show="curType == 1">
      <div class="rank_item rank_head d_flex">
        <div class="item">{{ $t('currency') }}</div>
        <div class="item">{{ $t('new_price') }}</div>
        <div class="item tx_r">{{ $t('24rate') }}</div>
      </div>
      <div class="rank_item rank_box d_flex" v-for="(list, index) in coinRankArr" :key="index" @click="goLinkFn(list.link)">
        <div class="item d_flex">
          <span>{{ list.coin_data.code }}</span>
          <span v-show="list.coin_data.code != 'TRX' && list.coin_data.code != 'BNB' && list.coin_data.code != 'ETH'" class="tx3  " :class="list.coin_data.type + '_color'"><span class="fz_10 font_bold">{{ coinChainType[list.coin_data.type] }}</span></span>

        </div>
        <div class="item">{{ list.price }}</div>
        <div class="item  tx_r">
          <span class="rate_box rate_box2">{{ list.rate }}</span>
        </div>
      </div>
    </section>






    <article class="com_popup_main" v-show="isShowCheck">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('link_wallet')}}</span>
          <i class="h5_icon icon_close" @click="isShowCheck = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="exchange_item_main ">
            <div class="exchange_item_box" @click="linkWalletFn('metamask')">
              <p><i class="h5_icon icon_metamask"></i></p>
              <p class="tx1">Metamask</p>
            </div>
            <div class="exchange_item_box" @click="linkWalletFn('trust_wallet')" v-show="false">
              <p><i class="h5_icon icon_trust_wallet"></i></p>
              <p class="tx1">Trust Wallet</p>
            </div>
            <div class="exchange_item_box" @click="linkWalletFn('token_pocket')">
              <p><i class="h5_icon icon_tokenpocket"></i></p>
              <p class="tx1">TokenPocket</p>
            </div>

            <div class="exchange_item_box" @click="linkWalletFn('imtoken')">
              <p><i class="h5_icon icon_imtoken"></i></p>
              <p class="tx1">Imtoken</p>
            </div>

            <div class="exchange_item_box" @click="linkWalletFn('wallet_connect')" v-show="false">
              <p><i class="h5_icon icon_walletconnect"></i></p>
              <p class="tx1">WalletConnect</p>
            </div>

          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowAddress">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('you_wallet')}}</span>
          <i class="h5_icon icon_close" @click="isShowAddress = false"></i>
        </div>
        <div class="com_popup_info_box wallet_address_box">
          <div class="wallet_address_item">
            <div class="tx1">{{$t('wallet_address')}}</div>
            <div class="tx2 d_flex">
              <p class="tx3">{{storeChangeAddress}}</p>
              <p class="r_box"><i class="h5_icon icon_green_copy" @click="copyText(storeChangeAddress)"></i></p>
            </div>
          </div>
          <div class="wallet_address_item">
            <div class="tx1">{{$t('wallet_id')}}</div>
            <div class="tx2 d_flex">
              <p class="tx3">{{storeChangeAddressId != 0 ? storeChangeAddressId : ''}}</p>
              <p class="r_box"><i class="h5_icon icon_green_copy" @click="copyText(storeChangeAddressId != 0 ? storeChangeAddressId : '')"></i></p>
            </div>
          </div>
        </div>
      </section>
    </article>



    <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading>
    <com-bind-wallet-id :isShow="isShowWallet" @on-hide="()=>{this.isShowWallet = false}"></com-bind-wallet-id>
    <index-footer :index="1"></index-footer>

    <article class="com_popup_main" v-show="isShowTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('index_view.msg10')}}</span>
          <i class="h5_icon icon_close" @click="isShowTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{$t('index_view.msg11')}}</p>
            <p class="tx5 color_T6 mb_10">{{$t('index_view.msg12')}}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('index_view.msg13')" @click="goRouter('/link_wallet_introduce')"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowRankTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('swap_trade_rank')}}</span>
          <i class="h5_icon icon_close" @click="isShowRankTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{$t('index_view.msg15')}}</p>
            <p class="tx5 color_T6 mb_10">{{$t('index_view.msg16')}}</p>
            <p class="tx5 color_T7 mb_10">{{$t('index_view.msg17')}}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="isShowRankTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>
    <article class="com_popup_main" v-show="isShowCoinRankTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('swap_coin_rank')}}</span>
          <i class="h5_icon icon_close" @click="isShowCoinRankTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{$t('index_view.msg14')}}</p>
            <p class="tx5 color_T6 mb_10">{{$t('index_view.msg14_1')}}</p>
            <div class="com_popup_btn_box">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_pending" :value="$t('i_confirm')" @click="isShowCoinRankTips = false"/>
            </div>
          </div>
        </div>
      </section>
    </article>

    <article class="com_popup_main" v-show="isShowAuthTips">
      <div class="com_popup_bg"></div>
      <section class="com_popup_box">
        <div class="com_popup_head">
          <span class="title_tx">{{$t('wallet_connect')}}</span>
          <i class="h5_icon icon_close" @click="isShowAuthTips = false"></i>
        </div>
        <div class="com_popup_info_box">
          <div class="com_popup_from_box">
            <p class="tx5 color_T6 mb_10">{{$t('wallet_connect_tip1')}}</p>
            <p class="tx5 color_T9">{{$t('wallet_connect_tip2')}}</p>
            <div class="com_popup_btn_box1 d_flex_space">
              <input type="button" class="com_popup_input_btn com_popup_input_btn_close" :value="$t('cancel')" @click="isShowAuthTips = false"/>
              <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('confirm')" @click="singauthFn"/>
            </div>
          </div>
        </div>
      </section>
    </article>

  </article>
</template>

<script>
import Swiper from 'swiper/swiper-bundle.min'
import 'swiper/swiper-bundle.min.css'
import indexFooter from '../components/index_footer'
import comLoading from '../components/loading'
import comBindWalletId from '../components/bind_wallet_id'
import CryptoJS from 'crypto-js';
import socket from '@/utils/socket'
export default {
  name: "homes",
  data() {
    return {
      status: 'loading',
      text: this.$t('tips_view.msg1'),
      isShowCoinRankTips: false,
      isShowRankTips: false,
      isShowAuthTips: false,
      isShowTips: false,
      isShowLoading: false,
      isShowWallet: false,
      isShowAddress: false,
      isShowCheck: false,
      isLoad: false,
      bannerList: [],
      noticeList: [],
      indexData: {},
      sing: '',
      balance: '',
      isInit: 1,
      timeOut: null,
      curType: 1,
      coinRankArr: [],
      tradeRankArr: [],
      socket: null,
      unMount: false,
      socketTimeOut: null,
      timeSec: 5000,
      whiteData: {
        'cn': 'https://mv.finmeta.me/whitepaper/scf_simplified_chinese.pdf',
        'en': 'https://mv.finmeta.me/whitepaper/scf.pdf',
        'es': 'https://mv.finmeta.me/whitepaper/scf_spanish.pdf',
        'fr': 'https://mv.finmeta.me/whitepaper/scf_french.pdf',
        'ja': 'https://mv.finmeta.me/whitepaper/scf_japanese.pdf',
        'ko': 'https://mv.finmeta.me/whitepaper/scf_korea.pdf',
        'ms': 'https://mv.finmeta.me/whitepaper/scf_malay.pdf',
        'vi': 'https://mv.finmeta.me/whitepaper/scf_vietnamese.pdf',
        'zh': 'https://mv.finmeta.me/whitepaper/scf_traditional_chinese.pdf',
      }
    }
  },
  async created() {
    /*
    // 切换网络
    await window.ethereum.request({
    method: 'wallet_switchEthereumChain',
    params: [{chainId: '0x38'}]
    });
    */
    /*
    // 切换网络
    await window.ethereum.request({
    method: 'wallet_addEthereumChain',
    params: [
      {
          chainId: '0x38',
          chainName: 'BSC',
          nativeCurrency: {
              name: 'BNB',
              symbol: 'BNB',
              decimals: 18,
          },
          rpcUrls: ['https://bsc-dataseed.binance.org/'],
          blockExplorerUrls: ['https://bscscan.com/'],
      },
    ]
    });
    */
    /*this.selectChain('ETHEREUM')
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    alert(chainId)*/
    // this.ethConnect()
    /*if (window.sessionStorage.getItem('isLoad') != 2) {
      window.sessionStorage.setItem('isLoad', 2)
      window.location.reload()
    }*/
    /*if (!window.sessionStorage.getItem('storeChangeAddress') ) {
      this.walletInit('ETHEREUM')
      //ETHEREUM
      /!*
      * features=1661416636
      * &id=11573
      * &signature=b9c51f68a45041c55c34dc45064d06925e7620b16ce3ab0e2de8391584dccfbe36bc8e690c367a407ae4efee9b064e5be6f53db5557e1cacb330696ea1b34c2c01
      * &user_id=ID78e145ea77cbf7f450eb10642da901fd00579302*!/
    }*/


    this.walletInit()
    this.resInit()
    this.socketInit()
    if (this.storeChangeAddressId && !this.storeSingAuth && !this.isShowWallet) {
      this.isShowAuthTips = true
    }

  },
  components: {
    indexFooter,
    comLoading,
    comBindWalletId,
  },
  mounted() {


  },
  beforeUnmount () {
    this.unMount = true
    if (this.socket) {
      this.socket.doClose()
      this.socket = null
    }

  },
  methods: {
     singauthFn () {
       this.isLoad = true
       this.isShowLoading = true
       this.status = 'loading'
       this.text = this.$t('connect_ing')

       if (this.ajax) {
         return false
       }
       this.isAjax = true
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}now`, req, "get")
          .then(async (res) => {
            let srcs = CryptoJS.enc.Utf8.parse(res);
            let encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse('ftcchaincrypto00'), {
              iv: CryptoJS.enc.Utf8.parse('ftcchaincrypto00'),
              mode: CryptoJS.mode.CBC,
              padding: CryptoJS.pad.Pkcs7
            })
            let hx = CryptoJS.enc.Hex.stringify(encrypted.ciphertext)
            let sing = await this.dappSingFn(hx)
            console.log(sing)
            if (!sing) {
              this.messageFn(this.$t('tips_view.msg5'))
              return false
            }
            let singAuth = this.storeChainType.toLocaleLowerCase() + '.' + hx + '.' + sing
            window.sessionStorage.setItem('storeSingAuth', singAuth)
            this.$store.commit('setSingAuth', singAuth)
            this.isShowAuthTips = false
            this.isAjax = false
            this.status = 'success'
            this.text = this.$t('connect_success')
            setTimeout(() => {
              this.isShowLoading = false
            }, 2000)
          }).catch((res) => {
        this.isAjax = false
        this.status = 'error'
        this.text = this.$t('connect_fail')
        setTimeout(() => {
          this.isShowLoading = false
        }, 2000)
        this.messageFn(res.msg)
      });
    },
    socketInit() {
      this.socket = new socket(`${this.Utils.URL.wsURL}swap_rank_ws`)
      this.socket.doOpen()

      this.socket.on('open', (data) => {
        this.socket.send('1')
      })
      this.socket.on('message', (res) => {
        if (res.type == 1) {
          this.tradeRankArr = res.data.list ? res.data.list : []
        }
        if (res.type == 2) {
          this.coinRankArr = res.data.list ? res.data.list : []
        }
      })
      let slef = this
      this.socket.on('close', (res) => {
        slef.socketResetFn()

      })

    },
    socketResetFn () {
      if (this.socket && !this.unMount) {
        this.socket = null
        clearTimeout(this.socketTimeOut)
        this.socketTimeOut = setTimeout(() => {
          this.socketInit()
          this.timeSec = this.timeSec + 5000
        }, this.timeSec)
      }
    },
    tabFn (type) {
      this.curType = type
    },
    linkRouterFn (path, query = {}) {
      if (!this.storeChainType) {
        this.messageFn(this.$t('tips_view.msg52'))
        return false
      }
      if (!this.storeChangeAddressId) {
        this.isShowWallet = true
        return false
      }
      this.goRouter(path, query)
    },
    async subCouponFn () {

      // this.sing = await this.dappSingFn('1661416636' + '' + '11573' + 'ID78e145ea77cbf7f450eb10642da901fd00579302')
    },

    linkWalletFn (type) {
      this.isShowCheck = false
      let url = ''
      let dappUrl = this.Utils.URL.dappUrl + '/?isLink=1'
      // this.Utils.URL.dappUrl + '/?isLink=1'
      // https://www.fintoch.io + '/?isLink=1'
      if (type == 'wallet_connect') {
        this.walletConnectFn()
        return false
      }
      if (type == 'metamask') {
        url = 'https://metamask.app.link/dapp/' + dappUrl.replace('https://', '')
      }
      if (type == 'trust_wallet') {
        url = 'trust://open_url?coin_id=60&url=' + dappUrl
      }
      if (type == 'imtoken') {
        url = 'imtokenv2://navigate/DappView?url=' + dappUrl
      }
      if (type == 'token_pocket') {
        let params = JSON.stringify({
          url: encodeURI(dappUrl),
          // chain: 'BSC',
          source: 'fintoch'
        })

        url = `tpdapp://open?params=${params}`

      }
      window.open(url, '_blank')
    },
    walletInit () {
      /*this.isInit = 1
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(()=> {
        if (this.isInit == 1 && this.storeChangeAddress) {
          this.eachWallet()
        }
      }, 2000)*/
      this.selectChain().then(res => {
        // this.isInit = 2


        // this.signUSDTBalanceFn('TYp8fXsbrLFpFwVyMJM4STxeDhRZhDd97A', '0.0001')

        this.eachWallet()



      }).catch(res => {
        if (this.$route.query.isLink == 1) {
          this.isShowTips = true
          // this.messageFn(this.$t('tips_view.msg3'))
        }


      })
    },
    showWalletFn () {
      this.isShowCheck = true
    },
    resInit () {
      let req = {


      };
      this.$ajax(`${this.Utils.URL.goAPPURL}home/home_page_interface`, req, "get")
          .then((res) => {
            this.indexData = res ? res : {}
            this.bannerList = this.indexData.banner_list ? this.indexData.banner_list : []
            this.noticeList = this.indexData.notice_list ? this.indexData.notice_list : []
            setTimeout(() => {
              let mySwiper = new Swiper ('.swiper_banner', {
                direction: 'horizontal',
                loop: true,
                autoplay: true,
                // 如果需要分页器
                pagination: {
                  el: '.swiper-pagination',
                },
              })
              let noticeSwiper = new Swiper ('.notice_banner', {
                direction: 'vertical',
                loop: true,
                autoplay: true,
              })

            }, 300)

          }).catch((res) => {
            this.messageFn(res.msg)
          });
    },


  },
  watch: {
    storeChangeAddressId (n, o) {
      if (n == '') {
        this.isShowWallet = true
      } else {
        this.isShowWallet = false
        if (!this.storeSingAuth && !this.isShowAuthTips) {
          this.isShowAuthTips = true
        }
      }
    }
  }
};
</script>

<style lang="scss">
.h5_index_view{
  .logo_main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.12rem 0.16rem;
    box-shadow: 0px 0.5px 0px $BR;
    color: $T6;
    .logo_box{
      display: flex;
      align-items: center;
      text-align: center;
      .tx1{
        font-size: 0.2rem;
        font-weight: bold;
        margin-left: 0.05rem;
      }
    }
    .address_box{
      background: $BG2;
      padding: 0.03rem 0.1rem 0.03rem 0.03rem;
      border-radius: 0.13rem;
      font-size: 0.13rem;
      display: flex;
      align-items: center;
      text-align: center;
      .tx1{

        border-radius: 0.13rem;
        min-width: 0.47rem;
        line-height: 0.2rem;
        color: $FFF;
        margin-right: 0.05rem;
      }
      .tx2{
        font-size: 0.13rem;
        color: $T6;
      }
      .tx3{
        background: $T8;
      }
      .tx4{
        background: $T14;
      }
      .tx6{
        background: $T16;
      }
    }
    .tx5{
      display: inline-block;
      color: $FFF;
      font-size: 0.13rem;
      border-radius: 0.13rem;
      font-weight: bold;
      background: $T8;
      padding: 0.04rem 0.12rem;
    }
  }
  .banner_box{
    margin: 0.16rem;
    height: 1.37rem;
    overflow: hidden;
    border-radius: 0.06rem;
    .img_banner{
      width: 100%;
      border-radius: 0.08rem;
    }
    .swiper{
      height: 1.37rem;
      .swiper-pagination-bullet{
        width: 0.12rem;
        height: 0.02rem;
        border-radius: 0.01rem;
        background: rgba(255, 255, 255, 0.5);
      }
      .swiper-pagination-bullet-active{
        width: 0.2rem;
        background: $FFF;
      }
    }
  }
  .notice_main{
    padding: 0 0.16rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .notice_box{
      display: flex;
      align-items: center;
      font-size: 0.13rem;
      color: $T6;
      .tx_box{
        width: 2.5rem;
        height: 0.2rem;
        overflow: hidden;
        margin-left: 0.08rem;
        .swiper{
          height: 0.2rem;
        }
      }
      .tx1{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap;
      }
    }
  }
  .main_box{
    padding: 0 0.16rem;
    .link_box1{
      color: $T6;
      font-size: 0.13rem;
      font-weight: bold;
      margin: 0.24rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      text-align: center;
      .item{
        width: 25%;
      }
      .tx1{
        text-align: center;
        // white-space: nowrap;
      }
    }
    .link_box2{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;
      .item{
        display: flex;
        align-items: center;
        width: 1.66rem;
        border-radius: 0.3rem;
        padding: 0.1rem;
        border: 1px solid $BR;
        color: $T6;
        .tx1{
          font-size: 0.14rem;
          font-weight: bold;
          margin-left: 0.1rem;
        }
      }
    }
    .link_box3{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      padding: 0.1rem 0.12rem 0.1rem 0.1rem;
      border-radius: 0.3rem;
      border: 1px solid $BR;
      .l_box{
        display: flex;
        align-items: center;
      }
      .tx1{
        color: $T6;
        font-size: 0.14rem;
        font-weight: bold;
        margin-left: 0.1rem;
      }
    }
    .link_box4{
      display: flex;
      align-items: center;
      border-radius: 0.06rem;
      background: $BG2;
      margin-bottom: 0.2rem;
      .r_box{
        margin-left: 0.4rem;
        padding: 0 0.12rem 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tx_box{
          color: $T6;
          width: 1.75rem;
          .tx1{
            font-size: 0.18rem;
            font-weight: bold;
          }
          .tx2{
            font-size: 0.12rem;
          }
        }
      }
    }
    .link_box5{
      display: flex;
      align-items: center;
      justify-content: space-between;


      margin-bottom: 0.2rem;

      .r_box{
        width: 1.66rem;
        background: $T20;
        border-radius: 0.06rem;
        padding: 0 0.12rem 0 0 ;
        .tx1{
          color: $T5;
          font-size: 0.16rem;
          font-weight: bold;
          margin-left: 0.12rem;
        }
      }
      .l_box{
        width: 1.66rem;
        background: $T10;
        border-radius: 0.06rem;
        padding: 0 0.12rem 0 0 ;
        .tx1{
          color: $T8;
          font-size: 0.16rem;
          font-weight: bold;
          margin-left: 0.12rem;
        }
      }
    }
  }
  .wallet_address_box{
    .wallet_address_item{
      color: $T6;
      font-size: 0.15rem;
      margin-bottom: 0.24rem;
      .tx1{
        font-weight: bold;
        margin-bottom: 0.1rem;
      }
      .tx2{
        padding: 0.12rem 0 0.12rem 0.12rem;
        justify-content: space-between;
        background: $BG3;
        border: 0.5px solid rgba(119, 142, 153, 0.25);
        border-radius: 0.06rem;
        .r_box{
          padding: 0.1rem 0.12rem 0.1rem 0.1rem;
        }
      }
      .tx3{
        word-wrap: break-word;
        word-break: break-word;
      }
    }
  }

  .exchange_item_main{
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    padding-bottom: 0.5rem;
    .exchange_item_box{
     width: 50%;
      text-align: center;
      margin-bottom: 0.3rem;
      .tx1{
        color: $T6;
        font-size: 0.16rem;
        font-weight: bold;
        margin-top: 0.05rem;
      }

    }

  }
  .tab_box{

    .item{
      font-size: 0.14rem;
      font-weight: bold;
      color: $T7;
      border-bottom: 1px solid $BR;
      padding: 0.1rem 0;
      text-align: center;
      width: 50%;
    }
    .item_cur{
      color: $T8;
      border-bottom: 1px solid $T8;
    }
  }
  .rank_box{
    .tx2{
      font-size: 0.12rem;
      color: $T7;
    }
    .tx3{
      font-size: 0.12rem;
      margin-left: 0.05rem;
      padding: 0.01rem 0.03rem;
      border-radius: 0.08rem;
      font-weight: bold;
      display: inline-block;
    }
    .item{
      width: 33.33%;
    }
    .rank_head{
      color: $T7;
      font-size: 0.13rem;
      padding: 0.16rem;
    }
    .rank_box{
      padding:0 0.16rem;
      margin-bottom: 0.2rem;
      color: $T6;
      font-size: 0.16rem;
    }
    .rate_box1{
      background: $T8;

    }
    .rate_box2{
      background: $T5;

    }
    .rate_box{
      display: inline-block;
      color: $FFF;
      font-weight: bold;
      width: 0.9rem;
      text-align: center;
      line-height: 0.36rem;
      border-radius: 0.06rem;

    }
  }
}
</style>
