<template>
  <article class="h5_fin_chain_node_index_view">
    <div class="wallet_tips" v-show="Wallet_Uuid">
      <p class="wallet_tips_wrap">
        <i class="h5_icon icon_origin_wallet_white mr_5"></i>
        <span>{{substr(Wallet_Uuid, 0, 8)}}...{{substr(Wallet_Uuid,-8)}}</span>
      </p>
    </div>
    <section class="banner_box " :class="'fin_chain_banner_' + storeLang" @click="linkOpenFn" >

    </section>
    
    <section class="activity_info_box">

        <p class="tx" >
          <span class="time">{{day}}</span><span class="time_unit">{{$t('time_unit.d')}}</span>
          <span class="time">{{hours}}</span><span class="time_unit">{{$t('time_unit.h')}}</span>
          <span class="time">{{minute}}</span><span class="time_unit">{{$t('time_unit.m')}}</span>
          <span class="time">{{second}}</span><span class="time_unit">{{$t('time_unit.s')}}</span>
        </p>
      <div class="btn_act">
        <p class="btn btn_cur1 d_center_flex" @click="goRouter('/vote_rule_fin_chain')">
          <span>{{ $t('voteActivityView.tips45') }}</span>
          <i class="h5_icon icon_white_arrow"></i>
        </p>
        <p class="btn btn_cur2 d_center_flex" @click="goRouter('/vote_node_record_fin_chain')">
          <span>{{ $t('voteActivityView.subscription_record') }}</span>
          <i class="h5_icon icon_white_arrow"></i>
        </p>
      </div>


    </section>

    <section class="res_main">
      <div class="t_box">
        <p class="tx1">{{ $t('voteActivityView.tips122') }}</p>
        <p class="search_box">
          <img src="../../assets/img/icon/global_search_16_white.svg" class="search_img" />
          <input type="text" v-model="searchTx" class="search_tx" @blur="searchFn" :placeholder="$t('voteActivityView.tips123')"/>
        </p>
      </div>
      <div class="list_box">
        <div class="list_item list_head">
          <div class="td tx1">{{ $t('voteActivityView.number') }}</div>
          <div class="td tx2">{{ $t('voteActivityView.tips124') }}</div>
          <div class="td tx3">{{ $t('voteActivityView.tips22') }}</div>
          <div class="td tx_r tx4">{{ $t('voteActivityView.operate') }}</div>
        </div>
          <div class="list_item list_body" v-for="(list, index) in resData" :key="index">
            <div class="td tx1">
              <span class="rank h5_icon">{{list.number}}</span>
            </div>
            <div class="td tx2" :class="list.status == 2 || list.status ==  5 ? 'tx2_cur' : ''">
              <div class="d_flex" @click="goDetails(list)">
                <span class="tx_over" >{{list.title}}</span>
                <i class="h5_icon icon_white_arrow_right"></i>
              </div>
            </div>
            <div class="td tx3">{{numFormat(list.gain_amount)}} USDT</div>
            <div class="td tx_r tx4">
              <span class="btn" @click="voteFn(index)" :class="list.status == 2 ? 'btn_cur' : list.status ==  5 ? 'btn_success' : ''">{{list.status == 2 ? $t('voteActivityView.subscription') : list.status == 5 ? $t('voteActivityView.success') : $t('voteActivityView.fail')}}</span>
            </div>
          </div>
        
        <div class="com_no_data_box" v-show="resData.length == 0">
          <p class="tx_cen"><img src="../../assets/img/icon/no_data1-02.svg" class="com_no_data_img"></p>
          <p class="tx_cen com_no_data_tx1">{{ $t('nodata') }}</p>
        </div>
      </div>
      <div class="pagination">
          <Page :total="total" @on-change="pageChangeFn" :current="page" @on-page-size-change="changePageSize"
            :page-size="size" simple :modelValue="1"/>
        </div>
    </section>
    <transition>
      <article class="com_popup_main" v-show="isVote">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('voteActivityView.tips146') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.tips147') }}</p>
              <p class="right_box d_flex">{{curData.title}}</p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.payment_address') }}</p>
              <p class="right_box">
                <span>{{Wallet_Uuid}}</span><i class="h5_icon icon_origin_copy" @click="appCopy(Wallet_Uuid)"></i>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.tips103') }}</p>
              <p class="right_box">
                <span>{{curData.user_id}}</span><i class="h5_icon icon_origin_copy" @click="appCopy(curData.user_id)"></i>
              </p>
            </div>
            <div class="item">
              <p class="left_box">{{ $t('voteActivityView.subscription_progress') }}</p>
              <p class="right_box d_flex">{{curData.gain_amount}} / {{curData.subscribe_amount}}</p>
            </div>
            <div class="com_popup_from_box">

              <p class="mb_10 d_flex">
                <i class="h5_icon mr_5" :class="!isCash ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                <span class="tx18 font_bold">{{$t('voteActivityView.tips86')}}</span>
              </p>

              <div class="com_popup_input_box mb_10" :class="errorCashTx ? 'com_popup_input_error' : ''" >
                <div>
                  <input type="number" pattern="[0-9]*" v-model="cashNum" class="com_popup_input_tx" :placeholder="$t('voteActivityView.tips87')"/>
                </div>
                <div class="error_tx" v-show="errorCashTx">{{errorCashTx}}</div>
              </div>

              <div class="tx1 mb_10">
                <span class="color_T14 mr_5">{{ $t('voteActivityView.cash_coupons') }}：<span class="color_T11">{{ cashBalance }} USDT</span></span>
              </div>

              <div class="com_popup_btn_box">
                <!-- <button class="com_popup_input_btn" :class="(cashNum != '' && +cashNum >= 100) ? 'com_popup_input_btn_cur' : ''" @click="voteSubmitFn">{{ $t('voteActivityView.subscription') }}</button> -->
                <input type="button" class="com_popup_input_btn" :class="cashNum ? 'com_popup_input_btn_cur' : ''" :value="$t('voteActivityView.subscription')" @click="voteSubmitFn"/>
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <transition>
      <article class="com_popup_main" v-show="isShowCoin">
        <div class="com_popup_bg"></div>
        <section class="com_popup_box">
          <div class="com_popup_head">
            <span class="title_tx">{{ $t('payment_type') }}</span>
            <i class="h5_icon icon_white_close" @click="hideFn"></i>
          </div>
          <div class="com_popup_info_box">
            <div class="coin_box">
              <div class="coin_item" v-for="(list, index) in coinIdObj" :key="index" @click="checkCoinFn(index)">
                <div class="coin_l">
                  <img :src="coinImgObj[list.coinId]" class="coin_img"/>
                  <div class="coin_info">
                    <p class="coin_tx">
                      <span class="coin_tx1">{{ list.coin }}</span>
                      <span class="fz_10"><span class="coin_tx2" :class="list.chain_type == 'tron' ? 'coin_tx3' : list.chain_type == 'bsc' ? 'coin_tx4' : 'coin_tx5'">{{ coinTxType(list.coinId) }}</span></span>
                    </p>
                    <p class="coin_balance">{{ $t('balance') }}: {{ cashBalance }}</p>
                  </div>
                </div>

                <div class="coin_r">
                  <i class="h5_icon" :class="coinCur == index ? 'global_many_main_sle1' : 'global_many_main_nor'"></i>
                </div>
              </div>

            </div>
            <div class="com_popup_from_box">
              <div class="com_popup_btn_box">
                <input type="button" class="com_popup_input_btn com_popup_input_btn_cur" :value="$t('next')" @click="nextVoteFn"/>
              </div>
            </div>
          </div>
        </section>
      </article>
    </transition>
    <com-loading :isShow="isShowLoading" text="loading"></com-loading>
    <!-- <com-loading :status="status" :text="text" :isShow="isShowLoading"></com-loading> -->
  </article>
</template>

<script>
import comLoading from "@/components/loading";
export default {
  name: "vote_fin_chain_index",
  data() {
    return {
      // text: this.$t('tips_view.msg8'),
      // status: 'loading',
      searchTx: '', // search input value
      nodeData: {}, // what
      timeData: {end_time: 0, begin_time: 0}, // for time remaining
      ticketNum: '',// what
      walletAddress: '', // wallet current
      walletChainTypeAddress: {
        'tron': '',
        'bsc': '',
        'eth': ''
      },
      searchTimeOutFn: null,
      timeOutFn: null,
      day: '0',
      hours: '0',
      minute: '0',
      second: '0',
      resData: [],
      page: 1,
      size: 100,
      total: 1,
      loadInfinite: false,
      isLoad: false,
      isVote: false,
      // errorTx: '',
      indexCur: 0,
      isShowCoin: false,
      coinCur: 0,
      coinImgObj: {},
      coinList: [],
      curData: {},
      coinIdObj: {},
      isShowLoading: false,
      cashNum: '', // input voucher value
      errorCashTx: '', // error for input voucher
      cashBalance: 0, //balance voucher
      isCash: false,
      xxx: window.navigator.userAgent,

    };
  },
  beforeUnmount () {
    window.Wallet_Uuid = null
  },
  created() {
    this.Wallet_Uuid = window.Wallet_Uuid
    this.resInit()
    this.resNodeInfoFn()
    this.getCoinList()
    this.cashBalanceFn()
    // this.resWalletAddressFn('bsc')
  },
  mounted() {
  },
  components: {comLoading},
  methods: {
    changePageSize (e) {
      this.size = parseInt(e)
      this.resInit()
    },
    pageChangeFn(e) {
      this.page = e
      this.resInit()
    },
    cashBalanceFn () { // balance voucher
      this.$ajax(`${this.Utils.URL.goAPPURL}super/ticket_num`, {}, 'get')
          .then(res => {
            this.cashBalance = res.quantity ? res.quantity : 0
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    goDetails (list) {
      window.sessionStorage.setItem('listData', JSON.stringify(list))
      this.$router.push('/fin_chain_node_token_details')
    },
    hideFn () {
      this.isVote = false
      this.isShowCoin = false
      this.errorCashTx = '' // error for input voucher
    },
    coinTxType (tx) {
      if (tx) {
        return ((tx.split('|'))[0]).toLocaleUpperCase()
      }
      return tx ? tx.toLocaleUpperCase() : tx
    },
    checkCoinFn (index) {
      this.coinCur = index
      this.walletAddress = this.walletChainTypeAddress[this.coinIdObj[this.coinCur].chain_type]
    },
    voteSubmitFn () {
      let cashNum = this.cashNum ? parseFloat(this.cashNum) : 0 // value voucher
      let cashBalance = this.cashBalance ? parseFloat(this.cashBalance) : 0 // balance voucher
      let min_subscribe = parseFloat(this.nodeData.min_subscribe)
      let subscribe_amount = parseFloat(this.curData.subscribe_amount) - parseFloat(this.curData.gain_amount)

      let req = {
        // title: this.curData.title,
        introduction: '',
        area: '',
        node_id: this.curData.id + '|' + this.cashNum,
        chain_type: 'bsc',
        receive_address: this.walletAddress, // cold wallet
        sender_address: this.curData.user_id
      }

      this.errorCashTx = ''

      if (!window.Wallet_Uuid) {
        this.messageFn(this.$t('voteActivityView.tips85'))
        return false
      }
      if (this.curData.status == 2) {
        if (cashNum > subscribe_amount) {
          this.errorTx = this.$t('voteActivityView.error_tips4')
          return false
        }

        if (subscribe_amount >= min_subscribe) {
          if (cashNum < min_subscribe) {
            this.errorCashTx = this.$t('voteActivityView.error_tips1_1') + min_subscribe +' USDT'
            return false
          }
        } else {
          if (cashNum != subscribe_amount) {
            this.errorCashTx = this.$t('voteActivityView.error_tips1_1') + subscribe_amount +' USDT'
            return false
          }
        }
        if (this.isCash) {
          if (cashNum < 1 || !cashNum) {
            this.errorCashTx = this.$t('voteActivityView.tips89')
            return false
          }
          if ((this.cashNum + '').indexOf('.') >= 0) {
            this.errorCashTx = this.$t('voteActivityView.error_tips2')
            return false
          }
          if (cashNum > cashBalance) {
            this.errorCashTx = this.$t('voteActivityView.tips88')
            return false
          }
        }

      }
      
      this.isShowLoading = true
      this.$ajax(`${this.Utils.URL.goAPPURL}super/subscribe/ticket`, req, 'post')
          .then(res => {
            if (res.result) {
              this.messageFn(this.$t('success'))
              this.isVote = false
              this.resInit()
            } else {
              this.messageFn(res.msg)
            }
            this.isShowLoading = false
          })
          .catch(res => {
            this.isShowLoading = false
            this.messageFn(res.msg)
          })
      return false
    },
    voteFn (index) { // click button detail in list
      this.indexCur = index
      this.curData = this.resData[index]
      if (!window.Wallet_Uuid) {
        this.messageFn(this.$t('voteActivityView.placeholder_tips7'))
        return false
      }

      if (this.timeData.end_time < 0) {
        this.messageFn(this.$t('voteActivityView.error_tips6'))
        return  false
      }

      if (this.resData[this.indexCur].status == 2) {

        this.cashNum = ''
        this.isVote = true
        this.resWalletAddressFn('bsc')
        return false
      }
    },

    nextVoteFn () {
      // console.log(this.coinIdObj[this.coinCur].chain_type)
      // if (!this.coinList.length) {
      //   this.messageFn(this.$t('voteActivityView.error_tips10'))
      //   return false
      // }

      // this.isShowLoading = true
      this.resWalletAddressFn('bsc')
      this.isVote = true
      this.isShowCoin = false
    },
    searchFn () {
      this.loadInfinite = false
      this.isLoad = false
      this.page = 1
      this.resData = []
      this.resInit(this.searchTx, 'search')
    },
    linkOpenFn () {
      // if (!this.walletAddress) { // not run on local
      if (!window.Wallet_Uuid) { // not run on local
        this.messageFn(this.$t('voteActivityView.placeholder_tips7'))
        return false
      }
      if (!this.cashBalance) {
        this.messageFn(this.$t('voteActivityView.placeholder_tips8'))
        return false
      }
      if (this.timeData.end_time < 0) {
        this.messageFn(this.$t('voteActivityView.tips84'))
        return false
      }
      if (this.timeData.end_time > 0 && this.timeData.begin_time <= 0) {
        this.$router.push('/vote_created_fin_chain_node')
      } else {
        this.messageFn(this.$t('voteActivityView.tips83'))
      }

    },
    getCoinList () {
      let req = {
        symbol: 'USDT',
        // is_recommend: true,
        page: 1,
        size: 100,
      }
      this.$ajax(`${this.Utils.URL.goAPPURL}coin`, req, 'get')
          .then(res => {
            let arr = res.list ? res.list : []
            arr.forEach(val => {
              if (val.chain_type == 'bsc') {
                this.coinImgObj[val.front_coin_id] = val.icon_url
                this.coinIdObj[val.front_coin_id] = {}
                if (!this.coinCur) {
                  this.coinCur = val.front_coin_id
                }
              }

            })
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    async resInit (type) {
      if (this.loadInfinite) {
        return false
      }
      if (this.isLoad) {
        return false
      }
      let req = {
        page: this.page,
        size: this.size,
        title: this.searchTx,
      }
      this.isLoad = true
      this.$ajax(`${this.Utils.URL.goAPPURL}fin/list`, req, 'get')
          .then(res => {
            this.isLoad = false
            this.resData = []
            let arr = res.list ? res.list : []
            this.resData = arr
            this.total = parseFloat(res.total)
          })
          .catch(res => {
            this.isLoad = false
            this.messageFn(res.msg)
          })
    },
    resNodeInfoFn () {
      let req = {

      }
      let startTime = new Date().getTime()
      this.$ajax(`${this.Utils.URL.goAPPURL}fin/info`, req, 'get')
          .then(res => {
            let endTimes = (new Date().getTime())
            let endTime =((endTimes - startTime) / 2000 ) + (endTimes / 1000)
            this.nodeData = res ? res : {}
            this.ticketNum = res.ticket_num ? res.ticket_num : ''
            this.timeData.begin_time = parseInt(res.period_start - endTime)
            this.timeData.end_time = parseInt(res.period_end - endTime)
            clearInterval(this.timeOutFn)
            this.timeOutFn = setInterval(this.timeFn, 1000)
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    resWalletAddressFn (chainType) {
      let req = {
        chain_type: 'bsc',
        node_id: this.curData.id,
        type: 6,
      }

      this.$ajax(`${this.Utils.URL.goAPPURL}get_cold_wallet`, req, 'get')
          .then(res => {
            this.walletChainTypeAddress[chainType] = res.addr
            this.walletAddress = res.addr
            
          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    },
    timeFn () {
      let time
      if (this.timeData.end_time > 0) {
        this.timeData.end_time = this.timeData.end_time - 1
        time = this.timeData.end_time
      }
      if (this.timeData.begin_time > 0) {
        this.timeData.begin_time = this.timeData.begin_time - 1
        time = this.timeData.begin_time
      }

      if (time >= 0) {

        let date = (time / 3600) / 24
        if (date >= 0) {
          this.day = date >= 10 ? parseInt(date) : '0' + parseInt(date)
        }
        date = (time / 3600) % 24
        if (time >= 0) {
          this.hours = date >= 10 ? parseInt(date) : '0' + parseInt(date)
        }
        date = time / 60 % 60
        if (date >= 0) {
          this.minute = date >= 10 ? parseInt(date, 10) : '0' + parseInt(date)
        }
        date = time % 60
        if (date >= 0) {
          this.second = date >= 10 ? parseInt(date) : '0' + parseInt(date)
        }
      } else {
        clearInterval(this.timeOutFn)
        if (this.nodeData.curr_period < 2) {
          this.resNodeInfoFn()
        }
      }
    },
  },
};
</script> 

<style lang="scss">
.h5_fin_chain_node_index_view{
  background: #F77600;
  min-height: 100vh;
  // padding-bottom: 0.5rem;
  .icon_white_arrow {
    width: 0.12rem;
    height: 0.12rem;
  }
  .com_no_data_tx1 {
    color: #dce5fa;
    font-weight: 700;
    font-size: .16rem;
  }
  .com_loading_box{
    // background: rgba(7, 22, 56, 1);
    .loading_tx{color: #454545;}
    .icon_loading{
      background-image: url("../../assets/img/icon/loading_origin.png");
      width: 0.7rem;
      height: 0.7rem;
    }
  }
  .com_popup_main{

    .com_popup_box{
      position: absolute;
      bottom:0;
      left: 0;
      width: 100vw;
      z-index: 3;
      background: white;
      border-radius: 0.2rem 0.2rem 0 0;
      .com_popup_head{
        padding: 0.16rem;
        border-bottom: 0;
        background: rgba(255, 255, 255, 0.06);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-color: #454545;
        .title_tx{
          font-size: 0.16rem;
          font-weight: bold;
          color: #454545;
        }
        .h5_icon {
          position: absolute;
          top: 20px;
          right: 20px;
          filter: invert(1);
        }
      }
      .com_popup_info_box{
        padding: 0.24rem 0.16rem;
        .item{
          display: flex;
          justify-content: space-between;
          font-size: 0.14rem;
          margin-bottom: 0.2rem;
          .left_box{
            color: #454545;
          }
          .right_box{
            max-width: 2.6rem;
            color: #454545;
            text-align: right;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
      }
      .com_popup_from_box{

        padding-top: 0.2rem;
        .tx1{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.14rem;
          span {

          }
        }

        .com_popup_input_box{
          border-radius: 0.12rem;
          background: #F1F1F1;
          padding: 0.12rem;
          .com_popup_input_tx{

            height: 0.3rem;
            font-size: 0.16rem;
            color: #454545;

            width: 100%;

          }

        }
        .com_popup_input_error{
          background: rgba(224, 49, 93, 0.05);
        }
        .error_tx{
          margin-top: 0.05rem;
          font-size: 0.12rem;
          color: $T9;
        }
        .com_popup_btn_box{
          .com_popup_input_btn{
            height: 0.44rem;
            border-radius: 0.2rem;
            text-align: center;
            background: #A9A9A9;
            color: white;
            font-size: 0.16rem;
            font-weight: bold;
            width: 100%;
            margin-top: 0.36rem;
            border: 0;
          }
          .com_popup_input_btn_cur{
            background: linear-gradient(180.07deg, #F59428 0.06%, #F86A32 99.94%);
            color: $FFF;
          }
        }
      }
    }
  }
  .coin_box{

    .coin_item:last-child{
      margin-bottom: 0;
    }
    .coin_item{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      .coin_l{
        display: flex;
        align-content: center;
        .coin_img{
          width: 0.4rem;
          height: 0.4rem;
          margin-right: 0.1rem;
        }
        .coin_tx{
          display: flex;
          align-items: center;
          margin-bottom: 0.03rem;
        }
        .coin_tx1{
          font-size: 0.16rem;
          font-weight: bold;
          color: #454545;
          margin-right: 0.1rem;
        }
        .coin_tx2{
          padding: 0.02rem 0.04rem;
          border-radius: 0.08rem;
          font-size: 0.12rem;
          font-weight: bold;
        }
        .type_tx1{
          font-size: 0.18rem;

        }
        .coin_tx3{
          background: rgba(197, 48, 39, 0.1);
          color: $T9;
        }
        .coin_tx4{
          background: rgba(244, 187, 11, 0.1);
          color: $T8;
        }
        .coin_tx5{
          background: rgba(73, 115, 145, 0.1);
          color: rgba(90, 142, 178, 1);
        }
        .coin_balance{
          font-size: 0.12rem;
          color: $T7;
        }
      }
    }
  }
  .wallet_tips{
    font-size: 0.14rem;
    padding: 0.13rem 0.16rem;
    display: flex;
    align-items: center;
    color: rgb(220, 229, 250);
    &_wrap {
      // background: #FE641F;
      // padding: 0.08rem 0.1rem;
      // border-radius: 20px;
      display: flex;
      place-items: center;
    }
  }
  .banner_box{
    position: relative;
    background-size: cover;
    height: 1.88rem;
    .tx_box{
      color: $T6;
      font-weight: bold;
      font-size: 0.17rem;
      position: relative;
      width: 1.6rem;
      left: 0.16rem;
      top: 0.49rem;
      text-align: center;
      display: flex;
      align-items: center;
      height: 0.4rem;
      justify-content: center;
    }
  }
  .fin_chain_banner_cn{
    background-image: url("/down/fin_chain_banner_cn.png");
  }
  .fin_chain_banner_en{
    background-image: url("/down/fin_chain_banner_en.png");
  }
  .fin_chain_banner_es{
    background-image: url("/down/fin_chain_banner_es.png");
  }
  .fin_chain_banner_fr{
    background-image: url("/down/fin_chain_banner_fr.png");
  }
  .fin_chain_banner_id{
    background-image: url("/down/fin_chain_banner_id.png");
  }
  .fin_chain_banner_ja{
    background-image: url("/down/fin_chain_banner_ja.png");
  }
  .fin_chain_banner_ko{
    background-image: url("/down/fin_chain_banner_ko.png");
  }
  .fin_chain_banner_ms{
    background-image: url("/down/fin_chain_banner_ms.png");
  }
  .fin_chain_banner_th{
    background-image: url("/down/fin_chain_banner_th.png");
  }
  .fin_chain_banner_vi{
    background-image: url("/down/fin_chain_banner_vi.png");
  }
  .fin_chain_banner_zh{
    background-image: url("/down/fin_chain_banner_zh.png");
  }
  .activity_period_main{
    padding: 0.2rem 0;
    .activity_period_box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 0 0.16rem;
      .item{
        width: 1.07rem;
        position: relative;
        line-height: 0.36rem;
      }
      .item_1{
        text-align: center;
      }

      .item_2{
        text-align: center;
      }

      .item_3{
        text-align: center;
      }

      .tx{
        font-weight: bold;
        white-space: nowrap;
        color: $T7;
        font-size: 0.13rem;
        background: rgba(255, 255, 255, 0.06);
        border-radius: 0.18rem;
      }
      .tx_cur{
        color: $FFF;
        background: $T11;
      }
    }
  }

  .activity_info_box{
    padding: 0.24rem 0rem 0.16rem;
    margin: 0.16rem 0.16rem 0.24rem;
    background: #F89C46;
    border-radius: 0.16rem ;
    position: relative;
    text-align: center;
    .btn{

      width: 1.54rem;
      background: linear-gradient(0deg, #F67600, #F67600);
      color: white;
      font-size: 0.13rem;

      margin-top: 0.24rem;
      line-height: 0.36rem;
      text-align: center;
      display: flex;
      place-items: center;
      justify-content: center;
      .icon_white_arrow_right{
        width: 0.12rem;
        height: 0.12rem
      }
    }
    .btn_act{
      display: flex;
      justify-content: space-between;
      padding: 0 0.16rem;
    }
    .btn_cur1{
      border-radius: 0.16rem 0 0 0.16rem;
    }
    .btn_cur2{
      border-radius: 0 0.16rem  0.16rem 0;
    }
    .btn_cur{
      background: $T11;
      color: $FFF;
    }

    .title_tx{
      font-weight: bold;
      color: white;
      font-size: 0.14rem;
      margin-bottom: 0.08rem;
    }
    .tx1{
      font-size: 0.2rem;
      color: $T11;
      font-weight: bold;
    }
    .tx{
      font-size: 0.24rem;
      color: #E15701;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      .time{
        width: 0.42rem;
        line-height: 0.4rem;
        font-weight: bold;
        display: inline-block;
        text-align: center;
        margin: 0 0.08rem 0 0;
      }
      .time_unit:last-child{
        margin-right:0 ;
      }
      .time_unit{
        font-weight: bold;
        font-size: 0.14rem;
        margin: 0 0.16rem 0 0;
        color: white;
      }
    }


  }
  .res_main{
    padding: 0.16rem 0.16rem 0.2rem;
    position: relative;
    min-height: 2rem;

    .t_box{
      text-align: center;
      margin-bottom: 0.16rem;

      .tx1{
        color: white;
        font-size: 0.17rem;
        font-weight: bold;
        margin-bottom: 0.16rem;
      }
      .search_box{
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 0.27451);
        border-radius: 0.18rem;
        padding: 0 0.16rem;
        .search_img{
          width: 0.16rem;
          margin-right: 0.05rem;
        }
        .search_tx{
          width: 2.5rem;
          line-height: 0.32rem;
          font-size: 0.12rem;
          color: white;
          &::placeholder{
            color: white;
          }
        }
      }
    }
    .list_box{
      .list_item{
        display: flex;
        align-items: center;
        .td{

        }
        .tx1{
          width: 0.4rem;
          text-align: center;
        }
        .tx2{
          width: 1.1rem;
          margin: 0 0.15rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .tx3{
          width: 0.8rem;
        }
        .tx4{
          width: 1.2rem;
        }
      }
      .list_body{
        margin-bottom: 0.28rem;
        .tx1{
          font-size: 0.14rem;
          color: white;
          font-weight: bold;
          line-height: 0.26rem;
        }
        .icon_rank_0{
          background: linear-gradient(180deg, #FFC933 0%, #FF9100 100%);
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 100%;
          color: $FFF;
        }
        .icon_rank_1{
          background: linear-gradient(180deg, #AFBEE0 0%, #8790C7 100%);
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 100%;
          color: $FFF;
        }
        .icon_rank_2{
          background: linear-gradient(180deg, #D6B589 0%, #B8946A 100%);
          width: 0.28rem;
          height: 0.28rem;
          border-radius: 100%;
          color: $FFF;
        }
        .tx3_img{
          width: 0.28rem;
          height: 0.28rem;
          margin-right: 0.05rem;
        }
        .tx2{
          overflow: hidden;
          text-overflow: ellipsis;
          color: white;
          font-size: 0.13rem;
          .icon_white_arrow_right {
            width: 0.12rem;
            height: 0.12rem;
          }
        }
        .tx3{
          color: white;
          font-size: 0.13rem;
        }
        .tx2_cur{
          color: white;
        }
        .btn{
          font-size: 0.13rem;
          padding: 0.05rem 0.12rem;
          display: inline-block;
          background: linear-gradient(180deg, #FC5C01 0%, #C85301 100%);
          color: $FFF;
          border-radius: 0.16rem;
          min-width: 0.64rem;
          text-align: center;
        }
        .btn_success{
          background: #F89C46;
          color: #E15701;
        }
        .btn_cur{
          background: linear-gradient(180deg, #FC5C01 0%, #C85301 100%);
          color: white;
        }
      }
      .list_head{
        color: white;
        margin-bottom: 0.3rem;
        font-size: 0.12rem;
      }
    }
  }
  .img {
    img {
      max-width: 100%;
    }
  }
  .pagination {
    margin-top: 0.1rem;
    display: flex;
    justify-content: center;
    // position: fixed;
    // bottom: 15px;
    // left: 15px;
    // right: 15px;
    .ivu-page-item-active {
      background:  linear-gradient(180deg, #F59428 0%, #F86A32 100%);
      border: 0;
      a {
        color: white;
      }
    }
    .ivu-page {
      width: 100%;
      text-align: center;
    }
    .ivu-page-prev  {
      float: left;
      background: linear-gradient(180deg, #FC5C01 0%, #C85301 100%);
      a {
        color: white;
      }
    }
    .ivu-page-next {
      float: right;
      background: linear-gradient(180deg, #FC5C01 0%, #C85301 100%);
      a {
        color: white;
      }
    }
    .ivu-page-next a, .ivu-page-prev a {
      font-size: 0.12rem;
      padding: 0.05rem 0.12rem;
    }
    .list_box {
      min-height: 3rem;
    }
    .ivu-page-simple-pager {
      color: white;
    }
    .ivu-page-simple {
      .ivu-page-simple-pager {
        input {
          background: #F89C46;
          border: 0;
          color: white;
          width: 35px;
        }
      }
      
    }
    .btn-gopage {
      font-size: 0.12rem;
      padding: 0.02rem 0.12rem;
      background: linear-gradient(180deg, #FC5C01 0%, #C85301 100%);
      border: 0;
      color: white;
      height: auto;
      margin-left: 0.15rem;
    }
  }
  .c-pagination {
    .btn {
      background: linear-gradient(180deg, #FC5C01 0%, #C85301 100%);
      color: white;
      border: 0;
      display: flex;
      place-items: center;
      justify-content: center;
      padding: 0 0.1rem 0 0.05rem;
      .icon_white_arrow_right {
        width: 0.1rem;
        height: 0.1rem;
      }
    }
  }
}

</style>

