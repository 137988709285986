<template>
  <article class="h5_loan_wallet_list_view">
    <article class="wallet_list_main">
      <section class="left_box">
        <div class="item" v-for="(list, index) in resData" :key="index" @click="tabFn(index)">
          <i class="h5_icon " :class="curIndex == index ? chainObj[list.chain_type].icon + 'yes' : chainObj[list.chain_type].icon + 'no'"></i>
        </div>

      </section>
      <section class="right_box">
        <p class="" v-if="curChainType">
          <span class="coin_symbol_tx">{{chainObj[curChainType].chain_name}}</span>
          <span class="coin_name_tx">{{ chainObj[curChainType].name }}</span>
        </p>
        <div class="item_main" :class="chainObj[curChainType].bg" v-for="(list, index) in walletList" :key="index" @click="goRouter('/loan_wallet', {addr: list.wallet_addr})">
          <i class="coin_card h5_icon" :class="chainObj[curChainType].bgCard"></i>
          <div class="item_box d_flex_space">
            <div class="l_box">
              <p class="tx1">{{chainObj[curChainType].chain_name}}~{{ $t('multi_signature') }}{{index + 1}}</p>
              <p class="tx2">{{substr(list.wallet_addr, 0, 10)}}...{{substr(list.wallet_addr, -10)}}</p>
            </div>
            <i class="h5_icon icon_white_check_no" :class="list.wallet_addr == $route.query.addr ? 'icon_white_check_yes' : ''"></i>
          </div>

        </div>

      </section>
    </article>

  </article>
</template>

<script>

export default {
  name: "loan_wallet_list",
  data () {
    return {
      resData: [],
      walletList: [],
      curIndex: 0,
      curChainType: '',
      chainObj: {
        'tron': {
          chain_name: 'TRX',
          name: 'TRON',
          icon: 'icon_trx_',
          bg: 'bg_14',
          bgCard: 'icon_tron_card',
        },
        'bsc': {
          chain_name: 'BSC',
          name: 'Binance Smart Chain',
          icon: 'icon_bsc_',
          bg: 'bg_12',
          bgCard: 'icon_bsc_card',
        },
        'eth': {
          chain_name: 'ETH',
          name: 'Ethereum',
          icon: 'icon_eth_',
          bg: 'bg_16',
          bgCard: 'icon_eth_card',
        }
      }
    }
  },
  created () {
    this.resInit()
  },

  mounted () {

  },

  methods: {
    tabFn (index) {
      this.curIndex = index
      this.curChainType = this.resData[index].chain_type
      this.walletList = this.resData[index].wallet_list ? this.resData[index].wallet_list : []
    },
    resInit () {

      let req = {

      }
      this.$ajax(`${this.Utils.URL.goAPPURL}borrow/wallet_chain_type_list`, req, 'get')
          .then(res => {
            this.resData = res.list ? res.list : []
            if (this.resData.length) {
              this.curChainType = this.resData[0].chain_type
              this.walletList = this.resData[0].wallet_list ? this.resData[0].wallet_list : []
              if (this.$route.query.addr) {
                this.resData.forEach((chain, index) => {
                  chain.wallet_list.forEach(val => {
                    if (this.$route.query.addr == val.wallet_addr) {
                      this.curIndex = index
                      this.curChainType = chain.chain_type
                      this.walletList = chain.wallet_list
                    }
                  })
                })
              }
            }

          })
          .catch(res => {
            this.messageFn(res.msg)
          })
    }
  }
}
</script>

<style lang="scss">
.h5_loan_wallet_list_view{
  min-height: 100vh;
  .wallet_list_main{
    display: flex;
  }
  .left_box{
    min-height: 100vh;
    border-right: 1px solid $BR2;
    .item{
      padding: 0.16rem;
    }
  }
  .right_box{
    width: 100%;
    padding: 0.2rem 0.16rem;
    .coin_symbol_tx{
      font-size: 0.16rem;
      color: $T6;
      margin-right: 0.1rem;
    }
    .coin_name_tx{
      font-size: 0.12rem;
      color: $T7;
    }
    .item_main{
      position: relative;
      padding: 0.12rem 0.16rem;
      margin-top: 0.16rem;
      border-radius: 0.06rem;
      .coin_card{
        position: absolute;
        z-index: 1;
        right: 0;
        top: 0;
      }
      .item_box{
        position: relative;
        z-index: 2;
        color: $FFF;

      }
      .tx1{
        font-size: 0.16rem;
      }
      .tx2{
        font-size: 0.12rem;
      }
    }
    .bg_16{
      background: $T16;
    }
    .bg_14{
      background: $T14;
    }
    .bg_12{
      background: $T12;
    }

  }
}

</style>