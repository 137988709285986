
let app = 'appfast.'
let website = 'website.'
let www = 'www.'
let dapp = 'dapp.'

if (process.env.NODE_ENV == 'production') {
  website = 'website.'
  app = 'app.'
  www = 'www.'
  dapp = 'dapp.'

}

let goUrl = window.location.origin.replace(/\w+\./, app)
let h5Url = window.location.origin.replace(/\w+\./, app)
let websiteUrl = window.location.origin.replace(/\w+\./, website)
let dappUrl = window.location.origin.replace(/\w+\./, dapp)
let wsUrl = window.location.host.replace(/\w+\./, app)
let wwwUrl = window.location.origin.replace(/\w+\./, www)



if (process.env.NODE_ENV == 'production') {
  goUrl = window.location.origin
  wsUrl = window.location.host
  if (!(goUrl.indexOf('pette') > 0) && !(goUrl.indexOf('uatfi') > 0)) {
    wwwUrl = 'https://www.scfchain.io'
    websiteUrl = 'https://website.scfchain.io'
    h5Url = 'https://appfast.scfchain.io'
    // dappUrl = 'https://dapp.scfchain.io'
    dappUrl = 'https://dapp.jhgsddev.com'
  }
}

export default process.env.NODE_ENV === 'production' ? {
  goAPPURL: goUrl + '/app_api/',
  wsURL: 'wss://' + wsUrl + '/app_api/',
  h5URL: websiteUrl,
  wwwUrl: wwwUrl,
  dappUrl: dappUrl,
  imgURL: websiteUrl + '/img_api/',
  

} : {
  goAPPURL: 'https://app.jhgsddev.com/app_api/',
  wsURL: 'wss://app.jhgsddev.com/',
  h5URL: 'https://app.jhgsddev.com',
  wwwUrl: 'https://app.jhgsddev.com',
  dappUrl: 'http://192.168.1.134:8186',
  imgURL: 'https://app.jhgsddev.com/img_api/',
}

